import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { CanBeRefundApiModel } from '../model/api-model/sales-document/can-be-refund.api.model';
import { SalesDocumentPaymentApiModel } from '../model/api-model/sales-document/sales-document-payment.api.model';
import { RefundApiModel } from '../model/api-model/sales-document/refund.api.model';
import { CanBeRefundedRequest } from '../model/request/order-refund.request.models';
import { RefundTransactionRequestModel } from '../model/request/refund-transaction-payment.request.model';
import { CanBeRefundedResponse } from '../model/response/order-refund.response.models';
import { RefundTransactionPaymentResponse, RefundTransactionResponseModel } from '../model/response/refund-transaction.response.model';
import { UserHistoryResponseModel } from '../model/response/user-history.response.model';
import { UserHistoryModel } from '../model/user-history.model';
import { SalesDocumentApiModel } from '../model/api-model/sales-document/sales-document.api.model';

@Injectable({
  providedIn: 'root',
})
export class SalesDocumentHttpService {
  constructor(private http: HttpClient) {}

  getSalesDocument(cinemaId: string, orderId: string): Observable<UserHistoryModel> {
    const params = new HttpParams();
    params.append('cinemaId', cinemaId);
    params.append('orderId', orderId);
    return this.http.get(`/user/history?cinemaId=${cinemaId}&orderId=${orderId}&externalId=null`).pipe(
      map((result) => plainToInstance(UserHistoryResponseModel, result as Object)),
      map((result) => plainToInstance(UserHistoryModel, instanceToPlain(result) as Object))
    );
    /*    .pipe(
          mergeMap(order => {
            const requestModel = [];
            order.items.forEach(item => {
              requestModel.push({id: item.id, quantity: 1});
            })
            return this.canBeRefunded(cinemaId, orderId, requestModel).pipe(
              map(res => {
                return order;
              })
            )
          })
        );*/
  }

  getSalesDocumentById(cinemaId: string, salesDocumentId: string): Observable<SalesDocumentApiModel> {
    return this.http.get<SalesDocumentApiModel>(`/cinema/${cinemaId}/salesDocument/${salesDocumentId}`);
  }

  getSalesDocumentPayment(cinemaId: string, salesDocumentId: string): Observable<RefundTransactionPaymentResponse[]> {
    return this.http
      .get<RefundTransactionPaymentResponse[]>(`/cinema/${cinemaId}/salesDocument/${salesDocumentId}/payment`)
      .pipe(map((result) => plainToInstance(RefundTransactionPaymentResponse, result as Object[])));
  }

  getSalesDocumentPaymentViaApiModel(cinemaId: string, salesDocumentId: string): Observable<SalesDocumentPaymentApiModel[]> {
    return this.http
      .get<SalesDocumentPaymentApiModel[]>(`/cinema/${cinemaId}/salesDocument/${salesDocumentId}/payment`)
      .pipe(map((result) => plainToInstance(SalesDocumentPaymentApiModel, result as Object[])));
  }

  canBeRefunded(cinemaId: string, request: CanBeRefundedRequest): Observable<CanBeRefundedResponse> {
    return this.http
      .post(`/cinema/${cinemaId}/salesDocument/canBeRefunded`, request ? instanceToPlain(request) : {})
      .pipe(map((res) => plainToInstance(CanBeRefundedResponse, res as object)));
  }

  canBeRefundedViaApiModel(cinemaId: string, request: CanBeRefundedRequest): Observable<CanBeRefundApiModel> {
    return this.http
      .post(`/cinema/${cinemaId}/salesDocument/canBeRefunded`, request ? instanceToPlain(request) : {})
      .pipe(map((res) => plainToInstance(CanBeRefundApiModel, res as object)));
  }

  refund(cinemaId: string, salesDocumentId: string, request: RefundTransactionRequestModel): Observable<RefundTransactionResponseModel> {
    return this.http
      .post(`/cinema/${cinemaId}/salesDocument/${salesDocumentId}/refund`, request)
      .pipe(map((res) => plainToInstance(RefundTransactionResponseModel, res as object)));
  }

  postSalesDocumentRefund(cinemaId: string, salesDocumentId: string, request: RefundTransactionRequestModel): Observable<RefundApiModel> {
    return this.http
      .post(`/cinema/${cinemaId}/salesDocument/${salesDocumentId}/refund`, request)
      .pipe(map((res) => plainToInstance(RefundApiModel, res as object)));
  }
}
