import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { ScreenPageComponent } from 'libs/shared/src/lib/page/screen/screen.component';

@Component({
  selector: 'app-page-screen',
  templateUrl: './screen.component.html',
})
export class CustomScreenPageComponent extends ScreenPageComponent {
  @ViewChild('content') contentView: ElementRef;
  @ViewChild('auditorium') auditoriumView: ElementRef;
  @ViewChild('pinch') pinchView: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.scaleAuditorium();
  }

  ngAfterViewChecked(): void {
    this.scaleAuditorium();
  }

  scaleAuditorium() {
    if (!this.auditoriumView || !this.contentView) {
      return;
    }

    let scale = 1;
    let offset = 0;
    let wOffset = 50;

    if (this.pinchView.properties.element.offsetHeight > this.contentView.nativeElement.offsetHeight) {
      scale = this.contentView.nativeElement.offsetHeight / this.auditoriumView.nativeElement.offsetHeight;
      offset = 15;
    } else if (this.pinchView.properties.element.offsetWidth + wOffset >= this.contentView.nativeElement.offsetWidth) {
      scale = this.contentView.nativeElement.offsetWidth / this.auditoriumView.nativeElement.offsetWidth;
      offset = 5;
    }

    scale = (scale > 1 ? 1 : scale) * 100 - offset;

    // console.log({
    //   // pinchViewOffsetHeight: this.pinchView.properties.element.offsetHeight,
    //   // contentViewOffsetHeight: this.contentView.nativeElement.offsetHeight,
    //   // auditoriumViewOffsetHeight: this.auditoriumView.nativeElement.offsetHeight,
    //   pinchViewOffsetWidth: this.pinchView.properties.element.offsetWidth,
    //   contentViewOffsetWidth: this.contentView.nativeElement.offsetWidth,
    //   auditoriumViewOffsetWidth: this.auditoriumView.nativeElement.offsetWidth,
    //   scale,
    // });

    this.auditoriumView.nativeElement.setAttribute('style', `transform: scale(${scale}%); max-width: none; max-height: none;`);

    if (this.pinchView && MobileDeviceUtils.isMobile()) {
      this.pinchView.properties.element.style.height =
        Math.max(this.auditoriumView.nativeElement.offsetHeight * (scale / 100), this.contentView.nativeElement.offsetWidth) + 'px';
    }
  }
}
