import {Expose} from 'class-transformer';

export class TicketExtraFeeApiModel {
  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  isOptional: boolean;
}
