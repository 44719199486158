import { DateTime } from 'luxon';

export class MovieCopyRequestModel {
  constructor(public cinema?: string, public dateTimeFrom?: DateTime, public dateTimeTo?: DateTime) {
    if (!dateTimeTo) {
      dateTimeTo = dateTimeFrom;
    }
  }
  public get isoDateTimeFrom() {
    return this.dateTimeFrom.toISO({ includeOffset: false });
  }
  public get isoDateTimeTo() {
    return this.dateTimeTo.toISO({ includeOffset: false });
  }
}
