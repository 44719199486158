import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';

@Component({
  template: '',
})
export class CustomLoyaltyInputComponent implements OnInit, OnDestroy {
  order: OrderViewModel;
  value: string;
  errors: string[];

  @Input() formLocked = false;

  @Output() onSubmit = new EventEmitter<string>();

  constructor(protected loadingService: LoadingService, protected orderStateService: OrderStateService) {}

  ngOnInit(): void {
    this.orderStateService.state$.subscribe((order) => (this.order = order));
  }

  ngOnDestroy(): void {
    this.clear();
  }

  onChange(event: string): void {
    this.value = event;
  }

  clear() {
    this.value = null;
    this.errors = [];
  }
}
