import { ScreeningAvailabilityStatus } from "../../../enum/screening-availability-status.enum";
import { ScreeningAvailabilityStrategyInterface } from "./screening-availability.strategy.interface";

/**
 * Determines if screening is available for reservation
 */
export class ScreeningReservationAvailability implements ScreeningAvailabilityStrategyInterface {
  public isAvailable(availabilityStatus: number): boolean {
    availabilityStatus = Number.parseInt(availabilityStatus as any);

    return availabilityStatus === ScreeningAvailabilityStatus.ForReservation ||
      availabilityStatus === ScreeningAvailabilityStatus.ForSaleAndReservation ||
      availabilityStatus === ScreeningAvailabilityStatus.ForPreview;
  }
}
