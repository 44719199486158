import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { CinemaHttpService } from '../http/cinema.http.service';
import { CmsHelper } from '../helper/cms.helper';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { WordpressDataProvider } from './wordpress.data-provider';

@Injectable({
  providedIn: 'root',
})
export class CinemaDataProvider {
  constructor(private httpService: CinemaHttpService, private wordpressDataProvider: WordpressDataProvider, private cmsHelper: CmsHelper) {}

  public getCinemas() {
    if (this.cmsHelper.canUseCms) {
      return this.wordpressDataProvider.getCinemas();
    }

    return this.httpService.getCinemas().pipe(map((models: CinemaApiModel[]) => models.map((model: CinemaApiModel) => new CinemaViewModel(model))));
  }

  public getCinemaById(id: string): Observable<CinemaViewModel> {
    if (this.cmsHelper.canUseCms) {
      return this.wordpressDataProvider.getCinemaById(id);
    }

    return this.httpService.getCinemaById(id).pipe(
      map((res) => plainToInstance(CinemaApiModel, res as Object, { strategy: 'excludeAll' })),
      map((res) => new CinemaViewModel(res))
    );
  }
}
