<div class="card">
  <div class="row  align-items-center">
    <div class="col-12 col-sm-12 text-sm-right text-center">

    </div>
    <div class="col-12  col-sm-12 text-center">
      <strong>{{'screen.ticketcountselector.tickets' | translate}}: {{seatIds.length}}</strong>
      <!--<span class="btn" (click)="onDecrementClick()"><i class="fa  fa-minus"></i></span>-->
      <!--<span class="count"></span>-->
      <!--<span class="btn" (click)="onIncrementClick()"><i class="fa fa-plus"></i></span>-->
    </div>
    <div class="col-12 col-sm-4">
    </div>
  </div>
</div>

