import {Expose, Transform, Type} from 'class-transformer';
import {DateTime} from 'luxon';
import { UserCardTypeTemplateApiModel } from './user-card-type-template.api.model';
import { UserCardTypeTypeApiModel } from './user-card-type-type.api.model';

export class UserCardTypeApiModel {
  @Expose()
  id: string;

  @Expose()
  type: number;

  @Expose()
  typeName: string;

  @Expose()
  description: string;

  @Expose()
  graphic: string;

  @Expose()
  multipleUse: boolean;

  @Expose()
  rechargeable: boolean;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  expirtationDate: DateTime;

  @Expose()
  autoNumber: boolean;

  @Expose()
  notLessThan: number;

  @Expose()
  maximumBalance: number;

  @Expose()
  @Type(() => UserCardTypeTemplateApiModel)
  graphicTemplateList: UserCardTypeTemplateApiModel[];

  @Expose()
  @Type(() => UserCardTypeTypeApiModel)
  types: UserCardTypeTypeApiModel[];
}
