import {Expose, Transform, Type} from 'class-transformer';
import {DateTime} from 'luxon';
import { UserHistoryFbItemModifierModel } from './user-history-fb-item-modifier.model';
import { UserMovieModel } from './user-movie.model';
import { UserMoviePrintModel } from './user-movie-print.model';
import { UserScreeningModel } from './user-screening.model';
import { UserTicketModel } from './user-ticket.model';

export class UserHistoryModel {
  orders: UserHistoryOrderModel[];
  screenings: UserScreeningModel[];
  tickets: UserTicketModel[];
  movies: UserMovieModel[];
  moviePrints: UserMoviePrintModel[];
  seats: UserSeatModel[];
  fbitems: UserHistoryFbItemsModel[];
  screens: UserHistoryScreensModel[];
  voucherItems: UserHistoryVoucherItemsModel[];
}

export class UserHistoryScreensModel {
  @Expose()
  id: string;
  @Expose()
  feature: string;
  @Expose()
  name: string;
  @Expose()
  number: number;
  @Expose()
  type: Date;
}

export class UserHistoryOrderModel {
  @Expose()
  id: string;
  @Expose()
  orderNumber: string;
  @Expose()
  name: string;
  @Expose()
  saleDate: Date;
  @Expose()
  totalValue: number;
  @Expose()
  cinemaId: string;
  @Expose()
  payments: any[];
  @Expose()
  screenings: UserScreeningModel[];
}

export class UserHistoryFbItemsModel {
  @Expose()
  additionalOrderId: string | null;

  @Expose()
  additionalOrderDate: string | null;

  @Expose()
  id: string;

  @Expose()
  itemId: string;

  @Expose()
  name: string;

  @Expose()
  orderId: string;

  @Expose()
  @Type(() => DateTime)
  @Transform(({value}) => value ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  pickupTime: DateTime;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  modifiers: UserHistoryFbItemModifierModel[];

  @Expose()
  subItems: UserHistoryFbItemSubArticleModel[];

  @Expose()
  voucherNumber: string;
  @Expose()
  voucherName: string;
  @Expose()
  voucherBatchName: string;
}

export class UserHistoryVoucherItemsModel {
  @Expose()
  orderId: string;

  @Expose()
  id: string;

  @Expose()
  itemId: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  additionalOrderId: string | null;

  @Expose()
  additionalOrderDate: string | null;
}

export class UserHistoryFbItemSubArticleModel {
  @Expose()
  subItemId: string;

  @Expose()
  subItem: string;

  @Expose()
  subItemQuantity: number;

  @Expose()
  subItemTaxRate: number;

  @Expose()
  subItemPrice: number;
}

export class UserPaymentModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
}

export class UserExtraFeeModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
  @Expose()
  price: number;
  @Expose()
  quantity: number;
  @Expose()
  isOptional: boolean;
}

export class UserSeatModel {
  @Expose()
  id: string;
  @Expose()
  symbol: string;
  @Expose()
  row: string;
  @Expose()
  seat: string;
}

export class UserMovieRattingModel {
  @Expose()
  symbol: string;
  @Expose()
  value: string;
  @Expose()
  description: string;
}

export class UserMovieGenreModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
}

export class UserScreenModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
  @Expose()
  type: string;
  @Expose()
  feature: string;
  @Expose()
  number: number;
}

export class UserMovieTag {
  @Expose()
  orderNo: number;
  @Expose()
  symbol: string;
  @Expose()
  description: string;
}


