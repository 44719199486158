import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseCardTypeAddressRequestModel } from 'libs/core/src/lib/model/request/purchase-card-type-address.request.model';
import { Observable, of } from 'rxjs';
import { GiftCardPurchaseComponent } from '../gift-card-purchase/gift-card-purchase.component';

enum Steps {
  Initial = 0,
  Address = 1,
}

@Component({
  selector: 'app-gift-card-physical',
  templateUrl: './gift-card-physical.component.html',
})
export class GiftCardPhysicalComponent extends GiftCardPurchaseComponent implements OnInit {
  steps = Steps;
  step = 0;
  envelopeItemPrice = 4;
  envelopeItemId = '';
  deliveryAddress: FormGroup;
  formSubmitAttempt: boolean;

  beforeAddToCart(): Observable<any> {
    this.formSubmitAttempt = true;
    return of(this.deliveryAddress.valid);
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.deliveryAddress = new FormGroup({
      lastName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      additionalAddress: new FormControl('', []),
      code: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
  }

  public reset(): void {
    super.reset();
    this.step = Steps.Initial;
    if (this.deliveryAddress) {
      this.deliveryAddress.reset();
    }
    this.formSubmitAttempt = false;
  }

  getAddress(): any {
    const address = new PurchaseCardTypeAddressRequestModel();
    address.firstName = this.deliveryAddress.get('firstName').value;
    address.lastName = this.deliveryAddress.get('lastName').value;
    address.city = this.deliveryAddress.get('city').value;
    address.street = this.deliveryAddress.get('street').value;
    address.streetNumber = this.deliveryAddress.get('number').value;
    address.postalCode = this.deliveryAddress.get('code').value;
    address.address1 = this.deliveryAddress.get('additionalAddress').value;

    return address;
  }
}
