import { AbstractViewModel } from "../../abstract.view.model";
import { UserDeletionReasonApiModel } from "../../api-model/user/user-deletion-reason.api.model";

export class UserDeletionReasonViewModel extends AbstractViewModel<UserDeletionReasonApiModel> {
  id: string;
  name: string;

  constructor(protected apiModel: UserDeletionReasonApiModel = new UserDeletionReasonApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
  }

  toApiModel(): UserDeletionReasonApiModel {
    return undefined;
  }

}
