import { AbstractViewModel } from '../../abstract.view.model';
import { RegionApiModel } from '../../api-model/region/region.api.model';
import { CinemaViewModel } from '../cinema/cinema.view.model';

export class RegionViewModel extends AbstractViewModel<RegionApiModel> {
  id: string;
  code: string;
  region: string;
  cinemas: CinemaViewModel[] = [];

  defaultCinemaId: string;
  isActive = false;

  public webUrl: string = null;
  public mobileUrl: string = null;

  constructor(protected apiModel: RegionApiModel = new RegionApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.code = this.apiModel.code;
    this.region = this.apiModel.region;
    this.cinemas = this.apiModel.cinemas.map((cinema) => {
      const c = new CinemaViewModel(cinema);
      c.regionId = this.id;
      return c;
    });
  }

  toApiModel(): RegionApiModel {
    return undefined;
  }

  get name(): string {
    return this.region;
  }

  get title(): string {
    return this.region;
  }

  public get cinemaIds(): string[] {
    return this.cinemas.map((cinema) => cinema.id);
  }

  public get defaultOrFirstCinemaId(): string {
    return this.defaultCinemaId ?? (this.cinemaIds && this.cinemaIds.length ? this.cinemaIds[0] : null);
  }
}
