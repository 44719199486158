import { IScreenModel } from '../interfaces';
import { SeatApiModel } from '../model/api-model/screen/seat/seat.api.model';
import { SeatViewModel } from '../model/view-model/screen/seat/seat.view.model';

export function seatInGroupComparer(rowSort: number): (a: SeatViewModel, b: SeatViewModel) => number {
  return (a, b) => {
    return rowSort === 1
      ? isNaN(Number(a.symbol))
        ? a.symbol.localeCompare(b.symbol)
        : Number(a.symbol) - Number(b.symbol)
      : isNaN(Number(a.symbol))
      ? b.symbol.localeCompare(a.symbol)
      : Number(b.symbol) - Number(a.symbol);
  };
}

export function getCoordinates(screenModel: IScreenModel, element: SeatApiModel) {
  const row = screenModel.rows.find((x) => x.id === element.rowId);
  const col = screenModel.cols.find((x) => x.id === element.colId);
  return {
    x: col.coordinate,
    y: row.coordinate,
    row,
    col,
  };
}
