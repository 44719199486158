import {Component, Inject, OnInit} from '@angular/core';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  selector: 'app-footer',
  template: ''
})
export class FooterComponent implements OnInit {  
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
  }

  ngOnInit() {
  }
}