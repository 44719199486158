<app-popup [isVisible]="true">
  <h4 header> <span class="font-weight-normal">{{ 'refund.modal.fail_first_line' | translate }}</span></h4>
  <div class="row pb-4">
    <div class="col-12">

      <div class="question">
        <span class="font-weight-normal">{{ 'refund.modal.fail_second_line' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button type="button" class="btn btn-next" (click)="close(true)">
        {{ 'refund.modal.button.back' | translate }}
      </button>
      <button type="button" class="btn btn-next" (click)="close()">
        {{ 'refund.modal.button.try_again' | translate }}
      </button>
    </div>
  </div>
</app-popup>