import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'addLang',
})
export class AddLanguagePipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT_TOKEN) private env: any, private translationService: TranslateService) {}

  transform(value: string, type: 'cms-route' | 'path' = 'cms-route'): string {
    if (type === 'cms-route') {
      value = (this.env?.cms?.routes ? this.env.cms.routes[value] : null) ?? value;
    }

    const formattedUrl = value.startsWith('/') ? value : `/${value}`;
    return `/${this.translationService.currentLang}${formattedUrl}`;
  }
}
