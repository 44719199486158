import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { CinemaDataProvider } from '../data-provider/cinema.data-provider';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { StateService } from '../state/state.service';
import { DateHelper } from '../date/date.helper';
import { BehaviorSubject, filter, finalize, of, switchMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  cinemas: CinemaViewModel[];

  constructor(protected cinemaDataProvider: CinemaDataProvider, protected stateService: StateService) {
    this.cinemaDataProvider
      .getCinemas()
      .pipe(
        tap((cinemas) => (this.cinemas = cinemas)),
        finalize(() => this.initialDataFetched$.next(true))
      )
      .subscribe();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  public isPastOrEqual(dt?: DateTime) {
    return DateHelper.isPastOrEqual(dt);
  }

  public isPast(dt?: DateTime): boolean {
    return DateHelper.isPast(dt);
  }

  public cinemaLocal(cinemaId?: string) {
    return this.initialDataFetched().pipe(switchMap(() => of(DateTime.now().setZone(this.getCinema(cinemaId)?.timezone))));
  }

  public local(cinemaId: string, now: DateTime = DateTime.now()): DateTime {
    return now.setZone(this.getCinema(cinemaId)?.timezone, { keepLocalTime: true });
  }

  public convertToCinemaTimeZone(value: DateTime | string, cinemaId?: string): DateTime {
    let dt: DateTime = value instanceof DateTime ? value : DateTime.fromISO(value);
    return dt.setZone(this.getCinema(cinemaId)?.timezone);
  }

  public getCinema(cinemaId?: string) {
    return this.cinemas?.find((f) => f.id === cinemaId);
  }
}
