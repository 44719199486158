import {Expose, Type} from 'class-transformer';
import { EventApiModel } from '../event/event.api.model';
import { MovieApiModel } from '../movie/movie.api.model';

export class UserWatchlistApiModel {
  @Expose()
  @Type(() => MovieApiModel)
  movies: MovieApiModel[];

  @Expose()
  @Type(() => EventApiModel)
  events: EventApiModel[];
}
