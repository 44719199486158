import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { LanguageDataProvider } from 'libs/core/src/lib/data-provider/language.data-provider';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { CoreRequestHandlerService } from 'libs/core/src/lib/service/request-handler/core/core-request-handler.service';
import { CoreComponent } from 'libs/shared/src/lib/component/core.component';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
})
export class CustomCoreComponent extends CoreComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected requestHandler: CoreRequestHandlerService,
    protected cinemaDataProvider: CinemaDataProvider,
    protected languageDataProvider: LanguageDataProvider,
    protected translate: TranslateService,
    protected messageService: MessageService,
    protected bsLocaleService: BsLocaleService,
    protected loadingService: LoadingService,
    protected stateService: StateService
  ) {
    super(
      environment,
      route,
      router,
      requestHandler,
      cinemaDataProvider,
      languageDataProvider,
      translate,
      messageService,
      bsLocaleService,
      loadingService,
      stateService
    );

    this.loadingService.showLoader(this.loaderEnum.MAIN);

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/error')) {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      }
    });
  }
}
