import { AbstractViewModel } from "../../abstract.view.model";
import { CardTypeValueApiModel } from "../../api-model/card/card-type-value.api.model";

export class CardTypeValueViewModel extends AbstractViewModel<CardTypeValueApiModel> {
  public paymentValue: number = null;
  public bonusValue: number = null;

  constructor(
    protected apiModel: CardTypeValueApiModel = new CardTypeValueApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.paymentValue = this.apiModel.paymentValue;
    this.bonusValue = this.apiModel.bonusValue;
  }

  toApiModel(): CardTypeValueApiModel {
    return undefined;
  }
}
