import cloneDeep from 'lodash-es/cloneDeep';
import remove from 'lodash-es/remove';
import uniqBy from 'lodash-es/uniqBy';
import { IScreenModel } from '../../interfaces';
import { ScreenApiModel } from '../../model/api-model/screen/screen.api.model';
import { SeatApiModel } from '../../model/api-model/screen/seat/seat.api.model';
import { ScreenColApiModel } from '../../model/api-model/screen/screen-col.api.model';
import { ScreenRowApiModel } from '../../model/api-model/screen/screen-row.api.model';
import { SeatGroupViewModel } from '../../model/view-model/screen/seat/seat-group.view.model';
import { ScreenElementViewModel } from '../../model/view-model/screen/screen-element.view.model';
import { SeatViewModel } from '../../model/view-model/screen/seat/seat.view.model';
import { ScreenViewModel } from '../../model/view-model/screen/screen.view.model';
import { OccupiedStatus } from '../../enum/occupied-status.enum';
import { SeatGroupSalesMode } from '../../enum/seat-group-sales-mode.enum';
import { getCoordinates, seatInGroupComparer } from '../../function/screen.functions';

export class ClientTransactionScreenHelper {
  public static mapFromScreenModel(model: ScreenApiModel) {
    const screenModel: ScreenApiModel = cloneDeep<ScreenApiModel>(model);
    const mappedRows = screenModel.rows.map((x) => ({ rowNum: x.coordinate, legendSymbol: x.legend }));

    screenModel.groups = uniqBy(screenModel.groups, (x) => x.id);

    const result = new ScreenViewModel();
    result.id = model.id;
    result.pseats = [];
    result.groups = [];
    result.rowSymbols = [];
    result.name = screenModel.name;
    result.feature = screenModel.feature;
    result.number = screenModel.number;
    result.type = screenModel.type;
    result.defaultSeatGroupDescription = screenModel.defaultSeatDescription;
    result.defaultSeatGroupName = screenModel.defaultSeatGroupName;
    result.defaultSeatGroupColor = screenModel.defaultSeatGroupColor;
    result.screenGroupId = screenModel.screenGroupId;

    if (typeof result.feature === 'string') {
      result.feature = result.feature.split(',').join(', ');
    }

    result.groups = screenModel.groups?.map((el) =>
      Object.assign(new SeatGroupViewModel(), {
        id: el.id,
        name: el.name,
        type: el.type,
        description: el.description,
        displayMode: el.displayMode,
        salesMode: el.salesMode,
        seatIds: el.seatIds,
        color: el.color,
      })
    );

    result.screenElements = screenModel.screenElements?.map((el) =>
      Object.assign(new ScreenElementViewModel(), { id: el.id, name: el.name, seatIds: el.seatIds })
    );

    //seats builder
    screenModel.rows.forEach((_) => {
      result.pseats.push(screenModel.cols.map((_) => new SeatViewModel()));
    });
    screenModel.seats.forEach((element) => {
      const coords = getCoordinates(screenModel, element);
      result.pseats[coords.y][coords.x] = this.createSeatModel(screenModel, element, coords.row, coords.col);
    });

    for (let y = 0; y < result.pseats.length; y++) {
      result.rowSymbols.push(mappedRows.find((x) => x.rowNum === y).legendSymbol);

      const row = result.pseats[y];
      const symbolOfFirstSeat = row.map((x) => x.symbol).find((s) => s.length > 0);
      const rowSort = isNaN(Number(symbolOfFirstSeat)) ? 1 : Number(symbolOfFirstSeat) > 1 ? -1 : 1;
      for (let x = 0; x < row.length; x++) {
        const seat = result.pseats[y][x];
        const seatGroups = result.groups.filter((group) => group.seatIds.includes(seat.id));
        const salesTogether = seatGroups.some((group) => group.salesMode == SeatGroupSalesMode.Together);

        //preparing multiseats
        if (seatGroups && salesTogether) {
          const seatsGroup = row.filter((x) => seatGroups.some((g) => g.seatIds.includes(x.id)));
          const seatsGroupSorted = seatsGroup.sort(seatInGroupComparer(rowSort));
          seatsGroupSorted[0].groupConnectedSeats = [];

          seatsGroup.forEach((seatInGroup) => {
            if (seatInGroup.id != seatsGroupSorted[0].id) {
              const element = screenModel.seats.find((x) => x.id === seatInGroup.id);
              const coords = getCoordinates(screenModel, element);
              const s = this.createSeatModel(screenModel, element, coords.row, coords.col);

              seatsGroupSorted[0].groupConnectedSeats.push(s);
              seatInGroup.id = undefined;
            }
          });
        }

        remove(row, (x) => x.id === undefined);
      }
    }

    return result;
  }

  private static createSeatModel(screenModel: IScreenModel, element: SeatApiModel, row: ScreenRowApiModel, col: ScreenColApiModel): SeatViewModel {
    const seat = new SeatViewModel();
    seat.occupied = OccupiedStatus.Free;
    seat.id = element.id;
    seat.isPlaceholder = element.kind == '1';
    seat.wheelchairSeat = element.wheelchairSeat;

    seat.groupId = element.groupId;
    seat.symbol = element.symbol;
    seat.rowNumber = row.coordinate.toString();
    seat.rowSymbol = row.legend;
    seat.colNumber = col.coordinate.toString();
    seat.defaultGroupDescription = screenModel.defaultSeatDescription;
    seat.defaultSeatGroupName = screenModel.defaultSeatGroupName;
    seat.defaultSeatGroupColor = screenModel.defaultSeatGroupColor;
    seat.kind = element.kind;

    if (seat.wheelchairSeat) {
      seat.groupTypes = ['wheelchair'];
      seat.defaultSeatGroupName = 'wheelchair';
      console.log('wheelchair is here');
    }

    const groups: Array<SeatGroupViewModel> = this.findAssignedGroupsForSeat(seat.id, screenModel.groups);
    if (groups.length > 0) {
      seat.groupTypes = groups.map((group: SeatGroupViewModel) => {
        seat.groupColor = group.color;
        return group.type;
      });
      seat.groups = groups;
      seat.groupDescriptionCollection = groups.filter((o) => o.description).map((x) => x.description);
      console.log('seat.groupDescriptionCollection:', seat.groupDescriptionCollection);
    }

    const screenElement = screenModel.screenElements?.find((o) => o.seatIds.map((o) => o.toLowerCase()).indexOf(element.id) > -1);
    if (screenElement) {
      seat.screenElements = [screenElement.name.toLowerCase()];
    }

    return seat;
  }

  private static findAssignedGroupsForSeat(seatId: string, groups: Array<any>): Array<SeatGroupViewModel> {
    const res: Array<SeatGroupViewModel> = new Array<SeatGroupViewModel>();
    for (const group of groups) {
      if (group.seatIds.indexOf(seatId) >= 0) {
        res.push(group);
      }
    }
    return res;
  }
}
