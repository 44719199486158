import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';

@Injectable({
  providedIn: 'root',
})
export class ScreenHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: ScreenHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  getScreens(cinemaId: string, screeningId: string | null = null): Observable<ScreenApiModel[]> {
    return this.http.get<ScreenApiModel[]>(`/cinema/${cinemaId}/screen`, {
      params: {
        screeningId: screeningId,
      },
    });
  }

  @Cacheable({
    cacheBusterObserver: ScreenHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  getScreen(cinemaId: string, screenId: string): Observable<ScreenApiModel> {
    return this.http.get<ScreenApiModel>(`/cinema/${cinemaId}/screen/${screenId}`);
  }
}
