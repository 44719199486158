import { AbstractViewModel } from "../../abstract.view.model";
import { PaymentRefundPaymentApiModel } from "../../api-model/sales-document/payment-refund-payment.api.model";

export class PaymentRefundPaymentViewModel extends AbstractViewModel<PaymentRefundPaymentApiModel> {
  id: string;
  name: string;

  constructor(protected apiModel: PaymentRefundPaymentApiModel = new PaymentRefundPaymentApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
  }

  toApiModel(): PaymentRefundPaymentApiModel {
    return undefined;
  }
}
