import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { SalesDocumentHttpService } from '../http/sales-document.http.service';
import { SalesDocumentViewModel } from '../model/view-model/sales-document/sales-document.view.model';

@Injectable({
  providedIn: 'root'
})
export class SalesDocumentDataProvider {
  constructor(
    private salesDocumentHttpService: SalesDocumentHttpService,
  ) {
  }

  getSalesDocumentById(cinemaId: string, salesDocumentId: string): Observable<SalesDocumentViewModel> {
    return this.salesDocumentHttpService.getSalesDocumentById(cinemaId, salesDocumentId).pipe(
      map(model => new SalesDocumentViewModel(model))
    );
  }
}
