import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';
import { PayuProviderParametersInterface } from './interface/payu-provider-parameters.interface';

@Component({
  selector: 'app-payu-tag-component',
  templateUrl: './payu.component.html',
  styleUrls: [
    './payu.component.scss'
  ]
})
export class PayuComponent extends AbstractTagProviderComponent implements OnInit {

  constructor(protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {

    super(renderer, _document);
  }

  public ngOnInit(): void {

    const version: string|null = (this.parameters as PayuProviderParametersInterface).version;

    if (version === null) {

      return;
    }

    this.addElement(
      this.getOpenPayUScriptElement(version),
      this._document.head
    );

    this.addElement(
      this.getOpenPayUPluginTokenScriptElement(version),
      this._document.head
    );

  }

  protected getOpenPayUScriptElement(version: string): HTMLElement {

    return this.createScriptExternalElement(
      `https://secure.payu.com/res/v2/openpayu-${version}.js`,
      {}
    );

  }

  protected getOpenPayUPluginTokenScriptElement(version: string): HTMLElement {

    return this.createScriptExternalElement(
      `https://secure.payu.com/res/v2/plugin-token-${version}.js`,
      {}
    );

  }
}
