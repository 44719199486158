import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralAdmissionService {
  public myTicketsEventEmitter: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  constructor() {
  }

  /**
   * Marks my tickets
   * @param myTickets
   */
  public markMyTickets(myTickets: Array<string>): void {
    this.myTicketsEventEmitter.next(myTickets);
  }
}
