
export enum PaymentErrorCode {

  /**
   * Code like T6 is invalid. Depends on payment method
   */
  authCodeInvalid = 422,

  /**
   * Buyer's email address is missing
   */
  missedBuyerEmailAddress = 423,

}
