<ng-container *ngIf="vouchers && vouchers.length > 0; else emptyVouchers">
  <span class="mb-2" *ngIf="vouchers?.length > 0">{{'user.user-my-profile.accountItemsDescription' | translate}}</span>
  <app-account-items-vouchers-list [vouchers]="vouchers"
                                   (itemSelected)="useItem($event)"></app-account-items-vouchers-list>
</ng-container>

<ng-template #emptyVouchers>
  <div [smooth-in]="vouchers" class="d-flex flex-column">
    <span>{{'user.rewards.buyNow' | translate}}</span>
    <a href="{{environment.homepageUrl}}">{{'user.upcoming.bookNow' | translate}}</a>
  </div>
</ng-template>