import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { IDeviceSize } from '../../component/size-detector/size-detector.component';

@Injectable()
export class DeviceService {
  get onResize$(): Observable<IDeviceSize> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<IDeviceSize>;

  constructor() {
    this.resizeSubject = new BehaviorSubject(null);
  }

  onResize(device: IDeviceSize) {
    this.resizeSubject.next(device);
  }
}
