import {Injectable} from '@angular/core';
import { DateTime } from 'luxon';
import {BehaviorSubject} from 'rxjs';
import { CountdownStateEnum } from '../model/enum/countdown-state.enum';

@Injectable({
  providedIn: 'root'
})
export class CountdownComponentService {
  private state = new BehaviorSubject<CountdownStateEnum>(null);
  public state$ = this.state.asObservable();
  
  private _isBeaconDisabled = false;
  private _initTime: number;
  private _isRunning = false;

  public constructor() {
  }

  public list() {
    return this.state.value;
  }

  public clear() {
    this.isRunning = false;
    this.state.next(CountdownStateEnum.CLEAR);
  }

  public restart() {
    this.isRunning = true;
    this.state.next(CountdownStateEnum.RESTART);
  }

  public start() {
    this.isRunning = true;
    this.state.next(CountdownStateEnum.START);
  }

  public resume() {
    this.isRunning = true;
    this.state.next(CountdownStateEnum.RESUME);
  }

  public stop() {
    this.isRunning = false;
    this.state.next(CountdownStateEnum.STOP);
  }

  public destroy() {
    this.isRunning = false;
    this.state.next(CountdownStateEnum.DESTROY);
  }

  public hide() {
    this.state.next(CountdownStateEnum.HIDDEN);
  }

  public visible() {
    this.state.next(CountdownStateEnum.VISIBLE);
  }

  public disableBeacon(): void {
    this._isBeaconDisabled = true;
  }

  public enableBeacon(): void {
    this._isBeaconDisabled = false;
  }

  public isBeaconDisabled(): boolean {
    return this._isBeaconDisabled;
  }

  public remove(): void {
  }

  public initTimeBy(dt: DateTime) {
    this.initTime = Math.round((dt.toMillis() - new Date().getTime()) / 1000);
  }

  get initTime(): number {
    return this._initTime;
  }

  set initTime(value: number) {
    this._initTime = value;
  }

  get isRunning(): boolean {
    return this._isRunning;
  }

  set isRunning(value: boolean) {
    this._isRunning = value;
  }
}
