import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private httpClient: HttpClient) {}

  loadScript() {
    const key = this.environment.scripts.apiKeys['googleMapApiKey'];
    if (key) {
      return this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${key}`, 'callback').pipe(
        map(() => true),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      );
    }

    return of(null);
  }
}
