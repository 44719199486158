import { AbstractViewModel } from "../../../abstract.view.model";
import { UserMoviePictureApiModel } from "../../../api-model/user-history/shared/user-movie-picture.api.model";

export class UserMoviePictureViewModel extends AbstractViewModel<UserMoviePictureApiModel> {
  public url1: string = null;
  public url2: string = null;

  constructor(
    protected apiModel: UserMoviePictureApiModel = new UserMoviePictureApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.url1 = this.apiModel.url1;
    this.url2 = this.apiModel.url2;
  }

  toApiModel(): UserMoviePictureApiModel {
    return undefined;
  }
}
