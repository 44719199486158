import { Component, Inject, Input, OnInit } from '@angular/core';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';

@Component({
  selector: 'app-imdb',
  templateUrl: './imdb.component.html',
  styleUrls: ['./imdb.component.scss'],
})
export class ImdbComponent implements OnInit {
  @Input('imdb_id') imdbId: string;
  @Input('imdb_rating') imdbRating: string;
  imdbLink: string = null;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  ngOnInit(): void {
    this.setUrl();
  }

  setUrl() {
    if (this.environment.imdb && this.imdbId) {
      this.imdbLink = this.environment.imdb.url + (this.environment.imdb.url.endsWith('/') ? '' : '/') + this.imdbId;
    }
  }

  goToLink(url: string) {
    if (!url) return;
    window.open(url, '_blank');
  }
}
