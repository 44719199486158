import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { TicketListSortStrategy } from "./ticket-list-sort.strategy";

/**
 * At real sorts tickets without strategy
 */
export class TicketListSortNoneStrategy implements TicketListSortStrategy {
  public sort(ticketList: Array<TicketViewModel>): Array<TicketViewModel> {
    return ticketList.map(x => x);
  }
}
