import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserVoucherTransferApiModel } from '../../../api-model/user-history/voucher-transfer/user-voucher-transfer.api.model';
import { VoucherTypeEnum } from '../voucher/voucher-type.enum';

export class UserVoucherTransferViewModel extends AbstractViewModel<UserVoucherTransferApiModel> {
  public id: string = null;
  public number: string = null;
  public name: string = null;
  public batchName: string = null;
  public pictureUrl: string = null;
  public transferDate: DateTime = null;
  public recipientClientId: string = null;
  public recipientClientEmail: string = null;
  public groupId: string = null;
  public groupName: string = null;

  public type: VoucherTypeEnum;
  
  constructor(
    protected apiModel: UserVoucherTransferApiModel = new UserVoucherTransferApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.number = this.apiModel.number;
    this.name = this.apiModel.name;
    this.batchName = this.apiModel.batchName;
    this.pictureUrl = this.apiModel.pictureUrl;
    this.transferDate = this.apiModel.transferDate;
    this.recipientClientId = this.apiModel.recipientClientId;
    this.recipientClientEmail = this.apiModel.recipientClientEmail;
    this.groupId = this.apiModel.groupId;
    this.groupName = this.apiModel.groupName;
  }

  toApiModel(): UserVoucherTransferApiModel {
    return undefined;
  }
}
