import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { MovieCopyRequestModel } from '../model/request/movie-copy.request.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';

@Injectable({
  providedIn: 'root',
})
export class MovieCopyHttpService {
  constructor(private http: HttpClient) {}

  //via API model
  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getMoviePrints(model: MovieCopyRequestModel): Observable<MoviePrintApiModel[]> {
    const params = model.cinema
      ? { cinemaId: model.cinema, dateTimeFrom: model.isoDateTimeFrom, dateTimeTo: model.isoDateTimeTo }
      : { dateTimeFrom: model.isoDateTimeFrom, dateTimeTo: model.isoDateTimeTo };
    return this.http.get<MoviePrintApiModel[]>(`/movieprint`, { params });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getMoviePrint(id: string): Observable<MoviePrintApiModel> {
    return this.http.get<MoviePrintApiModel>(`/movieprint/${id}`);
  }
}
