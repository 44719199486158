import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CateringAggregationArticleViewModel } from 'libs/core/src/lib/model/catering/catering-aggregation.view.model';
import { OrderSummaryFeeViewModel } from 'libs/core/src/lib/model/page/order/order-summary-fee.view.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { OrderSummaryBuilder } from 'libs/core/src/lib/model/page/order/order-summary.builder';
import { OrderSummaryViewModel } from 'libs/core/src/lib/model/page/order/order-summary.view.model';
import { VoucherViewModel } from 'libs/core/src/lib/model/view-model/voucher/voucher.view.model';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { OrderSummaryArticleCombinationItemViewModel } from 'libs/core/src/lib/model/page/order/order-summary-article-combination-item.view.model';

@Component({
  template: '',
})
export class OrderTicketsSummaryComponent {
  @Output()
  public articleCombinationQuantityChange: EventEmitter<CateringAggregationArticleViewModel> = new EventEmitter<CateringAggregationArticleViewModel>();

  @Input()
  screeningId: string = null;

  @Input()
  public isLoading = false;

  @Input()
  public couldChangeOptionalFees = false;

  @Output()
  public recalculate: EventEmitter<OrderSummaryViewModel> = new EventEmitter<OrderSummaryViewModel>();

  public configOrder: OrderViewModel = null;
  public orderSummary: OrderSummaryViewModel = null;
  public pickUpTime: DateTime;
  public periodBetweenRequestsInScreen = 500;
  private readonly forceOptionalFees: boolean;
  private totalDiscount = 0;
  private _ticketList: Array<TicketViewModel> = null;
  private _voucherTypeList: Array<VoucherTypeModel> = null;
  private _cateringArticleCombinationList: Array<CateringAggregationArticleViewModel> = null;
  private _order: OrderViewModel;

  private debouncer: Subject<void> = new Subject<void>();
  private refreshOrderSummaryDebouncer: Subject<void> = new Subject<void>();

  callCounter = 0;
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected orderStateService: OrderStateService,
    protected totalizerService: TotalizerService,
    protected appService: AppService
  ) {
    this.forceOptionalFees = environment.constants.forceOptionalFees;
    this.periodBetweenRequestsInScreen = environment.screen.periodBetweenRequestsInScreen;
    this.refreshOrderSummaryDebouncer.pipe(debounceTime(100)).subscribe(() => this.refreshOrderSummary());
  }

  public get order() {
    return this._order;
  }

  @Input()
  public set order(order: OrderViewModel) {
    if (order) {
      console.log('Input order:', order);
      this.configOrder = this._order = order;
      this.pickUpTime = order.pickupTime;
    }

    this.refreshOrderSummaryDebouncer.next();
  }

  @Input()
  public set ticketList(ticketList: Array<TicketViewModel>) {
    this._ticketList = ticketList;
    console.log('Input ticketList:', ticketList);
    if (ticketList) {
      this.refreshOrderSummaryDebouncer.next();
    }
  }

  private isFirstTime(): boolean {
    return this.order && this.order.screeningItems?.every((item) => item.ticketPrice === 0);
  }

  @Input()
  public set voucherTypeList(voucherTypeList: Array<VoucherTypeModel>) {
    this._voucherTypeList = voucherTypeList;
    this.refreshOrderSummaryDebouncer.next();
  }

  @Input()
  public set cateringArticleCombinationList(articleCombinationList: Array<CateringAggregationArticleViewModel>) {
    this._cateringArticleCombinationList = articleCombinationList;

    this.refreshOrderSummaryDebouncer.next();
  }

  @Input()
  public set initExtraFeeList(extraFeeIdentifierList: Array<Array<string>>) {
    if (this.orderSummary && extraFeeIdentifierList.length > 0) {
      const availableOptionalExtraFeeList: Array<OrderSummaryFeeViewModel> = this.orderSummary.optionalExtraFees;
      this.configOrder.screeningItems.forEach((item) => {
        item.optionalExtraFees = [];
      });

      for (const extraFeeIdentifierPair of extraFeeIdentifierList) {
        const optionalExtraFeeViewModel: OrderSummaryFeeViewModel = availableOptionalExtraFeeList.find((x) => x.id === extraFeeIdentifierPair[1]);
        if (!optionalExtraFeeViewModel) {
          continue;
        }
        const foundOrderItemModel = this.configOrder.screeningItems.find((item: ScreeningItemViewModel) => {
          return item.ticketId === extraFeeIdentifierPair[0] && !item.optionalExtraFees.includes(optionalExtraFeeViewModel.id);
        });
        if (foundOrderItemModel) {
          foundOrderItemModel.optionalExtraFees = [];
          foundOrderItemModel.optionalExtraFees.push(optionalExtraFeeViewModel.id);
        }
      }
    }
  }

  private _appliedVoucherList: VoucherViewModel[] = [];
  public get appliedVoucherList() {
    return this._appliedVoucherList;
  }

  public set appliedVoucherList(appliedVoucherList: VoucherViewModel[]) {
    this._appliedVoucherList = appliedVoucherList;
    if (appliedVoucherList && appliedVoucherList.length > 0) {
      this.totalDiscount = appliedVoucherList.reduce((x, y) => (x += y.positions[0].discount), 0);
      this.refreshOrderSummaryDebouncer.next();
    }
  }

  public doDecrement(extraFee: OrderSummaryFeeViewModel) {
    const foundOrderItemModel = this.configOrder.screeningItems.find((item: ScreeningItemViewModel) => {
      return extraFee.ticketIds.includes(item.ticketId) && item.optionalExtraFees.includes(extraFee.id);
    });

    if (foundOrderItemModel !== undefined) {
      const index = foundOrderItemModel.optionalExtraFees.indexOf(extraFee.id);
      if (index > -1) {
        foundOrderItemModel.optionalExtraFees.splice(index, 1);
      }

      this.debouncer.next();
    }
  }

  public doIncrement(extraFee: OrderSummaryFeeViewModel) {
    // Znajdź zamówienie bez optionalExtraFees
    const foundOrderItemModel = this.configOrder.screeningItems.find((item: ScreeningItemViewModel) => {
      return extraFee.ticketIds.includes(item.ticketId) && !item.optionalExtraFees.includes(extraFee.id);
    });

    if (foundOrderItemModel !== undefined) {
      foundOrderItemModel.optionalExtraFees.push(extraFee.id);
      this.debouncer.next();
    }
  }

  private emitQuantityChanged(item: OrderSummaryArticleCombinationItemViewModel) {
    this.articleCombinationQuantityChange.emit(item.articleCombination);
    this.refreshOrderSummaryDebouncer.next();
    this.recalculate.next(this.orderSummary);
  }

  public onDecrementCatering(item: OrderSummaryArticleCombinationItemViewModel): void {
    if (item.quantity === 0) {
      return;
    }
    item.quantity--;
    item.articleCombination.selectedQuantity--;
    this.emitQuantityChanged(item);
  }

  public onIncrementCatering(item: OrderSummaryArticleCombinationItemViewModel): void {
    item.quantity++;
    item.articleCombination.selectedQuantity++;
    this.emitQuantityChanged(item);
  }

  public getTotalOptionalExtraFee(): number {
    let sum = 0.0;

    if (this.orderSummary !== null && this.orderSummary.optionalExtraFees != null) {
      this.orderSummary.optionalExtraFees.forEach((item) => {
        sum += item.price * this.configOrder.getCountOptionalFeeByGroupFee(item.id);
      });
    }
    return sum;
  }

  public getTotalPrice(): number {
    let sum = 0.0;

    if (this.orderSummary) {
      this.orderSummary.items.forEach((element) => {
        sum += element.ticket.priceWithExtraFee * element.quantity;
      });

      this.orderSummary.voucherItems.forEach((element) => {
        sum += element.voucher.price * element.quantity;
      });

      this.orderSummary.cateringItems.forEach((element) => {
        sum += element.unitPriceWithSeparatedItems * element.quantity;
      });
    }

    sum += this.getTotalOptionalExtraFee();
    return sum;
  }

  private forcingOptionalFees(): void {
    for (const optionalFee of this.orderSummary.optionalExtraFees) {
      for (const ticket of this.configOrder.screeningItems) {
        if (optionalFee.ticketIds.includes(ticket.ticketId) && !ticket.optionalExtraFees.includes(optionalFee.id)) {
          ticket.optionalExtraFees.push(optionalFee.id);
        }
      }
    }
  }

  private refreshOrderSummary(): void {
    this.callCounter += 1;
    console.log(this.callCounter);

    if (!this.configOrder || this.isLoading) {
      return;
    }

    const ticketList: TicketViewModel[] = this._ticketList ?? [];
    const voucherTypeList: VoucherTypeModel[] = this._voucherTypeList || [];
    const cateringArticleCombinationList: CateringAggregationArticleViewModel[] = this._cateringArticleCombinationList
      ? this._cateringArticleCombinationList.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      : [];

    this.orderSummary = new OrderSummaryBuilder(
      this.environment,
      this.appService,
      this.translateService,
      this._order,
      ticketList,
      voucherTypeList,
      cateringArticleCombinationList,
      this.screeningId
    ).getOrderSummary();

    if (this.orderSummary) {
      this.orderSummary.items.forEach((item) => {
        item.seatIds.forEach((seatId) => {
          if (this.configOrder.screeningItems.find((x) => x.seatId === seatId) === undefined) {
            const screeningItem = new ScreeningItemViewModel();
            screeningItem.ticketId = item.ticket.id;
            screeningItem.seatId = seatId;
            screeningItem.screeningId = this.screeningId;
            this.configOrder.screeningItems.push(screeningItem);
          }
        });
      });

      if (this.configOrder && this.configOrder.defaultExtraFees.length > 0) {
        this.orderSummary.totalPrice += this.configOrder.defaultExtraFees[0].defaultExtraFeePrice;
      }

      if (this.forceOptionalFees && this.route.snapshot.data['pageIdentify'] === 'basket' && this.isFirstTime()) {
        this.forcingOptionalFees();
      }
    }

    this.recalculate.next(this.orderSummary);
  }
}
