import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { DefaultTicketStrategy } from "./default-ticket.strategy";

/**
 * Gets the ticket by the greatest price
 */
export class DefaultTicketFirstPriceDescendingStrategy implements DefaultTicketStrategy {
  public getDefaultTicket(ticketList: Array<TicketViewModel>): TicketViewModel | null {
    const sorted: Array<TicketViewModel> = ticketList.sort((a, b) => {
      return b.price - a.price;
    });

    for (const ticket of sorted) {
      if (ticket) {
        return ticket;
      }
    }

    return null;
  }
}
