import { Component, HostBinding, Inject, Input } from '@angular/core';
import { ENVIRONMENT_TOKEN, makeUrl } from 'libs/core/src/public-api';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
})
export class FlagComponent {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  _lang: string;

  @HostBinding('class.d-none') get hidden() {
    return !this.release && !this.displayImg;
  }

  @Input() release: string = null;
  @Input() public set lang(value: string) {
    if (value) {
      this._lang = value.toLowerCase();
    }
  }

  public get lang(): string {
    return this._lang;
  }

  public get imageUrl(): string {
    return makeUrl(this.environment, `/assets/images/flags/${this.lang}.png`);
  }

  public get displayImg(): boolean {
    return this.environment['flags'] && this.environment['flags'].some((o) => o === this.lang);
  }
}
