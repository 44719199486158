import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';

@Directive({ selector: '[pimg]' })
export class PlaceholderImgDirective implements OnInit {
  @Input() set src(value: string) {
    this.loadImage(value);
  }
  @Input() imageUrl: string;
  @Input() placeholderUrl: string = null;
  @Input() format: 'picture' | 'poster' | 'card' | 'square' | undefined;
  @Input() class: string = '';

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: any, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const parentElement = this.el.nativeElement.parentElement;
    if (parentElement) {
      this.renderer.addClass(parentElement, 'pimg-container');
      this.renderer.addClass(parentElement, `pimg-container-${this.format}`);
    }

    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
    this.lazyLoadImage();
  }

  private getDefaultPlaceholderByType(format: string) {
    switch (format) {
      case 'card':
        return this.environment.placeholder?.card;
      case 'square':
        return this.environment.placeholder?.square;
      case 'picture':
        return this.environment.placeholder?.picture;
      case 'poster':
        return this.environment.placeholder?.poster;
      default:
        return this.environment.placeholder?.default;
    }
  }

  private lazyLoadImage() {
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadImage(this.imageUrl);
          intersectionObserver.unobserve(this.el.nativeElement);
        }
      });
    });

    intersectionObserver.observe(this.el.nativeElement);
  }

  private loadImage(imageUrl: string) {
    this.renderer.setAttribute(this.el.nativeElement, 'class', `pimg pimg-${this.format} ${this.class}`);
    if (imageUrl?.length) {
      this.preLoad(imageUrl);
    } else {
      this.load(null, this.getPlaceholder());
    }
  }

  private preLoad(imageUrl) {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      this.load(img, imageUrl);
    };

    img.onerror = () => {
      this.load(img, this.getPlaceholder());
    };
  }

  private load(img: HTMLImageElement | null, imageUrl: string) {
    if (!img?.complete || img.naturalHeight === 0) {
      this.renderer.setStyle(this.el.nativeElement, 'transition', 'opacity 0.3s');
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', imageUrl);
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
  }

  private getPlaceholder() {
    return this.placeholderUrl ?? this.getDefaultPlaceholderByType(this.format);
  }
}
