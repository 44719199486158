import {Expose} from 'class-transformer';

export class ScreeningItemExtraFeeApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  quantity: number;

  @Expose()
  price: number;

  @Expose()
  taxRate: number;

  @Expose()
  isOptional: boolean;

  @Expose()
  defaultPriceLevelPrice: number;
}
