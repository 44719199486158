<div class="screening-select-date d-flex justify-content-center">
  <ng-container *ngFor="let date of dateList; let i = index">
    <div *ngIf="isCalendarDayVisible(0, i)" class="d-flex d-sm-none day"
         [ngClass]="{'selected': date.toFormat('X') === dateSelected.toFormat('X')}"
         [class.disabled]="date.toFormat('X') < today.toFormat('X')" (click)="onChange(date)">
      <ng-container [ngTemplateOutlet]="dayTemplate" [ngTemplateOutletContext]="{ value: date }"></ng-container>
    </div>
    <div *ngIf="isCalendarDayVisible(1, i)" class="d-none d-sm-flex d-md-none day"
         [ngClass]="{'selected': date.toFormat('X') === dateSelected.toFormat('X')}"
         [class.disabled]="date.toFormat('X') < today.toFormat('X')" (click)="onChange(date)">
      <ng-container [ngTemplateOutlet]="dayTemplate" [ngTemplateOutletContext]="{ value: date }"></ng-container>
    </div>
    <div class="d-none d-md-flex day" [ngClass]="{'selected': date.toFormat('X') === dateSelected.toFormat('X')}"
         [class.disabled]="date.toFormat('X') < today.toFormat('X')" (click)="onChange(date)">
      <ng-container [ngTemplateOutlet]="dayTemplate" [ngTemplateOutletContext]="{ value: date }"></ng-container>
    </div>

  </ng-container>

  <div class="day">
    <div class="date calendar" (click)="dp?.toggle()">
      <i class="fas fa-calendar-alt"></i>
    </div>
  </div>

</div>

<ng-template let-value="value" #dayTemplate>
  <div class="date">
    <ng-container>
      {{value | dateTime:'DAY_SHORT' | uppercase}}
    </ng-container>
    <div class="calendar-date">{{value | dateTime:'MONTH_SHORT'}} {{value.day}}</div>
  </div>
</ng-template>

<div #dp="bsDatepicker" bsDatepicker [bsValue]="bsDatepickerValue" [bsConfig]="bsConfig"
     [container]="'app-screening-select-date'" (bsValueChange)="onDatepickerValueChange($event)" [outsideClick]="true"
     triggers="" [isOpen]="dp.isOpen">