import {Expose} from 'class-transformer';

export class BannerApiModel {

  @Expose()
  resourceUrl: string;

  @Expose()
  targetUrl: string;

}
