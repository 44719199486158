import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { DefaultTicketStrategy } from "./default-ticket.strategy";

/**
 * Gets the first available ticket from list
 */
export class DefaultTicketFirstStrategy implements DefaultTicketStrategy {
  public getDefaultTicket(ticketList: Array<TicketViewModel>): TicketViewModel | null {
    for (const ticket of ticketList) {
      if (ticket && ticket.price) {
        return ticket;
      }
    }

    return null;
  }
}
