import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ui-radiobutton',
  templateUrl: './radiobutton.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioButtonComponent), multi: true }],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() isDisabled: boolean = false;
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() value: any;

  @Output() changed = new EventEmitter<boolean>();

  isChecked: boolean = false;

  private onChange: (event: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(obj: any): void {
    this.isChecked = obj === this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onRadioChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.checked) {
      this.onChange(this.value);
      this.changed.emit(this.value);
    }
    this.onTouched();
  }
}
