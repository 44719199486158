import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { PromotionConditionApiModel } from '../../api-model/member-get-member/promotion-condition.api.model';
import { PromotionConditionItemViewModel } from './promotion-condition-item.view.model';

export class PromotionConditionViewModel extends AbstractViewModel<PromotionConditionApiModel> {
  startDate: DateTime;
  expiryDate: DateTime;
  membershipItems: PromotionConditionItemViewModel[];

  toApiModel(): PromotionConditionApiModel {
    return undefined;
  }

  constructor(protected apiModel: PromotionConditionApiModel = new PromotionConditionApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.startDate = this.apiModel.startDate ? DateTime.fromISO(this.apiModel.startDate) : null;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.membershipItems = this.apiModel.membershipItems.map(membershipItem => new PromotionConditionItemViewModel(membershipItem));
  }
}
