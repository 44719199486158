import {Expose, Transform, Type} from 'class-transformer';
import { DateTime } from 'luxon';

export class AccountItemsModel {
  @Expose()
  @Type(() => ReservationModel)
  reservations: ReservationModel[];

  @Expose()
  @Type(() => VoucherModel)
  vouchers: VoucherModel[];

  @Expose()
  @Type(() => CardModel)
  cards: CardModel[];
}

export class ReservationModel {
  @Expose()
  reservationId: string;

  @Expose()
  salesDocumentId: string;

  @Expose()
  cinemaId: string;

  @Expose()
  bookingId: string;

  @Expose()
  isTransferred: boolean;
}

export class VoucherModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  @Type(() => DateTime)
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, {toClassOnly: true})
  expiryDate: DateTime;

  @Expose()
  number: string;

  @Expose()
  type: number;

  @Expose()
  usesLeft: number;

  @Expose()
  picture: string;
}

export class CardModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  message: string;

  @Expose()
  picture: string;

  @Expose()
  number: string;

  @Expose()
  @Type(() => DateTime)
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, {toClassOnly: true})
  expiryDate: DateTime;

  @Expose()
  type: number;

  @Expose()
  valueBalance: number;

  @Expose()
  pointsBalance: number;

  @Expose()
  typeDescriptions: string[];
}
