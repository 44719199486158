import { Component, OnInit } from '@angular/core';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoadingComponent } from 'libs/shared/src/lib/component/loading/loading.component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class CustomLoadingComponent extends LoadingComponent implements OnInit {
  constructor(protected loadingService: LoadingService) {
    super(environment, loadingService);
  }
}
