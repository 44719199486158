import { Injectable } from '@angular/core';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { trimEnd } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ScreenErrorDataBuilder {
  allreadyReserved(seatCollection: SeatViewModel[]) {
    const result: { row: string; place: string }[] = [];
    seatCollection.forEach((seatModel) => {
      console.log(seatModel, seatModel.occupied);
      if (seatModel.occupied === OccupiedStatus.Occupied) {
        result.push({ row: seatModel.rowSymbol, place: seatModel.symbol });

        if (seatModel.groupConnectedSeats && Array.isArray(seatModel.groupConnectedSeats)) {
          seatModel.groupConnectedSeats.forEach((groupSeatModel) => result.push({ row: groupSeatModel.rowSymbol, place: groupSeatModel.symbol }));
        }
      }
    });

    return result;
  }

  allreadyReservedString(seatCollection, translate) {
    let reservedSeatsStringify = '';
    seatCollection.forEach((x) => {
      if (!x) {
        return;
      }
      const seatLabel = translate.instant('shared.seat');
      const rowLabel = translate.instant('shared.row');
      if (x.groupConnectedSeats && Array.isArray(x.groupConnectedSeats)) {
        x.groupConnectedSeats.forEach((y) => {
          reservedSeatsStringify += rowLabel + ': ' + y.rowSymbol + ', ' + seatLabel + ': ' + y.symbol + '; ';
        });
      }
      reservedSeatsStringify += rowLabel + ': ' + x.rowSymbol + ', ' + seatLabel + ': ' + x.symbol + '; ';
    });
    reservedSeatsStringify = trimEnd(reservedSeatsStringify, '; ');
    return reservedSeatsStringify;
  }
}
