import { AbstractViewModel } from '../../abstract.view.model';
import { AccountItemsApiModel } from '../../api-model/account-items/account-items.api.model';
import { CardViewModel } from './card/card.view.model';
import { ReservationViewModel } from './reservation/reservation.view.model';
import { AccountItemsVoucherViewModel } from './voucher/account-items-voucher.view.model';

export class AccountItemsViewModel extends AbstractViewModel<AccountItemsApiModel> {
  public reservations: ReservationViewModel[] = [];
  public vouchers: AccountItemsVoucherViewModel[] = [];
  public cards: CardViewModel[] = [];

  constructor(protected apiModel: AccountItemsApiModel = new AccountItemsApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.reservations = this.apiModel.reservations
      ? this.apiModel.reservations.map((reservation) => {
          let r = new ReservationViewModel(reservation);
          r.screening.screeningId = reservation.screeningId;
          return r;
        })
      : [];
    this.vouchers = this.apiModel.vouchers ? this.apiModel.vouchers.map((voucher) => new AccountItemsVoucherViewModel(voucher)) : [];
    this.cards = this.apiModel.cards ? this.apiModel.cards.map((card) => new CardViewModel(card)) : [];
  }

  toApiModel(): AccountItemsApiModel {
    return undefined;
  }
}
