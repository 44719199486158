import { NotificationType } from './notification-type.enum';

export class NotificationModel {
  constructor(message: string, type: NotificationType, actionOnHidden?: () => void) {
    this.message = message;
    this.type = type;
    this.actionOnHidden = actionOnHidden;
  }

  public message: string;
  public type: NotificationType;
  public actionOnHidden: () => void;
}
