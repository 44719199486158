import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { AccountItemsVoucherApiModel } from '../../../api-model/account-items/voucher/account-items-voucher.api.model';
import { UserVoucherViewModel } from '../../user-history/voucher/user-voucher.view.model';
import { VoucherTypeEnum } from '../../user-history/voucher/voucher-type.enum';

export class AccountItemsVoucherViewModel extends AbstractViewModel<AccountItemsVoucherApiModel> {
  public id: string;
  public name: string;
  public description: string;
  public expiryDate: DateTime;
  public number: string;
  public type: number;
  public usesLeft: number;
  public picture: string;
  public voucherGroupId: string;
  public voucherGroupName: string;

  public isUsed = false;
  public voucherTypeEnum: VoucherTypeEnum;

  constructor(
    protected apiModel: AccountItemsVoucherApiModel = new AccountItemsVoucherApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.number = this.apiModel.number;
    this.type = this.apiModel.type;
    this.usesLeft = this.apiModel.usesLeft;
    this.picture = this.apiModel.picture;
    this.voucherGroupId = this.apiModel.voucherGroupId;
    this.voucherGroupName = this.apiModel.voucherGroupName;
  }

  toApiModel(): AccountItemsVoucherApiModel {
    return undefined;
  }

  toUserVoucherViewModel(extra?: UserVoucherViewModel): UserVoucherViewModel {
    const model = Object.assign(extra ?? new UserVoucherViewModel(), {
      voucherNumber: this.number,
      voucherName: this.name,
      voucherDescription: this.description,
      voucherUsageLeft: this.usesLeft,
      voucherExpiryDate: this.expiryDate,
      voucherGroupId: this.voucherGroupId,
      voucherGroupName: this.voucherGroupName,
      flgActive: true
    });

    return model;
  }
}
