import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { paymentStatusTypes } from './payment-status-type.model';

@Component({
  template: '',
})
export class WorldpayRedirectHubComponent implements OnInit {
  public status = '_';
  public paymentStatusTypes: typeof paymentStatusTypes = paymentStatusTypes;
  public redirectUrl: string;

  constructor(protected route: ActivatedRoute, protected router: Router, protected navigationHelper: NavigationHelperService) {}

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const urlTree = this.router
      .createUrlTree([this.navigationHelper.getNextRoute(snapshot)], {
        queryParams: { cinemaId: snapshot.queryParams['cinemaId'], orderId: snapshot.queryParams['orderId'] },
      })
      .toString();

    this.redirectUrl = `${window.location.origin}/#${urlTree}`;
  }
}
