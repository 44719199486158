import {Expose, Type} from 'class-transformer';
import { EventApiModel } from './event.api.model';
import { MovieApiModel } from './movie.api.model';
import { ScreenApiModel } from './screen.api.model';
import { ScreeningApiModel } from './screening.api.model';
import { TicketApiModel } from './ticket.api.model';

export class ReservationApiModel {
  @Expose()
  id: string;

  @Expose()
  expiryDate: string;

  @Expose()
  cinemaId: string;

  @Expose()
  screeningId: string;
  
  @Expose()
  isPurchased: boolean;

  @Expose()
  salesDocumentId: string;

  @Expose()
  bookingId: string;

  @Expose()
  isTransferred: boolean;

  @Expose()
  @Type(() => MovieApiModel)
  movie: MovieApiModel;

  @Expose()
  @Type(() => EventApiModel)
  event: EventApiModel;

  @Expose()
  @Type(() => ScreeningApiModel)
  screening: ScreeningApiModel;

  @Expose()
  @Type(() => ScreenApiModel)
  screen: ScreenApiModel;

  @Expose()
  @Type(() => TicketApiModel)
  tickets: TicketApiModel[];
}
