import {Expose} from 'class-transformer';

export class PaymentApiModel {
  @Expose()
  id: string;

  @Expose()
  type: string;

  @Expose()
  plainPayload: string;

  @Expose()
  provider: string;

  @Expose()
  merchantTransactionId: string;
}
