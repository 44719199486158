import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { BonusItemModel } from '../model/api-model/reward/reward.request.model';
import { BonusModel, BasketItemIdentifierModel } from '../model/api-model/reward/reward.response.model';
import { OrderHttpService } from './order.http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderRewardsHttpService {
  constructor(private http: HttpClient) {}

  // /api/cinema/{cinemaId}/card/{cardId}/bonus
  getCardBonuses(cinemaId: string, cardId: string): Observable<BonusModel[]> {
    return this.http
      .get<BonusModel[]>(`/cinema/${cinemaId}/card/${cardId}/bonus`)
      .pipe(map((res) => plainToInstance(BonusModel, res as Object[], { strategy: 'excludeAll' })));
  }

  // /api/cinema/{cinemaId}/order/{orderId}/bonusItem
  patch(cinemaId: string, orderId: string, item: BonusItemModel): Observable<BasketItemIdentifierModel> {
    return this.http.patch<BasketItemIdentifierModel>(`/cinema/${cinemaId}/order/${orderId}/bonusItem`, item).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      map((res) => {
        return plainToInstance(BasketItemIdentifierModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }
}
