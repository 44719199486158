import { PersonalModel } from "libs/core/src/lib/model/personal.model";
import { OrderViewModel } from "libs/core/src/lib/model/view-model/order/order.view.model";

export class OrderSummaryPersonalViewModel {
  private _personalModel: PersonalModel = new PersonalModel();

  constructor(private orderModel: OrderViewModel = null) {
    this._personalModel.phone = orderModel?.userPhone;
    this._personalModel.firstname = orderModel?.userFirstName;
    this._personalModel.lastname = orderModel?.userLastName;
    this._personalModel.email = orderModel?.userEmail;
  }

  get personalData(): PersonalModel {
    return this._personalModel;
  }
}
