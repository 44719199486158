<div class="navigation-container screen-navigation-container">
  <div class="d-flex justify-content-between">
    <div class="col-3 p-0">
      <div class="col-12 col-sm-8 col-lg-6 p-0">
        <button class="btn btn-back btn-default btn-block  btn-screen-navigation" *ngIf="isBackButtonVisible"
                (click)="onPreviousClick()">
          <span> {{ 'screen.navigation.previous' | translate }} </span>
        </button>
      </div>
    </div>
    <div class="col-9 p-0">
      <div class="row mx-0 flex justify-content-lg-center justify-content-end">
        <div class="w-75">
          <button class="btn btn-lg btn-next btn-block btn-screen-navigation"
                  [ngClass]="{ 'btn-disabled': isNextButtonDisabled }" [disabled]="isNextButtonDisabled"
                  (click)="onNextClick()">
            <span *ngIf="!isLoadingData"> {{ 'screen.navigation.next' | translate }} </span>
            <app-loading *ngIf="isLoadingData" [config]="config" backdrop-position="relative"
                         size="small"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>