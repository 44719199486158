import {Expose} from 'class-transformer';

export class TicketSeatApiModel {
  @Expose()
  rowSymbol: string;

  @Expose()
  seatSymbol: string;

  @Expose()
  seatRowCoordinate: number;

  @Expose()
  seatColumnCoordinate: number;

  @Expose()
  seatGroupId: string;

  @Expose()
  seatGroupName: string;

  @Expose()
  groupStampId: string;
}
