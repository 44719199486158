import { AbstractViewModel } from "../../../abstract.view.model";
import { VirtualPassApiModel } from "../../../api-model/sales-document/virtual-pass/virtual-pass.api.model";

export class VirtualPassViewModel extends AbstractViewModel<VirtualPassApiModel> {

  provider: string;
  passUrl: string;
  screeningId: string;
  screeningItemId: string;
  ticketNumber: string;

  constructor(
    protected apiModel: VirtualPassApiModel = new VirtualPassApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.provider = this.apiModel.provider;
    this.passUrl = this.apiModel.passUrl;
    this.screeningId = this.apiModel.screeningId;
    this.screeningItemId = this.apiModel.screeningItemId;
    this.ticketNumber = this.apiModel.ticketNumber;
  }

  toApiModel(): VirtualPassApiModel {
    return undefined;
  }
}
