import {Expose, Transform} from 'class-transformer';
import {DateTime} from 'luxon';

export class UserMoviePrintApiModel {
  @Expose()
  id: string;

  @Expose()
  printType: string;

  @Expose()
  soundType: string;

  @Expose()
  speakingType: string;

  @Expose()
  movieId: string;

  @Expose()
  language: string;

  @Expose()
  subtitles: string;

  @Expose()
  subtitles2: string;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  premiereDate: DateTime;

  @Expose()
  release: string;
}
