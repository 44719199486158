import { Injectable } from '@angular/core';

declare var ApplePaySession: any | undefined;

@Injectable({
  providedIn: 'root'
})
export class ApplePayService {
  public canMakePayments(): boolean {
    return ApplePaySession !== undefined && ApplePaySession.canMakePayments() === true;
  }

  public getSession(merchantName: string, orderAmount: number): any {
    const paymentRequest = {
      currencyCode: 'PLN',
      countryCode: 'PL',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: merchantName,
        amount: orderAmount
      },
    };

    return new ApplePaySession(1, paymentRequest);
  }
}
