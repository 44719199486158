<ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'div'">
        <div class="date-picker-container d-flex align-items-center">
            <div class="date-picker-bar">
                <ng-template [ngIf]="!label" [ngIfElse]="onlyLabel">
                    <div class="d-flex align-items-center pointer" (click)="dp.toggle()">
                        <i *ngIf="allowChangeDate" class="link pc-icon-calendar2"></i>
                        <span class="mx-3">
                            <span>{{date | extendedDate: 'DATE_SHORT_WITH_WEEKDAY':true }}</span>
                        </span>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="allowChangeDate" class="date-picker-paginator d-flex">
                <i class="pc-icon-chevron-normal-left pointer p-2" (click)="dateDecrease()"
                   [ngClass]="{'disabled': canPrev(this.date.minus({days: this.step}))}"></i>
                <i class="pc-icon-chevron-normal-right pointer p-2" (click)="dateIncrease()"></i>
            </div>
        </div>
        <div #dp="bsDatepicker" bsDatepicker [bsValue]="bsDatepickerValue" [bsConfig]="bsConfig"
             [container]="'app-date-picker'" (bsValueChange)="onDatepickerValueChange($event)" [outsideClick]="true"
             triggers="" [isOpen]="dp.isOpen">
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'input'">
        <input type="text" bsDatepicker [bsValue]="bsDatepickerValue" [bsConfig]="bsConfig"
               (bsValueChange)="onDatepickerValueChange($event)" [outsideClick]="true">
    </ng-container>
</ng-container>

<ng-template #onlyLabel>
    <div class="d-inline-flex align-items-center">
        <i class="pc-icon-calendar2"></i>
        <span class="mx-3">{{label}}</span>
    </div>
</ng-template>