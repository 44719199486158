export enum WpOptionEnum {
  ENVELOPE_ITEM_PRICE = 'EnvelopeItemPrice',
  ENVELOPE_ITEM_ID = 'EnvelopeItemId',
  AGREEMENT_GROUP_ID_AGREEMENTS = 'AgreementGroupId-Agreements',
  VOUCHER_GROUP_ID_PASSES = 'VoucherGroupId-Passes',
  VOUCHER_GROUP_ID_FLAT_RATES = 'VoucherGroupId-FlatRates',
  VOUCHER_GROUP_ID_MEMBERSHIP = 'VoucherGroupId-Membership',
  DEFAULT_TICKET_ID = 'DefaultTicketID',
  INSURANCE_ID = 'InsuranceID',
  BRIGHTCOVE_ACCOUNT_ID = 'BrightcoveAccountID',
  BRIGHTCOVE_PLAYER_ID = 'BrightcovePlayerID',
  ORDER_AGREEMENT = 'OrderAgreement',
  CINEMA_DAY_OFFSET = 'CinemaDayOffset',
  BEST_SEATS_QUANTITY = 'BestSeatsQuantity',
  INTERNAL_PAYMENT_TYPE_ID_FOR_GIFTCARD = 'InternalPaymentTypeIdForGiftCard',
  INTERNAL_PAYMENT_TYPE_ID_FOR_PREPAID = 'InternalPaymentTypeIdForPerPaid',
  DISPLAY_MANDATORY_EXTRA_FEES = 'DisplayMandatoryExtraFees',
  GIFTCARDPURCHASE_MINVALUE = 'GiftCardPurchaseMinValue',
  GIFTCARDPURCHASE_MAXVALUE = 'GiftCardPurchaseMaxValue',
  RESERVATION_ALWAYS_VISIBLE = 'ReservationAlwaysVisible',
  DEFAULT_PREROLL_VIDEO = 'DefaultPrerollVideo',
  DEFAULT_PREROLL_SKIP_TIME = 'DefaultPrerollSkipTime',
  LOCATION_SWITCHER_MODE = 'LocationSwitcherMode',
  LOCATION_AUTOSELECTION = 'LocationAutoselection',
  HIDE_INACTIVE_SCREENINGS = 'HideInactiveScreenings',
  NO_SHOWS_ADDITIONAL_TEXT_MESSAGE = 'NoShowsAdditionalTextMessage',
  TICKETS_REFUND = 'TicketsRefund',
  TERMS_OF_USE = 'TermsOfUse',
  PRIVACY_POLICY = 'PrivacyPolicy',
}
