import { Expose } from 'class-transformer';

export class ScreeningApiModel {
  @Expose()
  id: string;

  @Expose()
  regionId: string;

  @Expose()
  cinemaId: string;

  @Expose()
  screenId: string;

  @Expose()
  moviePrintId: string;

  @Expose()
  movieExternalId: string;

  @Expose()
  moviePrintExternalId: string;

  @Expose()
  screeningTimeFrom: string;

  @Expose()
  screeningTimeTo: string;

  @Expose()
  screeningDuration: number;

  @Expose()
  audience: number;

  @Expose()
  generalAdmission: boolean;

  @Expose()
  saleTimeTo: string;

  @Expose()
  reservationTimeTo: string;

  @Expose()
  isScreenSwapping: boolean;

  @Expose()
  availabilityStatus: number;

  @Expose()
  maxOccupancy: number;

  @Expose()
  movieId: string;

  @Expose()
  printType: string;

  @Expose()
  speakingType: string;

  @Expose()
  language: string;

  @Expose()
  subtitles: string;

  @Expose()
  subtitles2: string;

  @Expose()
  soundType: string;

  @Expose()
  release: string;

  @Expose()
  format: string;

  @Expose()
  resolution: string;

  @Expose()
  frameRate: string;
}
