import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { Subscription } from 'rxjs';
import { VoucherSelectorService } from './service/voucher-selector.service';

@Component({
  selector: 'app-voucher-selector-component',
  templateUrl: './voucher-selector.component.html',
  styleUrls: ['./voucher-selector.component.scss'],
})
export class CustomVoucherSelectorComponent implements OnInit {
  @Output()
  public onReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onSelected: EventEmitter<VoucherTypeModel> = new EventEmitter<VoucherTypeModel>();

  @Output()
  public onDropped: EventEmitter<VoucherTypeModel> = new EventEmitter<VoucherTypeModel>();

  @Input()
  public set voucherTypeList(value: Array<VoucherTypeModel>) {
    this._voucherTypeList = value;

    value.forEach((x) => {
      if (!this.selectedVouchers[x.id]) {
        this.selectedVouchers[x.id] = 0;
      }
    });
  }

  public get voucherTypeList(): Array<VoucherTypeModel> {
    return this._voucherTypeList;
  }

  @Input()
  public currency: string;

  @Input()
  public limit = 2;

  public allSelectedVoucherCount = 0;

  public selectedVouchers: { [key: string]: number } = {};

  private _voucherTypeList: Array<VoucherTypeModel> = new Array<VoucherTypeModel>();

  private defaultSelectedVoucherListSubscription: Subscription = Subscription.EMPTY;

  constructor(private voucherSelectorService: VoucherSelectorService) {}

  public ngOnInit(): void {
    this.onReady.next(true);
    this.onReady.complete();

    this.defaultSelectedVoucherListSubscription = this.voucherSelectorService.defaultSelectedVoucherListState.subscribe(
      (selectedVoucherList: Array<VoucherTypeModel>) => {
        this.resetSelectedVouchers();

        selectedVoucherList.forEach((selectedVoucher) => {
          this.increment(selectedVoucher);
        });
      }
    );
  }

  public onDecrementClick(voucher: VoucherTypeModel): void {
    this.allSelectedVoucherCount - this.countAllSelectedVouchers();

    let value: number = this.selectedVouchers[voucher.id];

    if (value > 0) {
      this.onDropped.next(voucher);
    }

    value--;

    if (value < 0) {
      value = 0;
    }

    this.selectedVouchers[voucher.id] = value;

    this.allSelectedVoucherCount = this.countAllSelectedVouchers();
  }

  public onIncrementClick(voucher: VoucherTypeModel): void {
    if (this.increment(voucher)) {
      this.onSelected.next(voucher);
    }
  }

  private increment(voucher: VoucherTypeModel): boolean {
    this.allSelectedVoucherCount = this.countAllSelectedVouchers();

    const allSelectedAmount: number = this.countAllSelectedVouchers();

    if (allSelectedAmount + 1 <= this.limit) {
      this.selectedVouchers[voucher.id]++;

      this.allSelectedVoucherCount = this.countAllSelectedVouchers();

      return true;
    }

    return false;
  }

  private countAllSelectedVouchers(): number {
    let amount = 0;

    for (const id of Object.keys(this.selectedVouchers)) {
      amount += this.selectedVouchers[id];
    }

    return amount;
  }

  private resetSelectedVouchers(): void {
    for (const id of Object.keys(this.selectedVouchers)) {
      this.selectedVouchers[id] = 0;
    }
  }
}
