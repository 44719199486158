import { AbstractViewModel } from "../../../abstract.view.model";
import { UserPaymentApiModel } from "../../../api-model/user-history/order/user-payment.api.model";

export class UserPaymentViewModel extends AbstractViewModel<UserPaymentApiModel> {
  public id: string = null;
  public name: string;

  constructor(
    protected apiModel: UserPaymentApiModel = new UserPaymentApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
  }

  toApiModel(): UserPaymentApiModel {
    return undefined;
  }
}
