import { Component, OnInit } from '@angular/core';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { ScreenTagComponent } from 'libs/shared/src/lib/component/screen/screen-tag/screen-tag.component';

@Component({
  selector: 'app-screen-tag',
  templateUrl: './screen-tag.component.html',
})
export class CustomScreenTagComponent extends ScreenTagComponent implements OnInit {
  constructor(protected dateTimeService: DateTimeService) {
    super(dateTimeService);
  }
}
