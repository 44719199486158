import { Component } from '@angular/core';
import { ModalComponent } from 'libs/shared/src/lib/component/modal/modal.component';
import { CustomRefundFailModalComponent } from './refund/refund-fail/refund-fail.component';
import { CustomRefundSuccessModalComponent } from './refund/refund-success/refund-success.component';
import { CustomRefundModalComponent } from './refund/refund/refund.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class CustomModalComponent extends ModalComponent {
  templates = {
    refundSuccess: CustomRefundSuccessModalComponent,
    refundFail: CustomRefundFailModalComponent,
    refundSelector: CustomRefundModalComponent,
  };
}
