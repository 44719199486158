/**
 * Checks if number is between specified range
 * @param value
 * @param rangeStart
 * @param rangeEnd
 */
export function betweenPartial(value: number, rangeStart: number|null, rangeEnd: number|null): boolean {

  if (rangeStart && rangeEnd) {
    return value >= rangeStart || value <= rangeEnd;
  }

  if (rangeStart && !rangeEnd) {
    return value >= rangeStart;
  }

  if (!rangeStart && rangeEnd) {
    return value <= rangeEnd;
  }

  return false;
}

/**
 * Checks if any number at collection is between specified range
 * @param valueCollection
 * @param rangeStart
 * @param rangeEnd
 */
export function betweenPartialAll(valueCollection: Array<number>, rangeStart: number|null, rangeEnd: number|null): boolean {
  let isMatchedAny = false;

  valueCollection.forEach(item => {
    if (betweenPartial(item, rangeStart, rangeEnd)) {
      isMatchedAny = true;
    }
  });

  return isMatchedAny;
}

export function hookRange(range: [number, number], targetRange: [number, number]): boolean {
  /**
   *       X .............. Y
   *   A...............B.....
   */
  const leftInner = range[0] <= targetRange[0] && range[1] <= targetRange[1] && range[1] >= targetRange[0];
  /**
   *      X ............... Y
   *          A ............... B
   */
  const rightInner = range[0] >= targetRange[0] && range[0] <= targetRange[1] && range[1] >= targetRange[1];
  /**
   *     X ................ Y
   *     A ................ B
   *
   *     X ................ Y
   *        A ........... B
   */
  const inner = range[0] >= targetRange[0] && range[1] <= targetRange[1];
  /**
   *
   *     X ................ Y
   *  A ...................... B
   */
  const outer = range[0] <= targetRange[1] && range[1] >= targetRange[1];

  return leftInner || rightInner || inner || outer;
}

export function XOR(a, b) {
  return ( a || b ) && !( a && b );
}

export function isNumber(value: string | number): boolean {
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
}

export function panValue(value: string | number, length: number = 3) {
  if (!value) {
    return '';
  }

  if (typeof value === 'number') {
    value = value.toString();
  }

  return value && value.length ? value.padStart(length, '0') : '';
}