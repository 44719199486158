<div class="book-tickets">
  <div *ngIf="orderModel.hasVoucher()" class="bg-white">
    <div class="row mx-4 px-0 py-3 pt-sm-0 pb-sm-3 voucher-container" style="gap: 0.5rem">
      <ng-container *ngFor="let voucher of orderModel?.getVouchers()">
        <div class="col-12 voucher-box">
          <span class="voucher-icon"></span>
          <div class="d-flex flex-wrap justify-content-between px-2" style="flex-grow: 1;">
            <span class="font-weight-normal voucher-name">{{ voucher.name }}</span>
            <span class="font-weight-normal voucher-name"> {{ voucher.number }}</span>
          </div>
          <span class="remove-icon" (click)="deleteVoucher(voucher.number)"></span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="book-tickets-list d-flex flex-column gap-05">
    <div class="row m-0" *ngFor="let seat of selectedSeats">
      <div class="row m-0 p-1 seat-item w-100 d-flex justify-content-between">
        <div class="col-1">
          <div class="row-head">{{ 'ticketssummary.row' | translate }}</div>
          <div class="row-body">{{ seat.rowNumber}}</div>
        </div>
        <div class="col-2">
          <div class="row-head">{{ 'ticketssummary.seat' | translate }}</div>
          <div class="row-body">{{ seat.symbol }}</div>
        </div>
        <div class="col-5 pr-0 d-flex justify-content-end align-items-center">
          <ng-container *ngVar="getOrderItem(seat.id) let item">
            <app-dropdown [ngModel]="item.ticketId" [isReadOnly]="item.hasVoucher()" class="wide-options"
                          (optSelect)="onTicketSelect($event, item)"
                          [options]="basketPageModel.findTicketsByScreeningItemIdAndApplyVoucherChanges(item)|sort:'price'">
            </app-dropdown>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>