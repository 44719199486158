import { HttpParams } from '@angular/common/http';

export function makeHttpParams(obj: any): HttpParams {
  let params = new HttpParams();
  for (const property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      params = params.set(property, obj[property]);
    }
  }

  return params;
}
