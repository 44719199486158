import { forkJoin, iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { plainToInstance } from 'class-transformer';
import { UserDataProvider } from './user.data-provider';
import { BasketPageModel } from '../model/page/basket/basket.page.model';
import { ScreenHttpService } from '../http/screen.http.service';
import { ScreeningHttpService } from '../http/screening.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { MovieHttpService } from '../http/movie.http.service';
import { TicketHttpService } from '../http/order-ticket.http.service';
import { EventDataProvider } from './event.data-provider';
import { OrderStatus, OrderViewModel } from '../model/view-model/order/order.view.model';
import { storageKey } from '../../app.const';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';
import { ScreeningViewModel } from '../model/view-model/screening/screening.view.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { TicketViewModel } from '../model/view-model/shared/ticket/ticket.view.model';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { StateService } from '../state/state.service';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';

@Injectable({
  providedIn: 'root',
})
export class BasketDataProvider {
  public basketPageModel: BasketPageModel;

  constructor(
    private screenHttpService: ScreenHttpService,
    private screeningHttpService: ScreeningHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private ticketHttpService: TicketHttpService,
    private userDataProvider: UserDataProvider,
    private eventDataProvider: EventDataProvider,
    private stateService: StateService
  ) {
    this.basketPageModel = new BasketPageModel();
  }

  public do(order: OrderViewModel, cinemaId: string): Observable<BasketPageModel> {
    const basketModel = this.basketPageModel ?? new BasketPageModel();
    console.log('#do', basketModel);

    const isEvent = this.stateService.getItem(storageKey.isEvent) === 'true';
    const screeningId = !!order?.screeningItems?.length
      ? order?.screeningItems[0]?.screeningId
      : JSON.parse(this.stateService.getItem('parentOrder'))?.parentOrderScreening?.id;
    const id = (isEvent ? this.stateService.getItem(storageKey.lastEventId) : screeningId) ?? null;

    let eventModel: EventViewModel | null = null;

    let genres: GenreViewModel[] = [];

    const screening$ = (cinemaId, id) =>
      this.screeningHttpService.getCinemaScreening(cinemaId, id).pipe(
        map((res) => {
          return plainToInstance(ScreeningApiModel, res as object);
        }),
        catchError(() => {
          return of(null);
        })
      );

    const event$ = (cinemaId, id, screeningId) => {
      if (!isEvent) {
        return of(null);
      }

      const eventDate = this.stateService.getItem(storageKey.chosenDate) ? DateTime.fromISO(this.stateService.getItem(storageKey.chosenDate)) : null;
      return this.eventDataProvider.getCinemaEvent(cinemaId, id, screeningId, eventDate?.startOf('day'), eventDate?.endOf('day'));
    };

    return iif(
      () => id,
      forkJoin({
        screeningModel: screening$(cinemaId, id),
        eventModel: event$(cinemaId, id, screeningId),
      }).pipe(
        mergeMap((data) => {
          eventModel = data.eventModel;
          if (data.screeningModel && !eventModel) {
            basketModel.screening = new ScreeningViewModel(data.screeningModel);
          } else {
            const screening = new ScreeningViewModel();
            screening.id = eventModel.screeningId;

            screening.screeningTimeFrom = eventModel.screeningTimeFrom;
            screening.generalAdmission = false;
            screening.screeningDuration = eventModel.duration;
            screening.isScreenSwapping = false;
            screening.availabilityStatus = eventModel.availabilityStatus;
            screening.reservationTimeTo = eventModel.reservationTimeTo;
            screening.saleTimeTo = eventModel.saleTimeTo;
            screening.screeningTimeFrom = eventModel.screeningTimeFrom;
            screening.screeningTimeTo = eventModel.timeTo;
            screening.moviePrintId = eventModel.movieCopy?.id;

            basketModel.screening = screening;
            genres = eventModel.genres;
          }

          return iif(
            () => !basketModel.screening?.moviePrintId,
            of(null),
            this.movieCopyHttpService.getMoviePrint(basketModel.screening?.moviePrintId).pipe(
              tap((moviePrintApiModel: MoviePrintApiModel) => {
                basketModel.movieCopy = new MoviePrintViewModel(moviePrintApiModel);
              })
            )
          );
        }),
        mergeMap((moviePrintApiModel: MoviePrintApiModel) => {
          return iif(
            () => !moviePrintApiModel?.movieId,
            of(null),
            this.movieHttpService.getMovie(moviePrintApiModel?.movieId).pipe(
              tap((movieApiModel: MovieApiModel) => {
                basketModel.movie = new MovieViewModel(movieApiModel);
                genres = basketModel.movie.genres;
              })
            )
          );
        }),
        switchMap(() =>
          this.screenHttpService.getScreens(cinemaId, screeningId).pipe(
            tap((screenResponseModel: ScreenApiModel[]) => {
              basketModel.screen = new ScreenViewModel(screenResponseModel[0]);
              basketModel.screen.isEvent = isEvent;

              if (isEvent) {
                basketModel.screen.movieName = eventModel?.name;
                basketModel.screen.movieDate = basketModel.screening.screeningTimeFrom;
                basketModel.screen.movieDuration = eventModel.duration;
                basketModel.screen.posterUrl = eventModel.posters ? eventModel.posters[0] || '' : '';
                basketModel.screen.pictureUrl = eventModel.pictures ? eventModel.pictures[0] || '' : '';
                basketModel.screen.movieDescription = eventModel.description;
                basketModel.screen.generalAdmission = basketModel.screening.generalAdmission;
                basketModel.screen.movieGenres = genres || [];
                basketModel.screen.movieTags = eventModel.tagGroups || [];
                basketModel.screen.movieRating = eventModel.ratings;
                basketModel.screen.screeningDuration = basketModel.screening.screeningDuration;
                basketModel.screen.isScreenSwapping = basketModel.screening.isScreenSwapping;
                basketModel.screen.screeningAvailabilityStatus = basketModel.screening.availabilityStatus;
                basketModel.screen.reservationTimeTo = basketModel.screening.reservationTimeTo;
                basketModel.screen.saleTimeTo = basketModel.screening.saleTimeTo;
                basketModel.screen.screeningTimeFrom = basketModel.screening.screeningTimeFrom;
                basketModel.screen.screeningTimeTo = basketModel.screening.screeningTimeTo;
                basketModel.screen.movieCopyRelease = eventModel.release;
              } else {
                basketModel.screen.movieName = eventModel?.name ?? basketModel.movie.title;
                basketModel.screen.movieDate = basketModel.screening.screeningTimeFrom;
                basketModel.screen.moviePremiere = basketModel.movie.premiereDate;
                basketModel.screen.movieDuration = basketModel.movie.duration;
                basketModel.screen.movieLanguage = basketModel.movieCopy.language;
                basketModel.screen.movieSpeakingType = basketModel.movieCopy.speakingType;
                basketModel.screen.posterUrl = basketModel.movie.posters ? basketModel.movie.posters[0] || '' : '';
                basketModel.screen.pictureUrl = basketModel.movie.pictures ? basketModel.movie.pictures[0] || '' : '';
                basketModel.screen.movieDescription = basketModel.movie.description;
                basketModel.screen.generalAdmission = basketModel.screening.generalAdmission;
                basketModel.screen.movieGenres = genres || [];
                basketModel.screen.movieTags = basketModel.movie.tagGroups || [];
                basketModel.screen.movieRating = basketModel.movie.ratings;
                basketModel.screen.moviePrintType = basketModel.movieCopy.printType;
                basketModel.screen.screeningDuration = basketModel.screening.screeningDuration;
                basketModel.screen.isScreenSwapping = basketModel.screening.isScreenSwapping;
                basketModel.screen.screeningAvailabilityStatus = basketModel.screening.availabilityStatus;
                basketModel.screen.reservationTimeTo = basketModel.screening.reservationTimeTo;
                basketModel.screen.saleTimeTo = basketModel.screening.saleTimeTo;
                basketModel.screen.screeningTimeFrom = basketModel.screening.screeningTimeFrom;
                basketModel.screen.screeningTimeTo = basketModel.screening.screeningTimeTo;
                basketModel.screen.movieSoundType = basketModel.movieCopy.soundType;
                basketModel.screen.movieCopyRelease = basketModel.movieCopy.release;
              }
            })
          )
        ),
        switchMap(() =>
          iif(
            () => order.status !== OrderStatus.CANCELLED,
            this.ticketHttpService.getOrderTickets(cinemaId, order.id),
            of(
              order.screeningItems
                .filter((item) => item.ticketId)
                .map((item) => {
                  const ticketModel = new TicketApiModel();
                  ticketModel.id = item.ticketId;
                  ticketModel.name = item.name;
                  ticketModel.price = item.price;
                  ticketModel.screeningItemId = item.id;
                  ticketModel.tax = item.tax;
                  ticketModel.defaultPriceLevelPrice = item.defaultPriceLevelPrice;

                  return ticketModel;
                })
            )
          ).pipe(
            map((ticketModels: TicketApiModel[]) => ticketModels?.map((m) => new TicketViewModel(m))),
            tap((tickets) => {
              tickets?.forEach((ticket) => (ticket.seatId = order.screeningItems.find((x) => x.id === ticket.screeningItemId).seatId ?? null));
              basketModel.tickets = tickets;
            })
          )
        ),
        map(() => {
          basketModel.screeningId = id;
          basketModel.event = eventModel;
          return basketModel;
        })
      ),
      of(basketModel)
    ).pipe(
      switchMap(() =>
        iif(
          () => !basketModel.agreements,
          this.userDataProvider.getAgreements().pipe(
            tap((agreements) => (basketModel.agreements = agreements.marketingAgreements)),
            switchMap(() => of(basketModel))
          ),
          of(basketModel)
        )
      )
    );
  }
}
