import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { storageKey } from '../../app.const';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class AllowedGuard implements CanActivate {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router, private stateService: StateService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.environment.constants.allowedPages.indexOf(next.data.pageIdentify) === -1) {
      const backUrl = this.stateService.getItem(storageKey.backUrl);
      this.router.ngOnDestroy();
      window.location.href = backUrl ? backUrl : this.environment.backUrl;
      return false;
    }

    return true;
  }
}
