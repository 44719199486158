import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class RefundPaymentApiModel {
  @Expose()
  public paymentTypeId: string;

  @Expose()
  public cardId: string;

  @Expose()
  public refundValue: number;

  @Expose()
  public transactionId: string;
}