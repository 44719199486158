<div class="d-flex justify-content-between align-items-center w-100">
  <div class="d-flex pl-3">
    <span class="fa fa-chevron-left" [ngStyle]="{'visibility': canLeft() ? 'visible' : 'hidden'}"
          (click)="leftArrow()"></span>
  </div>
  <div class="items m-2 d-flex justify-content-center" #elements>
    <ng-container *ngFor="let item of items | slice: elementsOffset: elementsOffset + maxElements">
      <div class="item pointer pimg"
           [ngClass]="{'selected': selectedItem?.key === item.key, 'empty-card': !item?.graphic}"
           (click)="select(item)">
        <img pimg [imageUrl]="item.graphic" format="square">
      </div>
    </ng-container>
  </div>

  <div class="d-flex pr-3">
    <span class="fa fa-chevron-right" [ngStyle]="{'visibility': canRight() ? 'visible' : 'hidden'}"
          (click)="rightArrow()"></span>
  </div>
</div>