import { Injectable } from '@angular/core';
import { storageKey } from '../../app.const';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  constructor(private stateService: StateService) {}

  /**
   * Stores an URI to redirect on next app launch
   * @param uriToRedirect
   */
  public saveRedirectionOnNextAppLaunch(uriToRedirect: string): void {
    this.stateService.setItem(storageKey.redirectImNappLa, uriToRedirect);
  }

  /**
   * Gets an URI to redirect on next app lanuch
   */
  public getRedirectionOnNextAppLaunch(): string {
    return this.stateService.getItem(storageKey.redirectImNappLa);
  }

  /**
   * Removes any existing occurrence of URI to redirect
   */
  public removeRedirectionOnNextAppLaunch(): void {
    this.stateService.removeItem(storageKey.redirectImNappLa);
  }
}
