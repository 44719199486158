import { Component, Inject, Injector, Input, OnInit, inject } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import brightcovePlayerLoader from '@brightcove/player-loader';
import { TrailerTypeEnum, TrailerViewModel } from 'libs/core/src/lib/model/view-model/movie/trailer.view.model';
import { storageKey } from 'libs/core/src/app.const';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WebComponentsService } from '@lib/webcomponent/service';
import { WpOptionEnum } from 'libs/webcomponent/src/lib/enum/wp-option.enum';
import { AdModel } from 'libs/core/src/lib/model/ad.model';
import { VideoModel } from 'libs/core/src/lib/model/video.model';
import { VideoType } from 'libs/core/src/lib/enum/video-type.enum';
import { VideoPlayState } from 'libs/core/src/lib/enum/video-play-state.enum';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { WebComponentOptionsService } from 'libs/webcomponent/src/lib/service/webcomponent-options.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  webComponentOptionsService: WebComponentOptionsService;
  videoPlayState = VideoPlayState;
  mobileDeviceUtils: MobileDeviceUtils = MobileDeviceUtils;

  @Input() trailers: KeyValue<TrailerViewModel[]>[] = [];
  @Input() ads: AdModel[] = [];
  @Input() autostart = false;
  @Input() brightcoveAccountId: string = null;
  @Input() brightcovePlayerId: string = null;
  @Input() thumbnail: string = null;

  private brightcoveConfig: any = null;
  private globalPrerollSource: string;
  private globalPrerollSkipTime: number;

  public preload = 'auto';
  public api: VgApiService;
  public videoIndex = 0;
  public langIndex = 0;
  public currentVideos: VideoModel[] = [];
  public currentVideo: VideoModel = null;
  public embeddedPlayer: string;
  public embeddedPlayerConfig: any = null;
  public playerType = 'standalone';

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected embedVideoService: EmbedVideoService,
    protected stateService: StateService,
    private sanitized: DomSanitizer
  ) {
    this.webComponentOptionsService = inject(WebComponentOptionsService);
  }

  ngOnInit(): void {
    this.globalPrerollSource = String(this.webComponentOptionsService.getWpOption(WpOptionEnum.DEFAULT_PREROLL_VIDEO));
    this.globalPrerollSkipTime = Number(this.webComponentOptionsService.getWpOption(WpOptionEnum.DEFAULT_PREROLL_SKIP_TIME));

    this.init();
  }

  buildVideosQueue() {
    const notViewedAds = this.ads?.filter((ad) => !ad.wasViewed);

    if (notViewedAds.length > 0) {
      notViewedAds.forEach((ad) => {
        this.currentVideos.push(new VideoModel(ad));
      });
    } else if (this.globalPrerollSource && this.globalPrerollSkipTime) {
      this.currentVideos.push(new VideoModel(new AdModel(this.globalPrerollSource, this.globalPrerollSkipTime)));
    }

    if (this.trailers.length > 0) {
      const trailerInUserLang = this.trailers.find((x) => x.key === this.stateService.getItem(storageKey.lang).toLowerCase());
      const langTrailers = trailerInUserLang || this.trailers[0];
      this.currentVideos.push(...langTrailers.value.map((v) => new VideoModel(v)));
    }

    this.videoIndex = 0;
  }

  public init(): void {
    this.buildVideosQueue();
    this.initPlayer();
  }

  private initPlayer() {
    this.setCurrentTrailer();

    if (this.isStreamingResource()) {
      if (this.api) {
        this.api.currentTime = 0;
      }

      this.embeddedPlayer = null;
      return;
    }

    this.playerType = 'embeded';

    if (this.isBrightcove()) {
      this.embeddedPlayer = null;
      this.embedBrightcovePlayer();
    } else {
      this.embeddedPlayer = this.getEmbededHtml(this.currentVideo.source);
    }
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.vgReady.bind(this));
    this.api.getDefaultMedia().subscriptions.playing.subscribe(this.vgPlaying.bind(this));
    this.api.getDefaultMedia().subscriptions.pause.subscribe(this.vgPaused.bind(this));
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.vgEnded.bind(this));
  }

  vgReady() {
    this.currentVideo.playState = VideoPlayState.Ready;

    if (this.currentVideo.autoPlay) {
      this.api.play();
    }
  }

  vgPlaying() {
    this.currentVideo.playState = VideoPlayState.Playing;
    console.log('vgPlaying');
  }

  vgPaused() {
    this.currentVideo.playState = VideoPlayState.Paused;
    console.log('vgPaused');
  }

  vgEnded() {
    this.currentVideo.playState = VideoPlayState.Ended;
    this.nextVideo();
  }

  nextVideo() {
    this.videoIndex += 1;
    if (this.videoIndex === this.currentVideos.length) {
      this.videoIndex = this.currentVideos.indexOf(this.currentVideos.find((v) => !v.isAd()));
    }

    this.initPlayer();
  }

  private setCurrentTrailer() {
    const currentIsAd = !!this.currentVideo?.isAd();
    this.currentVideo = this.currentVideos[this.videoIndex];

    if (this.currentVideo) {
      this.currentVideo.autoPlay = currentIsAd;
    }
  }

  canSkipVideo() {
    return this.api?.currentTime >= this.currentVideo?.skipTime && this.currentVideo.canBeSkipped();
  }

  getEmbededHtml(url: string): any {
    if (!url) {
      return;
    }

    let returnValue: any;
    const embedded = this.embedVideoService.embed(url);
    if (url.includes('vimeo')) {
      const regex = /vimeo.com\/video\/([0-9a-z\-_]+)/;
      const matches = regex.exec(embedded?.changingThisBreaksApplicationSecurity);
      returnValue = embedded?.changingThisBreaksApplicationSecurity.replace(matches[0], matches[0] + '?dnt=true');
    } else if (url.includes('youtube') || url.includes('youtu.be')) {
      returnValue = embedded?.changingThisBreaksApplicationSecurity.replace('youtube.com', 'youtube-nocookie.com');
    }

    return this.sanitized.bypassSecurityTrustHtml(returnValue);
  }

  private isStreamingResource(): boolean {
    return this.currentVideo.model.trailerType !== TrailerTypeEnum.BRIGHTCOVE_URL && this.currentVideo.source.indexOf('.mp4') > -1;
  }

  private isBrightcove() {
    return (
      this.currentVideo.videoType === VideoType.Trailer &&
      (this.currentVideo.model.trailerType === TrailerTypeEnum.BRIGHTCOVE_ID || this.currentVideo.model.trailerType === TrailerTypeEnum.BRIGHTCOVE_URL)
    );
  }

  private embedBrightcovePlayer() {
    if (!this.brightcoveAccountId) {
      return;
    }

    if (!this.brightcoveConfig) {
      this.brightcoveConfig = {
        refNode: '#embeded-player',
        accountId: this.brightcoveAccountId,
        playerId: this.brightcovePlayerId,
        options: {
          autoplay: this.autostart,
          controls: false,
          playsinline: true,
          preload: 'true',
        },
      };

      if (this.environment['brightcove']) {
        this.brightcoveConfig = Object.assign(this.brightcoveConfig, this.environment['brightcove']);
      }
    }

    setTimeout(() => {
      brightcovePlayerLoader.reset();
      brightcovePlayerLoader(Object.assign(this.brightcoveConfig, { videoId: this.currentVideo.source }))
        .then(function (playerObj) {
          const player = playerObj.ref;
          if (player) {
            player.ready(() => {
              player.loadingSpinner.hide();
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }, 100);
  }
}
