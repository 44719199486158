import { AbstractViewModel } from "../../../abstract.view.model";
import { MoviePrintApiModel } from "../../../api-model/account-items/reservation/movie-print.api.model";

export class MoviePrintViewModel extends AbstractViewModel<MoviePrintApiModel> {
  public id: string;
  public printType: string;
  public soundType: string;
  public speakingType: string;
  public language: string;
  public subtitles: string;
  public subtitles2: string;
  public release: string;
  public format: string;
  public frameRate: string;
  public resolution: string;

  constructor(
    protected apiModel: MoviePrintApiModel = new MoviePrintApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.printType = this.apiModel.printType;
    this.soundType = this.apiModel.soundType;
    this.speakingType = this.apiModel.speakingType;
    this.language = this.apiModel.language;
    this.subtitles = this.apiModel.subtitles;
    this.subtitles2 = this.apiModel.subtitles2;
    this.release = this.apiModel.release;
    this.format = this.apiModel.format;
    this.frameRate = this.apiModel.frameRate;
    this.resolution = this.apiModel.resolution;
  }

  toApiModel(): MoviePrintApiModel {
    return undefined;
  }
}
