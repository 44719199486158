import { AbstractViewModel } from "../../../abstract.view.model";
import { VoucherItemMembershipPriceApiModel } from "../../../api-model/order/voucher-item/voucher-item-membership-price.api.model";

export class VoucherItemMembershipPriceViewModel extends AbstractViewModel<VoucherItemMembershipPriceApiModel> {
  public isAppliedOnOrder: boolean;
  public membershipLevel: number;
  public membershipCardTypeId: string;
  public membershipItemPrice: number;
  public membershipValue: number;

  constructor(protected apiModel: VoucherItemMembershipPriceApiModel = new VoucherItemMembershipPriceApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipItemPrice = this.apiModel.membershipItemPrice;
    this.membershipValue = this.apiModel.membershipValue;
  }

  toApiModel(): VoucherItemMembershipPriceApiModel {
    return undefined;
  }
}
