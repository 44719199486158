import {Expose} from 'class-transformer';

export class UserSetpasswordRequestModel {
  @Expose()
  public passwordToken: string;

  @Expose()
  public password: string;

  @Expose()
  public oldPassword: string;
}
