import { panValue } from 'libs/core/src/lib/tool/number/number';
import { AbstractViewModel } from '../../../abstract.view.model';
import { ScreeningItemApiModel } from '../../../api-model/order/screening-item/screening-item.api.model';
import { SeatGroupViewModel } from '../../screen/seat/seat-group.view.model';
import { TicketViewModel } from '../../shared/ticket/ticket.view.model';
import { ScreeningItemExtraFeeViewModel } from './screening-item-extra-fee.view.model';
import { ScreeningItemMembershipPriceViewModel } from './screening-item-membership-price.view.model';

export enum OrderItemType {
  FB_ITEM = 3,
  VOUCHER = 4,
}

export class ScreeningItemViewModel extends AbstractViewModel<ScreeningItemApiModel> {
  id: string;
  seatId: string;
  screeningId: string;
  ticketId: string;
  price: number;
  ticketPrice: number;
  ticketPriceWithMandatoryExtraFees: number;
  ticketDefaultPriceLevelPriceWithMandatoryExtraFees: number;
  quantity: number;
  tax: number;
  extraFees: ScreeningItemExtraFeeViewModel[] = [];
  bookingId: string;
  cardId: string;
  optionalExtraFees: string[] = [];
  name: string;
  printoutName: string;
  voucherNumber: string;
  voucherName: string;
  defaultPriceLevelPrice: number;
  ticketDefaultPriceLevelPrice: number;
  itemEarnedPoints: number;
  itemRedemptionPoints: number;
  promotionId: string;
  promotionName: string;

  // customFields
  row: string;
  seat: string;
  group: SeatGroupViewModel = null;
  groupScreeningItems: ScreeningItemViewModel[];
  ticket: TicketViewModel;
  membershipPrices: ScreeningItemMembershipPriceViewModel[] = [];

  constructor(protected apiModel: ScreeningItemApiModel = new ScreeningItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.seatId = this.apiModel.seatId;
    this.screeningId = this.apiModel.screeningId;
    this.ticketId = this.apiModel.ticketId;
    this.price = this.apiModel.price;
    this.ticketPrice = this.apiModel.ticketPrice;
    this.ticketPriceWithMandatoryExtraFees = this.apiModel.ticketPriceWithMandatoryExtraFees;
    this.ticketDefaultPriceLevelPriceWithMandatoryExtraFees = this.apiModel.ticketDefaultPriceLevelPriceWithMandatoryExtraFees;
    this.quantity = this.apiModel.quantity;
    this.tax = this.apiModel.tax;
    this.extraFees = this.apiModel.extraFees?.map((extraFee) => new ScreeningItemExtraFeeViewModel(extraFee));
    this.bookingId = this.apiModel.bookingId;
    this.optionalExtraFees = this.apiModel.optionalExtraFees?.map((optionalExtraFee) => optionalExtraFee);
    this.name = this.apiModel.name;
    this.printoutName = this.apiModel.printoutName;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.voucherName = this.apiModel.voucherName;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
    this.ticketDefaultPriceLevelPrice = this.apiModel.ticketDefaultPriceLevelPrice;
    this.itemEarnedPoints = this.apiModel.itemEarnedPoints;
    this.itemRedemptionPoints = this.apiModel.itemRedemptionPoints;
    this.promotionId = this.apiModel.promotionId;
    this.promotionName = this.apiModel.promotionName;
    this.membershipPrices = this.apiModel.membershipPrices?.map((membershipPrice) => new ScreeningItemMembershipPriceViewModel(membershipPrice));
    this.row = this.apiModel.row;
    this.seat = this.apiModel.seat;
  }

  toApiModel(): ScreeningItemApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      seatId: this.seatId,
      screeningId: this.screeningId,
      ticketId: this.ticketId,
      quantity: this.quantity,
      extraFees: this.extraFees.map((extraFee) => extraFee.toApiModel()),
      optionalExtraFees: this.optionalExtraFees,
    });
  }

  public hasVoucher() {
    return Boolean(this.voucherNumber) || Boolean(this.voucherName);
  }

  public hasVoucherNumber(number: string) {
    return this.voucherNumber === number;
  }

  public get seatPlace(): string {
    return `${panValue(this.row)}_${panValue(this.seat)}`;
  }
}
