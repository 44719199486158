import {Expose } from 'class-transformer';

export class ScreenRowApiModel {

  @Expose()
  id: string;

  @Expose()
  legend: string;

  @Expose()
  coordinate: number;

}
