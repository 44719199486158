import { Inject, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsHelper } from '../helper/cms.helper';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { MovieHttpService } from '../http/movie.http.service';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { MovieCopyRequestModel } from '../model/request/movie-copy.request.model';
import { MovieRequestModel } from '../model/request/movie.request.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { WordpressDataProvider } from './wordpress.data-provider';

@Injectable({
  providedIn: 'root',
})
export class MovieDataProvider {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private movieHttpService: MovieHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private wordpressDataProvider: WordpressDataProvider,
    private cmsHelper: CmsHelper
  ) {}

  getMovieById(id: string) {
    if (this.cmsHelper.canUseCms) {
      return this.wordpressDataProvider.getMovieById(id);
    }

    return this.movieHttpService.getMovie(id).pipe(map((result) => new MovieViewModel(result)));
  }

  getMovies(requestModel: MovieRequestModel) {
    const restEndpoint = this.movieHttpService.getMovies(requestModel);
    if (this.cmsHelper.canUseCms) {
      return forkJoin({
        restMovies: restEndpoint,
        wpMovies: this.wordpressDataProvider.getMovies(),
      }).pipe(map((res) => res.wpMovies.filter((movie) => res.restMovies.some((o) => o.id === movie.id))));
    }

    return restEndpoint.pipe(map((result) => result.map((movie) => new MovieViewModel(movie))));
  }

  getMovieCopiesByCienemaId(model: MovieCopyRequestModel) {
    return this.movieCopyHttpService.getMoviePrints(model).pipe(map((result) => result.map((copy) => new MoviePrintViewModel(copy))));
  }
}
