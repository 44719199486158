import { DateTime } from 'luxon';

export class WeekdayApiModel {
  public day_of_week: number; //start from 0 (Monday)
  public translation: string;
  public included: string[];
  public excluded: string[];
}

export class WeekdayViewModel {
  public day_of_week: number;
  public translation: string;
  public included: string[];
  public excluded: string[];

  constructor(protected apiModel: WeekdayApiModel = new WeekdayApiModel()) {
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.day_of_week = this.apiModel.day_of_week + 1; //because luxon DateTime.weekday start from 1 (Monday)
    this.translation = this.apiModel.translation;
    this.included = this.apiModel.included;
    this.excluded = this.apiModel.excluded;
  }
}
