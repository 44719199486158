import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserEventApiModel } from '../../../api-model/user-history/event/user-event.api.model';
import { UserMovieGenreViewModel } from '../shared/user-movie-genre.view.model';
import { UserMoviePictureViewModel } from '../shared/user-movie-picture.view.model';
import { UserMovieRatingViewModel } from '../shared/user-movie-rating.view.model';
import { UserMovieTagViewModel } from '../shared/user-movie-tag.view.model';
import { UserEventItemViewModel } from './user-event-item.view.model';

export class UserEventViewModel extends AbstractViewModel<UserEventApiModel> {
  public screeningId: string = null;
  public cinemaId: string = null;
  public eventId: string = null;
  public eventName: string = null;
  public eventTimeFrom: DateTime = null;
  public eventTimeTo: DateTime = null;
  public eventDescription: string = null;
  public posters: string[] = [];
  public pictures: UserMoviePictureViewModel[] = [];
  public genres: UserMovieGenreViewModel[] = [];
  public ratings: UserMovieRatingViewModel[] = [];
  public tags: UserMovieTagViewModel[] = [];
  public eventItems: UserEventItemViewModel[] = [];

  constructor(protected apiModel: UserEventApiModel = new UserEventApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.screeningId = this.apiModel.screeningId;
    this.cinemaId = this.apiModel.cinemaId;
    this.eventId = this.apiModel.eventId;
    this.eventName = this.apiModel.eventName;
    this.eventTimeFrom = this.apiModel.eventTimeFrom ? DateTime.fromISO(this.apiModel.eventTimeFrom, { setZone: true }) : null;
    this.eventTimeTo = this.apiModel.eventTimeTo ? DateTime.fromISO(this.apiModel.eventTimeTo, { setZone: true }) : null;
    this.eventDescription = this.apiModel.eventDescription;
    this.posters = this.apiModel.posters ? this.apiModel.posters.map((x) => x) : [];
    this.pictures = this.apiModel.pictures ? this.apiModel.pictures.map((picture) => new UserMoviePictureViewModel(picture)) : [];
    this.genres = this.apiModel.genres ? this.apiModel.genres.map((genre) => new UserMovieGenreViewModel(genre)) : [];
    this.ratings = this.apiModel.ratings ? this.apiModel.ratings.map((rating) => new UserMovieRatingViewModel(rating)) : [];
    this.tags = this.apiModel.tags ? this.apiModel.tags.map((tag) => new UserMovieTagViewModel(tag)) : [];
    this.eventItems = this.apiModel.eventItems ? this.apiModel.eventItems.map((tag) => new UserEventItemViewModel(tag)) : [];
  }

  toApiModel(): UserEventApiModel {
    return undefined;
  }
}
