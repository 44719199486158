import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(protected router: Router) {}

  back(): void {
    window.history.back();
  }

  locationHref(href: string = '/'): void {
    window.location.href = href;
  }

  goToHomePage(signin?: boolean) {
    if (signin) {
      this.router.navigate(['screening'], { queryParams: { signin: 'true' } });
    } else {
      this.router.navigate(['screening']);
    }
  }
}
