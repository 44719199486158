import {Expose, Type} from 'class-transformer';
import { RefundPaymentApiModel } from './refund-payment.api.model';

export class RefundApiModel {
  @Expose()
  refundTotalValue: number;

  @Expose()
  @Type(() => RefundPaymentApiModel)
  public paymentTypeList: RefundPaymentApiModel[];
}
