import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { CardItemApiModel } from './card-item/card-item.api.model';
import { CardTypeItemApiModel } from './card-type-item/card-type-item.api.model';
import { DefaultExtraFeeApiModel } from './default-extra-fee/default-extra-fee.api.model';
import { FbItemApiModel } from './fb-item/fb-item.api.model';
import { OrderMembershipPriceApiModel } from './order-membership-price.api.model';
import { PaymentMethodApiModel } from './payment-method/payment-method.api.model';
import { ScreeningItemApiModel } from './screening-item/screening-item.api.model';
import { VoucherItemApiModel } from './voucher-item/voucher-item.api.model';

export class OrderApiModel {
  @Expose()
  id: string;

  @Expose()
  bookingId: string;

  @Expose()
  dateEntry: string;

  @Expose()
  dateTimeToLive: string;

  @Expose()
  status: number;

  @Expose()
  userFirstName: string;

  @Expose()
  userLastName: string;

  @Expose()
  userPhone: string;

  @Expose()
  userEmail: string;

  @Expose()
  @Type(() => ScreeningItemApiModel)
  screeningItems: ScreeningItemApiModel[] = [];

  @Expose()
  @Type(() => CardTypeItemApiModel)
  cardTypeItems: CardTypeItemApiModel[] = [];

  @Expose()
  @Type(() => VoucherItemApiModel)
  voucherItems: VoucherItemApiModel[] = [];

  @Expose()
  @Type(() => FbItemApiModel)
  fbItems: FbItemApiModel[] = [];

  @Expose()
  @Type(() => CardItemApiModel)
  cardItems: CardItemApiModel[] = [];

  @Expose()
  externalUserId: string;

  @Expose()
  cardId: string;

  @Expose()
  @Type(() => PaymentMethodApiModel)
  paymentMethods: PaymentMethodApiModel[] = [];

  @Expose()
  valueToPay: number;

  @Expose()
  totalValue: number;

  @Expose()
  totalNetValue: number;

  @Expose()
  totalTaxValue: number;

  @Expose()
  defaultPriceLevelTotalValue: number;

  @Expose()
  taxId: string;

  @Expose()
  orderNumber: string;

  @Expose()
  totalEarnedPoints: number;

  @Expose()
  totalRedemptionPoints: number;

  @Expose()
  @Type(() => DefaultExtraFeeApiModel)
  defaultExtraFees: DefaultExtraFeeApiModel[] = [];

  @Expose()
  @Type(() => OrderMembershipPriceApiModel)
  membershipPrices: OrderMembershipPriceApiModel[] = [];

  @Expose()
  documentNumber: string;

  @Expose()
  salesDocumentId: string;
}
