export enum SupportedDateTimeFormat {
  DAY_SHORT = 'DAY_SHORT',
  MONTH_SHORT = 'MONTH_SHORT',
  DATE_SHORT = 'DATE_SHORT',
  DATE_SHORT_WITH_WEEKDAY_SHORT = 'DATE_SHORT_WITH_WEEKDAY_SHORT',
  DATE_SHORT_WITH_WEEKDAY = 'DATE_SHORT_WITH_WEEKDAY',
  DATE_SHORT_WITH_WEEKDAY_FULL = 'DATE_SHORT_WITH_WEEKDAY_FULL',
  DAY_MONTH_WITH_WEEKDAY_SHORT = 'DAY_MONTH_WITH_WEEKDAY_SHORT',
  TIME_SIMPLE = 'TIME_SIMPLE',
  MONTH_WIDE = 'MONTH_WIDE',
  CARD_EXPIRATION = 'CARD_EXPIRATION',
}
