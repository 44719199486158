import {Expose} from 'class-transformer';
export class UserFavotiePaymentApiModel {
  @Expose()
  providerId: string;

  @Expose()
  token: string;

  @Expose()
  paymentTypeName: string;

  @Expose()
  paymentTypeDetails: string;
  
  @Expose()
  paymentExpirationDate: string;

  @Expose()
  creationDate: string;

  @Expose()
  paymentTypeId: string;
}
