export class VoucherTypeModel {
  public id: string;
  public name: string;
  public itemId: string;
  public price: number;
  public taxRate: number;
  public description: string;

  public get taxPrice() {
    return this.price - this.price / (1.0 + this.taxRate / 100.0);
  }
}
