import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() minDate: DateTime;
  @Input() maxDate: DateTime;
  @Input() initialDate: DateTime;
  @Input() isDisabled: boolean;
  @Input() showMeridian = false;
  @Input() showSpinners = true;
  @Input() placeholder = '';
  @Output() dateTimeChange = new EventEmitter<DateTime>();

  dateTime: FormGroup;
  propagateChange = (_: any) => {};

  public get min() {
    return this.minDate?.toJSDate();
  }

  public get max() {
    return this.maxDate?.toJSDate();
  }

  public get initial() {
    return this.initialDate?.toJSDate();
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialDate && !changes.initialDate.isFirstChange()) {
      this.dateTime.patchValue({
        date: this.formatDate(this.initialDate),
        time: this.formatTime(this.initialDate),
      });
      this.propagateChange(this.initialDate);
    }
  }

  writeValue(date: DateTime): void {
    if (date) {
      this.dateTime.patchValue({
        date: this.formatDate(date),
        time: this.formatTime(date),
      });
      this.propagateChange(date);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  updateDateTime() {
    if (this.dateTime.valid) {
      const date = DateTime.fromJSDate(this.dateTime.value.date);
      const time = DateTime.fromJSDate(this.dateTime.value.time);
      const selectedDate = this.mergeDatesWithTime(date, time);

      this.dateTimeChange.emit(selectedDate);
      this.propagateChange(selectedDate);
    }
  }

  private initializeForm() {
    this.dateTime = this.fb.group({
      date: ['', [Validators.required]],
      time: ['', Validators.required],
    });

    if (this.initialDate) {
      this.dateTime.patchValue({
        date: this.formatDate(this.initialDate),
        time: this.formatTime(this.initialDate),
      });
      this.propagateChange(this.initialDate);
    }

    this.dateTime.valueChanges.subscribe(() => {
      this.updateDateTime();
    });
  }

  private formatDate(date: DateTime): string {
    return date.toFormat('yyyy-MM-dd');
  }

  private formatTime(date: DateTime): string {
    return date.toFormat('HH:mm');
  }

  private mergeDatesWithTime(dateWithDay: DateTime, timeOnly: DateTime): DateTime | null {
    if (!dateWithDay || !timeOnly) {
      return null;
    }

    const dateWithoutTime = dateWithDay.startOf('day');
    const mergedDate = dateWithoutTime.plus({ hours: timeOnly.hour, minutes: timeOnly.minute });
    return mergedDate;
  }
}
