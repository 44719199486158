import {Expose} from 'class-transformer';

export class CanBeRefundItemExtraFeeApiModel {
  @Expose()
  extraFeeItemId: string;

  @Expose()
  extraFeeName: string;

  @Expose()
  extraFeeQuantity: number;

  @Expose()
  extraFeeRefundValue: number;

  @Expose()
  isRefundable: boolean;
}
