import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherRedeemDayOfScreeningApiModel } from '../../api-model/voucher/voucher-redeem-day-of-screening.api.model';

export class VoucherRedeemDayOfScreeningViewModel extends AbstractViewModel<VoucherRedeemDayOfScreeningApiModel> {
  dayOfWeek: number;
  flgDayRedeemable: boolean;

  constructor(protected apiModel: VoucherRedeemDayOfScreeningApiModel = new VoucherRedeemDayOfScreeningApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.dayOfWeek = this.apiModel.dayOfWeek;
    this.flgDayRedeemable = this.apiModel.flgDayRedeemable;
  }

  toApiModel(): VoucherRedeemDayOfScreeningApiModel {
    return undefined;
  }
}
