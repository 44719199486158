import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent {
  @Input() title: string;

  private _active: boolean;
  @Input()
  set active(value: boolean) {
    this._active = value;
    if (value) {
      this.activated.emit();
    }
  }
  get active(): boolean {
    return this._active;
  }

  @Output() activated = new EventEmitter();
}
