import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { ModalEventEmitter, ModalStatusEnum } from '../../model/modal-status.enum';

@Component({
  template: ''
})
export class RefundSuccessModalComponent implements OnInit {

  @Output() event = new EventEmitter<ModalEventEmitter>();
  @Input() public content: any;
  @Input() public lang: string;
  
  public ticketsCount: number;
  public allTicketsCount: number;

  constructor(protected bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  close(redirect: boolean = false) {
    this.event.emit({state: redirect ? ModalStatusEnum.back : ModalStatusEnum.close});
    this.bsModalRef.hide();
  }
}
