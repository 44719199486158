import { AbstractViewModel } from "../../abstract.view.model";
import { UserPreferencesApiModel } from "../../api-model/user/user-preferences.api.model";

export class UserPreferencesViewModel extends AbstractViewModel<UserPreferencesApiModel> {
  public cinemaId: string = null;
  public cinemaName: string = null;
  public languageId: string = null;
  public languageSymbol: string = null;
  public speakingTypeId: string = null;
  public speakingTypeName: string = null;

  constructor(
    protected apiModel: UserPreferencesApiModel = new UserPreferencesApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.cinemaId = this.apiModel.cinemaId;
    this.cinemaName = this.apiModel.cinemaName;
    this.languageId = this.apiModel.languageId;
    this.languageSymbol = this.apiModel.languageSymbol;
    this.speakingTypeId = this.apiModel.speakingTypeId;
    this.speakingTypeName = this.apiModel.speakingTypeName;
  }

  toApiModel(): UserPreferencesApiModel {
    return undefined;
  }
}
