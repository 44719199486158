<div class="main-container mt-1">
  <ng-container *ngIf="movieScheduleCollection?.length > 0; else noScreenings">
    <div class="row ">
      <ng-container *ngFor="let movieSchedule of movieScheduleCollection">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 my-3"
             *ngIf="movieSchedule.isPrintable() && showOnlyGeneralAdmission === false || (showOnlyGeneralAdmission === true && movieSchedule.hasAnyGeneralAdmission())">
          <app-screening-list-item [movieSchedule]="movieSchedule" [cinema]="cinema"
                                   [showOnlyGeneralAdmission]="showOnlyGeneralAdmission">
          </app-screening-list-item>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noScreenings>
  <div class="row justify-content-center">
    <span style="font-size: 14px">
      {{'screening.empty_schedule' | translate}}
    </span>
  </div>
</ng-template>