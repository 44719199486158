import { AbstractViewModel } from '../../abstract.view.model';
import { TrailerApiModel } from '../../api-model/movie/trailer.api.model';

export class TrailerViewModel extends AbstractViewModel<TrailerApiModel> {
  trailerType: string;
  lang: string;
  value: string;

  constructor(protected apiModel: TrailerApiModel = new TrailerApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.trailerType = this.apiModel.type;
    this.lang = this.apiModel.lang;
    this.value = this.apiModel.value;
  }

  toApiModel(): TrailerApiModel {
    return undefined;
  }
}

export enum TrailerTypeEnum {
  BRIGHTCOVE_ID = 'brightcove-id',
  BRIGHTCOVE_URL = 'brightcove-url',
  GENERIC = 'generic',
}
