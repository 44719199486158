import { AbstractViewModel } from "../../../abstract.view.model";
import { SeparatorApiModel } from "../../../api-model/screening/separator/separator.api.model";

export class SeparatorViewModel extends AbstractViewModel<SeparatorApiModel> {
  rowNumber: number;
  enabled: boolean;
  name: string;
  price: number;
  tax: number;
 
  constructor(protected apiModel: SeparatorApiModel = new SeparatorApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.rowNumber = this.apiModel.rowNumber;
    this.enabled = this.apiModel.enabled;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.tax = this.apiModel.tax;
  }

  toApiModel(): SeparatorApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
