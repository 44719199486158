import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherApiModel } from '../../api-model/voucher/voucher.api.model';
import { UserVoucherViewModel } from '../user-history/voucher/user-voucher.view.model';
import { VoucherPositionViewModel } from './voucher-position.view.model';

export class VoucherViewModel extends AbstractViewModel<VoucherApiModel>  {
  voucherName: string;
  voucherType: string;
  voucherDescription: string;
  voucherExpiryDate: DateTime;
  voucherNote: string;
  voucherNumber: string;
  batchVouchersID: string;
  flgVoucherType: boolean;
  flgActive: boolean;
  flgHidden: boolean;
  voucherUsesNumber: number;
  numberPerScreening: number;
  numberPerDay: number;
  numberPerWeek: number;
  numberPerMonth: number;
  usesLeft: number;
  usesPerScreeningLeft: number;
  usesPerDayLeft: number;
  usesPerWeekLeft: number;
  usesPerMonthLeft: number;
  flgRealizationDay: boolean;
  flgValidityDay: boolean;
  realizationHoursBeforeScreening: number;
  flgProjectsSpecial: boolean;
  flgMultiuse: boolean;
  voucherGroupId: string;
  voucherGroupName: string;
  positions: VoucherPositionViewModel[] = [];

  constructor(protected apiModel: VoucherApiModel = new VoucherApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.voucherName = this.apiModel.voucherName;
    this.voucherType = this.apiModel.voucherType;
    this.voucherDescription = this.apiModel.voucherDescription;
    this.voucherExpiryDate = this.apiModel.voucherExpiryDate;
    this.voucherNote = this.apiModel.voucherNote;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.batchVouchersID = this.apiModel.batchVouchersID;
    this.flgVoucherType = this.apiModel.flgVoucherType;
    this.flgActive = this.apiModel.flgActive;
    this.flgHidden = this.apiModel.flgHidden;
    this.voucherUsesNumber = this.apiModel.voucherUsesNumber;
    this.numberPerScreening = this.apiModel.numberPerScreening;
    this.numberPerDay = this.apiModel.numberPerDay;
    this.numberPerWeek = this.apiModel.numberPerWeek;
    this.numberPerMonth = this.apiModel.numberPerMonth;
    this.usesLeft = this.apiModel.usesLeft;
    this.usesPerScreeningLeft = this.apiModel.usesPerScreeningLeft;
    this.usesPerDayLeft = this.apiModel.usesPerDayLeft;
    this.usesPerWeekLeft = this.apiModel.usesPerWeekLeft;
    this.usesPerMonthLeft = this.apiModel.usesPerMonthLeft;
    this.flgRealizationDay = this.apiModel.flgRealizationDay;
    this.flgValidityDay = this.apiModel.flgValidityDay;
    this.realizationHoursBeforeScreening = this.apiModel.realizationHoursBeforeScreening;
    this.flgProjectsSpecial = this.apiModel.flgProjectsSpecial;
    this.flgMultiuse = this.apiModel.flgMultiuse;
    this.voucherGroupId = this.apiModel.voucherGroupId;
    this.voucherGroupName = this.apiModel.voucherGroupName;
    this.positions = this.apiModel.positions.map(model => new VoucherPositionViewModel(model));
  }

  toApiModel(): VoucherApiModel {
    return undefined;
  }

  toUserVoucherViewModel(): UserVoucherViewModel {
    const model = new UserVoucherViewModel();
    model.batchVoucherID = this.batchVouchersID;
    //model.voucherID = this.voucherID;
    model.voucherNumber = this.voucherNumber;
    model.voucherName = this.voucherName;
    model.voucherDescription = this.voucherDescription;
    model.voucherPrice = this.positions && this.positions.length ? this.positions[0].price : 0;
    //model.voucherFlgUnlimited = this.voucherFlgUnlimited;
    model.voucherUsageLeft = this.usesLeft;
    model.voucherExpiryDate = this.voucherExpiryDate;
    model.voucherNote = this.voucherNote;
    model.flgMultiUse = this.flgMultiuse;
    model.flgActive = this.flgActive;
    model.flgHidden = this.flgHidden;
    model.voucherGroupId = this.voucherGroupId;
    model.voucherGroupName = this.voucherGroupName;
    return model;
  }
}