import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum SendProcessState {
  NONE,
  PENDING,
  FAILED,
  COMPLETED,
}

@Component({
  selector: 'app-send-box',
  templateUrl: './send-box.component.html',
})
export class SendBoxComponent implements OnInit {
  @Input() title: string;
  @Input() inputPlaceholder = '';
  @Input() buttonLabel = '';
  @Input() emailValidatorPatern = '';
  @Input() inProgress = false;

  @Output() sendButtonClick = new EventEmitter<string>();

  public form: FormGroup;
  public formSubmitAttempt = false;

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailValidatorPatern)]),
    });
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      const email = this.form.get('email')?.value;
      if (email?.length) {
        this.sendButtonClick.emit(email);
      }
    }
  }
}
