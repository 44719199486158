import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var OpenPayU: any;

@Injectable({
  providedIn: 'root',
})
export class PayuExpressService {
  public getCardToken(merchantId: string): Observable<string> {
    OpenPayU.merchantId = merchantId;

    return new Observable<string>((subscriber) => {
      const result = OpenPayU.Token.create({}, (data) => {
        if (data) {
          if (data['data'] && data['data']['token']) {
            subscriber.next(data['data']['token']);
          } else if (data['status']) {
            subscriber.error({
              code: data['status']['code'],
              message: data['status']['codeLiteral'],
              status: data['status']['statusCode'],
            });
          }
        }

        subscriber.complete();
      });

      if (result !== true) {
        subscriber.error(result);
        subscriber.complete();
      }
    });
  }
}
