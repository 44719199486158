<div class="row align-items-center mt-3 pl-3 pr-3">
  <div class="col-12 col-sm-12 text-sm-right text-center"></div>

  <div class="col-12 col-sm-12 header mb-3">
    <div class="row p-2">
      <div class="col-4 text-left pl-0">
        {{ 'order.ticketGeneralAdmission.header.tickets' | translate }}
      </div>
      <div class="col-3 text-center">
        {{ 'order.ticketGeneralAdmission.header.price' | translate }} (&nbsp;{{ currency }}&nbsp;)
      </div>
      <div class="col-5 text-right pr-0">
        {{ 'order.ticketGeneralAdmission.header.quantity' | translate }}
      </div>
    </div>
  </div>

  <ng-container *ngFor="let ticket of ticketList">
    <div class="col-12 col-sm-12 text-center">
      <div class="row ticket mt-2 mb-2 p-2">

        <div class="col-4 text-left pl-0">
          {{ ticket.name }}
        </div>

        <div class="col-3 text-right d-flex justify-content-center align-items-center">
          <span class="font-weight-bold"> {{ ticket.price | number:'1.2-2' }} {{currency}}</span>
        </div>

        <div class="col-5 d-flex justify-content-end align-items-center pr-0">
          <span class="increment" [ngClass]="{ 'disabled': limit === 0 || !selectedTickets[ticket.id] }"
                (click)="onDecrementClick(ticket)"><i class="fa fa-minus"></i></span>
          <b
             class="d-flex justify-content-center align-items-center count px-3">{{ selectedTickets[ticket.id] ? selectedTickets[ticket.id] : 0 }}</b>
          <span class="increment"
                [ngClass]="{ 'disabled': limit === 0 || (ticketUsedByGroup[ticket.seatGroupId] >= ticketLimitByGroup[ticket.seatGroupId]) || ticket.availableAmount === 0  || sumAllTicketUsed >= limit}"
                (click)="onIncrementClick(ticket)"><i class="fa fa-plus"></i></span>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="col-12 col-sm-12 text-center">
    <div class="row ticket mt-2 mb-2 p-2">

      <div class="col-7 text-left pl-0">
        <b>{{ 'order.summary.total' | translate }}</b>
      </div>
      <div class="col-5 text-right pr-0">
        <b>{{ totalValue | number:'1.2-2'}} {{currency}}</b>
      </div>
    </div>
  </div>
</div>