import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable } from 'rxjs';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { AppService } from 'libs/core/src/lib/service/app.service';

@Component({
  selector: 'app-payment-provider-fiservdev-component',
  templateUrl: './fiservdev-payment-provider.component.html',
})
export class FiservDevPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public readonly paymentProviderIdentifier: string = 'fiservdev';
  private readonly paymentChannel: string | null = null;

  formParams: { key: string; value: string }[] = [];

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected appService: AppService) {
    this.paymentChannel = environment.constants.paymentChannel;
  }

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
      subscriber.next(preInitPaymentEvent);
      subscriber.complete();
    });
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    this.events.next(new WorkPaymentProviderEvent());

    const urlParts = paymentModel.plainPayload.split('?');
    const actionUrl = urlParts[0];
    const queryParamsString = urlParts[1];
    const queryParams = new URLSearchParams(queryParamsString);

    queryParams.forEach((value, key) => {
      this.formParams.push({ key, value });
    });

    setTimeout(() => {
      const form = document.getElementById('fiservDevPaymentForm') as HTMLFormElement;
      form.setAttribute('method', 'post');
      form.setAttribute('action', actionUrl);
      if (form) {
        form.submit();
      }
    }, 1);
  }

  public onLoad() {
    this.events.next(new DonePaymentProviderEvent());
  }
}
