import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutTemplateType } from 'libs/core/src/lib/enum/layout-template.enum';
import { AllowedGuard } from 'libs/core/src/lib/guard/allowed.guard';
import { ErrorGuard } from 'libs/core/src/lib/guard/error.guard';
import { HomePageComponent } from 'libs/shared/src/lib/page/home/home.component';
import { LeavePageGuard } from 'libs/core/src/lib/guard/leave-page.guard';
import { CustomMessagePageComponent } from './page/message/message.component';
import { CustomOrderSummaryPageComponent } from './page/order-summary/order-summary.component';
import { CustomPaymentPageComponent } from './page/payment/payment.component';
import { CustomBasketPageComponent } from './page/basket/basket.component';
import { CustomScreenPageComponent } from './page/screen/screen.component';
import { CustomScreeningPageComponent } from './page/screening/screening.component';
import { CustomCoreComponent } from './component/core/core.component';
import { TimeZoneTestPageComponent } from 'libs/shared/src/lib/page/test/tz.component';
import { CustomPersonalPageComponent } from './page/personal/personal.component';
import { CustomUserPageComponent } from './page/user/user-page.component';
import { LoggedGuard } from 'libs/core/src/lib/guard/logged.guard';
import { CustomGiftCardsPageComponent } from './page/gift-cards/gift-cards-page.component';
import { CustomRefundPageComponent } from './page/refund/refund.component';
import { CustomUserRegisterComponent } from './page/user/register/user-register.component';
import { CustomWorldpayRedirectHubComponent } from './page/worldpay/worldpay-redirect-hub.component';
import { CustomTicketsPageComponent } from './page/tickets/tickets.component';

const routes: Routes = [
  {
    path: 'p',
    component: CustomWorldpayRedirectHubComponent,
    data: {
      pageIdentify: 'paymentstate',
      templateType: LayoutTemplateType.Simple,
    },
  },
  {
    path: '',
    component: CustomCoreComponent,
    data: {
      templateType: LayoutTemplateType.Default,
    },
    children: [
      {
        path: '',
        component: HomePageComponent,
        data: {
          pageIdentify: 'home',
        },
      },
      {
        path: 'test/timezone',
        component: TimeZoneTestPageComponent,
        data: {
          pageIdentify: 'test-timezone',
        },
      },
      {
        path: 'screening',
        component: CustomScreeningPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'screening',
        },
      },
      {
        path: 'screen',
        component: CustomScreenPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'screen',
        },
      },
      {
        path: 'tickets',
        component: CustomTicketsPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'tickets',
        },
      },
      {
        path: 'basket',
        component: CustomBasketPageComponent,
        canActivate: [AllowedGuard],
        canDeactivate: [LeavePageGuard],
        data: {
          pageIdentify: 'basket',
        },
      },
      {
        path: 'personal',
        component: CustomPersonalPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'personal',
        },
      },
      {
        path: 'payment',
        component: CustomPaymentPageComponent,
        canActivate: [AllowedGuard],
        canDeactivate: [LeavePageGuard],
        data: {
          pageIdentify: 'payment',
        },
      },
      {
        path: 'order/summary',
        component: CustomOrderSummaryPageComponent,
        data: {
          pageIdentify: 'summary',
        },
      },
      {
        path: 'refund',
        component: CustomRefundPageComponent,
        data: {
          pageIdentify: 'refund',
        },
      },
      {
        path: 'user/:displaymode/:token',
        component: CustomUserRegisterComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'user/:displaymode',
        component: CustomUserRegisterComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'user',
        canActivate: [LoggedGuard],
        component: CustomUserPageComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'gc',
        component: CustomGiftCardsPageComponent,
      },
      {
        path: 'error/:id',
        component: CustomMessagePageComponent,
        canActivate: [ErrorGuard],
        data: {
          pageIdentify: 'error',
        },
      },
      {
        path: '**',
        redirectTo: 'error/404',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
