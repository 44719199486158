import {Expose} from 'class-transformer';

export class OrderAliasApiModel {
  @Expose()
  cinemaId: string;

  @Expose()
  salesDocumentId: string;

  @Expose()
  bookingId: string;
}
