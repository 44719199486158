<div class="mt-3 with-content voucher-card">
  <ng-container *ngFor="let voucher of order?.getVouchers(); let last = last">
    <div class="col-12 px-0" [ngClass]="{ 'mb-3': last }">
      <app-voucher-card-summary [voucher]="voucher" (voucherRemoveEvent)="onRemove($event)"
                                [formLocked]="formLocked"></app-voucher-card-summary>
    </div>
  </ng-container>

  <div class="d-flex flex-column" [ngClass]="{'gap-05': type !== null}">
    <div class="d-flex gap-05 align-items-center">
      <span>{{'shared.use' | translate}}</span>
      <button class="btn btn-aslink p-0 m-0 line-height-1"
              (click)="changeType(typeEnum.VOUCHER)">{{'voucher.voucher' | translate}}</button>
      <span>{{'shared.or' | translate}}</span>
      <button class="btn btn-aslink p-0 m-0 line-height-1"
              (click)="changeType(typeEnum.GIFT_CARD)">{{'gift-card.gift-card' | translate}}</button>
    </div>

    <div class="">
      <ng-container *ngIf="type === typeEnum.VOUCHER">
        <app-voucher-input [formLocked]="formLocked"></app-voucher-input>
      </ng-container>
      <ng-container *ngIf="type === typeEnum.GIFT_CARD">
        <app-card-input [formLocked]="formLocked"></app-card-input>
      </ng-container>
    </div>

    <div *ngIf="authStateService.userIsLoggedAndTokenIsValid()" class="d-flex justify-content-between">
      <a [routerLink]="['/user']" fragment='home'>{{'user.user-my-profile.goToRewards' | translate}}</a>
    </div>
  </div>
</div>