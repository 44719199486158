import {Component} from '@angular/core';

@Component({
  selector: 'app-contributor-component',
  templateUrl: './app-contributor.component.html'
})
export class AppContributorComponent {


}
