import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-today-tomorrow',
  templateUrl: './today-tomorrow.component.html',
})
export class TodayTomorrowComponent {
  public display: 'today' | 'tomorrow' | null = null;

  @Input()
  set date(value: DateTime | null | undefined) {
    if (value?.isValid) {
      this.onDateChange(value);
    }
  }
  @Input() spanClass: string = '';

  private onDateChange(value: DateTime) {
    const today = DateTime.utc().startOf('day');
    const startOfThisDay = value.setZone('utc').startOf('day');
    const startOfTomorrow = today.plus({ days: 1 });

    this.display = this.getDisplayValue(startOfThisDay, today, startOfTomorrow);
  }

  private getDisplayValue(startOfThisDay: DateTime, startOfToday: DateTime, startOfTomorrow: DateTime): 'today' | 'tomorrow' | null {
    if (startOfThisDay.equals(startOfToday)) {
      return 'today';
    } else if (startOfThisDay.equals(startOfTomorrow)) {
      return 'tomorrow';
    } else {
      return null;
    }
  }
}
