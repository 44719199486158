import {Expose, Type} from 'class-transformer';
import { TicketExtraFeeApiModel } from './ticket-extra-fee.api.model';
import { TicketSeatApiModel } from './ticket-seat.api.model';

export class TicketApiModel {
  @Expose()
  salesDocumentItemId: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  ticketNumber: string;

  @Expose()
  @Type(() => TicketSeatApiModel)
  seats: TicketSeatApiModel[];

  @Expose()
  @Type(() => TicketExtraFeeApiModel)
  extraFees: TicketExtraFeeApiModel[];
}
