import {Expose, Type} from 'class-transformer';
import { UserFbModifierItemApiModel } from './user-fb-modifier-item.api.model';

export class UserFbModifierApiModel {
  @Expose()
  modifierId: string;

  @Expose()
  modifierName: string;

  @Expose()
  separateItem: number;

  @Expose()
  @Type(() => UserFbModifierItemApiModel)
  modifierItems: UserFbModifierItemApiModel[];
}
