import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherPurchaseItemApiModel } from '../../api-model/voucher/voucher-purchase-item.api.model';

export class VoucherPurchaseItemViewModel extends AbstractViewModel<VoucherPurchaseItemApiModel> {
  id: string;
  name: string;
  priceAdjustment: number;

  constructor(protected apiModel: VoucherPurchaseItemApiModel = new VoucherPurchaseItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.priceAdjustment = this.apiModel.priceAdjustment;
  }

  toApiModel(): VoucherPurchaseItemApiModel {
    return undefined;
  }
}
