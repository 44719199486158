<div class="grid-col4">
  <div class="form-group align-items-center" *ngFor="let item of items">
    <div class="checkbox w-100">
      <label [for]="item.id" [ngClass]="item.checked ? 'checkbox-active' : ''">
        <input type="checkbox" class="form-checkbox form-radio" [id]="item.id" [(ngModel)]="item.checked"
               (ngModelChange)="updateCheckAll()">
        <span [className]="item.checked ? 'checkbox-active cr' : 'cr'">
          <i class="cr-icon fa fa-check"></i>
        </span>
        <span class="label-content" class="w-100">
          {{item.name}}
        </span>
      </label>
    </div>
  </div>
</div>