import { TicketViewModel } from '../../view-model/shared/ticket/ticket.view.model';

export class OrderSummaryItemViewModel {
  private _ticket: TicketViewModel;
  private _quantity: number;
  private _seatIds: string[] = new Array<string>();
  private _ticketSymbols: Array<Array<string | number>> = new Array<Array<string | number>>();

  constructor(ticket: TicketViewModel, quantity: number) {
    this.ticket = ticket;
    this._quantity = quantity;
  }

  get ticket(): TicketViewModel {
    return this._ticket;
  }

  set ticket(value: TicketViewModel) {
    this._ticket = value;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(value: number) {
    this._quantity = value;
  }

  get seatIds(): string[] {
    return this._seatIds;
  }

  set seatIds(value: string[]) {
    this._seatIds = value;
  }

  get ticketSymbols(): Array<Array<string | number>> {
    return this._ticketSymbols;
  }

  set ticketSymbols(value: Array<Array<string | number>>) {
    this._ticketSymbols = value;
  }

  hasVoucher() {
    return this._ticket.voucherNumber;
  }
}
