<div class="m-0">
  <div class="p-0 px-0">
    <div class="text-center col-12 p-0">
      <app-steps [step]="order?.isOnlyGiftCardOrder() || stepsService.FlowType === 'tickets' ? 3 : 4"></app-steps>
    </div>
  </div>

  <div class="container mt-4">
    <div class="main-container">
      <div [smooth-in]="!loadingService.isLoading(loaderEnum.MAIN)">
        <ng-container *ngIf="basketPageModel">
          <div class="row mx-0 d-block d-md-none">
            <div class="col-12 m-3">
              <app-simple-countdown></app-simple-countdown>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-12 mx-0" [class]="basketPageModel?.screen ? 'col-md-3': 'd-none'">
              <app-book-movie-list [screen]="basketPageModel?.screen"
                                   [cinemaId]="order?.cinemaId"></app-book-movie-list>
            </div>
            <div class="col-12" [class]="basketPageModel?.screen ? 'col-md-9': ''">
              <div class="row d-none d-md-block">
                <div class="col-12">
                  <app-simple-countdown></app-simple-countdown>
                </div>
              </div>

              <div class="row mx-0">
                <div class="col-12 col-lg-6 mt-4">
                  <div>
                    <app-sidebar class="w-100">
                      <div sidebar-title>
                        {{ 'basket.title' | translate }}
                      </div>
                      <div class="cart-sidebar" sidebar-body>
                        <ng-container *ngIf="order && basketPageModel">
                          <app-order-order-summary [basketPageModel]="basketPageModel"
                                                   buttonText="{{'personal.personal.form.submit'|translate}}"
                                                   [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList"
                                                   [order]="order" [submitButtonShow]="false">
                          </app-order-order-summary>
                        </ng-container>
                      </div>
                    </app-sidebar>
                  </div>
                  <div class="mt-4 pt-3 payment-box" [class.d-none]="canCompleteOrder()">
                    <app-sidebar class="w-100">
                      <div sidebar-title>
                        {{'payment.title' | translate}}
                      </div>
                      <div class="cart-sidebar" sidebar-body>
                        <div class="provider">
                          <div class="col-md-12 block px-0">
                            <ng-container *ngFor="let paymentMethod of paymentMethodList">
                              <app-payment-proxy-component #paymentProxyComponent [paymentMethod]="paymentMethod"
                                                           (providerEvent)="onPaymentProviderEvent($event)">
                              </app-payment-proxy-component>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </app-sidebar>
                  </div>

                </div>
                <div class="col-12 col-lg-6 mt-4">
                  <app-sidebar>
                    <div sidebar-title>
                      {{paymentProxyComponent?.paymentInitialized ? ('payment.title' | translate) : ('payment.title2' | translate)}}
                    </div>
                    <div sidebar-body class="d-flex align-items-center">
                      <div class="px-0 w-100" *ngIf="order && !paymentProxyComponent?.paymentInitialized">
                        <div class="col-12 px-0">
                          <app-loyalty-payment-list></app-loyalty-payment-list>
                        </div>
                      </div>

                      <div class="payment-block" *ngIf="paymentMethodList?.length > 0"
                           [hidden]="!paymentProxyComponent?.paymentInitialized">
                        <ng-container *ngFor="let paymentMethod of paymentMethodList">
                          <app-payment-proxy-component #paymentProxyComponent
                                                       *ngIf="selectedPaymentMethod?.id === paymentMethod.id"
                                                       [paymentMethod]="paymentMethod"
                                                       (providerEvent)="onPaymentProviderEvent($event)">
                          </app-payment-proxy-component>
                        </ng-container>
                        <app-loading *ngIf="loadingService.isLoading(loaderEnum.PAYMENT)" [config]="{}"
                                     backdrop-position="relative" size="small"></app-loading>
                      </div>
                    </div>
                  </app-sidebar>
                </div>
              </div>
            </div>
          </div>


          <div class="d-flex justify-content-between mt-3">
            <div class="col-3 p-0">
              <div class="col-12 col-sm-8 col-lg-6 p-0">
                <button (click)="goToPreviousRoute()"
                        class="btn btn-back btn-default btn-block btn-screen-navigation">{{ 'screen.navigation.previous' | translate }}</button>
              </div>
            </div>
            <div class="col-9 p-0">
              <div class="row mx-0 flex justify-content-lg-center justify-content-end">
                <div class="w-75">
                  <ng-container *ngIf="order?.getCount() > 0">
                    <button type="submit"
                            [disabled]="(!selectedPaymentMethod || paymentProxyComponent?.paymentInProgress) && !canCompleteOrder()"
                            (click)="payButtonClicked()" class="btn btn-lg btn-next btn-block btn-screen-navigation">
                      <ng-container *ngIf="!canCompleteOrder()">
                        {{ 'shared.pay' | translate }}
                        {{order?.valueToPay | number:'1.2-2'}}
                        {{environment.constants.currency}}
                      </ng-container>
                      <ng-container *ngIf="canCompleteOrder()">
                        {{ 'order.summary.complete_order' | translate | uppercase }}
                      </ng-container>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="row mx-0 px-3">
            <div class="col-6 col-md-4 mt-4 d-flex justify-content-start p-0">
              <div class="col-12 col-sm-8 col-lg-6 p-0">
                <button type="button" (click)="goToPreviousRoute()"
                        class="btn btn-lg btn-default btn-screen-navigation">{{ 'screen.navigation.previous' | translate }}</button>
              </div>
            </div>
            <div class="col-6 col-md-8 mt-4 d-flex justify-content-end">
              <div class="flex justify-content-lg-center justify-content-end">
                <div class="w-100">
                  <ng-container *ngIf="order?.getCount() > 0">
                    <button type="submit"
                            [disabled]="!selectedPaymentMethod || paymentProxyComponent?.paymentInProgress"
                            (click)="payButtonClicked()" class="btn btn-lg btn-next btn-block btn-screen-navigation">
                      <ng-container *ngIf="!canCompleteOrder()">
                        {{ 'shared.pay' | translate }}
                        {{order?.valueToPay | number:'1.2-2'}}
                        {{environment.constants.currency}}
                      </ng-container>
                      <ng-container *ngIf="canCompleteOrder()">
                        {{ 'order.summary.complete_order' | translate | uppercase }}
                      </ng-container>
                    </button>
                  </ng-container>
                </div>
              </div>

            </div>
          </div> -->
        </ng-container>
      </div>

      <app-loading [id]="loaderEnum.LOYALTY" [show]="false"></app-loading>
    </div>
  </div>
</div>

<app-popup [isVisible]="paymentErrorMessage">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="paymentErrorMessage"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <input type="submit" class="btn btn-lg btn-primary" value="{{ 'screen.popup.close_button' | translate }}"
             (click)="onClickedCloseModal()" />
    </div>
  </div>
</app-popup>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>