import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent {
  @Output() public closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  public _isVisible = false;

  @Input()
  public set isVisible(value: boolean) {
    this._isVisible = value;
  }

  public onCloseButtonClicked(): void {
    this.isVisible = false;
    this.closeButtonClicked.next(null);
  }
}
