import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class UserTokenRequestModel {
  @Expose()
  public grant_type: string;

  @Expose()
  public client_id: string;

  @Expose()
  public refresh_token: string;
}
