import {Expose} from 'class-transformer';

export class MoviePrintApiModel {
  @Expose()
  id: string;

  @Expose()
  printType: string;

  @Expose()
  soundType: string;

  @Expose()
  speakingType: string;

  @Expose()
  language: string;

  @Expose()
  subtitles: string;

  @Expose()
  subtitles2: string;

  @Expose()
  release: string;

  @Expose()
  format: string;

  @Expose()
  frameRate: string;

  @Expose()
  resolution: string;
}
