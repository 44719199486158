import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GenreHttpService } from '../http/genre.http.service';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';
import { IProgramFilterItem } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class GenreDataProvider {
  public constructor(private httpService: GenreHttpService) {}

  public listViaApiModel() {
    return this.httpService.getGenres().pipe(map((models: GenreApiModel[]) => models.map((model: GenreApiModel) => new GenreViewModel(model))));
  }

  listOfFilterItems() {
    return this.httpService.getGenres().pipe(map((items) => items.map((item) => ({ key: item.id, label: item.name } as IProgramFilterItem))));
  }
}
