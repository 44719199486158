import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserOrderApiModel } from '../../../api-model/user-history/order/user-order.api.model';
import { UserPaymentViewModel } from './user-payment.view.model';

export class UserOrderViewModel extends AbstractViewModel<UserOrderApiModel> {
  public id: string = null;
  public bookingId: string = null;
  public reservationId: string = null;
  public saleDate: DateTime;
  public payments: UserPaymentViewModel[] = [];
  public totalValue: number = null;
  public totalNetValue: number = null;
  public totalTaxValue: number = null;
  public orderNumber: string = null;
  public pointsAffected: number;
  public clientName: string;
  public clientLastName: string;
  public clientEmail: string;
  public cinemaId: string;
  public cinemaName: string;

  constructor(protected apiModel: UserOrderApiModel = new UserOrderApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.bookingId = this.apiModel.bookingId;
    this.reservationId = this.apiModel.reservationId;
    this.saleDate = this.apiModel.saleDate ? DateTime.fromISO(this.apiModel.saleDate, { setZone: true }) : null;
    this.payments = this.apiModel.payments.map((payment) => new UserPaymentViewModel(payment));
    this.totalValue = this.apiModel.totalValue;
    this.totalNetValue = this.apiModel.totalNetValue;
    this.totalTaxValue = this.apiModel.totalTaxValue;
    this.orderNumber = this.apiModel.orderNumber;
    this.pointsAffected = this.apiModel.pointsAffected;
    this.clientName = this.apiModel.clientName;
    this.clientLastName = this.apiModel.clientLastName;
    this.clientEmail = this.apiModel.clientEmail;
    this.cinemaId = this.apiModel.cinemaId;
  }

  toApiModel(): UserOrderApiModel {
    return undefined;
  }
}
