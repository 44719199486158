import { DateTime } from "luxon";
import { AbstractViewModel } from "../../abstract.view.model";
import { EventItemApiModel } from "../../api-model/event/event-item.api.model";

export class EventItemViewModel extends AbstractViewModel<EventItemApiModel> {
  movieId: string;
  moviePrintId: string;
  timeFrom: DateTime;
  timeTo: DateTime;
  movieName: string;
  speakingType: string;
  printType: string;
  screenId: string;

  constructor(
    protected apiModel: EventItemApiModel = new EventItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.movieId = this.apiModel.movieId;
    this.moviePrintId = this.apiModel.moviePrintId;  
    this.timeFrom = this.apiModel.timeFrom ? DateTime.fromISO(this.apiModel.timeFrom) : null;
    this.timeTo = this.apiModel.timeTo ? DateTime.fromISO(this.apiModel.timeTo) : null;
    this.movieName = this.apiModel.movieName;
    this.speakingType = this.apiModel.speakingType;
    this.printType = this.apiModel.printType;
    this.screenId = this.apiModel.screenId;
  }
  toApiModel(): EventItemApiModel {
    return undefined;
  }
}
