import { Component, Inject } from '@angular/core';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { UserApiModel } from 'libs/core/src/lib/model/api-model/user/user.api.model';
import { TranslationService } from 'libs/core/src/lib/service/translation.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { SocialAuthService } from 'libs/shared/src/lib/module/auth/public-api';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { Router } from '@angular/router';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';

@Component({
  template: '',
})
export class UserStatusComponent {
  showLoginTemplate = false;
  public user: UserApiModel | undefined;
  public loggedIn: boolean;

  public logoutRedirectPage: string | undefined;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected authStateService: AuthStateService,
    protected socialAuthService: SocialAuthService,
    protected translationService: TranslationService,
    protected orderStateService: OrderStateService,
    protected loadingService: LoadingService,
    protected router: Router
  ) {
    this.logoutRedirectPage = environment.homepageUrl;
    this.authStateService.state$.subscribe((authState) => {
      if (this.authStateService.userIsLoggedAndTokenIsValid()) {
        this.user = authState.user;
        this.loggedIn = true;
      } else {
        this.user = null;
      }
    });

    this.orderStateService.state$.subscribe((order) => (this.showLoginTemplate = !order || order.status === 0));
  }

  public signOut(): void {
    this.authStateService.logout();
    this.orderStateService.removeOrder();
    window.location.href = this.logoutRedirectPage.replace('{lang}', this.translationService.currentLang);
  }

  redirectTo(route: 'register' | 'resetPassword') {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    this.router.navigate(['user', route]);
  }
}
