import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { DiscountTypeEnum } from 'libs/core/src/lib/model/enum/discount-type.enum';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';

@UntilDestroy()
@Component({
  selector: 'app-voucher-card',
  templateUrl: './voucher-card.component.html',
  styleUrls: ['./voucher-card.component.scss'],
})
export class CustomVoucherCardComponent implements OnInit {
  type: DiscountTypeEnum = null;
  typeEnum: typeof DiscountTypeEnum = DiscountTypeEnum;
  voucherInputErrors: Array<string>;
  loadingStatusEnum: typeof LoadingStatus = LoadingStatus;
  order: OrderViewModel;

  @Input() formLocked = false;

  constructor(
    protected loadingService: LoadingService,
    protected orderStateService: OrderStateService,
    protected voucherService: VoucherService,
    protected cardDataProvider: CardDataProvider,
    protected authStateService: AuthStateService
  ) {}

  ngOnInit(): void {
    this.orderStateService.state$.pipe(untilDestroyed(this)).subscribe((s) => (this.order = s));
  }

  onRemove(value: string): void {
    this.loadingService.showLoader(LoaderEnum.MAIN);
    this.voucherService.removeVoucherFromOrder(this.order.cinemaId, this.order.id, value).subscribe({
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }

  changeType(type: DiscountTypeEnum) {
    if (this.type === type) {
      this.type = null;
      return;
    }

    this.type = type;
  }
}
