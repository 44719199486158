import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class AgreementApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  required: boolean;

  @Expose()
  validFrom: string;

  @Expose()
  validTo: string;

  @Expose()
  priority: number;

  @Expose()
  contents: string;

  @Expose()
  marketingAgreementGroups: string[];
}
