import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
import { delay, startWith, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { UserAreaService } from 'libs/core/src/lib/service/user-area.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { NotificationService } from 'libs/core/src/lib/service/notification/notification.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { CountdownStateEnum } from 'libs/core/src/lib/model/enum/countdown-state.enum';
import { storageKey } from 'libs/core/src/app.const';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationType } from 'libs/core/src/lib/service/notification/notification-type.enum';
import { NotificationModel } from 'libs/core/src/lib/service/notification/notification.model';

@UntilDestroy()
@Component({
  template: '',
})
export class CountdownComponent implements OnInit, AfterViewInit, AfterContentChecked {
  public readonly timerInit;

  public countSub: Observable<number>;
  public count = 360;
  public visible = false;

  private wasResumeRequest = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected changeDetector: ChangeDetectorRef,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected countdownComponentService: CountdownComponentService,
    protected userAreaService: UserAreaService,
    protected appService: AppService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService
  ) {
    this.timerInit = environment.constants.countdownStartInit;
    this.count = this.timerInit;

    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.setTime();
        console.log('time was corrected');
      }
    });
  }

  ngAfterViewInit(): void {
    this.countdownComponentService.state$.pipe(startWith(null), delay(0), untilDestroyed(this)).subscribe((state) => {
      switch (state) {
        case CountdownStateEnum.RESTART:
          this.count = this.timerInit;
          this.initCounter();
          break;
        case CountdownStateEnum.START:
          if (this.countdownComponentService.initTime !== undefined) {
            this.count = this.countdownComponentService.initTime;
            this.orderStateService.removeItem(storageKey.lastEpochName);
            this.orderStateService.removeItem(storageKey.timeStampName);
          }

          this.initCounter();
          break;
        case CountdownStateEnum.RESUME:
          this.wasResumeRequest = true;
          break;
        case CountdownStateEnum.STOP:
          break;
        case CountdownStateEnum.DESTROY:
          this.count = this.timerInit;
          this.orderStateService.removeItem(storageKey.timeStampName);
          this.orderStateService.removeItem(storageKey.lastEpochName);
          break;
        case CountdownStateEnum.HIDDEN:
          this.visible = false;
          break;
        case CountdownStateEnum.VISIBLE:
          this.visible = true;
          break;
        case CountdownStateEnum.CLEAR:
          this.count = 0;
          break;
      }
    });
  }

  public ngOnInit(): void {
    this.countSub = timer(0, 1000).pipe(
      take(this.count),
      tap(() => {
        this.setTime();
      })
    );
  }

  setTime(): number {
    const currentTimestamp: number = new Date().getTime();
    const lastEpochTime: number = this.orderStateService.getItem(storageKey.lastEpochName)
      ? parseInt(this.orderStateService.getItem(storageKey.lastEpochName))
      : currentTimestamp;
    const diffInSeconds: number = Math.round((currentTimestamp - lastEpochTime) / 1000);

    let time;
    if (diffInSeconds > 1) {
      this.count -= diffInSeconds;
      time = this.count;
    }
    time = --this.count;

    if (this.count < 1) {
      this.onFinished();
    } else {
      this.orderStateService.setItem(storageKey.timeStampName, time.toString());
      this.orderStateService.setItem(storageKey.lastEpochName, new Date().getTime().toString());
    }

    return time;
  }

  public ngAfterContentChecked(): void {
    const timeStamp = this.orderStateService.getItem(storageKey.timeStampName);
    if (timeStamp !== null) {
      this.count = parseInt(timeStamp);
      if (this.wasResumeRequest && this.count !== null && this.count !== undefined && this.count < this.timerInit) {
        this.initCounter();
      }
    }

    this.changeDetector.detectChanges();
  }

  private onFinished() {
    this.count = 0;
    this.orderStateService.removeItem(storageKey.timeStampName);
    this.countdownComponentService.remove();
    this.orderStateService.removeOrder();
    this.orderStateService.removeOrderFromSessionStorage();
    this.orderStateService.removeReservation();
    this.notificationService.addNotification(new NotificationModel(this.translateService.instant('cart.timeIsOver'), NotificationType.ALERT));
  }

  private initCounter() {
    this.countSub.pipe(untilDestroyed(this)).subscribe();
    this.visible = true;
  }
}
