import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsHelper } from '../helper/cms.helper';
import { RegionHttpService } from '../http/region.http.service';
import { RegionApiModel } from '../model/api-model/region/region.api.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';
import { WordpressDataProvider } from './wordpress.data-provider';

@Injectable({
  providedIn: 'root',
})
export class RegionDataProvider {
  constructor(private httpService: RegionHttpService, private wordpressDataProvider: WordpressDataProvider, private cmsHelper: CmsHelper) {}

  getRegions(): Observable<RegionViewModel[]> {
    if (this.cmsHelper.canUseCms) {
      return this.wordpressDataProvider.getRegions();
    }

    return this.httpService.getRegions().pipe(
      map((result) => plainToInstance(RegionApiModel, result as object[])),
      map((models: RegionApiModel[]) => models.map((model: RegionApiModel) => new RegionViewModel(model)))
    );
  }

  getRegionByCinemaId(id: string): Observable<RegionViewModel> {
    return this.getRegions().pipe(map((regions) => regions.find((region) => region.cinemaIds.indexOf(id) >= 0)));
  }
}
