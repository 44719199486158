import {Expose, Type} from 'class-transformer';
import { CinemaApiModel } from '../cinema/cinema.api.model';

export class RegionApiModel {
  @Expose()
  id: string;

  @Expose()
  code: string;

  @Expose()
  region: string;

  @Expose()
  @Type(() => CinemaApiModel)
  cinemas: CinemaApiModel[] = [];
}
