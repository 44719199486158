<div class="d-flex flex-wrap" [smooth-in]="cards">
  <ng-container *ngFor="let card of cards">
    <button class="btn btn-border text-black btn-card w-100" [ngClass]="card?.checked ? 'non-clickable' : ''"
            (click)="useItem(card)">
      <i class="fas" [ngClass]="card.checked ? 'fa-check' : 'fa-star'"
         [ngStyle]="{'color': !card.checked ? '#ffffff' : '#8c755c'}"></i>
      <span>{{card?.name}}</span>
      <span class="font-weight-bold ml-3 ml-auto">{{card?.model?.valueBalance}} {{ env.constants.currency }}</span>
    </button>
  </ng-container>
</div>