import { AfterViewInit, Component, ElementRef, HostListener } from '@angular/core';
import { DeviceService } from '../../service/device/device.service';

export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL,
}

export interface IDeviceSize {
  id: SCREEN_SIZE;
  name: string;
  css: string;
  device: 'mobile' | 'tablet' | 'desktop';
}

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes: IDeviceSize[] = [
    {
      id: SCREEN_SIZE.XS,
      name: 'xs',
      css: `d-block d-sm-none`,
      device: 'mobile',
    },
    {
      id: SCREEN_SIZE.SM,
      name: 'sm',
      css: `d-none d-sm-block d-md-none`,
      device: 'mobile',
    },
    {
      id: SCREEN_SIZE.MD,
      name: 'md',
      css: `d-none d-md-block d-lg-none`,
      device: 'tablet',
    },
    {
      id: SCREEN_SIZE.LG,
      name: 'lg',
      css: `d-none d-lg-block d-xl-none`,
      device: 'desktop',
    },
    {
      id: SCREEN_SIZE.XL,
      name: 'xl',
      css: `d-none d-xl-block`,
      device: 'desktop',
    },
  ];

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  constructor(private elementRef: ElementRef, private deviceService: DeviceService) {}

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find((x) => {
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
      const isVisible = window.getComputedStyle(el).display != 'none';
      return isVisible;
    });

    this.deviceService.onResize(currentSize);
  }
}
