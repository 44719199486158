<div class="row pay-method-container">
  <div class="col-12 px-0 px-sm-3">
    <div class="row">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentType]="paymentMethod.TYPE_CTX" [paymentProvider]="paymentProviderIdentifier"
                            [paymentMethod]="conotoxiaPaymentMethod"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_CTX">
        </app-payment-method>
      </div>
    </div>
  </div>
</div>