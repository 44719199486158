import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { storageKey } from 'libs/core/src/app.const';
import { LanguageDataProvider } from 'libs/core/src/lib/data-provider/language.data-provider';
import { LayoutTemplateType } from 'libs/core/src/lib/enum/layout-template.enum';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { RequestHandlerModel } from 'libs/core/src/lib/service/request-handler/request-handler.model';
import { RequestHandlerService } from 'libs/core/src/lib/service/request-handler/request-handler.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { LanguageViewModel } from 'libs/core/src/lib/model/view-model/language/language.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { StateService } from 'libs/core/src/lib/state/state.service';

@Component({
  template: '',
})
export class BaseComponent implements OnInit {
  public clearMessageOnPageChange: boolean = null;
  public textDir: string;
  public languageList: LanguageViewModel[];
  public layoutTemplateType: LayoutTemplateType = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected languageDataProvider: LanguageDataProvider,
    protected requestHandler: RequestHandlerService,
    protected messageService: MessageService,
    protected bsLocaleService: BsLocaleService,
    protected loadingService: LoadingService,
    protected stateService: StateService
  ) {}

  public ngOnInit(): void {
    this.clearMessageOnPageChange = this.environment.constants.clearMessageOnPageChange;
    this.configureLayoutDirection();
    this.route.data.subscribe((data: Data) => {
      this.layoutTemplateType = data.templateType;
    });

    this.subscribeForLanguageContext();
    this.handleNavigationError();
    // Uruchomienie requestHandlera po każdej zmianie ścieżki routingu
    this.handleRequestHandler();
    this.subscribeForRouteChanges();
  }

  protected configureLayoutDirection(): void {
    const lang = this.stateService.getItem(storageKey.lang);
    this.textDir = lang && lang.startsWith('ar') ? 'rtl' : 'ltr';
  }

  protected subscribeForLanguageContext(): void {
    this.languageDataProvider.listViaApiModel().subscribe((languageList: Array<LanguageViewModel>) => {
      this.languageList = languageList;
      this.requestHandler.handle(new RequestHandlerModel(this.route.snapshot.queryParams));
    });

    // Zapis języka w sessionStorage po każdej zmianie
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.textDir = event.lang === 'ar' ? 'rtl' : 'ltr';
      const sessionLang = this.stateService.getItem(storageKey.lang);
      if (!sessionLang || sessionLang !== event.lang) {
        this.stateService.setItem(storageKey.lang, event.lang);
        this.bsLocaleService.use(event.lang);
      }
    });
  }

  protected subscribeForRouteChanges(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (this.clearMessageOnPageChange === true) {
          this.messageService.clear();
        }
      }

      if (event instanceof NavigationStart) {
        this.loadingService.showLoader(LoaderEnum.MAIN);
      }
    });
  }

  protected handleRequestHandler(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.requestHandler.handle(new RequestHandlerModel(this.route.snapshot.queryParams));
        this.requestHandler.handleNavigationEnd(event);
      }
    });
  }

  protected handleNavigationError(): void {
    const eventSubscription = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationError) {
        const backUrl = this.stateService.getItem(storageKey.backUrl);
        this.router.ngOnDestroy();
        eventSubscription.unsubscribe();
        window.location.href = backUrl ? backUrl : this.environment.backUrl;
      }
    });
  }
}
