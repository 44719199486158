import { AbstractViewModel } from "../../../abstract.view.model";
import { UserMovieRatingApiModel } from "../../../api-model/user-history/shared/user-movie-rating.api.model";

export class UserMovieRatingViewModel extends AbstractViewModel<UserMovieRatingApiModel> {
  public symbol: string = null;
  public value: string = null;
  public description: string = null;

  constructor(
    protected apiModel: UserMovieRatingApiModel = new UserMovieRatingApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.symbol = this.apiModel.symbol;
    this.value = this.apiModel.value;
    this.description = this.apiModel.description;
  }

  toApiModel(): UserMovieRatingApiModel {
    return undefined;
  }
}
