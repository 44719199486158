import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent {
  @HostBinding('class.active') @Input() visible = false;
  @Input() animate = true;
  @Input() contentClass: string;
  @Output() visibleChange = new EventEmitter<boolean>();

  @ViewChild('content') public content: ElementRef;

  @HostListener('click', ['$event'])
  keyPressHandle(event: Event) {
    if (event.target === this.content?.nativeElement) {
      this.close();
    }
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(false);
  }
}
