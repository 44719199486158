import {Expose, Type} from 'class-transformer';
import { CardTypeBatchApiModel } from './card-type-batch.api.model';
import { CardTypeGraphicTemplateApiModel } from './card-type-graphic-template.api.model';
import { CardTypeMembershipExtensionFeeApiModel } from './card-type-membership-extension-fee.api.model';
import { CardTypeMembershipUpgradeFeeApiModel } from './card-type-membership-upgrade-fee.api.model';
import { CardTypeTypeApiModel } from './card-type-type.api.model';
import { CardTypeValueApiModel } from './card-type-value.api.model';

export class CardTypeApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  issueFee: number;

  @Expose()
  graphic: string;

  @Expose()
  canBeIssued: boolean;

  @Expose()
  membershipNewFee: number;

  @Expose()
  reservationsAllowed: boolean;

  @Expose()
  @Type(() => CardTypeTypeApiModel)
  types: CardTypeTypeApiModel[];

  @Expose()
  @Type(() => CardTypeGraphicTemplateApiModel)
  graphicTemplateList: CardTypeGraphicTemplateApiModel[];

  @Expose()
  @Type(() => CardTypeValueApiModel)
  valueList: CardTypeValueApiModel[];

  @Expose()
  @Type(() => CardTypeMembershipExtensionFeeApiModel)
  membershipExtensionFeeList: CardTypeMembershipExtensionFeeApiModel[];

  @Expose()
  @Type(() => CardTypeMembershipUpgradeFeeApiModel)
  membershipUpgradeFeeList: CardTypeMembershipUpgradeFeeApiModel[];

  @Expose()
  @Type(() => CardTypeBatchApiModel)
  batchList: CardTypeBatchApiModel[];
}
