import { ScreeningAvailabilityStrategyInterface } from "./screening-availability.strategy.interface";
import { ScreeningReservationAvailability } from "./screening-reservation-availability.strategy";
import { ScreeningSaleAvailabilityStrategy } from "./screening-sale-availablity.strategy";

export class ScreeningAvailabilityStrategyContext {
  public static readonly SALE_STRATEGY = 'sale';
  public static readonly RESERVATION_STRATEGY = 'reservation';
  private strategy: ScreeningAvailabilityStrategyInterface = null;

  public constructor(strategy: string) {
    if (strategy === ScreeningAvailabilityStrategyContext.SALE_STRATEGY) {
      this.strategy = new ScreeningSaleAvailabilityStrategy();
    } else if (strategy === ScreeningAvailabilityStrategyContext.RESERVATION_STRATEGY) {
      this.strategy = new ScreeningReservationAvailability();
    } else {
      throw new Error(`Unknown screening availability strategy ${strategy}`);
    }
  }

  public isAvailable(availabilityStatus: number): boolean {
    return this.strategy.isAvailable(availabilityStatus);
  }
}
