<div class="d-flex movie-attributes align-items-center">
   <i *ngIf="existsMovieCopyAttribute('OC')" class="movie-attribute oc-attribute" data-tooltip
      title="{{'screening.movie-copy-attributes.oc' | translate}}"></i>
   <i *ngIf="existsMovieCopyAttribute('CC')" class="fa fa-closed-captioning movie-attribute" data-tooltip
      title="{{'screening.movie-copy-attributes.cc' | translate}}"></i>
   <i *ngIf="existsMovieCopyAttribute('AD')" class="fa fa-audio-description movie-attribute" data-tooltip
      title="{{'screening.movie-copy-attributes.ad' | translate}}"></i>
   <i *ngIf="existsMovieCopyAttribute('LD')" class="fa fa-assistive-listening-systems movie-attribute-default-fs"
      data-tooltip title="{{'screening.movie-copy-attributes.ld' | translate}}"></i>
   <i *ngIf="existsMovieCopyAttribute('WC')" class="fa fa-wheelchair movie-attribute-default-fs" data-tooltip
      title="{{'screening.movie-copy-attributes.wc' | translate}}"></i>
</div>