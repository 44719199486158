
export class PhoneDefinitionModel {

  public code: string;

  public prefix: string;

  public countryTitle: string;

  public translations: any = {};

}
