import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ICoordinates, IRowLegend } from 'libs/core/src/public-api';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';

@Component({
  template: '',
})
export class CurvedScreenSeatsComponent implements AfterViewInit {
  @Input() hallItemSize: number = 34;
  @Input() hallPadding: number = 26;
  @Input() highlightColor: string | null = null;
  @Input() highlightType: 'place' | 'row' | 'none' = 'none';
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;
  private offset = 0;

  hallSize = {
    width: 0,
    height: 0,
  };

  rowLegends: IRowLegend[] = [];
  endAngle = 2 * Math.PI;

  public hallByCoordinates: boolean = false;

  ngAfterViewInit(): void {
    this.offset = this.hallItemSize / 2;
    this.initCanvas();
  }

  ifPossibleUseCoordinates(pseats: SeatViewModel[][]) {
    this.hallByCoordinates = pseats.some((row) => row.some((col) => col.coordinates !== null));
    if (!this.hallByCoordinates) {
      return;
    }

    let maxCoordinates: ICoordinates = { x: 0, y: 0 };
    const setMaxCoordinates = ({ x, y }) => {
      maxCoordinates.x = Math.max(maxCoordinates.x, x);
      maxCoordinates.y = Math.max(maxCoordinates.y, y);
    };

    const recalculateCoordinates = (coordinates: ICoordinates) => {
      coordinates.x = this.hallPadding + (coordinates.x + 1) * this.hallItemSize;
      coordinates.y = this.hallPadding + coordinates.y * this.hallItemSize;
    };

    const calculate = (s: SeatViewModel) => {
      recalculateCoordinates(s.coordinates);
      setMaxCoordinates(s.coordinates);
      if (s.groupConnectedSeats?.length) {
        s.groupConnectedSeats.forEach((seat) => calculate(seat));
      }
    };

    pseats.forEach((pseat) => {
      pseat.forEach((s) => {
        calculate(s);
      });
    });

    this.hallSize = {
      width: maxCoordinates.x + 2 * this.hallItemSize + 2 * this.hallPadding,
      height: maxCoordinates.y + this.hallItemSize + 2 * this.hallPadding,
    };

    let rowIndex = 0;
    pseats.forEach((pseat) => {
      this.setRowLegend(rowIndex++, pseat[0].coordinates, pseat[pseat.length - 1].coordinates);
    });
  }

  setRowLegend(rowIndex: number, first: ICoordinates, last: ICoordinates) {
    this.rowLegends.push({
      index: rowIndex,
      begin: { x: this.hallPadding, y: first.y },
      end: { x: this.hallSize.width - this.hallItemSize - this.hallPadding, y: last.y },
    });
  }

  private initCanvas() {
    const canvas = this.canvas?.nativeElement;
    if (canvas) {
      this.ctx = canvas.getContext('2d');
      this.clearCanvas();
    }
  }

  protected clearCanvas(): void {
    const canvas = this.canvas?.nativeElement;
    if (canvas) {
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  protected drawRowGlow(rowSeats: SeatViewModel[]): void {
    const ctx = this.ctx;
    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(this.hallPadding, rowSeats[0].coordinates.y + this.offset);

      for (let i = 0; i < rowSeats.length; i++) {
        const x = i === 0 ? this.hallPadding : i + 1 === rowSeats.length ? this.hallSize.width - this.hallPadding : rowSeats[i].coordinates.x + this.offset;
        ctx.lineTo(x, rowSeats[i].coordinates.y + this.offset);

        if (rowSeats[i].groupConnectedSeats?.length) {
          const groups = rowSeats[i].groupConnectedSeats;
          for (let k = 0; k < groups.length; k++) {
            ctx.lineTo(groups[k].coordinates.x + this.offset, groups[k].coordinates.y + this.offset);
          }
        }
      }

      ctx.strokeStyle = this.highlightColor;
      ctx.lineWidth = this.hallItemSize;
      ctx.stroke();
    }
  }

  protected drawPlaceGlow(rowSeats: SeatViewModel[], col: number, sub?: number): void {
    const curentSeat = sub == null ? rowSeats[col] : rowSeats[col].groupConnectedSeats[sub];
    const offset = this.offset - 3;
    const ctx = this.ctx;
    if (ctx) {
      ctx.beginPath();
      ctx.fillStyle = this.highlightColor;

      const firstX = this.offset + this.hallPadding;
      const firstY = rowSeats[0].coordinates.y + offset;
      ctx.moveTo(firstX, firstY);
      ctx.arc(firstX, firstY, 26, 0, this.endAngle, true);

      const x = curentSeat.coordinates.x + offset;
      const y = curentSeat.coordinates.y + offset;
      ctx.moveTo(firstX, firstY);
      ctx.arc(x, y, 26, 0, this.endAngle, true);

      const lastX = this.hallSize.width - this.offset - this.hallPadding;
      const lastY = rowSeats[rowSeats.length - 1].coordinates.y + offset;
      ctx.moveTo(firstX, firstY);
      ctx.arc(lastX, lastY, 26, 0, this.endAngle, true);

      ctx.fill();
    }
  }
}
