import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { DateTime } from 'luxon';
import { tap, timer } from 'rxjs';

@UntilDestroy()
@Component({
  template: '',
})
export class SimpleCountdownComponent implements OnInit {
  orderTtl: DateTime;
  orderTtlSeconds: number;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected orderStateService: OrderStateService) {}

  ngOnInit(): void {
    timer(0, 1000)
      .pipe(
        tap(() => {
          this.setTime();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.orderStateService.state$.pipe(untilDestroyed(this)).subscribe((order) => {
      this.orderTtl = order?.status != 4 ? order?.dateTimeToLive : null;
      this.setTime();
    });
  }

  setTime() {
    const seconds = this.orderTtl?.toSeconds() - DateTime.now().toSeconds();
    this.orderTtlSeconds = !isNaN(seconds) ? seconds : null;
  }
}
