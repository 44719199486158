<form [formGroup]="refundForm">
  <div [innerHTML]='"refund.subtitle" | translate'></div>

  <div class="card-body">
    <div class="form-group row required">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4 label-col">
            {{'refund.form.reservationNumber'|translate}}
          </div>
          <div class="col-12 col-sm-8">
            <input type="text" class="form-control" id="bookingId" formControlName="bookingId"
                   placeholder="{{'refund.sample.reservationNumber'|translate}}">
          </div>
        </div>
      </div>
      <div class="help-block with-errors col-12 text-right" *ngIf="!refundForm.get('bookingId').valid">
        <p *ngIf="refundForm.get('bookingId')?.errors?.required && formSubmitAttempt === true">{{'errors.10015' |
          translate }}</p>
      </div>
    </div>

    <div class="form-group row required">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4 label-col">
            {{'refund.form.email'|translate}}
          </div>
          <div class="col-12 col-sm-8">
            <input type="text" class="form-control" id="email" formControlName="email"
                   placeholder="{{'refund.sample.email'|translate}}">
          </div>
        </div>
      </div>
      <div class="help-block with-errors col-12 text-right" *ngIf="!refundForm.get('email').valid">
        <p *ngIf="refundForm.get('email')?.errors?.required && formSubmitAttempt === true">{{'errors.10015' |
          translate}}</p>
      </div>
    </div>

  </div>
</form>