import { ScreeningApiModel } from './api-model/screening/screening.api.model';
import { ScreenShowModel } from './screenshow.model';
import { CinemaViewModel } from './view-model/cinema/cinema.view.model';
import { EventViewModel } from './view-model/event/event.view.model';
import { MoviePrintViewModel } from './view-model/movie/movie-print.view.model';
import { MovieViewModel } from './view-model/movie/movie.view.model';
import { RegionViewModel } from './view-model/region/region.view.model';
import { ScreenheadViewModel } from './view-model/screen/screen-head.view.model';

export class ScreeningObject {
  showid: string;
  regionid: string;
  cinemaid: string;
  screenid: string;
  movieprintid: string;
  dayRange: any;
  region: RegionViewModel;
  cinema: CinemaViewModel;
  show: MovieViewModel | EventViewModel;
  moviePrint: MoviePrintViewModel;
  screen: ScreenheadViewModel;
  screenings: ScreenShowModel[];
}
