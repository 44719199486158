import {Expose} from 'class-transformer';
import {EventItemApiModel} from '../event/event-item.api.model';
import {EventApiModel} from '../event/event.api.model';
import {TagGroupApiModel} from '../tag-group.api.model';

export class ReservationEventApiModel extends EventApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  name: string;

  @Expose()
  items: EventItemApiModel[];

  @Expose()
  tagGroups: TagGroupApiModel[];
}
