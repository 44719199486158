import { Component, ComponentFactory, ComponentRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { TagProviderComponentInterface } from './provider/tag-provider.component.interface';
import { ProviderConfigInterface } from './provider/interface/provider-config.interface';
import { ProviderComponentFactory } from './factory/provider-component.factory';
import { ProviderEnum } from 'libs/core/src/lib/enum/provider.enum';
import { ComponentTagName } from '@lib/core';

@ComponentTagName('pc-tag-manager')
@Component({
  selector: 'app-tag-manager',
  templateUrl: './tag-manager.component.html',
})
export class TagManagerComponent implements OnInit, OnDestroy {
  @ViewChild('providerContainer', { read: ViewContainerRef, static: true })
  public providerContainer: ViewContainerRef;

  protected componentRefs: Array<ComponentRef<TagProviderComponentInterface>> = new Array<ComponentRef<TagProviderComponentInterface>>();

  protected registeredProviders: Array<ProviderConfigInterface> = new Array<ProviderConfigInterface>();

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected providerComponentFactory: ProviderComponentFactory) {}

  public ngOnInit(): void {
    this.registeredProviders = this.environment.tagManager?.providers as any;
    if (this.registeredProviders) {
      for (const provider of this.registeredProviders) {
        this.createComponent(provider.name, provider.parameters);
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyComponents();
  }

  private createComponent(providerType: ProviderEnum, parameters: any): void {
    const componentFactory: ComponentFactory<TagProviderComponentInterface> = this.providerComponentFactory.getFactory(providerType);

    const componentRef: ComponentRef<TagProviderComponentInterface> = this.providerContainer.createComponent(componentFactory);

    componentRef.instance.parameters = parameters;
    this.registerComponent(componentRef);
  }

  private registerComponent(componentRef: ComponentRef<TagProviderComponentInterface>): void {
    this.componentRefs.push(componentRef);
    this.providerContainer.insert(componentRef.hostView);
  }

  private destroyComponents(): void {
    for (const componentRef of this.componentRefs) {
      componentRef.destroy();
    }
  }
}
