<div class="steps-bar topTab">
  <div class="topTabLinks row d-flex justify-content-center">
    <ng-container *ngFor="let i of getSteps()">
      <ng-container [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{ value: i }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template let-value="value" #stepTemplate>
  <div class="step step-{{value}} gap-05" [ngClass]="{'current': step === value, 'completed': step > value}">
    <div class="d-none d-md-inline-flex step-number"><span>{{value}}</span></div>
    <div>
      <span>{{ getTranslationSection() + '.' + value + '.label' | translate }}</span>
    </div>
  </div>
</ng-template>