import { cardType } from '../../app.const';
import { Guid } from '../helper/guid';

export function isNullOrEmpty(value: any): boolean {
  return !value || value.length === 0;
}

export function getTopLevelDomain(url: string): string {
  let hostname;

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  if (url.indexOf('www.') > -0) {
    hostname = url.split('www.')[1];
  }

  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];

  hostname = hostname.split('.');

  if (hostname.length > 2) {
    hostname.shift();
  }

  return hostname.join('.');
}

export function use<T>(sourceValue: T, defaultValue: T): T {
  if (sourceValue === undefined) {
    return defaultValue;
  }

  return sourceValue;
}

export function isBitwiseFlag(value: number, flag: number) {
  return value & flag;
}

export function getCardTypeNamesOfBitwiseFlag(value: number): string[] {
  return Object.keys(cardType)
    .filter((key) => isBitwiseFlag(value, cardType[key].value))
    .map((key) => cardType[key].name as string);
}

export function getGuidOrNull(value: any): string {
  return value && typeof value === 'string' && Guid.isValid(value) ? value?.toLowerCase() : null;
}

export function getRandomIndex(len: number) {
  return Math.floor(Math.random() * len);
}

export function ifExists(value: string, collection: string[], resultIfEmptyCollection = false) {
  if (collection?.length) {
    return collection.map((el) => el.toLowerCase()).indexOf(value.toLowerCase()) > -1;
  }

  return resultIfEmptyCollection;
}

export function makeUrl(env: any, fileName: string): string {
  const isWebpageMode = env.app.mode === 'webpage';
  const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '4200';

  const basePath =
    isWebpageMode || isLocalhost
      ? '.'
      : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${env.app.assetsPath}`;

  return `${basePath}${fileName}`;
}

export function makeAhref(url: string, label: string, target: string = '_blank'): string {
  return `<a href="${url}" target="${target}">${label}</a>`;
}
