import { Expose, Type } from 'class-transformer';
import { PurchaseCardTypeAddressRequestModel } from './purchase-card-type-address.request.model';

export class PurchaseCardTypeRequestModel {
  constructor(cardTypeId?: string, quantity?: number) {
    this.cardTypeId = cardTypeId;
    this.quantity = quantity;
  }

  @Expose()
  public cardTypeId: string;

  @Expose()
  public cardTemplateId: string;

  @Expose()
  public chargeValue: number;

  @Expose()
  public email: string;

  @Expose()
  public batchId: string;

  @Expose()
  public quantity: number;

  @Expose()
  @Type(() => PurchaseCardTypeAddressRequestModel)
  public shippingAddress: PurchaseCardTypeAddressRequestModel;

  @Expose()
  public message: string;
}
