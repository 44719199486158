import {Expose} from 'class-transformer';
export class PromotionConditionItemApiModel {
  @Expose()
  id: string;

  @Expose()
  membershipType: number;

  @Expose()
  cardTypeId: string;
}