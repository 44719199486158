import { Component, Input, Output, OnInit, OnChanges, OnDestroy, EventEmitter, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShareService, ShareButtonsConfig, SHARE_BUTTONS } from 'ngx-sharebuttons';
import { CmsGoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/cms-google-tag-manager.service';

interface ButtonsState {
  includedButtons?: string[];
  excludedButtons?: string[];
  userButtons?: string[];
  selectedButtons?: string[];
  expanded?: boolean;
  shownCount?: number;
  moreIcon?: any;
  lessIcon?: any;
}

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonsComponent implements OnInit, OnChanges, OnDestroy {
  state$: Observable<ButtonsState>;

  private _state$ = new BehaviorSubject<ButtonsState>({
    includedButtons: [],
    excludedButtons: [],
    userButtons: [],
    selectedButtons: [],
    expanded: true,
    shownCount: Object.keys(SHARE_BUTTONS).length,
  });

  private _configSub$ = Subscription.EMPTY;

  @Input() theme = this._share.config.theme;
  @Input() include: string[];
  @Input() exclude: string[];
  @Input() show: number;
  @Input() url: string;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() tags: string;
  @Input() autoSetMeta: boolean;
  @Input() showIcon = true;
  @Input() showText = false;
  @Input() size = 0;
  @Input() disabled: boolean;
  @Input() contentId: string;
  @Input() contentType: string;
  @Output() opened = new EventEmitter<string>();
  @Output() closed = new EventEmitter<string>();

  constructor(private _share: ShareService, protected cmsGoogleTagManagerService: CmsGoogleTagManagerService) {}

  ngOnInit() {
    this.state$ = this._state$.pipe(
      map((state: ButtonsState) => {
        const includedButtons = state.includedButtons && state.includedButtons.length ? state.includedButtons : state.userButtons;
        const userButtons = state.excludedButtons ? includedButtons.filter((btn) => state.excludedButtons.indexOf(btn) < 0) : includedButtons;
        const selectedButtons = userButtons.slice(0, state.expanded ? userButtons.length : state.shownCount);
        return {
          userButtons,
          selectedButtons,
          expanded: state.expanded,
          shownCount: state.shownCount,
          moreIcon: state.moreIcon,
          lessIcon: state.lessIcon,
        };
      })
    );

    this._configSub$ = this._share.config$.subscribe((config: ShareButtonsConfig) => {
      const includedButtons = config.include.length ? config.include : Object.keys(SHARE_BUTTONS);
      const userButtons = includedButtons.filter((btn) => config.exclude.indexOf(btn) < 0);
      this.updateState({
        userButtons,
        expanded: false,
        moreIcon: config.moreButtonIcon,
        lessIcon: config.lessButtonIcon,
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldUpdate =
      (changes['include'] && changes['include'].currentValue !== changes['include'].previousValue) ||
      (changes['exclude'] && changes['exclude'].currentValue !== changes['exclude'].previousValue) ||
      (changes['show'] && changes['show'].currentValue !== changes['show'].previousValue);

    if (shouldUpdate) {
      this.updateState({
        includedButtons: this.include,
        excludedButtons: this.exclude,
        shownCount: this.show,
      });
    }
  }

  ngOnDestroy() {
    this._configSub$.unsubscribe();
    this._state$.complete();
  }

  updateState(state: ButtonsState) {
    this._state$.next({ ...this._state$.value, ...state });
  }

  share(event: any) {
    this.cmsGoogleTagManagerService.share(event, this.contentType, this.contentId);
    this.opened.emit(event);
  }
}
