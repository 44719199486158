import { DateTime } from 'luxon';
import { ScreenTag } from 'libs/core/src/lib/model/screen-tag.model';
import { GenreViewModel } from 'libs/core/src/lib/model/view-model/genre/genre.view.model';
import { RatingViewModel } from 'libs/core/src/lib/model/view-model/rating.view.model';
import { ScreeningAvailabilityStatus } from 'libs/core/src/lib/enum/screening-availability-status.enum';

export class MovieScheduleViewModel {
  public movieId: string;
  public movieTitle: string | null = null;
  public movieShortTitle: string | null = null;
  public symbol: string | null = null;
  public duration = 0;
  public posters: Array<string> = new Array<string>();
  public pictures: Array<string> = new Array<string>();
  public genres: Array<GenreViewModel> = new Array<GenreViewModel>();
  public rating: Array<RatingViewModel> = new Array<RatingViewModel>();
  public poster: string = null;
  public isPosterDefault = false;
  public picture: string = null;
  public shortDescription: string | null = null;
  public description: string | null = null;
  public yearOfProduction: string | null = null;
  public premiereDate: DateTime | null = null;
  public worldPremiereDate: DateTime | null = null;
  public movieCopyCollection: Array<MovieCopyScheduleViewModel> = new Array<MovieCopyScheduleViewModel>();
  public screenTag?: ScreenTag;

  public isPrintable(): boolean {
    return this.movieCopyCollection.some((c) => c.screenings.length > 0);
  }

  public getRatingByCinemaGroupId(groupId: string): string {
    return this.rating
      .filter((x) => x.cinemaGroupId === groupId)
      .map((x) => x.value)
      .join(' ');
  }

  public getGenresName(separator: string): string {
    return this.genres.map((x) => x.name).join(separator);
  }

  /**
   * Checks if there is any screening marked as general admission
   */
  public hasAnyGeneralAdmission(): boolean {
    for (const movieCopy of this.movieCopyCollection) {
      for (const screening of movieCopy.screenings) {
        if (screening.generalAdmission === true) {
          return true;
        }
      }
    }

    return false;
  }
}

export class MovieCopyScheduleViewModel {
  public id: string;
  public name: string;
  public default: boolean;
  public movieLanguage: string;
  public description: string | null = null;
  public posters: Array<string> = new Array<string>();
  public screenings: Array<ScreeningScheduleViewModel> = new Array<ScreeningScheduleViewModel>();
  public subtitles: string;
  public subtitles2: string;
  public printType: string;
  public release: string;

  public hasAnyGeneralAdmission(): boolean {
    for (const screening of this.screenings) {
      if (screening.generalAdmission === true) {
        return true;
      }
    }

    return false;
  }

  public get attributes() {
    return this.release.concat(this.subtitles, this.subtitles2);
  }
}

export class ScreeningScheduleViewModel {
  public id: string;
  public name: string;
  public timeFrom: DateTime;
  public timeTo: DateTime;
  public screeningDuration = 0;
  public screenId: string;
  public generalAdmission: boolean;
  public isScreenSwapping = false;
  public availabilityStatus: ScreeningAvailabilityStatus;
  public reservationTimeTo: DateTime;
  public saleTimeTo: DateTime;
  public isEvent = false;
}
