import { identities } from 'libs/core/src/app.const';
import { AbstractViewModel } from '../../../abstract.view.model';
import { TicketApiModel } from '../../../api-model/shared/ticket/ticket.api.model';
import { TicketExtraFeeViewModel } from './ticket-extra-fee.view.model';

export class TicketViewModel extends AbstractViewModel<TicketApiModel> {
  id: string;
  name: string;
  description: string;
  price: number;
  tax: number;
  extraFees: TicketExtraFeeViewModel[];
  screeningItemId: string;
  seatId: string;
  defaultPriceLevelPrice: number;

  oldPrice: number = null;
  isVouchered: boolean;
  voucherName = null;
  voucherNumber = null;
  row: string;
  seat: string;

  minimalTicketQuantity: number = null;
  maximumTicketQuantity: number = null;

  constructor(protected apiModel: TicketApiModel = new TicketApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.price = this.apiModel.price;
    this.tax = this.apiModel.tax;
    this.extraFees = this.apiModel.extraFees.map((model) => new TicketExtraFeeViewModel(model));
    this.screeningItemId = this.apiModel.screeningItemId;
    this.seatId = this.apiModel.seatId;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
  }

  toApiModel(): TicketApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  hasVoucher() {
    return Boolean(this.voucherNumber) || Boolean(this.voucherName);
  }

  get priceWithExtraFee(): number {
    return this.extraFees.reduce((acc, curr) => {
      if (!curr.isOptional) {
        acc += curr.price;
      }
      return acc;
    }, this.price);
  }

  get oldPriceWithExtraFee(): number {
    return this.extraFees.reduce((acc, curr) => {
      if (!curr.isOptional) {
        acc += curr.price;
      }
      return acc;
    }, this.oldPrice);
  }

  get requiredExtraFeesPrice(): number {
    let sum = 0.0;

    this.extraFees.forEach((value) => {
      if (value.isOptional === false) {
        sum += value.price;
      }
    });

    return sum;
  }

  get serviceFeePrice(): number {
    let sum = 0.0;
    this.extraFees.forEach((value) => {
      if (value.extraFeeId.toLowerCase() === identities.ticketServiceFeeId) {
        sum += value.price;
      }
    });

    return sum;
  }

  get hasServiceFee(): boolean {
    return this.extraFees.some((fee) => fee.extraFeeId.toLowerCase() === identities.ticketServiceFeeId);
  }

  get hasDiscountPrice(): boolean {
    return this.defaultPriceLevelPrice > this.price;
  }
}
