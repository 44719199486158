export class OrderSummaryFeeViewModel {

  private _id: string;
  private _name: string;
  private _limit: number;
  private _price = 0;
  private _ticketIds: string[];

  public constructor(init?: Partial<OrderSummaryFeeViewModel>) {
    Object.assign(this, init);
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get limit(): number {
    return this._limit;
  }

  set limit(value: number) {
    this._limit = value;
  }

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  get ticketIds(): string[] {
    return this._ticketIds;
  }

  set ticketIds(value: string[]) {
    this._ticketIds = value;
  }
}
