export class Package {
    constructor(
      public ticketType: string,
      public minimalTicketQuantity: number,
      public maximumTicketQuantity: number,
      public price: number,
      public title: string,
      public description: string,
    ) {
    }
  }
  