export enum ModalStatusEnum {
  open = 'open',
  close = 'close',
  back = 'back',
  accept = 'accept'
}

export interface ModalEventEmitter {
  state: ModalStatusEnum;
  count?: number;
}
