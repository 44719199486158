import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { PaymentPageComponent } from 'libs/shared/src/lib/page/payment/payment.component';

@UntilDestroy()
@Component({
  selector: 'app-page-payment',
  templateUrl: './payment.component.html',
})
export class CustomPaymentPageComponent extends PaymentPageComponent {
  public getContinueUrl(): string {
    const continueUrl = this.generateContinueUrl();
    return environment.postprocesorUrl?.length
      ? environment.postprocesorUrl.replace('{email}', this.order.userEmail).replace('{callback}', encodeURIComponent(continueUrl))
      : continueUrl;
  }
}
