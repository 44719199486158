import { Expose } from 'class-transformer';

export class UserEventItemApiModel {
  @Expose()
  eventItemDateFrom: string;

  @Expose()
  eventItemTimeTo: string;

  @Expose()
  movieId: string;

  @Expose()
  moviePrintId: string;

  @Expose()
  movieName: string;

  @Expose()
  screenId: string;

  @Expose()
  moviePrintType: string;

  @Expose()
  movieSpeakingType: string;
}
