import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { storageKey } from '../../app.const';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { IVoucher, OrderViewModel } from '../model/view-model/order/order.view.model';
import { PaymentMethodViewModel } from '../model/view-model/order/payment-method/payment-method.view.model';
import { UserCardViewModel } from '../model/view-model/user-history/card/user-card.view.model';
import { UserVoucherViewModel } from '../model/view-model/user-history/voucher/user-voucher.view.model';
import { CountdownComponentService } from '../service/countdown.service';
import { TotalizerService } from '../service/totalizer.service';
import { StateService } from './state.service';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TokenStorageService } from '../service/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { PaymentProviderPayMethodRequestModel } from '../model/request/payment-provider-pay-method.request.model';
import { ProviderEnum } from '../enum/provider.enum';
import { CmsGoogleTagManagerService } from '../service/analytics-services/cms-google-tag-manager.service';
import { IOrder } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrderStateService extends StateService {
  private model: OrderViewModel | null;

  private state = new BehaviorSubject<OrderViewModel>(null);
  state$: Observable<OrderViewModel>;

  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  voucher$ = new ReplaySubject<IVoucher[]>();

  private summaryState = new ReplaySubject<void>(1);
  summaryState$: Observable<void>;

  private interceptLoadData = false;
  private initialized = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private totalizerService: TotalizerService,
    private orderDataProvider: OrderDataProvider,
    private countdownComponentService: CountdownComponentService,
    protected tokenStorageService: TokenStorageService,
    protected cookieService: CookieService,
    protected cmsGoogleTagManagerService: CmsGoogleTagManagerService
  ) {
    super(tokenStorageService, cookieService);
    this.state$ = this.initialDataFetched().pipe(switchMap(() => this.state.asObservable()));
    this.summaryState$ = this.summaryState.asObservable();
    this.initState();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  private initState() {
    const storedOrder = this.storageReadOrder();
    let initObservable$ = new Observable<any>();

    if (!storedOrder) {
      initObservable$ = of(null).pipe(tap(() => this.removeState()));
    } else {
      initObservable$ = this.orderDataProvider.getOrder(storedOrder.cinemaId, storedOrder.id).pipe(
        tap({
          next: (res) => {
            if (!this.interceptLoadData) {
              this.setOrder(res);
            }
            this.initialized = true;
          },
          error: (e) => this.removeState(),
        })
      );
    }

    initObservable$.pipe(finalize(() => this.initialDataFetched$.next(true))).subscribe();
  }

  storageHasOrder(): boolean {
    return Boolean(this.getItem(storageKey.order));
  }

  storageReadOrder(): IOrder {
    const order = this.getItem(storageKey.order);
    return order ? (JSON.parse(order) as IOrder) : null;
  }

  storageSaveOrder(order: IOrder) {
    if (order) {
      this.setItem(storageKey.order, JSON.stringify({ id: order.id, cinemaId: order.cinemaId }));
    } else {
      this.removeItem(storageKey.order);
    }
  }

  getOrder(): OrderViewModel {
    return this.model;
  }

  getCinemaId(): string {
    return this.model?.cinemaId;
  }

  getPaymentMethod(): PaymentMethodViewModel {
    const lsSelectedPaymentMethod = this.getItem(storageKey.selectedPaymentMethod);
    return lsSelectedPaymentMethod ? JSON.parse(lsSelectedPaymentMethod) : null;
  }

  removeState() {
    this.removeOrder();
  }

  setOrder(order: OrderViewModel) {
    this.storageSaveOrder(order);

    if (order !== null) {
      if (this.initialized) {
        this.cmsGoogleTagManagerService.editCart(this.model, order);
      }
    }

    this.model = order;
    this.state.next(order);
  }

  setVoucher(number: string, name?: string) {
    if (!number) {
      return;
    }

    const vouchers = this.getVouchers();
    vouchers.push({
      name: name,
      number: number,
      count: [...this.model?.fbItems, ...this.model?.screeningItems].filter((i) => i.hasVoucher()).length,
    });
    this.setItem(storageKey.vouchers, JSON.stringify(vouchers));
    this.voucher$.next(vouchers);
  }

  removeVoucher(number: string) {
    if (!number) {
      return;
    }

    const vouchers = this.getVouchers().filter((v) => v.number !== number);
    if (vouchers.length) {
      this.setItem(storageKey.vouchers, JSON.stringify(vouchers));
      this.voucher$.next(vouchers);
    } else {
      this.removeVouchers();
      this.voucher$.next([]);
    }
  }

  getVouchers(): IVoucher[] {
    return (JSON.parse(sessionStorage.getItem(storageKey.vouchers)) as IVoucher[]) || [];
  }

  setPaymentMethod(model: PaymentMethodViewModel) {
    this.setItem(storageKey.selectedPaymentMethod, JSON.stringify(model));
  }

  removeOrder() {
    this.totalizerService.clear();
    this.setOrder(null);
  }

  removeOrderFromSessionStorage(): void {
    this.removeFromSessionStorage();
    this.countdownComponentService.destroy();
  }

  cinemaChosen(): boolean {
    return Boolean(this.getItem(storageKey.chosenLocation));
  }

  public useIsDisabled(item?: UserVoucherViewModel | UserCardViewModel): boolean {
    return this.orderNotExist() || this.model?.isRewardsTransaction || item?.isUsed || (item instanceof UserVoucherViewModel && !item?.flgActive);
  }

  hasVoucherNumber(voucherNumber: string) {
    return this.model?.hasVoucherNumber(voucherNumber);
  }

  hasCardPayment(cardId: string) {
    return this.model.hasCardPayment(cardId);
  }

  public orderNotExist(): boolean {
    return !this.model;
  }

  clearSessionStorage() {
    this.interceptLoadData = true;

    if (this.model?.id && this.model.cinemaId && this.orderDataProvider.silentDelete(this.model.cinemaId, this.model)) {
      this.removeOrder();
    }
    this.removeUserSessionStorage();
    this.removeOrderFromSessionStorage();
    this.removeReservation();
  }

  removeOrderWithDelete(withDestroyCountdown: boolean = true): void {
    if (!this.model?.id || !this.model.cinemaId) {
      return;
    }
    this.orderDataProvider.delete(this.model.cinemaId, this.model.id).subscribe(() => this.removeOrder());

    if (withDestroyCountdown) {
      this.countdownComponentService.destroy();
    }
  }

  removeOrderWithDelete$(withDestroyCountdown: boolean = true): Observable<boolean> {
    if (!this.model?.id || !this.model.cinemaId) {
      return of(false);
    }

    return this.orderDataProvider.delete(this.model.cinemaId, this.model.id).pipe(
      tap(() => {
        if (withDestroyCountdown) {
          this.countdownComponentService.destroy();
        }

        this.removeOrder();
      })
    );
  }

  removeOrderWithRequest(withDestroyCountdown: boolean = true): void {
    if (!this.model?.id || !this.model.cinemaId) {
      return;
    }

    this.orderDataProvider.silentDelete(this.model.cinemaId, this.model);
    this.removeOrder();

    if (withDestroyCountdown) {
      this.countdownComponentService.destroy();
    }
  }

  public setLastScreeningId(screeningId: string): void {
    this.setItem(storageKey.lastScreeningId, screeningId);
  }

  public checkLastScreening(screeningId: string): boolean {
    return this.getItem(storageKey.lastScreeningId) !== screeningId;
  }

  public getMemberGetMemberPromotionId() {
    return this.getItem(storageKey.mgmPromotionId);
  }

  public setMemberGetMemberPromotionId(id: string) {
    return this.setItem(storageKey.mgmPromotionId, id);
  }

  public setEmbededPaymentUrl(value: string) {
    this.setItem(storageKey.embededPaymentUrl, value);
  }

  public getEmbededPaymentUrl() {
    return this.getItem(storageKey.embededPaymentUrl);
  }

  public hasVouchersOrGiftCards(): boolean {
    if (this.model) {
      return [...this.model.screeningItems, ...this.model.fbItems].some((x) => x.hasVoucher()) || this.model.paymentMethods.some((x) => x.cardId);
    }

    return false;
  }

  getReservationData() {
    const result = {
      id: this.getItem(storageKey.reservationId),
      cinemaId: this.getItem(storageKey.reservationCinemaId),
      screeningId: this.getItem(storageKey.reservationScreeningId),
      timeTo: DateTime.fromISO(this.getItem(storageKey.reservationScreeningTimeTo)),
    };

    return result.id && result.cinemaId ? result : null;
  }

  isEmailFilled() {
    return this.model?.isEmailFilled();
  }

  hasExternalPayments() {
    return this.model?.hasExternalPayments();
  }

  clearExternalPayments(provider: ProviderEnum): Observable<OrderViewModel> {
    if (!this.model?.id || !this.model.cinemaId) {
      return of(null);
    }

    return this.orderDataProvider.removeExternalPaymentMethod(new PaymentProviderPayMethodRequestModel(this.model.cinemaId, this.model.id, provider)).pipe(
      switchMap(() => this.orderDataProvider.getOrder(this.model.cinemaId, this.model.id)),
      tap({
        next: (o) => {
          this.setOrder(o);
        },
        error: (e) => {},
      })
    );
  }

  changeSummaryStateEmit() {
    this.summaryState.next();
  }
}
