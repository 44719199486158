import { forkJoin, iif, noop, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { CardTypeViewModel } from '../model/view-model/card/card-type.view.model';
import { CardViewModel } from '../model/view-model/account-items/card/card.view.model';
import { appProjectName, cardType } from '../../app.const';
import { AppService } from '../service/app.service';
import { CmsHelper } from './cms.helper';
import { AuthStateService } from '../state/auth.state.service';
import { isBitwiseFlag } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class CardHelper {
  constructor(
    protected userDataProvider: UserDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected appService: AppService,
    protected cmsHelper: CmsHelper,
    protected authStateService: AuthStateService
  ) {}

  public findMembership(cinemaId?: string): Observable<MembershipCardInterface> {
    return iif(
      () => !this.authStateService.userIsLoggedAndTokenIsValid(),
      of(null),
      forkJoin({
        accountItems: this.userDataProvider.getAccountItemsViaApiModel(),
        cardTypes: cinemaId ? this.cardDataProvider.types(cinemaId) : of(null),
      }).pipe(
        mergeMap((response) => {
          let cardTypeModel: CardTypeViewModel;

          const card: CardViewModel = response.accountItems.cards.find(
            (x) => isBitwiseFlag(x.type, cardType.membership.value) && isBitwiseFlag(x.type, cardType.loyalty.value)
          );

          if (card && response.cardTypes) {
            cardTypeModel = response.cardTypes.find((x) => x.id === card.cardTypeId);
          }

          return of({
            card: card,
            cardType: cardTypeModel,
            canReserve: cardTypeModel ? cardTypeModel.reservationsAllowed : false,
            cinemaId: cinemaId,
          });
        })
      )
    );
  }

  findCard(cinemaId?: string): Observable<CardInterface> {
    if (!this.cmsHelper.canUseCms && !this.appService.isProject(appProjectName.ONEIL, appProjectName.KINOTEKA)) {
      return of(null);
    }

    return forkJoin({
      accountItems: this.userDataProvider.getAccountItemsViaApiModel(),
      cardTypes: cinemaId ? this.cardDataProvider.types(cinemaId) : of(null),
    }).pipe(
      mergeMap((response) => {
        let cardTypeModel: CardTypeViewModel;
        let card: CardViewModel;

        //TODO brać po sumie binarnej typu x.type
        if (this.appService.isProject(appProjectName.ONEIL)) {
          card = response.accountItems.cards.find((x) => isBitwiseFlag(x.type, cardType.loyalty.value));
        } else if (this.appService.isProject(appProjectName.KINOTEKA)) {
          card = response.accountItems.cards.find((x) => isBitwiseFlag(x.type, cardType.membership.value));
        } else {
          card = response.accountItems.cards.find((x) => isBitwiseFlag(x.type, cardType.membership.value) && isBitwiseFlag(x.type, cardType.loyalty.value));
        }

        if (card && response.cardTypes) {
          cardTypeModel = response.cardTypes.find((x) => x.id === card.cardTypeId);
        }

        return of({
          card: card,
          cardType: cardTypeModel,
          canReserve: cardTypeModel ? cardTypeModel.reservationsAllowed : false,
        });
      })
    );
  }
}

export interface CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface MembershipCardInterface extends CardInterface, CanReserveCardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
  canReserve: boolean;
  cinemaId: string;
}

export interface LoyaltyCardInterface extends CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface CanReserveCardInterface {
  canReserve: boolean;
}
