import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAndFilter',
})
export class SortAndFilterPipe implements PipeTransform {
  transform(array: any[], allowedKeys: string[]): any[] {
    return array.filter((item) => allowedKeys.includes(item.key)).sort((a, b) => allowedKeys.indexOf(a.key) - allowedKeys.indexOf(b.key));
  }
}
