<div [smooth-in]="cards.length > 0">
  <app-tabs (tabChange)="tabChange()">
    <ng-container *ngFor="let card of cards">
      <app-tab [title]="card.name" (activated)="selectedCard = card">
        <app-choice-slider [items]="findAvailableGraphic(card.id)"
                           [(selectedItem)]="selectedTemplate"></app-choice-slider>
      </app-tab>
    </ng-container>
  </app-tabs>
  <div class="col-12 mb-2 mb-md-3 d-lg-flex px-lg-6">
    <div class="preview w-360 rounded-lg d-flex justify-content-center align-items-center py-md-0">
      <img pimg [src]="selectedTemplate?.graphic" *ngIf="selectedTemplate" class="rounded" format="card"
           data-origin="{{selectedTemplate?.graphic}}">
    </div>

    <div class="px-5 py-5 py-lg-0 value-panel d-flex flex-column justify-content-end">
      <ng-container *ngIf="step === 0">
        <div class=" rounded my-2 top-row">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="m-0 font-weight-bold">
              {{ 'shared.value' | translate }}
            </h6>
            <div class="d-flex align-items-center">
              <h5 class="font-weight-bold mb-0 line-height-1">{{ environment.constants.currency }}</h5>
              <input type="text" class="text-center ml-2 form-control form-control-bigger" [(ngModel)]="value"
                     mask="000" [disabled]="possibleValues?.length > 0" [size]="2">
            </div>
          </div>
          <div *ngIf="possibleValues?.length === 0" class="text-center value-slider mt-2">
            <mv-slider [(value)]="value" [min]="defaultMin" [max]="defaultMax"></mv-slider>
            <!-- [ticks]="[1,2,3,4,5]" -->
          </div>
        </div>
        <div class="rounded bottom-row py-2">
          <div class="py-2 d-flex justify-content-between align-items-center">
            <h6 class="m-0 font-weight-bold">
              {{ 'shared.quantity' | translate }}
            </h6>
            <div class="">
              <app-numeric-stepper (quantityEvent)="quantityEvent($event)"></app-numeric-stepper>
            </div>
          </div>
          <div class="d-flex align-items-center py-4">
            <div class="checkbox">
              <label class="m-0">
                <input type="checkbox" [checked]="envelopes" (click)="envelopes = !envelopes">
                <span class="cr"><i class="cr-icon fa" [ngClass]="envelopes ? 'fa-check' : ''"></i></span>
              </label>
            </div>
            <h6 class="m-0">
              {{ 'gift-card.addEnvelopes' | translate: {price: envelopeItemPrice | money } }}
            </h6>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="step === 1">
        <div class="col">
          <div class="navigation-color rounded my-5 my-lg-auto">
            <h7 class="px-2 font-weight-bold"> {{ 'gift-card.deliveryAddress' | translate }}</h7>
            <div class="">
              <form [formGroup]="deliveryAddress">
                <div class="row py-3 px-sm-5">
                  <div class="col-md-6 px-1"
                       [ngClass]="{'error': deliveryAddress.get('firstName').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.firstName' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="firstName">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('firstName').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('firstName')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 px-1"
                       [ngClass]="{'error': deliveryAddress.get('lastName').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.lastName' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="lastName">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('lastName').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('lastName')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-8 pt-2 px-1"
                       [ngClass]="{'error': deliveryAddress.get('street').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.street' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="street">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('street').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('street')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 pt-2 px-1"
                       [ngClass]="{'error': deliveryAddress.get('number').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.number' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="number">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('number').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('number')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12 pt-2 px-1">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.additionalAddress' | translate }}
                      <input class="w-100 form-control" type="text" formControlName="additionalAddress">
                    </label>
                  </div>

                  <div class="col-md-4 pt-2 px-1"
                       [ngClass]="{'error': deliveryAddress.get('code').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.code' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="code">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('code').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('code')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-8 pt-2 px-1"
                       [ngClass]="{'error': deliveryAddress.get('city').invalid && formSubmitAttempt}">
                    <label class="w-100 mb-0 form-control-description px-2 px-sm-0">
                      {{ 'gift-card.form.city' | translate }} *
                      <input class="w-100 form-control" type="text" formControlName="city">
                    </label>
                    <div class="help-block with-errors"
                         *ngIf="deliveryAddress.get('city').invalid && formSubmitAttempt">
                      <p class="m-0" *ngIf="deliveryAddress.get('city')?.errors?.required">
                        {{'errors.10015' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="d-flex justify-content-between" style="gap: 1rem">
        <div>
          <button *ngIf="step === steps.Address" class="btn btn-primary px-2 px-lg-4 mt-2" (click)="step = step - 1">
            {{ 'shared.back' | translate }}
          </button>
        </div>
        <div class="d-flex" style="gap: 1rem">
          <button class="btn btn-primary px-2 px-lg-4 mt-2" (click)="goToCart()" *ngIf="orderWithCards()">
            {{ 'shared.goToCart' | translate }}
          </button>
          <button *ngIf="step === steps.Initial" class="btn btn-primary px-2 px-lg-4 mt-2" (click)="step = step + 1">
            {{ 'shared.continue' | translate }}
          </button>
          <button *ngIf="step === steps.Address" class="btn btn-primary px-2 px-lg-4 mt-2" (click)="addToCart()">
            {{ 'shared.addToCart' | translate }}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<app-popup [isVisible]="error !== undefined">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{'gift-card.cannotAddCard' | translate }}</h4>
      <div class="question">
        {{error?.message}}
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="error = undefined">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>