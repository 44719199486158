import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ProgramApiModel } from '../../api-model/member-get-member/program.api.model';
import { ProgramEmailViewModel } from './program-email.view.model';

export class ProgramViewModel extends AbstractViewModel<ProgramApiModel> {
  id: string;
  name: string;
  info: string;
  cashierMessage: string;
  url: string;
  startDate: DateTime;
  expiryDate: DateTime;
  qtyLeftToRecommend: number;
  recommendedEmails: ProgramEmailViewModel[];

  toApiModel(): ProgramApiModel {
    return undefined;
  }

  constructor(protected apiModel: ProgramApiModel = new ProgramApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.info = this.apiModel.info;
    this.cashierMessage = this.apiModel.cashierMessage;
    this.url = this.apiModel.url;
    this.startDate = this.apiModel.startDate ? DateTime.fromISO(this.apiModel.startDate) : null;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.qtyLeftToRecommend = this.apiModel.qtyLeftToRecommend;
    this.recommendedEmails = this.apiModel.recommendedEmails.map(model => new ProgramEmailViewModel(model));
  }
}
