<div class="overlay fade-in" *ngIf="_isVisible === true">
  <div class="popup">
    <div class="header">
      <ng-content select="[header]"></ng-content>
      <span class="close" (click)="onCloseButtonClicked()"></span>
    </div>
    <div class="body">
      <ng-content></ng-content>
    </div>
    <div class="footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>