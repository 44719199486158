import { Media } from './media';

export class Post {
  constructor(
    public id: number,
    public slug: string,
    public type: string,
    public title: string,
    public content: string,
    public excerpt: string,
    public link: string,
    public featured_media: number,
    public customFields: { [key: string]: string | any }
  ) {}

  media: Media;

  public getCustomField(fieldName: string): string {
    return this.customFields[fieldName];
  }

  public getCustomFieldValue(fieldName: string): string {
    const value = this.getCustomField(fieldName);

    if (value && value.length > 0) {
      return value;
    }

    return null;
  }
}
