import { Component, OnInit } from '@angular/core';

@Component({
  template: ''
})
export class PolicyComponent implements OnInit {
  public isCollapsedFilmRating = true;
  public isCollapsedTicketAge = true;

  constructor() { }

  ngOnInit(): void {
  }
}
