import {Component, Input, OnInit} from '@angular/core';
import { LayoutTemplateType } from 'libs/core/src/lib/enum/layout-template.enum';
import { LanguageViewModel } from 'libs/core/src/lib/model/view-model/language/language.view.model';

@Component({
  template: ''
})
export class LayoutComponent implements OnInit {
  @Input() languageList: LanguageViewModel[];
  @Input() templateType: LayoutTemplateType;

  constructor() {
  }

  ngOnInit() {
  }
}
