import {Expose} from 'class-transformer';

export class CardTypeMembershipExtensionFeeApiModel {
  @Expose()
  feeId: string;

  @Expose()
  name: string;

  @Expose()
  extensionTime: number;

  @Expose()
  extensionType: number;

  @Expose()
  feeValue: number;
}
