<div class="container">
  <div class="main-container">
    <div class="row mt-3 justify-content-center">
      <div class="col-12 col-md-6">
        <app-sidebar>
          <div sidebar-title>
            <div>{{ 'refund.title' | translate }}</div>
          </div>
          <div sidebar-body>
            <app-refund-component [formEvent]="formEvent"></app-refund-component>
          </div>
          <div sidebar-footer>
            <div class="d-flex p-3 justify-content-end">
              <button class="btn btn-next" (click)="onNextClick()">
                {{ 'refund.form.submit' | translate }}
              </button>
            </div>
          </div>
        </app-sidebar>
      </div>
    </div>

  </div>
</div>


<ng-container *ngIf="isSuccessSend">
  <app-modal *ngIf="isSuccessSend == 'normal'" [modalContent]="items" [templateType]="'refundSelector'"
             (clickEvent)="onModalClick($event)"></app-modal>
  <app-modal *ngIf="isSuccessSend == 'success'" [modalContent]="successContent" [templateType]="'refundSuccess'"
             (clickEvent)="onModalClick($event)"></app-modal>
  <app-modal *ngIf="isSuccessSend == 'fail'" [modalContent]="failContent" [templateType]="'refundFail'"
             (clickEvent)="onModalClick($event)"></app-modal>
</ng-container>