import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';

export class SalesDocumentApiModel {
  @Expose()
  id: string = null;

  @Expose()
  totalValue: number = null;

  @Expose()
  @Transform(({ value }) => (value ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  @Type(() => DateTime)
  transactionDate: DateTime = null;

  @Expose()
  @Transform(({ value }) => (value === '' ? null : value), { toClassOnly: true })
  userFirstName: string = null;

  @Expose()
  @Transform(({ value }) => (value === '' ? null : value), { toClassOnly: true })
  userLastName: string = null;

  @Expose()
  @Transform(({ value }) => (value === '' ? null : value), { toClassOnly: true })
  userPhoneNumber: string = null;

  @Expose()
  @Transform(({ value }) => (value === '' ? null : value), { toClassOnly: true })
  userEmail: string = null;

  @Expose()
  userId: string = null;

  @Expose()
  cardId: string = null;

  @Expose()
  orderNumber: string = null;

  @Expose()
  @Transform(({ value }) => (value ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  @Type(() => DateTime)
  pickupTime: DateTime = null;

  @Expose()
  @Type(() => SalesDocumentItemApiModel)
  items: SalesDocumentItemApiModel[] = [];

  @Expose()
  @Type(() => SalesDocumentReservationItemApiModel)
  reservationItems: SalesDocumentReservationItemApiModel[] = [];

  @Expose()
  @Type(() => SalesDocumentBookingApiModel)
  bookings: SalesDocumentBookingApiModel[] = [];
}

export class SalesDocumentItemApiModel {
  @Expose()
  itemType: number = null;

  @Expose()
  name: string = null;

  @Expose()
  price: number = null;

  @Expose()
  quantity: number = null;

  @Expose()
  value: number = null;

  @Expose()
  picture: string = null;

  @Expose()
  voucherName: string = null;

  @Expose()
  @Type(() => SalesDocumentSubItemApiModel)
  subItems: SalesDocumentSubItemApiModel[] = [];

  @Expose()
  @Type(() => SalesDocumentModifierItemApiModel)
  modifierItems: SalesDocumentModifierItemApiModel[] = [];
}

export class SalesDocumentModifierItemApiModel {
  @Expose()
  name: string = null;

  @Expose()
  price: number = null;

  @Expose()
  quantity: number = null;

  @Expose()
  modifierItemName: string = null;

  @Expose()
  itemName: string = null;
}

export class SalesDocumentReservationItemExtraFeeApiModel {
  @Expose()
  name: string = null;

  @Expose()
  price: number = null;

  @Expose()
  isOptional: boolean = null;
}

export class SalesDocumentReservationItemApiModel {
  @Expose()
  row: string = null;

  @Expose()
  seat: string = null;

  @Expose()
  ticketName: string = null;

  @Expose()
  price: number = null;

  @Expose()
  checkStatus: number = null;

  @Expose()
  @Type(() => DateTime)
  @Transform(({ value }) => (value ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  checkDate: string = null;

  @Expose()
  voucherName: string = null;

  @Expose()
  ticketNumber: string = null;

  @Expose()
  @Type(() => SalesDocumentReservationItemExtraFeeApiModel)
  extraFees: SalesDocumentReservationItemExtraFeeApiModel[] = [];
}

export class SalesDocumentSubItemApiModel {
  @Expose()
  name: string = null;

  @Expose()
  price: number = null;

  @Expose()
  quantity: number = null;
}

export class SalesDocumentBookingApiModel {
  @Expose()
  bookingId: string;

  @Expose()
  cinemaName: string;

  @Expose()
  screeningId: string;

  @Expose()
  screeningName: string;

  @Expose()
  @Transform(({ value }) => (value ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  @Type(() => DateTime)
  screeningDate: DateTime;

  @Expose()
  @Transform(({ value }) => (value ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  @Type(() => DateTime)
  screeningEndDate: DateTime;

  @Expose()
  screeningStatus: number;

  @Expose()
  screenName: string;

  @Expose()
  screeningTimeToLeft: string;
}
