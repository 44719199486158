import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import uniq from 'lodash-es/uniq';
import { map, mergeMap, tap } from 'rxjs/operators';
import { OrderStatus, OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { CateringViewModel } from 'libs/core/src/lib/model/view-model/order/catering.view.model';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { CateringAggregationArticleViewModel, CateringAggregationViewModel } from 'libs/core/src/lib/model/catering/catering-aggregation.view.model';
import { ScreenScreeningLinkModel } from './model/screen-screening-link.model';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { ENVIRONMENT_TOKEN, makeUrl } from 'libs/core/src/public-api';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { FindByScreeningOptions, ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { SalesDocumentDataProvider } from 'libs/core/src/lib/data-provider/sales-document.data-provider';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { CateringService } from 'libs/core/src/lib/service/catering/catering.service';
import { OrderCateringService, SelectedModifierItemMap } from 'libs/core/src/lib/service/catering/order-catering.service';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { AnalyticsHelperService } from 'libs/core/src/lib/service/analytics-helper.service';
import { BackgroundService } from 'libs/core/src/lib/service/background.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { TranslationService } from 'libs/core/src/lib/service/translation.service';
import { storageKey } from 'libs/core/src/app.const';
import { SalesDocumentViewModel } from 'libs/core/src/lib/model/view-model/sales-document/sales-document.view.model';
import { OrderItemType, ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { ScreeningItemExtraFeeViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item-extra-fee.view.model';
import { FbItemViewModel } from 'libs/core/src/lib/model/view-model/order/fb-item/fb-item.view.model';
import { FbItemSubArticleViewModel } from 'libs/core/src/lib/model/view-model/order/fb-item/fb-item-sub-article.view.model';
import { FbItemModifierItemViewModel } from 'libs/core/src/lib/model/view-model/order/fb-item/fb-item-modifier-item.view.model';
import { VoucherItemViewModel } from 'libs/core/src/lib/model/view-model/order/voucher-item/voucher-item.view.model';
import { CateringAggregationBuilder } from 'libs/core/src/lib/model/catering/catering-aggregation.builder';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { OrderHttpService } from 'libs/core/src/lib/http/order.http.service';
import { paymentStatusTypes } from '../payment/worldpay-redirect-hub/payment-status-type.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderApiModel } from 'libs/core/src/lib/model/api-model/order/order.api.model';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';

@Component({
  template: '',
})
export class OrderSummaryPageComponent implements OnInit, OnDestroy {
  private orderSubscription: Subscription = Subscription.EMPTY;
  public cinemaId: string = null;
  public cinema: CinemaViewModel = null;
  public orderId: string = null;
  public salesDocumentId: string = null;
  public order: OrderViewModel = null;
  public screen: ScreenViewModel = null;
  public generatingVirtualPass = false;
  public tickets: Array<TicketViewModel> = null;
  public catering: CateringViewModel = null;
  public basketPageModel: BasketPageModel;
  public selectedVoucherList: Array<VoucherTypeModel> = null;
  public selectedCateringArticleCombinationList: Array<CateringAggregationArticleViewModel> = null;
  public voucherNumber: string | null = null;
  public isVoucherQrCodeZoom = false;
  public isQrCodeZoom: { [key: string]: boolean } = {};
  public screenMap: Array<ScreenScreeningLinkModel> = new Array<ScreenScreeningLinkModel>();
  private queryParamsSubscription: Subscription = Subscription.EMPTY;
  private maxTriesAmount = 6;
  private currentTry = 0;
  public readonly voucherSaleEnabled: boolean = false;
  public cateringSaleEnabled = false;
  public cateringAvailableSubscription: Subscription = Subscription.EMPTY;
  public childLoadingStatus: LoadingStatus;
  public showZoomIcon = !MobileDeviceUtils.isAndroid();
  public modalRef: BsModalRef;

  @ViewChild('modal') modal: TemplateRef<any>;

  public flowType: FlowType = null;
  public flowTypeEnum: typeof FlowType = FlowType;

  public userLoggedIn = false;
  public documentNumber: string;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected screenDataProvider: ScreenDataProvider,
    protected navigationHelper: NavigationHelperService,
    protected screeningDataProvider: ScreeningDataProvider,
    protected salesDocumentDataProvider: SalesDocumentDataProvider,
    protected router: Router,
    protected route: ActivatedRoute,
    protected countdownComponentService: CountdownComponentService,
    protected cinemaDataProvider: CinemaDataProvider,
    protected voucherService: VoucherService,
    protected cateringService: CateringService,
    protected orderCateringService: OrderCateringService,
    protected basketDataProvider: BasketDataProvider,
    protected appService: AppService,
    protected cateringStateService: CateringStateService,
    protected analyticsHelperService: AnalyticsHelperService,
    protected backgroundService: BackgroundService,
    protected headerService: HeaderService,
    protected translationService: TranslationService,
    protected googleTagManagerService: GoogleTagManagerService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected stepsService: StepsService,
    protected userDataProvider: UserDataProvider,
    protected authStateService: AuthStateService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    this.voucherSaleEnabled = this.environment.constants.voucherSaleEnabled;
    this.flowType = this.stepsService.FlowType;

    if (this.cateringAvailableSubscription === Subscription.EMPTY) {
      this.cateringAvailableSubscription = this.cateringStateService.state$.subscribe((value) => {
        this.cateringSaleEnabled = value;
      });
    }
  }

  public ngOnInit() {
    this.backgroundService.changeDeleteOrderOnWindowUnloadState(false);
    this.cateringStateService.checkAvailability();
    this.countdownComponentService.stop();
    this.countdownComponentService.hide();
    this.childLoadingStatus = LoadingStatus.pending;
    this.setOrder(this.orderStateService.getOrder());

    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      this.cinemaId = params['cinemaId'];
      this.orderId = params['orderId'];
      this.salesDocumentId = params['salesDocumentId'];

      if (!this.cinemaId) {
        this.cinemaId = this.orderStateService.getItem(storageKey.chosenLocation);

        if (!this.cinemaId) {
          console.error('No cinemaId');
          return this.navigationHelper.goToRepertoire();
        }
      }

      if (!this.orderId && !this.salesDocumentId) {
        const order = this.orderStateService.storageReadOrder();
        if (!order) {
          console.error('No orderId or salesDocumentId');
          return this.navigationHelper.goToRepertoire();
        }

        this.orderId = order.id;
        this.cinemaId = order.cinemaId;
      }

      const hostedPaymentStatus = params['HostedPaymentStatus'];
      if (hostedPaymentStatus && hostedPaymentStatus == paymentStatusTypes.cancel) {
        return this.navigationHelper.goToRepertoire();
      }

      this.cinemaDataProvider.getCinemaById(this.cinemaId).subscribe((res) => {
        this.cinema = res;

        if (this.salesDocumentId) {
          this.loadSalesDocument();
          return;
        }

        this.checkOrderStatus();
      });
    });
  }

  private setOrder(order) {
    this.order = order;
    if (order) {
      if (order.documentNumber?.length) {
        this.documentNumber = order.documentNumber;
        return;
      }
      if (order.bookingId?.length) {
        this.documentNumber = order.bookingId;
        return;
      }
    }

    this.documentNumber = null;
  }

  private buildOrderFromSalesDocument(salesDocument: SalesDocumentViewModel): OrderViewModel {
    const order = new OrderApiModel();
    order.bookingId = salesDocument.bookings[0]?.bookingId;
    order.status = OrderStatus.FINISHED;
    order.totalValue = salesDocument.totalValue;
    order.userFirstName = salesDocument.userFirstName;
    order.userLastName = salesDocument.userLastName;
    order.userPhone = salesDocument.userPhoneNumber;
    order.userEmail = salesDocument.userEmail;
    order.screeningItems = this.buildItemsModelFromSalesDocument(salesDocument);
    order.voucherItems = this.buildVoucherItemsModelFromSalesDocument(salesDocument);
    order.documentNumber = salesDocument.orderNumber;

    const result = new OrderViewModel(this.cinemaId, order);
    result.pickupTime = salesDocument.pickupTime;
    result.createdFromSalesDocument = true;
    result.fbItems = this.buildCateringItemsModelFromSalesDocument(salesDocument);
    return result;
  }

  private buildItemsModelFromSalesDocument(salesDocument: SalesDocumentViewModel): ScreeningItemViewModel[] {
    const items: ScreeningItemViewModel[] = Array<ScreeningItemViewModel>();

    for (const salesDocumentItem of salesDocument.reservationItems) {
      const item: ScreeningItemViewModel = new ScreeningItemViewModel();
      const optionalExtraFees: Array<string> = new Array<string>();

      item.seat = salesDocumentItem.seat;
      item.row = salesDocumentItem.row;
      item.name = salesDocumentItem.ticketName;
      item.screeningId = salesDocument.bookings[0]?.screeningId;
      item.bookingId = salesDocument.bookings[0]?.bookingId;
      item.price = salesDocumentItem.price;
      item.ticketPrice = salesDocumentItem.price;
      item.voucherName = salesDocumentItem.voucherName;
      item.voucherNumber = null;
      item.optionalExtraFees = optionalExtraFees;
      item.quantity = 1;

      for (const salesDocumentExtraFee of salesDocumentItem.extraFees) {
        const extraFee: ScreeningItemExtraFeeViewModel = new ScreeningItemExtraFeeViewModel();

        extraFee.name = salesDocumentExtraFee.name;
        extraFee.price = salesDocumentExtraFee.price;
        extraFee.isOptional = salesDocumentExtraFee.isOptional;

        if (extraFee.isOptional) {
          optionalExtraFees.push(salesDocumentExtraFee.name); // name is ID
        }

        item.extraFees.push(extraFee);
      }

      items.push(item);
    }

    return items;
  }

  private buildCateringItemsModelFromSalesDocument(salesDocument: SalesDocumentViewModel): FbItemViewModel[] {
    const items: FbItemViewModel[] = Array<FbItemViewModel>();

    for (const salesDocumentItem of salesDocument.items) {
      if (salesDocumentItem.itemType !== OrderItemType.FB_ITEM) {
        continue;
      }

      const item: FbItemViewModel = new FbItemViewModel();

      item.name = salesDocumentItem.name;
      item.price = salesDocumentItem.price;
      item.quantity = salesDocumentItem.quantity;
      item.voucherName = salesDocumentItem.voucherName;

      for (const salesDocumentSubItem of salesDocumentItem.subItems) {
        const subItem: FbItemSubArticleViewModel = new FbItemSubArticleViewModel();

        subItem.name = salesDocumentSubItem.name;
        subItem.price = salesDocumentSubItem.price;
        subItem.quantity = salesDocumentSubItem.quantity;

        item.subArticleList.push(subItem);
      }

      for (const salesDocumentModifier of salesDocumentItem.modifierItems) {
        const modifier: FbItemModifierItemViewModel = new FbItemModifierItemViewModel();

        modifier.modifierName = salesDocumentModifier.itemName;
        modifier.modifierItemDescription = salesDocumentModifier.name;
        modifier.modifierItemName = salesDocumentModifier.modifierItemName;
        modifier.quantity = salesDocumentModifier.quantity / salesDocumentItem.quantity;
        modifier.price = salesDocumentModifier.price;

        item.modifierItemList.push(modifier);
      }

      items.push(item);
    }

    return items;
  }

  private buildVoucherItemsModelFromSalesDocument(salesDocument: SalesDocumentViewModel): VoucherItemViewModel[] {
    const items: VoucherItemViewModel[] = Array<VoucherItemViewModel>();

    for (const salesDocumentItem of salesDocument.items) {
      if (salesDocumentItem.itemType !== OrderItemType.VOUCHER) {
        continue;
      }

      const item: VoucherItemViewModel = new VoucherItemViewModel();

      item.itemName = salesDocumentItem.name;
      item.value = salesDocumentItem.price;
      item.quantity = salesDocumentItem.quantity;

      items.push(item);
    }

    return items;
  }

  public ngOnDestroy(): void {
    if (this.queryParamsSubscription !== Subscription.EMPTY) {
      this.queryParamsSubscription.unsubscribe();
      this.queryParamsSubscription = Subscription.EMPTY;
    }

    if (this.cateringAvailableSubscription !== Subscription.EMPTY) {
      this.cateringAvailableSubscription.unsubscribe();
      this.cateringAvailableSubscription = Subscription.EMPTY;
    }

    this.orderSubscription.unsubscribe();
  }

  public onClickBackToHome(): void {
    window.location.href = this.environment.homepageUrl.replace('{lang}', this.translationService.currentLang);
  }

  private checkOrderStatus(): void {
    OrderHttpService.cacheBuster$.next();
    this.orderDataProvider.getOrder(this.cinemaId, this.orderId).subscribe((orderSimple) => {
      if (orderSimple.status !== OrderStatus.FINISHED && orderSimple.status !== OrderStatus.CANCELLED && ++this.currentTry < this.maxTriesAmount) {
        setTimeout(() => {
          this.checkOrderStatus();
        }, 5000);
        return;
      }

      this.loadOrder(orderSimple);
    });
  }

  private loadSalesDocument(): void {
    this.salesDocumentDataProvider
      .getSalesDocumentById(this.cinemaId, this.salesDocumentId)
      .pipe(
        mergeMap((salesDocument) => {
          return this.loadScreening(salesDocument);
        })
      )
      .subscribe((result) => {
        this.setOrder(this.buildOrderFromSalesDocument(result));
        this.loadVoucherItemList();
        this.screen = result.screen;
        const screenModel: ScreenViewModel = result.screen;
        const screenMap: ScreenScreeningLinkModel[] = new Array<ScreenScreeningLinkModel>();
        screenMap.push(new ScreenScreeningLinkModel(result.screen.id, result.bookings[0]?.bookingId, result.orderNumber, screenModel));
        this.screenMap = screenMap.map((x) => x);

        if (screenModel.posterUrl === undefined || screenModel.posterUrl === null || screenModel.posterUrl === '') {
          screenModel.posterUrl = makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);
        }

        this.headerService.setData(screenModel);

        const articleCombinationList: CateringAggregationArticleViewModel[] = new Array<CateringAggregationArticleViewModel>();
        for (const cateringItem of this.order.fbItems) {
          articleCombinationList.push(this.cateringService.buildSelectedArticleFromSalesDocument(cateringItem));
        }

        this.selectedCateringArticleCombinationList = articleCombinationList.map((x) => x);
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      });
  }

  private loadScreening(salesDocument: SalesDocumentViewModel) {
    return this.screeningDataProvider.findByIdViaApiModel(this.cinemaId, salesDocument.bookings[0]?.screeningId).pipe(
      mergeMap((movieCopy) => {
        // salesDocument.movieCopy = movieCopy.copy;
        return this.screenDataProvider.getScreenBy(this.cinemaId, movieCopy.screenings[0].screenId).pipe(
          map((screen) => {
            screen.movieDate = salesDocument.bookings[0]?.screeningDate;
            screen.movieName = salesDocument.movieCopy.movie.title;

            screen.id = salesDocument.movieCopy.screenings[0].id;
            screen.moviePremiere = salesDocument.movieCopy.movie.premiereDate;
            screen.movieRating = salesDocument.movieCopy.movie.ratings;
            screen.movieDuration = salesDocument.movieCopy.movie.duration;
            screen.movieLanguage = movieCopy.copy.language;
            screen.posterUrl = (movieCopy.movie.posters?.length ? movieCopy.movie.posters[0] : null) || '';
            screen.movieGenres = movieCopy.movie.genres || [];
            screen.movieTags = movieCopy.movie.tagGroups || [];
            screen.moviePrintType = movieCopy.copy.printType;
            screen.screeningDuration = salesDocument.movieCopy.screenings[0].screeningDuration;
            screen.isScreenSwapping = salesDocument.movieCopy.screenings[0].isScreenSwapping;
            screen.screeningAvailabilityStatus = salesDocument.movieCopy.screenings[0].availabilityStatus;
            screen.reservationTimeTo = salesDocument.movieCopy.screenings[0].reservationTimeTo;
            screen.saleTimeTo = salesDocument.movieCopy.screenings[0].saleTimeTo;
            screen.screeningTimeFrom = salesDocument.movieCopy.screenings[0].screeningTimeFrom;
            screen.screeningTimeTo = salesDocument.movieCopy.screenings[0].saleTimeTo;
            screen.movieSoundType = movieCopy.copy.soundType;
            screen.movieSubtitles = [movieCopy.copy.subtitles, movieCopy.copy.subtitles2].filter((value) => value !== null);
            screen.generalAdmission = salesDocument.movieCopy.screenings[0].generalAdmission;
            screen.movieDescription = movieCopy.movie.description;

            salesDocument.screen = screen;
            return salesDocument;
          })
        );
      })
    );
  }

  private loadOrder(order: OrderViewModel): void {
    this.setOrder(cloneDeep(order));
    this.googleTagManagerService.purchase();

    if (this.order.userPhone) {
      this.order.userPhone = this.order.userPhone
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{3})/g, '$1 ')
        .trim();
    }

    const screeningIdentifierCollection: Array<string> = uniq(order.screeningItems.map((x) => x.screeningId));
    if (screeningIdentifierCollection.length === 0 && this.orderStateService.getItem(storageKey.parentOrder)) {
      screeningIdentifierCollection.push(JSON.parse(this.orderStateService.getItem(storageKey.parentOrder)).parentOrderScreening.id);
    }

    const bookingIdByScreeningId: Map<string, string> = new Map<string, string>();
    this.order.screeningItems.forEach((x) => {
      bookingIdByScreeningId.set(x.screeningId, x.bookingId);
    });

    if (!this.basketPageModel) {
      this.loadBasket((basketPageModel: BasketPageModel) => {
        const screenMap = new Array<ScreenScreeningLinkModel>();
        this.tickets = basketPageModel.tickets;

        const chain: Array<Observable<any>> = screeningIdentifierCollection.map((screeningId) => {
          const findByScreeningOptions: FindByScreeningOptions = {
            disableOccupancy: true,
          };

          if (basketPageModel.event !== null) {
            const screenModel: ScreenViewModel = new ScreenViewModel();
            return of([screeningId, this.screenDataProvider.makeScreenModelForEvent(screenModel, basketPageModel.event)]);
          } else {
            return this.screenDataProvider.getScreenByAndCheckIfScreeningIsEvent(this.cinemaId, screeningId, findByScreeningOptions).pipe(
              map((x) => {
                return [screeningId, x];
              })
            );
          }
        });

        merge(...chain).subscribe({
          next: ([screeningId, screen]) => {
            const screenModel: ScreenViewModel = screen as ScreenViewModel;
            this.screen = screen;
            this.analyticsHelperService.findAnalysisProviderToInformByMovieId(screen.movieId, order);
            screenMap.push(new ScreenScreeningLinkModel(screeningId, bookingIdByScreeningId.get(screeningId), this.order.orderNumber, screenModel));

            if (screenModel.posterUrl === undefined || screenModel.posterUrl === null || screenModel.posterUrl === '') {
              screenModel.posterUrl = makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);
            }
          },
          error: (e) => {
            this.countdownComponentService.destroy();
            this.orderStateService.removeOrder();
          },
          complete: () => {
            this.screenMap = screenMap;
            const getCatering = this.cateringSaleEnabled
              ? this.orderDataProvider.getCatering(this.cinemaId, this.orderId, this.screen.screenGroupId).pipe(
                  map((cateringCollection) => {
                    this.catering = cateringCollection;
                    return cateringCollection;
                  })
                )
              : of(null);
            const result = forkJoin([this.loadVoucherItemList(), this.loadCateringItemList(), getCatering]);
            result.subscribe({
              complete: () => {
                this.checkAndShowModal(order);
                this.loadingService.hideLoader(LoaderEnum.MAIN);
                this.countdownComponentService.destroy();
                this.orderStateService.removeOrder();
              },
            });
          },
        });
      });
    }
  }

  checkAndShowModal(order: OrderViewModel) {
    if (order.status !== OrderStatus.CANCELLED && this.modal) {
      this.modalRef = this.modalService.show(this.modal, { ignoreBackdropClick: true });
    }
  }

  toogleIsQrCodeZoom(bookingId: string): void {
    if (!this.showZoomIcon) {
      return;
    }

    this.isQrCodeZoom[bookingId] = !this.isQrCodeZoom[bookingId];
  }

  toggleVoucherQrCodeZoom(): void {
    this.isVoucherQrCodeZoom = !this.isVoucherQrCodeZoom;
  }

  /**
   * Loads voucher item list
   */
  private loadVoucherItemList(): Observable<Array<VoucherTypeModel>> {
    this.selectedVoucherList = this.voucherService.createVoucherCollectionFromOrder(this.order);

    return of([]);
  }

  private loadBasket(callback?: any) {
    console.log('#loadBasket');
    this.basketDataProvider.do(this.order, this.cinema.id).subscribe((result: BasketPageModel) => {
      this.basketPageModel = result;
      if (callback) {
        callback(result);
      }
    });
  }

  /**
   * Loads catering item list
   */
  private loadCateringItemList(): Observable<CateringViewModel> {
    if (this.cateringSaleEnabled === false) {
      return of(null);
    }

    return this.orderDataProvider.getCatering(this.cinema.id, this.order.id).pipe(
      tap((catering) => {
        const cateringAggregation: CateringAggregationViewModel = new CateringAggregationBuilder(catering).build();

        const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(cateringAggregation, this.order.fbItems);

        if (Object.keys(selectedMap).length > 0) {
          const articleCombinationList: Array<CateringAggregationArticleViewModel> = this.selectedCateringArticleCombinationList || [];
          for (const articleId of Object.keys(selectedMap)) {
            for (const mapping of selectedMap[articleId]) {
              const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
                mapping.article,
                mapping.selectedQuantity,
                mapping.selectedModifierItemMap,
                mapping.selectedSubArticleMap
              );

              articleCombinationList.push(articleCombination);
            }
          }

          this.selectedCateringArticleCombinationList = articleCombinationList.map((x) => x);
        }
      })
    );
  }

  public findTicketById(id: string): TicketViewModel {
    const ticket = this.tickets.filter(function (item) {
      return item.id === id;
    });

    return ticket.length > 0 ? ticket[0] : null;
  }

  public findCateringNameById(id: string): string {
    for (const item of this.catering.groups) {
      if (item.id === id) {
        return item.name;
      }
    }

    for (const item of this.catering.articles) {
      if (item.id === id) {
        return item.name;
      }

      for (const subItem of item.replacementList) {
        if (subItem.id === id) {
          return subItem.name;
        }
      }

      for (const subItem of item.modifierCollectionList) {
        if (subItem.id === id) {
          return subItem.name;
        }
      }

      for (const subItem of item.subArticleList) {
        if (subItem.id === id) {
          return subItem.name;
        }
      }
    }
  }

  public backToScreen(): void {
    this.router.navigate(['screen', this.screenMap[0].screeningId], {
      queryParams: {
        cinemaId: this.cinema.id,
      },
    });
  }

  public backToMain(): void {
    this.router.navigate(['screening']);
  }

  public loadingEvent($event) {
    this.childLoadingStatus = this.loadingService.loadingStatus;
  }

  onModalClose() {
    this.modalRef.hide();
  }
}
