import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import cloneDeep from 'lodash-es/cloneDeep';
import { FbItemApiModel } from '../model/api-model/order/fb-item/fb-item.api.model';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { OrderHttpService } from './order.http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderCateringItemHttpService {
  constructor(private http: HttpClient) {}

  patchItem(cinemaId: string, orderId: string, basketItemId: string, item: FbItemApiModel): Observable<OrderApiModel> {
    const clonedItem: FbItemApiModel = cloneDeep<FbItemApiModel>(item);
    const plainClonedItem = instanceToPlain(clonedItem, { strategy: 'excludeAll' });

    return this.http.patch<FbItemApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/fbitem/${basketItemId}`, plainClonedItem).pipe(
      tap((res) => OrderCateringItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  patchQuantityItem(cinemaId: string, orderId: string, basketItemId: string, quantity: number): Observable<OrderApiModel> {
    return this.http.patch<FbItemApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/fbitem/${basketItemId}/quantity`, { quantity: quantity }).pipe(
      tap((res) => OrderCateringItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  put(cinemaId: string, orderId: string, items: Array<FbItemApiModel>): Observable<OrderApiModel> {
    const clonedItems: Array<FbItemApiModel> = cloneDeep<Array<FbItemApiModel>>(items);
    const plainClonedItems = instanceToPlain(clonedItems, { strategy: 'excludeAll' });

    return this.http.put<FbItemApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/fbitem`, plainClonedItems).pipe(
      tap((res) => OrderCateringItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  post(cinemaId: string, orderId: string, item: FbItemApiModel): Observable<OrderApiModel> {
    const clonedItem: FbItemApiModel = cloneDeep<FbItemApiModel>(item);
    const plainClonedItem = instanceToPlain(clonedItem, { strategy: 'excludeAll' });

    return this.http.post<FbItemApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/fbitem`, plainClonedItem).pipe(
      tap((res) => OrderCateringItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  delete(cinemaId: string, orderId: string, itemId: string): Observable<OrderApiModel> {
    return this.http.delete(`/cinema/${cinemaId}/order/${orderId}/fbitem/${itemId}`).pipe(
      tap((res) => OrderCateringItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  private static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }
}
