export abstract class FinancialHelper {
  public static fixedValue(value, fractionDigital = 2): string {
    if (!value) {
      return '';
    }

    return Number.parseFloat(value).toFixed(fractionDigital);
  }

  public static fixedValueAsNumber(value, fractionDigital = 2): number {
    return Number(this.fixedValue(value, fractionDigital));
  }
}
