import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { WeekdayService } from '../service/weekday.service';
import { DateTime } from 'luxon';
import { DateTimeLocaleDataService } from '../service/datetime-locale-data.service';
import { SupportedDateTimeFormat } from '../locale-data/supported-datetime-format.enum';

@Pipe({
  name: 'extendedDate',
})
export class ExtendedDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected localeId: string, protected localeDataService: DateTimeLocaleDataService, private weekdayService: WeekdayService) {}

  transform(
    value: DateTime | Date | string | number,
    format?: string,
    addRelativeCalendarDays: boolean = false,
    relativeCalendarDaysCount: number = 2
  ): string | null {
    let date: DateTime;
    switch (typeof value) {
      case 'string':
        date = DateTime.fromISO(value);
        break;
      case 'number':
        date = DateTime.fromMillis(value);
        break;
      default:
        date = value instanceof Date ? DateTime.fromJSDate(value) : value;
        break;
    }

    if (!(date instanceof DateTime) || !date.isValid) {
      return null;
    }

    return `${addRelativeCalendarDays ? this.preText(date, relativeCalendarDaysCount) : ''}${this.localeDataService.transform(
      date,
      format as SupportedDateTimeFormat
    )}`;
  }

  preText(value: DateTime, relativeCalendarDaysCount?: number) {
    const now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (value >= now && now >= value.minus({ days: relativeCalendarDaysCount })) {
      return `${this.capitalized(value.toRelativeCalendar({ locale: this.localeId }))}, `;
    }

    return '';
  }

  capitalized(input: string): string {
    if (!input) {
      return '';
    }

    return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
  }
}
