import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { FbItemMembershipPriceApiModel } from './fb-item-membership-price.api.model';
import { FbItemModifierItemApiModel } from './fb-item-modifier-item.api.model';
import { FbItemSubArticleApiModel } from './fb-item-sub-article.api.model';

export class FbItemApiModel {
  @Expose()
  id: string;

  @Expose()
  articleId: string;

  @Expose()
  quantity: number;

  @Expose()
  voucherNumber: string;

  @Expose()
  voucherName: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  comboMinPrice: number;

  @Expose()
  taxValue: number;

  @Expose()
  pickupTime: string;

  @Expose()
  defaultPriceLevelPrice: number;

  @Expose()
  @Type(() => FbItemSubArticleApiModel)
  subArticleList: FbItemSubArticleApiModel[] = [];

  @Expose()
  @Type(() => FbItemModifierItemApiModel)
  modifierItemList: FbItemModifierItemApiModel[] = [];

  @Expose()
  itemEarnedPoints: number;

  @Expose()
  itemRedemptionPoints: number;

  @Expose()
  promotionId: string;

  @Expose()
  promotionName: string;

  @Expose()
  @Type(() => FbItemMembershipPriceApiModel)
  membershipPrices: FbItemMembershipPriceApiModel[] = [];

  @Expose()
  graphicUrl: string;

  @Expose()
  assignedSeatId: string;

  @Expose()
  assignedScreeningId: string;
}
