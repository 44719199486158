import {Expose, Transform} from 'class-transformer';
import {DateTime} from 'luxon';

export class FbItemSubArticleApiModel {
  @Expose()
  articleId: string;

  @Expose()
  quantity: number;

  @Expose()
  name: string;

  @Expose()
  taxRate: number;

  @Expose()
  price: number;

  @Expose()
  defaultPriceLevelPrice: number;
}
