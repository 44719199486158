import {Expose} from 'class-transformer';

export class KeyValue<T> {
  constructor(key: string, value: T) {
    this.key = key;
    this.value = value;
  }

  @Expose()
  key: string;

  @Expose()
  value: T;
}
