<div class="content">
  <app-tag-manager></app-tag-manager>
  <app-header *ngIf="templateType === 'default'" [languageList]="languageList"></app-header>

  <div class="container px-1 px-sm-3">
    <div class="row">
      <div class="col-md-7 offset-md-2 col-12">
        <app-message></app-message>
      </div>
    </div>
  </div>

  <app-main></app-main>
</div>

<footer class="footer">
  <app-footer *ngIf="templateType === 'default'"></app-footer>
</footer>