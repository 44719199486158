import { Expose, Type } from 'class-transformer';
import { TicketExtraFeeApiModel } from './ticket-extra-fee.api.model';

export class TicketApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  price: number;

  @Expose()
  tax: number;

  @Expose()
  @Type(() => TicketExtraFeeApiModel)
  extraFees: TicketExtraFeeApiModel[] = [];

  @Expose()
  screeningItemId: string;

  @Expose()
  seatId: string;

  @Expose()
  defaultPriceLevelPrice: number;
}
