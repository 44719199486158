import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private baseDomain: string = null;

  public constructor() {

    this.baseDomain = this.generateBaseDomain();
  }

  private generateBaseDomain(): string {

    const hostname: string = window.location.hostname;

    const exploded: Array<string> = hostname.split('.');

    if (exploded.length <= 2) {

      return exploded.join('.');
    }

    exploded.shift();

    return '.' + exploded.join('.');
  }

  get BaseDomain(): string {

    return this.baseDomain;
  }

}
