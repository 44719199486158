import {Expose} from 'class-transformer';

export class UserMovieTagApiModel {
  @Expose()
  orderNo: number;

  @Expose()
  symbol: string;

  @Expose()
  description: string;
}
