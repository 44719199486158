import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { distinctUntilChanged, flatMap, forkJoin, from, map, mergeMap, Observable, of, Subscription, switchMap, take, toArray } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderSummaryComponent } from '../../component/order/order-summary/order-summary.component';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { storageKey, appProjectName } from 'libs/core/src/app.const';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { VoucherErrorEnum } from 'libs/core/src/lib/model/component/basket/voucher-error.enum';
import { VoucherModalStateEnum } from 'libs/core/src/lib/model/component/basket/voucher-modal-state.enum';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { DefaultTicketStrategy } from 'libs/core/src/lib/model/page/basket/strategy/default-ticket/default-ticket.strategy';
import {
  DefaultTicketStrategyContext,
  DefaultTicketStrategyType,
} from 'libs/core/src/lib/model/page/basket/strategy/default-ticket/default-ticket.strategy-context';
import { TicketListSortStrategy } from 'libs/core/src/lib/model/page/basket/strategy/ticket-list-sort/ticket-list-sort.strategy';
import {
  TicketListSortStrategyContext,
  TicketListSortStrategyType,
} from 'libs/core/src/lib/model/page/basket/strategy/ticket-list-sort/ticket-list-sort.strategy-context';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';
import { IVoucher, OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { ChangeTicketEvent } from '../../component/basket/basket-list/event/change-ticket.event';
import { VoucherViewModel } from 'libs/core/src/lib/model/view-model/voucher/voucher.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { isEqual } from 'lodash';
import { ModalEventEmitter } from '../../component/modal/model/modal-status.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';

@UntilDestroy()
@Component({
  template: '',
})
export class BasketPageComponent implements OnInit, AfterViewInit, OnDestroy {
  loaderEnum: typeof LoaderEnum = LoaderEnum;

  @ViewChild('orderSummaryMobile') public orderSummaryComponentMobile: OrderSummaryComponent;
  @ViewChild('orderSummaryDesktop') public orderSummaryComponentDesktop: OrderSummaryComponent;
  @ViewChild('stepBackConfirmTemplate') public stepBackConfirmTemplate!: TemplateRef<any>;

  public warningModalVisibled = false;
  public basketPageModel: BasketPageModel = null;
  public order: OrderViewModel = null;
  public ticketList: Array<TicketViewModel> = new Array<TicketViewModel>();
  public isOrderAgreementsRequired = false;
  public shouldStartPaymentAfterPatch = false;
  public requireExtraFees = [];
  public totalPrice = 0;
  public totalOptionalExtraFeePrice = 0;
  public enabledHeliosVoucher = false;
  public voucherErrors: VoucherErrorEnum[] = [];
  public fetchedVoucher = false;
  public voucher: VoucherViewModel = null;
  public voucherModalState: VoucherModalStateEnum = null;
  public modalRef: BsModalRef;
  private deactivated = false;
  protected orderSubscription: Subscription = Subscription.EMPTY;
  private ticketListSortStrategyContext: TicketListSortStrategy = null;
  private defaultTicketStrategyContext: DefaultTicketStrategy = null;
  protected doSubscription: Subscription = Subscription.EMPTY;

  vouchers: IVoucher[];

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderService: OrderStateService,
    protected basketDataProvider: BasketDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected navigationHelperService: NavigationHelperService,
    protected messageService: MessageService,
    protected translate: TranslateService,
    protected countdownComponentService: CountdownComponentService,
    protected voucherService: VoucherService,
    protected responseValidatorService: ResponseValidatorService,
    protected modalService: BsModalService,
    protected appService: AppService,
    protected headerService: HeaderService,
    protected cateringStateService: CateringStateService,
    protected loadingService: LoadingService,
    protected totalizerService: TotalizerService
  ) {
    this.isOrderAgreementsRequired = this.environment.pages.basket.showAgreements;
    this.shouldStartPaymentAfterPatch = this.environment.pages.basket.autoStartPayment;
    this.enabledHeliosVoucher = this.environment.pages.basket.enabledHeliosVoucher;

    this.ticketListSortStrategyContext = new TicketListSortStrategyContext(this.environment.pages.basket.ticketList.sortStrategy as TicketListSortStrategyType);

    this.defaultTicketStrategyContext = new DefaultTicketStrategyContext(this.environment.pages.basket.ticketList.defaultTicket as DefaultTicketStrategyType);
  }

  public ngOnInit() {
    this.orderSubscription = this.orderService.state$
      //.pipe(distinctUntilChanged((prev, curr) => !isEqual(prev, curr)))
      .subscribe((order: OrderViewModel) => {
        this.justDoIt(order);
      });
  }

  ngAfterViewInit(): void {
    this.countdownComponentService.start();
    this.countdownComponentService.visible();
  }

  /**
   * Performed when user changes the ticket
   */
  public onChangedSeatTicket(event: ChangeTicketEvent) {
    const order = this.orderService.getOrder();
    if (!order) {
      return;
    }

    const item: ScreeningItemViewModel | undefined = order.screeningItems.find((x) => x.seatId === event.seatId);

    if (!item) {
      return;
    }

    item.ticketId = event.ticketId;
    this.updateSelectedTicketList();
  }

  public onNavigationClick(event: string) {
    switch (event) {
      case 'previous':
        this.onPreviousAction();
        break;
      case 'next':
        this.onNextAction();
        break;
    }
  }

  public onModalClick(event: string) {
    this.warningModalVisibled = false;
    this.onNextAction(event);
  }

  private deleteVouchers() {
    this.vouchers = this.order?.getVouchers();
    if (!this.vouchers || this.vouchers.length === 0) {
      console.info('No vouchers found');
      return;
    }

    this.vouchers.forEach((v) => {
      this.onVoucherDelete(v.number);
    });
  }

  public onPreviousAction(previousRoute: boolean = true) {
    const cinemaId = this.order?.cinemaId;
    let screeningId = this.order?.screeningItems[0]?.screeningId;
    // if (this.orderService.getItem(storageKey.isEvent) === 'true') {
    //   screeningId = this.orderService.getItem(storageKey.lastEventId);
    // }

    console.log('onPreviousAction #clearSubscriber');
    this.clearSubscriber();
    if (previousRoute) {
      this.router.navigate(['screen'], { queryParams: { screeningId: screeningId, cinemaId: cinemaId } });
    } else {
      window.history.back();
    }
  }

  public onNextAction(customTarget: string | null = null) {
    if (this.cateringStateService.cateringSaleEnabled) {
      if (customTarget === null) {
        this.warningModalVisibled = true;
        return;
      }
    } else {
      customTarget = 'skip';
    }

    this.orderService.setItem(storageKey.isExtraFeesSelected, 'true');
    const orderItemsWithoutSelectedTickets: Array<ScreeningItemViewModel> = this.order.screeningItems.filter(
      (element) => element.ticketId === null || element.ticketId === undefined
    );

    if (orderItemsWithoutSelectedTickets.length > 0) {
      this.messageService.add(new MessageModel(MessageType.info, this.translate.instant('errors.102')));
      return;
    }

    this.loadingService.showLoader(LoaderEnum.MAIN);

    const observableChain: Array<Observable<any>> = new Array<Observable<any>>();

    if (this.isOrderAgreementsRequired === true) {
      const agreements = this.basketPageModel.agreements?.filter((agreement) => agreement.checked === true).map((element) => element.id);

      observableChain.push(this.orderDataProvider.updateAgreements(this.order.cinemaId, this.order.id, agreements));
    } else {
      observableChain.push(of(null));
    }

    observableChain.push(this.orderDataProvider.patchItems(this.order.cinemaId, this.order, this.order.screeningItems));

    forkJoin(...observableChain).subscribe(([s, order]: [any, OrderViewModel]) => {
      this.orderService.setOrder(order);

      if (this.shouldStartPaymentAfterPatch === true) {
        const paymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
        paymentRequest.cinemaId = this.order.cinemaId;
        paymentRequest.orderId = this.order.id;
        paymentRequest.paymentChannel = this.environment.constants.paymentChannel;
        paymentRequest.paymentProviderIdentifier = this.environment.constants.autoSelectPaymentMethod;

        this.orderDataProvider.getPayment(paymentRequest).subscribe({
          next: (paymentResponse) => {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
            this.orderService.removeOrder();
            this.orderSubscription.unsubscribe();
            window.location.href = paymentResponse.plainPayload;
          },
          error: (e) => {
            console.log(e);
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          },
        });
      } else {
        if (customTarget === null) {
          this.router.navigate([this.navigationHelperService.getNextRoute(this.route.snapshot)]);
        } else {
          this.router.navigate([this.navigationHelperService.getRouteFor(customTarget, this.route.snapshot)]);
        }
        return;
      }
    });
  }

  public ngOnDestroy(): void {
    this.clearSubscriber();
    this.fetchedVoucher = false;
    this.voucherModalState = null;
  }

  public clearSubscriber() {
    if (this.orderSubscription !== Subscription.EMPTY) {
      this.orderSubscription.unsubscribe();
      this.orderSubscription = Subscription.EMPTY;
    }
  }

  public onVoucherAddModal(eventEmitter: ModalEventEmitter) {
    this.loadingService.showLoader(LoaderEnum.MAIN);
    this.voucherErrors = [];
    let isFetched = false;

    this.orderDataProvider
      .patchItems(this.order.cinemaId, this.order, this.order.screeningItems)
      .pipe(
        mergeMap((result) => {
          const limit = eventEmitter.count;
          const orderItems = result.screeningItems;

          if (limit > 0) {
            if (this.voucherUses === limit) {
              return this.voucherService
                .assignVoucherToOrder(this.order.cinemaId, this.order.id, this.voucher.voucherNumber)
                .pipe(map((order) => order.voucher));
            }
            return this.voucherService
              .assignVoucherToOrder(this.order.cinemaId, this.order.id, this.voucher.voucherNumber, limit)
              .pipe(map((order) => order.voucher));
          }

          if (limit === 0) {
            isFetched = true;
            return of(null);
          }

          const orderItemsArray: ScreeningItemViewModel[] = [];
          for (let i = 0; i < orderItems.length; i++) {
            if (this.voucher.positions.find((ps) => ps.ticketId === orderItems[i].ticketId) && orderItemsArray.length < limit) {
              orderItemsArray.push(orderItems[i]);
            }
          }

          if (orderItemsArray.length < 1) {
            isFetched = true;
            this.voucherErrors = [VoucherErrorEnum.CANNOT_USE];
            return of(null);
          }

          return from(orderItemsArray).pipe(
            flatMap((item) =>
              this.voucherService.assignVoucherToOrderItem(this.order.cinemaId, this.order.id, item.id, this.voucher.voucherNumber, this.voucher.voucherName)
            ),
            toArray()
          );
        })
      )
      .subscribe({
        next: (x) => {
          const order = this.orderService.getOrder();
          this.vouchers = order?.getVouchers();

          if (this.vouchers?.length <= 0 || !this.vouchers.some((v) => v.number === this.voucher.voucherNumber)) {
            this.voucherErrors = [VoucherErrorEnum.CANNOT_USE];
          }

          this.fetchedVoucher = x !== null;

          if (isFetched) {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          } else {
            this.voucherModalState = VoucherModalStateEnum.COMPLETE;
            this.justDoIt(order);
          }
        },
        error: (e) => {
          this.fetchedVoucher = false;
          if (e && e.error && e.error.error && e.error.error.code === 0) {
            this.voucherErrors = [VoucherErrorEnum.CANNOT_USE];
          }

          this.handleError(e);

          if (isFetched) {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          }
        },
      });
  }

  public onVoucherModalClose(eventEmitter: ModalEventEmitter) {
    this.fetchedVoucher = false;
    this.voucherModalState = null;
  }

  public get voucherUses() {
    if (this.voucher.usesLeft === -1) {
      return this.order.screeningItems.length;
    }

    return this.voucher.usesLeft;
  }

  public onVoucherItemCheck(voucherNumber) {
    this.orderService.setItem(storageKey.isExtraFeesSelected, 'true');
    this.voucherErrors = [];
    if (this.enabledHeliosVoucher) {
      this.heliosVoucherFlowChecking(voucherNumber);
    } else {
      this.loadingService.showLoader(LoaderEnum.MAIN);
      this.orderDataProvider
        .patchItems(this.order.cinemaId, this.order, this.order.screeningItems)
        .pipe(switchMap((order) => this.voucherService.assignVoucherToOrder(order.cinemaId, order.id, voucherNumber)))
        .subscribe({
          next: (order) => {
            this.vouchers = this.orderService.getVouchers();

            console.log('vouchers:', this.vouchers, order.voucher);
            this.justDoIt(order);
          },
          error: (e) => {
            this.handleError(e);
            this.setVoucherErrors(e);
          },
        });
    }
  }

  protected setVoucherErrors(error: any) {
    if (error === null && error.error === null && error.error.error === null) {
      return;
    }

    const code = error.error.error.code;
    if (code === 0 || code === -6 || code === 11) {
      this.voucherErrors = [VoucherErrorEnum.CANNOT_USE];
    } else if (code === 460) {
      this.voucherErrors = [VoucherErrorEnum.NOT_FOUND];
    } else if (code === 461) {
      this.voucherErrors = [VoucherErrorEnum.ALREADY_USED];
    } else if (code === 233) {
      this.voucherErrors = [VoucherErrorEnum.ONLY_FOR_LOGGED_USER];
    }
  }

  public heliosVoucherFlowChecking(voucherNumber: string) {}
  public displayAdditionalNotification(cinemaId: string) {}

  public onVoucherDelete(voucherNumber: string) {
    this.voucherErrors = [];
    this.loadingService.showLoader(LoaderEnum.MAIN);
    this.voucherService.removeVoucherFromOrder(this.order.cinemaId, this.order.id, voucherNumber).subscribe({
      next: (x) => {
        const order = this.orderService.getOrder();

        this.vouchers = this.orderService.getVouchers();

        this.justDoIt(order);
      },
      error: (e) => this.handleError(e),
    });
  }

  public onSingleVoucherItemDelete(item: ScreeningItemViewModel) {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.voucherService.removeVoucherFromOrderItem(this.order.cinemaId, this.order.id, item.id, item.voucherNumber).subscribe({
      next: (x) => {
        this.vouchers = this.orderService.getVouchers();
        const order = this.orderService.getOrder(); //TODO potencjalny problem z brakiem informacji o order
        this.justDoIt(order);
      },
      error: (e) => this.handleError(e),
    });
  }

  /**
   * Selects default tickets
   */
  private selectDefaultTickets(order): void {
    if (!order) {
      return;
    }

    order.screeningItems.forEach((orderItem) => {
      const availableTicketsForOrderItem: Array<TicketViewModel> = this.basketPageModel.findTicketsByScreeningItemId(orderItem.id);
      if (!orderItem.ticketId) {
        const ticket: TicketViewModel | null = this.defaultTicketStrategyContext.getDefaultTicket(availableTicketsForOrderItem);
        if (ticket) {
          orderItem.ticketId = ticket.id;
        }
      }
    });

    this.updateSelectedTicketList();
  }

  /**
   * Updates selected ticket list
   */
  private updateSelectedTicketList(): void {
    const selectedTicketList: Array<TicketViewModel> = new Array<TicketViewModel>();

    this.order.screeningItems.forEach((orderItem) => {
      const ticket: TicketViewModel | undefined = this.basketPageModel.tickets.find((element) => {
        return element.seatId === orderItem.seatId && element.id === orderItem.ticketId;
      });

      if (ticket) {
        selectedTicketList.push(ticket);
      }
    });
    this.ticketList = selectedTicketList;

    this.orderService.changeSummaryStateEmit();
  }

  protected justDoIt(order) {
    console.log('#Basket justDoIt');
    if (this.doSubscription !== Subscription.EMPTY) {
      this.doSubscription.unsubscribe();
    }

    this.order = order;
    if (!order || !order.cinemaId) {
      return;
    }

    this.doSubscription = this.basketDataProvider.do(order, order.cinemaId).subscribe({
      next: (basketModelView) => {
        basketModelView.tickets = this.ticketListSortStrategyContext.sort(basketModelView.tickets);
        this.basketPageModel = basketModelView;
        this.headerService.setData(this.basketPageModel.screen);
        this.selectDefaultTickets(order);
        if (this.voucherModalState === 'complete') {
          this.fetchedVoucher = true;
        }

        this.loadingService.hideLoader(LoaderEnum.MAIN);
        setTimeout(() => {
          this.displayAdditionalNotification(order.cinemaId);
          if (this.orderSummaryComponentMobile) {
            this.requireExtraFees = this.orderSummaryComponentMobile.orderSummaryView.requireExtraFees;
          } else if (this.orderSummaryComponentDesktop) {
            this.requireExtraFees = this.orderSummaryComponentDesktop.orderSummaryView.requireExtraFees;
          }
        }, 1);
      },
      error: (e) => {
        this.fetchedVoucher = false;
        this.voucherModalState = null;
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }

  protected handleError(err: HttpErrorResponse) {
    const errStatus = err && err.status ? err.status : null;
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    if (errStatus === 400) {
      const isHandled: boolean = this.handleValidationError(err);
      if (isHandled === false) {
        return this.responseValidatorService.viewErrors(err);
      }
    }
  }

  /**
   * Handles an well described errors
   */
  private handleValidationError(err: HttpErrorResponse): boolean {
    let infoCode = err.error && err.error.error && err.error.error.info_code ? err.error && err.error.error && err.error.error.info_code : null;

    if (infoCode && typeof infoCode !== 'string') {
      infoCode = (infoCode as any).toString();
    }

    let translationResult = false;

    if (infoCode !== null) {
      const translationKey = `errors.${infoCode}`;
      const errorContentTranslation: string = this.translate.instant(translationKey);

      if (errorContentTranslation !== translationKey) {
        this.messageService.clear();
        this.messageService.add(new MessageModel(MessageType.danger, errorContentTranslation, infoCode));
        translationResult = true;
      }
    }
    return translationResult;
  }

  public showStepBackModal(template: TemplateRef<any>) {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public onStepBackModalClick(deactivate: boolean) {
    this.modalRef.hide();
    if (!deactivate) {
      return;
    }

    if (this.appService.isProject(appProjectName.HELIOS)) {
      this.countdownComponentService.destroy();
    }

    this.deactivated = true;
    this.deleteVouchers();
    console.log('onStepBackModalClick #clearSubscriber');
    this.clearSubscriber();
    this.onPreviousAction(false);
  }

  public canDeactivate(): Observable<boolean> {
    if (this.deactivated || !this.stepBackConfirmTemplate) {
      return of(true);
    }

    this.showStepBackModal(this.stepBackConfirmTemplate);
    return of(false);
  }
}
