import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { ScreeningItemApiModel } from '../model/api-model/order/screening-item/screening-item.api.model';
import { OrderHttpService } from './order.http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderItemHttpService {
  constructor(private http: HttpClient) {}

  patch(cinemaId: string, orderId: string, items: ScreeningItemApiModel[]): Observable<OrderApiModel> {
    const clonedItems = cloneDeep(items);

    return this.http.patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/screeningitem`, clonedItems).pipe(
      tap((res) => OrderItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  delete(cinemaId: string, orderId: string, screeningItemId: string): Observable<OrderApiModel> {
    return this.http.delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/screeningitem/${screeningItemId}`).pipe(
      tap((res) => OrderItemHttpService.cacheModify(orderId, res)),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  private static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }
}
