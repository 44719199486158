import { AbstractViewModel } from "../../abstract.view.model";
import { ProgramEmailApiModel } from "../../api-model/member-get-member/program-email.api.model";

export class ProgramEmailViewModel extends AbstractViewModel<ProgramEmailApiModel> {
  email: string;
  redeemed: string;

  toApiModel(): ProgramEmailApiModel {
    return undefined;
  }

  constructor(protected apiModel: ProgramEmailApiModel = new ProgramEmailApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.email = this.apiModel.email;
    this.redeemed = this.apiModel.redeemed;
  }
}
