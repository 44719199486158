import { DateTime } from 'luxon';
import { CmsNavigateEnum } from './enum/cms-navigate.enum';
import { FilterChangeState } from './enum/filter-change-state';
import { MyAccountPageEnum } from './enum/my-account-page.enum';
import { ScreeningType } from './enum/screening-type';
import { SeatGroupDisplayMode } from './enum/seat-group-display-mode.enum';
import { SeatGroupSalesMode } from './enum/seat-group-sales-mode.enum';
import { SlideTypeEnum } from './enum/slide-type.enum';
import { CinemaViewModel } from './model/view-model/cinema/cinema.view.model';
import { MoviePrintViewModel } from './model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from './model/view-model/movie/movie.view.model';
import { QuickFilterType } from 'libs/core/src/lib/enum/filter-type';
import { QuickFiltersMode } from 'libs/webcomponent/src/lib/webcomponent/screening/program/model/quick-filters-mode.enum';
import { TrailerViewModel } from './model/view-model/movie/trailer.view.model';
import { AdditionalFeatureViewModel } from './model/view-model/movie/additional-feature.view.model';

export interface IScreeningItem {
  id: string;
  cinemaId: string;
  screenId: string;
  eventId: string;
  startAt: DateTime;
  saleTimeTo: DateTime;
  reservationTimeTo: DateTime;
  printType: string;
  inactive: boolean;

  visible: boolean;

  content: any;
}

export interface IMoviePoster {
  id: string;
  image?: string;
  video?: string;
  thumbImage?: string;
  posterImage?: string;
  title?: string;
  alt?: string;
  slug?: string;
  link?: string;
}

export interface IComingSoonMovie {
  id: string;
  title: string;
  slug?: string;
  poster?: string;
  hasPoster: boolean;
  date: DateTime;
  link?: string;
  trailersGroupByLang?: TrailerViewModel[];
  ageRestriction: string;
  additionalFeatures: AdditionalFeatureViewModel[];
}

export interface IComingSoonItem {
  date: DateTime;
  movies: IComingSoonMovie[];
}

export interface IScreeningCardItem {
  id: string;
  slug: string;

  genresString: string;
  countryYear: string;
  ageRestriction: string;
  location: string;

  moviePrint: MoviePrintViewModel;
  movie: MovieViewModel;
  screenings: IScreeningItem[];
  cinema: CinemaViewModel;

  filterLanguages: string[];
  filterCinemas: string[];
  filterGenres: string[];

  visible: boolean;
}

export interface IScreeningCard {
  id: string;
  title: string;
  slug: string;
  agerating: string;
  poster: string;

  items: IScreeningCardItem[];
  collapse?: boolean;
  isEvent: boolean;

  visible: boolean;
}

export interface IProgramFilterItem {
  key: string;
  label: string;
  orderNo?: number;
  type: QuickFilterType;
  group: number;
  selected?: boolean;
}

export interface IProgramGroupingOption {
  key: string;
  label: string;
  selected?: boolean;
}

export interface IProgramFilterCategory {
  key: string;
  name: string;
  items: IProgramFilterItem[];
  collapse?: boolean;
  selected?: boolean;

  [key: string]: string | boolean | IProgramFilterItem[] | undefined;
}

export interface IProgramFilter {
  attributes: IProgramFilterItem[];
  categories: IProgramFilterCategory[];

  date: DateTime;
  changeState: FilterChangeState;
  quickFiltersMode?: QuickFiltersMode;
}

export interface IMoviePackage {
  moviePrint: MoviePrintViewModel;
  movie: MovieViewModel;
  screenings: IScreeningItem[];
  cinema: CinemaViewModel;
}

export interface IScreenRowModel {
  id: string;
  legend: string;
  coordinate: number;
}

export interface IScreenColModel {
  id: string;
  coordinate: number;
}

export interface ISeatModel {
  id: string;
  groupId: string;
  kind: string;
  colId: string;
  rowId: string;
  symbol: string;
}

export interface IScreenElementModel {
  id: string;
  name: string;
  seatIds: string[];
}

export interface ISeatBlockedModel {
  id: string;
  seatId: string;
}

export interface ISeatGroupModel {
  seatIds: string[];
  id: string;
  ico: string;
  color: string;
  name: string;
  description: string;
  type: string;
  displayMode: SeatGroupDisplayMode;
  salesMode: SeatGroupSalesMode;
}

export interface IScreenModel {
  id: string;
  name: string;
  type: string;
  feature: string;
  number: number;
  rows: IScreenRowModel[];
  cols: IScreenColModel[];
  seats: ISeatModel[];
  groups: ISeatGroupModel[];
  defaultSeatDescription: string;
  defaultSeatGroupName: string;
  defaultSeatGroupColor: string;
  blockedList: ISeatBlockedModel[];
  screenGroupId: string;
  ffaNumber: string;
  proCinema: string;
  screenElements: IScreenElementModel[];
}

export interface IResponsiveBoolean {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

export interface ISliderOptions {
  interval?: number | boolean;
  slideSchedule?: string;
  backgroundFit?: 'cover' | 'contain' | 'none';
  displayPoster?: boolean;
  displayBackground?: boolean;
  displayIndicator?: IResponsiveBoolean;
  displayChevrons?: IResponsiveBoolean;
  contentPosition?: 'top' | 'bottom';
  gradientDirection?: 'top' | 'left' | 'bottom' | 'right';
  gradients?: any[];
  showHeadline?: boolean;
}

export interface ISlideModel {
  id: string;
  slug: string;

  genres: string;
  countryYear: string;
  ageRestriction: string;
  location: string;

  title: string;
  titleColor: string;
  imdbId: string;
  imdbRating: string;
  duration: number;
  description: string;
  posters: string[];
  pictures: string[];

  screenings: IScreeningItem[];

  movieId?: string;
  eventId?: string;

  type: SlideTypeEnum;

  poster: string;
  buttonLabel: string;
  backgroundImage: string;
  focalpoint: number;

  callToAction?: string;
  callToActionUrl?: string;

  imageRedirectUrl?: string;
  //allowedBackgroundClick: boolean;

  date: DateTime;
  cinemaDayOffset: number;

  topLayerImg: string;
  options: ISliderOptions;
  headline?: string;
}

export interface MyAccountPageChangeInterface {
  page: MyAccountPageEnum;
  additionalInfo: string;
}

export interface GiftCardPurchaseUpdateFormInterface {
  template?: string;
  cardType?: string;
  giftCardsQuantity?: number;
  giftCardsValue?: string;
  envelopesQuantity?: number;
}

export interface IScreeningDetails extends IMoviePrintInfoBase, IMovieInfoBase {
  regionName: string;
  cinemaName: string;
  screeningDate: DateTime;
  screenNumber: number;
  screenName: string;
  screenFeature: string;
}

export interface IMoviePrintInfoBase {
  duration: number;
  movieLanguage: string;
  movieRelease: string;
}

export interface IMovieInfoBase {
  posterUrl: string;
  title: string;
  ageRestriction: string;
}

export interface IFilterCard {
  collapse: boolean;
  visible: boolean;
  attributes: string[];
  content: any;
}

export interface INaviModel {
  type: CmsNavigateEnum;
  value: string | IScreeningItem;
}

export interface FieldJumperInterface {
  length?: number;
}

export class FilterCard<Type> implements IFilterCard {
  constructor(public content: Type, public attributes: string[] = []) {}

  collapse: boolean = false;
  visible: boolean = true;
}

export interface IScreeningOptions {
  type: string;
  id: string;
  cinemaDayOffset: number;
}

export interface ForceLoginInterface {
  preventCloseModal?: boolean;
  href?: string;
  skipForceClose?: boolean;
}

export interface ForceCloseInterface {
  modalToClose?: string;
}

export interface ForceEditInterface {
  preventCloseModal?: boolean;
  href?: string;
}

export interface UserStateInterface {
  isLogged: boolean;
}

export interface IComingSoonFilter {
  months: IComingSoonFilterButton[];
  changeState: FilterChangeState;
}

export interface IComingSoonFilterButton {
  key: DateTime;
  selected?: boolean;
}

export interface IVoucherShopFilter {
  groups: IVoucherShopFilterButton[];
  changeState: FilterChangeState;
}

export interface IVoucherShopFilterButton {
  id: string;
  name: string;
  selected?: boolean;
}

export interface IItemShopCard {
  id: string;
  name: string;
  description: string;
  cost: number;
  image: string;

  quantity: number;
  maxQuantity: number;

  groupId: string;
  groupName: string;
}

export interface IGroupingOrderGroup {
  group: string;
  showDescription: string;
}

export interface IGroupingOption {
  isConstant: boolean;
  isDefault: boolean;
  groupIndex: number;
  groupingType: string;
  groupingKey: (o: any) => any;
  groupingTitle: (o: any) => any;
  showTitle: boolean;
  groupingElement: (o: any) => any;
}

export interface IRegionCinema {
  regionId: string;
  cinemaId: string;
}

export interface IOrder {
  id: string;
  cinemaId: string;
}

export interface ICoordinates {
  x: number;
  y: number;
}

export interface IRowLegend {
  index: number;
  begin: ICoordinates;
  end: ICoordinates;
}
