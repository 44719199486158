import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { storageKey } from 'libs/core/src/app.const';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { RedirectionService } from 'libs/core/src/lib/service/redirection-service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { take } from 'rxjs';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { HttpParams } from '@angular/common/http';
import { catchError, pipe, tap } from 'rxjs';

@Component({
  selector: 'app-positivecinema',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  translationLoaded: boolean = false;

  @ViewChild('loginPopupTemplate') loginModal: TemplateRef<any>;

  constructor(
    protected translateService: TranslateService,
    protected title: Title,
    protected redirectionService: RedirectionService,
    protected stateService: StateService,
    protected authStateService: AuthStateService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected modalService: BsModalService,
    protected screenDataProvider: ScreenDataProvider,
    protected stepsService: StepsService,
    protected orderStateService: OrderStateService
  ) {
    translateService.setDefaultLang(environment.constants.language.default);
    const lang = this.stateService.getItem(storageKey.lang);
    this.useLanguage(lang ? lang : environment.constants.language.default);
    this.makeRedirectionOnAppLaunch();
  }

  public ngOnInit(): void {
    this.authStateService.retrieveUserToken();

    this.router.events.subscribe((event) => {
      this.title.setTitle(environment.metadata.title);

      if (event instanceof NavigationStart && event.url.startsWith('/screen?')) {
        const cinemaId = this.getParamValueQueryString(event.url, 'cinemaId');
        const screeningId = this.getParamValueQueryString(event.url, 'screeningId');

        if (cinemaId && screeningId) {
          this.setFlowType(cinemaId, screeningId);
        }
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params?.signin === 'true' && !this.authStateService.userIsLoggedAndTokenIsValid()) {
        this.modalService.show(this.loginModal, {});
      }

      // if (params?.cinemaId && params?.screeningId && this.router.routerState.snapshot?.url.startsWith('/screen?')) {
      //   this.setFlowType(params.cinemaId, params.screeningId);
      // }
    });
  }

  getParamValueQueryString(url, paramName) {
    let paramValue;

    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }

    return paramValue;
  }

  async useLanguage(locale: string) {
    // await the http request for the translation file
    await this.translateService.use(locale).toPromise();
    this.translationLoaded = true;
  }

  private makeRedirectionOnAppLaunch(): void {
    let urlToRedirect: string | null = this.redirectionService.getRedirectionOnNextAppLaunch();
    this.redirectionService.removeRedirectionOnNextAppLaunch();
    if (!urlToRedirect) {
      return;
    }

    const queryParameters: URLSearchParams = new URLSearchParams(window.location.search);
    const urlParameters: string = queryParameters.toString();

    if (urlParameters && urlParameters.length > 0) {
      urlToRedirect = urlToRedirect + '&' + urlParameters;
    }

    window.location.href = urlToRedirect;
  }

  private getPageIdentity() {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data['pageIdentify'] || undefined;
  }

  private setFlowType(cinemaId, screeningId) {
    this.screenDataProvider.getScreenByAndCheckIfScreeningIsEvent(cinemaId, screeningId).subscribe((data: ScreenViewModel) => {
      this.stepsService.FlowType = data?.generalAdmission ? FlowType.Tickets : FlowType.Standard;
      if (this.stepsService.FlowType === FlowType.Tickets) {
        this.router.navigate(['tickets'], { queryParams: { screeningId: screeningId, cinemaId: cinemaId } });
      }
    });
  }
}
