import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FacebookEventEnum } from 'libs/shared/src/lib/component/tag-manager/provider/enum/facebook-event.enum';
import { ProviderConfigInterface } from 'libs/shared/src/lib/component/tag-manager/provider/interface/provider-config.interface';
import { ProviderParametersPageInterface } from 'libs/shared/src/lib/component/tag-manager/provider/interface/provider-parameters-page.interface';
import { ProviderEnum } from '../enum/provider.enum';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { FinancialHelper } from '../helper/financial.helper';

declare let gtag: Function;
declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsHelperService {
  private readonly registeredProviders: Array<any> = new Array<any>();
  private readonly currency: string;

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router) {
    this.registeredProviders = environment.tagManager.providers;
    this.currency = environment.constants.currency || 'USD';

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.findAnalysisProviderToInformByUrl(event.url);
      }
    });
  }

  private findAnalysisProviderToInformByUrl(url: string): void {
    if (!url || !this.registeredProviders) {
      return;
    }

    for (const provider of this.registeredProviders) {
      if (!provider.parameters || !provider.parameters.pages) {
        continue;
      }

      for (const page of provider.parameters.pages) {
        if ((!page.page && !page.movieId) || (!page.movieId && typeof page.page === 'string' && url.toUpperCase().includes(page.page.toUpperCase()))) {
          this.informAnalysisProvider(provider, page);
        }
      }
    }
  }

  public findAnalysisProviderToInformByMovieId(movieId: string, order: OrderViewModel | null = null): void {
    if (!movieId || !this.registeredProviders) {
      return;
    }

    for (const provider of this.registeredProviders) {
      if (!provider.parameters || !provider.parameters.pages) {
        continue;
      }

      for (const page of provider.parameters.pages) {
        if (!page.movieId) {
          continue;
        }

        if (movieId.toUpperCase() === page.movieId.toUpperCase()) {
          if (typeof page.page === 'string') {
            if (window.location.href.toUpperCase().includes(page.page.toUpperCase())) {
              this.informAnalysisProvider(provider, page, order);
            }
          } else {
            this.informAnalysisProvider(provider, page, order);
          }
        }
      }
    }
  }

  private informAnalysisProvider(provider: ProviderConfigInterface, page: ProviderParametersPageInterface, order: OrderViewModel | null = null): void {
    switch (provider.name) {
      case ProviderEnum.GTAG:
        // @ts-ignore
        if (!provider.parameters || !provider.parameters.key) {
          throw new Error(`GTAG: key is required!'`);
        }
        // @ts-ignore
        gtag('config', provider.parameters.key, { page_path: window.location.href });
        break;
      case ProviderEnum.FACEBOOK:
        const value = page.value ? page.value : 'PageView';

        if (value === FacebookEventEnum.PURCHASE) {
          if (order && order.status === 4) {
            fbq('track', 'Purchase', { value: FinancialHelper.fixedValue(order.totalValue), currency: this.currency });
          }
        } else {
          fbq('track', value);
        }

        break;
    }
  }
}
