import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appHideOnError]',
})
export class HideOnErrorDirective {
  constructor(private el: ElementRef) {}

  @HostListener('error')
  private onError() {
    this.el.nativeElement.style.display = 'none';
  }
}
