import {Expose} from 'class-transformer';

export class BonusModel {
  @Expose()
  groupId: string;

  @Expose()
  groupName: string;

  @Expose()
  itemType: number;

  @Expose()
  itemId: string;

  @Expose()
  itemName: string;

  @Expose()
  valueInPoints: number;

  @Expose()
  graphicUrl: string;
}

export class BasketItemIdentifierModel {
  @Expose()
  basketItemId: string;
}
