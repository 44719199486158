import { Injectable } from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class MobileDeviceUtils {
  static isMobile(): Boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }

  static isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }

  static isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}
