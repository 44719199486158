import { AbstractViewModel } from "../../../abstract.view.model";
import { UserFbModifierItemApiModel } from "../../../api-model/user-history/fb-item/user-fb-modifier-item.api.model";

export class UserFbModifierItemViewModel extends AbstractViewModel<UserFbModifierItemApiModel> {
  public modifierItemId: string = null;
  public modifierItem: string = null;
  public modifierItemQuantity: number = null;
  public modifierItemTaxRate: number = null;
  public modifierItemPrice: number = null;

  constructor(
    protected apiModel: UserFbModifierItemApiModel = new UserFbModifierItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.modifierItemId = this.apiModel.modifierItemId;
    this.modifierItem = this.apiModel.modifierItem;
    this.modifierItemQuantity = this.apiModel.modifierItemQuantity;
    this.modifierItemTaxRate = this.apiModel.modifierItemTaxRate;
    this.modifierItemPrice = this.apiModel.modifierItemPrice;
  }

  toApiModel(): UserFbModifierItemApiModel {
    return undefined;
  }
}
