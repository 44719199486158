import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'excludeClassKey',
    pure: false
})
export class LegendPipe implements PipeTransform {
    transform(items: any[], classKey: string): any {
        if (!items || !classKey) {
            return items;
        }
        
        return items.filter(item => item.classKey.indexOf(classKey) === -1);
    }
}