import { Injectable } from '@angular/core';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherSelectorService {
  public defaultSelectedVoucherListState: Observable<Array<VoucherTypeModel>>;
  private _defaultSelectedVoucherListSubject = new ReplaySubject<Array<VoucherTypeModel>>(1, 400);
  private _defaultSelectedVoucherList: Array<VoucherTypeModel> = new Array<VoucherTypeModel>();

  constructor() {
    this.defaultSelectedVoucherListState = this._defaultSelectedVoucherListSubject.asObservable();
  }

  public setDefaultSelectedVoucherCollection(selectedVoucherCollection: Array<VoucherTypeModel>): void {
    this._defaultSelectedVoucherListSubject.next(selectedVoucherCollection);
  }
}
