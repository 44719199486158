<div class="row  align-items-center pl-3 pr-3">
  <div class="col-12 col-sm-12 text-sm-right text-center">

  </div>


  <ng-container *ngFor="let voucher of voucherTypeList">

    <div class="col-12 col-sm-12 text-center">
      <div class="row ticket mt-2 mb-2 p-3">

        <div class="col-4 text-left">
          {{ voucher.name }}
        </div>
        <div class="col-4 text-center">

          <div class="row">
            <div class="offset-sm-3 col-12 col-sm-1 col-lg-1 px-0 mr-2 col-decrement">
              <span class="increment" [ngClass]="{ 'disabled': limit === 0 || !selectedVouchers[voucher.id] }"
                    (click)="onDecrementClick(voucher)"><i class="fa fa-minus"></i></span>
            </div>
            <div class="col-12 col-sm-1 col-lg-1 px-0 sel-amount">
              <span class="count font-weight-bold">{{ selectedVouchers[voucher.id] ? selectedVouchers[voucher.id] : 0 }}</span>
            </div>
            <div class="col-12 col-sm-1 col-lg-1 px-0 ml-sm-2">
              <span class="increment" [ngClass]="{ 'disabled': allSelectedVoucherCount === limit }"
                    (click)="onIncrementClick(voucher)"><i class="fa fa-plus"></i></span>
            </div>
            <div class="col-12 col-sm-3 col-md-12 col-lg-3 px-0 ml-0 ml-sm-3 ml-md-0 ml-lg-3 ml-xl-0 sel-price">
              <span class="font-weight-bold ml-2 mr-1 d-none d-lg-inline">/</span>
              <span>{{ voucher.price | number:'1.2-2' }}</span>
            </div>
          </div>



        </div>
        <div class="col-4 text-right">
          <span class="font-weight-bold">{{ (voucher.price * (selectedVouchers[voucher.id] || 0)) | number:'1.2-2' }}</span>
        </div>

      </div>

    </div>

  </ng-container>

</div>


