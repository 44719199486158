import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { DefaultTicketFirstPriceAscendingStrategy } from "./default-ticket-first-price-ascending.strategy";
import { DefaultTicketFirstPriceDescendingStrategy } from "./default-ticket-first-price-descending.strategy";
import { DefaultTicketFirstStrategy } from "./default-ticket-first.strategy";
import { DefaultTicketLastStrategy } from "./default-ticket-last.strategy";
import { DefaultTicketStrategy } from "./default-ticket.strategy";

export enum DefaultTicketStrategyType {

  First = 'first',

  FirstByPriceAscending = 'first_price_asc',

  FirstByPriceDescending = 'first_price_desc',

  Last = 'last'

}

export class DefaultTicketStrategyContext implements DefaultTicketLastStrategy {

  private strategy: DefaultTicketStrategy;

  constructor(strategy: DefaultTicketStrategyType) {

    if (strategy === DefaultTicketStrategyType.First) {

      this.strategy = new DefaultTicketFirstStrategy();
    } else if (strategy === DefaultTicketStrategyType.FirstByPriceAscending) {

      this.strategy = new DefaultTicketFirstPriceAscendingStrategy();
    } else if (strategy === DefaultTicketStrategyType.FirstByPriceDescending) {

      this.strategy = new DefaultTicketFirstPriceDescendingStrategy();
    } else if (strategy === DefaultTicketStrategyType.Last) {

      this.strategy = new DefaultTicketLastStrategy();
    } else {

      throw new Error(`Unknown default ticket strategy '${strategy}'`);
    }

  }

  public getDefaultTicket(ticketList: Array<TicketViewModel>): TicketViewModel | null {
    return this.strategy.getDefaultTicket(ticketList);
  }




}
