import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { UserFavoritePaymentViewModel } from 'libs/core/src/lib/model/view-model/user/user-favorite-payment.view.model';
import {Subject} from 'rxjs';

@Component({
  template: ''
})
export class ConfirmModalComponent {
  public title: string;
  public content: string;
  public content2: string;
  public accept: string;
  public decline: string;
  public favoritePayment: UserFavoritePaymentViewModel;

  public warning = false;
  public subject: Subject<boolean>;

  constructor(protected bsModalRef: BsModalRef) {
  }

  onPopupBackClose(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}
