import {Injectable} from '@angular/core';
import { ScreenStateModel } from '../model/state/screen.state.model';

@Injectable({
  providedIn: 'root'
})
export class ScreenStateService {
  public state: ScreenStateModel = new ScreenStateModel();

  public reset(): void {
    this.state.showSeatGroupPopups = {};
    this.state.screeningId = null;
  }
}
