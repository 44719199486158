import { AbstractViewModel } from '../../abstract.view.model';
import { CanBeRefundApiModel } from '../../api-model/sales-document/can-be-refund.api.model';
import { CanBeRefundItemViewModel } from './can-be-refund-item.view.model';

export class CanBeRefundViewModel extends AbstractViewModel<CanBeRefundApiModel> {
  executionStatus: number;
  executionMessage: string;
  salesDocumentId: string;
  refundToken: string;
  refundTotalValue: number;
  itemList: CanBeRefundItemViewModel[] = [];

  constructor(protected apiModel: CanBeRefundApiModel = new CanBeRefundApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.executionStatus = this.apiModel.executionStatus;
    this.executionMessage = this.apiModel.executionMessage;
    this.salesDocumentId = this.apiModel.salesDocumentId;
    this.refundToken = this.apiModel.refundToken;
    this.refundTotalValue = this.apiModel.refundTotalValue;
    this.itemList = this.apiModel.itemList ? this.apiModel.itemList.map((item) => new CanBeRefundItemViewModel(item)) : [];
  }

  toApiModel(): CanBeRefundApiModel {
    return undefined;
  }
}
