import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserScreeningApiModel } from '../../../api-model/user-history/screening/user-screening.api.model';

export class UserScreeningViewModel extends AbstractViewModel<UserScreeningApiModel> {
  public id: string = null;
  public cinemaID: string = null;
  public screenId: string = null;
  public moviePrintID: string = null;
  public moviePrintExternalID: string = null;
  public screeningTimeFrom: DateTime = null;
  public screeningTimeTo: DateTime = null;
  public generalAdmission: number = null;

  constructor(protected apiModel: UserScreeningApiModel = new UserScreeningApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.cinemaID = this.apiModel.cinemaID;
    this.screenId = this.apiModel.screenId;
    this.moviePrintID = this.apiModel.moviePrintID;
    this.moviePrintExternalID = this.apiModel.moviePrintExternalID;
    this.screeningTimeFrom = this.apiModel.screeningTimeFrom ? DateTime.fromISO(this.apiModel.screeningTimeFrom, { setZone: true }) : null;
    this.screeningTimeTo = this.apiModel.screeningTimeTo ? DateTime.fromISO(this.apiModel.screeningTimeTo, { setZone: true }) : null;
    this.generalAdmission = this.apiModel.generalAdmission;
  }

  toApiModel(): UserScreeningApiModel {
    return undefined;
  }
}
