import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { LoaderState } from 'libs/core/src/lib/model/loading/loader-state.model';
import { ILoadingConfig, loadingAnimationTypes, LoadingConfig, loadingSize, loadingStyle } from 'libs/core/src/lib/model/loading/loading-config';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';

@Component({
  template: '',
})
export class LoadingComponent implements OnInit {
  @Input() id: string = '';
  @Input() show: boolean = true;
  @Input() config: ILoadingConfig = new LoadingConfig();
  @Input() template: TemplateRef<any>;
  @Input() size: string;
  @Input() centerScreen: boolean = false;
  @Input('backdrop-position') public backdropPosition: string = 'fixed';

  canShow = false;
  protected defaultConfig: ILoadingConfig;
  public loadingAnimationTypes = loadingAnimationTypes;
  public loadingSize = loadingSize;
  public loadingStyle = loadingStyle;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected loadingService: LoadingService) {
    this.defaultConfig = environment.loader;
  }

  ngOnInit() {
    this.setupConfig();
    this.loadingService.loaderState$.subscribe((res: LoaderState) => {
      if (res !== null && (res.id === this.id || res.id === LoaderEnum.ALL)) {
        this.canShow = res.active;
      }
    });
    this.loadingService.loaderMapState$.subscribe((res: Map<string, LoadingStatus>) => {
      this.canShow = (res && res?.get(this.id) === LoadingStatus.pending) ?? true;
    });
  }

  protected setupConfig(): void {
    const tempConfig: ILoadingConfig = this.config ?? this.defaultConfig;

    for (const option in tempConfig) {
      if (this.config[option] != null) {
        continue;
      }

      if (this.size && option === 'size') {
        this.config[option] = this.size;
        continue;
      }

      this.config[option] = this.loadingService.loadingConfig[option] != null ? this.loadingService.loadingConfig[option] : this.defaultConfig[option];
    }
  }
}
