import { AbstractViewModel } from "../../../abstract.view.model";
import { OccupancyLockGroupApiModel } from "../../../api-model/screening/occupancy/occupancy-lock-group.api.model";

export class OccupancyLockGroupViewModel extends AbstractViewModel<OccupancyLockGroupApiModel> {
  name: string;
  lockedSeats: string[];
  
  constructor(protected apiModel: OccupancyLockGroupApiModel = new OccupancyLockGroupApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.lockedSeats = this.apiModel.lockedSeats;
  }

  toApiModel(): OccupancyLockGroupApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
