import { AbstractViewModel } from "../../abstract.view.model";
import { MoviePrintApiModel } from "../../api-model/movie/movie-print.api.model";
import { ScreeningViewModel } from "../screening/screening.view.model";
import { MovieViewModel } from "./movie.view.model";

export class MoviePrintViewModel extends AbstractViewModel<MoviePrintApiModel> {
  id: string;
  printType: string;
  printExternalId: string;
  soundType: string;
  speakingType: string;
  movieId: string;
  language: string;
  subtitles: string;
  subtitles2: string;
  release: string;
  format: string;
  frameRate: string;
  resolution: string;

  movie: MovieViewModel;
  screenings: ScreeningViewModel[];

  constructor(protected apiModel: MoviePrintApiModel = new MoviePrintApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.printType = this.apiModel.printType;
    this.printExternalId = this.apiModel.printExternalId;
    this.soundType = this.apiModel.soundType;
    this.speakingType = this.apiModel.speakingType;
    this.movieId = this.apiModel.movieId;
    this.language = this.apiModel.language;
    this.subtitles = this.apiModel.subtitles;
    this.subtitles2 = this.apiModel.subtitles2;
    this.release = this.apiModel.release;
    this.format = this.apiModel.format;
    this.frameRate = this.apiModel.frameRate;
    this.resolution = this.apiModel.resolution;
  }

  toApiModel(): MoviePrintApiModel {
    return undefined;
  }

}
