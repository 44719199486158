import {Expose} from 'class-transformer';

export class PurchaseCardTypeAddressRequestModel {
  @Expose()
  public firstName: string;

  @Expose()
  public lastName: string;

  @Expose()
  public city: string;

  @Expose()
  public street: string;

  @Expose()
  public streetNumber: string;

  @Expose()
  public postalCode: string;

  @Expose()
  public address1: string;

  @Expose()
  public address2: string;
}
