import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { LanguageApiModel } from '../model/api-model/language/language.api.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageHttpService {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getLanguages(): Observable<LanguageApiModel[]> {
    return this.http.get<LanguageApiModel[]>('/language');
  }

  @Cacheable({ maxAge: 3600000 })
  getAvailableLanguages(): Observable<LanguageApiModel[]> {
    return this.http
      .get<LanguageApiModel[]>('/language')
      .pipe(
        map((res) =>
          res
            .filter((i) => this.environment.constants.language.available.map((l) => l.toLowerCase()).indexOf(i.symbol.toLowerCase()) !== -1)
            .map((result) => plainToInstance(LanguageApiModel, result as Object))
        )
      );
  }
}
