import { Component, Input, OnInit } from '@angular/core';
import { IScreeningDetails } from 'libs/core/src/lib/interfaces';

@Component({
  selector: 'app-print-movie-detail',
  template: '',
})
export class PrintMovieDetailComponent implements OnInit {
  @Input() public details: IScreeningDetails;

  constructor() {}

  ngOnInit(): void {}
}
