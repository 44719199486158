import { Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export class CardApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  pointBalance: number;

  @Expose()
  valueBalance: number;

  @Expose()
  statusId: number;

  @Expose()
  cardTypeId: string;

  @Expose()
  clientId: string;

  @Expose()
  status: boolean;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  issueDate: DateTime;

  @Expose()
  salesChannelId: string;

  @Expose()
  cardBatchId: string;

  @Expose()
  flgUseInLocal: number;

  @Expose()
  cinemaId: string;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  expireDate: DateTime;

  @Expose()
  message: string;

  @Expose()
  templateId: string;

  @Expose()
  isHidden: boolean;

  @Expose()
  type: number;

  @Expose()
  graphic: string;
}
