import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { UserFavotiePaymentApiModel } from '../../api-model/user/user-favorite-payment.api.model';

export class UserFavoritePaymentViewModel extends AbstractViewModel<UserFavotiePaymentApiModel> {
  public providerId: string = null;
  public token: string = null;
  public paymentTypeName: string = null;
  public paymentTypeDetails: string = null;
  public paymentExpirationDate: DateTime;
  public creationDate: DateTime;
  public paymentTypeId: string = null;

  constructor(
    protected apiModel: UserFavotiePaymentApiModel = new UserFavotiePaymentApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.providerId = this.apiModel.providerId;
    this.token = this.apiModel.token;
    this.paymentTypeName = this.apiModel.paymentTypeName;
    this.paymentTypeDetails = this.apiModel.paymentTypeDetails;
    this.paymentExpirationDate = this.apiModel.paymentExpirationDate ? DateTime.fromISO(this.apiModel.paymentExpirationDate) : null;
    this.creationDate = this.apiModel.creationDate ? DateTime.fromISO(this.apiModel.creationDate) : null;
    this.paymentTypeId = this.apiModel.paymentTypeId;
  }

  toApiModel(): UserFavotiePaymentApiModel {
    return undefined;
  }
}
