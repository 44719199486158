import { Injectable } from '@angular/core';
import uniqBy from 'lodash-es/uniqBy';
import remove from 'lodash-es/remove';
import { OccupancyModel } from '../model/occupancy.model';
import { OccupiedStatus } from '../enum/occupied-status.enum';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { SeatViewModel } from '../model/view-model/screen/seat/seat.view.model';
import { SeatGroupSalesMode } from '../enum/seat-group-sales-mode.enum';

@Injectable({
  providedIn: 'root',
})
export class SeatsOccupancyService {
  public totalOccupied = 0;
  private _seatsFromOrder = 0;
  public maxOccupancy: number;
  private _isCateringSelected = false;

  constructor() {}

  markMySeats(screenModel: ScreenViewModel, myOccupancyList: Array<string>, limit: number) {
    if (!screenModel || !screenModel.pseats) {
      return;
    }

    if (limit < myOccupancyList.length) {
      const poppedId = myOccupancyList.pop();
      screenModel.pseats.forEach((x) =>
        x.forEach((seat) => {
          if (seat.groupConnectedSeats != null && seat.groupConnectedSeats.find((connectedSeat) => connectedSeat.id === poppedId) != null) {
            for (let i = 0; i < seat.groupConnectedSeats.length; i++) {
              myOccupancyList.pop();
            }
          }
        })
      );
    }

    for (const seatsRow of screenModel.pseats) {
      for (const seat of seatsRow) {
        if (seat.occupied === OccupiedStatus.Mine) {
          seat.occupied = OccupiedStatus.Free;

          if (seat.groupConnectedSeats) {
            seat.groupConnectedSeats.forEach((element) => {
              element.occupied = OccupiedStatus.Free;
            });
          }
        }

        if (myOccupancyList.find((x) => x === seat.id)) {
          seat.occupied = OccupiedStatus.Mine;
          console.log(seat.id, 'marked');

          if (seat.groupConnectedSeats) {
            seat.groupConnectedSeats.forEach((element) => {
              element.occupied = OccupiedStatus.Mine;
            });
          } else if (screenModel.groups.filter((f) => f.id === seat.groupId && f.salesMode === SeatGroupSalesMode.Together).length > 0) {
            const group = screenModel.groups.filter((f) => f.id === seat.groupId && f.salesMode === SeatGroupSalesMode.Together)[0];

            if (seat.groupId === group.id) {
              seat.occupied = OccupiedStatus.Mine;
            }
          }
        }
      }
    }
  }

  markOccupiedSeats(screenModel: ScreenViewModel, screeningOccupancy: OccupancyModel) {
    const targetLockedSeats = new Array<string>();
    screeningOccupancy.lockGroups.forEach((value) => {
      targetLockedSeats.push(...value.lockedSeats);
    });
    const lockedSeatsUnique = uniqBy(targetLockedSeats, (x) => x); // Because locked seats are sometimes duplicated.

    this.totalOccupied = screeningOccupancy.totalOccupied ? screeningOccupancy.totalOccupied : 0;
    this.totalOccupied -= lockedSeatsUnique.length;
    this.maxOccupancy = screenModel.maxOccupancy;
    console.log('init:', this.totalOccupied + '(+' + lockedSeatsUnique.length + ' locked) / ' + screenModel.maxOccupancy);

    for (const seatList of screenModel.pseats) {
      for (const seat of seatList) {
        const isSeatOccupied: boolean = screeningOccupancy.occupiedSeats.indexOf(seat.id) >= 0;
        const isSeatLocked: boolean = targetLockedSeats.indexOf(seat.id) >= 0;

        if (isSeatLocked === true) {
          seat.occupied = OccupiedStatus.Locked;

          if (isSeatOccupied === true) {
            remove(targetLockedSeats, (x) => x === seat.id);
          }

          continue;
        }

        this.markOccupiedWithGroup(seat, isSeatOccupied ? OccupiedStatus.Occupied : OccupiedStatus.Free);
      }
    }
  }

  markOccupiedWithGroup(seat: SeatViewModel, status: OccupiedStatus) {
    seat.occupied = status;
    if (seat.groupConnectedSeats) {
      seat.groupConnectedSeats.forEach((x) => (x.occupied = status));
    }
  }

  checkSeatsLimit(seatIds: Array<string>, seat: SeatViewModel, limit: number): boolean {
    return !(seatIds.find((x) => x === seat.id) === undefined && seatIds.length + seat.getGroupConnectedSeatsLength() > limit);
  }

  checkMaxOccupancyLimit(seatIds: Array<string>, seat: SeatViewModel): boolean {
    console.log('checkMaxOccupancyLimit', seatIds, seatIds.length + this.totalOccupied - this.seatsFromOrder + 1 + '/' + this.maxOccupancy);
    return !(seatIds.find((x) => x === seat.id) === undefined && seatIds.length + this.totalOccupied - this.seatsFromOrder >= this.maxOccupancy);
  }

  get seatsFromOrder(): number {
    return this._seatsFromOrder;
  }

  set seatsFromOrder(value: number) {
    this._seatsFromOrder = value;
  }

  get isCateringSelected(): boolean {
    return this._isCateringSelected;
  }

  set isCateringSelected(value: boolean) {
    this._isCateringSelected = value;
  }
}
