import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { TargetApiEnum } from '../enum/target-api.enum';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') === -1) {
      // add api url
      switch (req.headers.get('targetApi')) {
        case TargetApiEnum.WP_API:
          req = req.clone({url: this.environment['cms']?.apiUrl + req.url, headers: req.headers.delete('targetApi')});
          break;
        default:
          req = req.clone({url: req.url.startsWith('http') ? req.url : this.environment.apiUrl + req.url});
      }
    }

    return next.handle(req);
  }
}
