import {Expose, Type} from 'class-transformer';

export class PersonalModel {
  @Expose()
  firstname: string = null;

  @Expose()
  lastname: string = null;

  @Expose()
  phone: string = null;

  @Expose()
  email: string = null;

  @Expose()
  emailRepeat: string = null;

  @Expose()
  taxId: string = null;

  @Expose()
  newsletterAgreement = false;

  @Expose()
  customAgreement = false;
}
