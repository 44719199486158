import {Expose} from 'class-transformer';

export class LanguageApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  symbol: string;

  @Expose()
  default: boolean;
}
