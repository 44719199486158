import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  constructor() {}

  transform(array: any, field: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    array.sort((a: any, b: any) => {
      if ((field && a[field] > b[field]) || (!field && a > b)) {
        return order === 'asc' ? 1 : -1;
      } else if ((field && a[field] < b[field]) || (!field && a < b)) {
        return order === 'asc' ? -1 : 1;
      } else {
        return 0;
      }
    });

    return array;
  }
}
