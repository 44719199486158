import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ScreenService } from '../screen/service/screen.service';
import { NextActionType } from '../screen/model/next-action.type';
import { cloneDeep } from 'lodash';
import { storageKey } from 'libs/core/src/app.const';
import { GeneralAdmissionService } from 'libs/core/src/lib/component/screen/ticket-count-general-admission/service/general-admission.service';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { NavigationEventType } from 'libs/core/src/lib/enum/navigation-event-type.enum';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { GaTicketViewModel } from 'libs/core/src/lib/model/view-model/screening/ga/ga-ticket.view.model';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';

@Component({
  template: '',
})
export class TicketsPageComponent implements OnInit, OnDestroy {
  LoaderEnum = LoaderEnum;

  @Input() public screeningId: string = null;

  initialSum: number = 0;
  orderStateSubscription: Subscription;

  public cinemaId: string;
  public order: OrderViewModel;
  public availableTicketListGeneralAdmission = new Array<GaTicketViewModel>();
  public screen: ScreenViewModel = null;
  public generalAdmissionMaxTicketsLimit = 10;
  public generalAdmissionMaxTicketsLimitWithSelectedTickets = 0;
  public maxSelectedTicketsLimit = 10;
  public ticketList: Array<TicketViewModel> = [];
  public ticketsChanged = false;
  public initExtraFeeIdentifierList = new Array<Array<string>>();
  public orderTickets: { [key: string]: number } = {};

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected route: ActivatedRoute,
    protected orderStateService: OrderStateService,
    protected screenDataProvider: ScreenDataProvider,
    protected screeningDataProvider: ScreeningDataProvider,
    protected screenService: ScreenService,
    protected generalAdmissionService: GeneralAdmissionService,
    protected router: Router,
    protected navigationHelper: NavigationHelperService,
    protected orderDataProvider: OrderDataProvider,
    protected countdownComponentService: CountdownComponentService,
    protected loadingService: LoadingService
  ) {}

  public ngOnInit() {
    const screeningId = this.route.snapshot.queryParamMap.get('screeningId') || this.route.snapshot.paramMap.get('screeningId');
    const cinemaId = this.route.snapshot.queryParamMap.get('cinemaId') || this.orderStateService.getItem(storageKey.chosenLocation);

    this.screeningId = screeningId;
    this.cinemaId = cinemaId;

    if (this.screeningId) {
      this.screeningId = this.screeningId.toLowerCase();
    }

    this.loadOrderState();
  }

  private loadOrderState(): void {
    this.orderStateSubscription = this.orderStateService.state$.subscribe((order) => {
      this.order = cloneDeep(order);
      if (order?.screeningItems?.length) {
        this.countdownComponentService.start();
      }
      this.loadTicketsFromOrder();

      this.screenDataProvider
        .getScreenByAndCheckIfScreeningIsEvent(this.order?.cinemaId ?? this.cinemaId, this.screeningId, null)
        .subscribe((screen: ScreenViewModel) => {
          if (!screen) {
            this.router.navigate(['error', 'default']);
            return;
          }
          if (screen?.occupancy?.seatsLeft <= 0) {
            this.router.navigate(['error', '5001']);
            return;
          }

          this.screen = screen;
          if (screen.generalAdmission === true) {
            this.fetchAvailableTicketListGeneralAdmission().subscribe((availableTicketList: Array<GaTicketViewModel>) => {
              this.availableTicketListGeneralAdmission = availableTicketList;
            });
          }
        });
    });
  }

  public loadTicketsFromOrder() {
    let ot: { [key: string]: number } = {};
    if (this.order) {
      this.order.screeningItems.forEach((element) => {
        ot[element.ticketId] = isNaN(ot[element.ticketId]) ? 1 : ++ot[element.ticketId];
        this.ticketList.push(
          Object.assign(new TicketViewModel(), {
            id: element.ticketId,
            name: element.ticket,
            price: element.price,
          })
        );
      });
    }
    this.orderTickets = ot;
    let sum = 0;
    for (const key of Object.keys(this.orderTickets)) {
      sum += this.orderTickets[key];
    }
    this.initialSum = sum;
  }

  getTicketsLimit() {
    return Math.min(this.screen?.occupancy?.seatsLeft + this.initialSum ?? 0, this.environment.constants.maxSelectedTickets);
  }

  private fetchAvailableTicketListGeneralAdmission(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this.screeningDataProvider
        .getTicketListGeneralAdmission(this.order?.cinemaId ?? this.cinemaId, this.screeningId)
        .subscribe((ticketListGeneralAdmission: Array<GaTicketViewModel>) => {
          const availableTicketsBySeatGroup: { [key: string]: number } = {};

          ticketListGeneralAdmission.forEach((x) => {
            availableTicketsBySeatGroup[x.seatGroupId] = x.availableAmount;
          });
          const availableTickets = Object.entries(availableTicketsBySeatGroup).reduce((curr, [x, y]) => {
            return (curr += y);
          }, 0);

          this.generalAdmissionMaxTicketsLimit = availableTickets > this.maxSelectedTicketsLimit ? this.maxSelectedTicketsLimit : availableTickets;
          this.generalAdmissionMaxTicketsLimit = this.generalAdmissionMaxTicketsLimit <= 0 ? 0 : this.generalAdmissionMaxTicketsLimit;
          let currentSelectedTicketsAmount: number = this.generalAdmissionMaxTicketsLimit;

          if (this.order?.screeningItems) {
            const [t, ticketUsageBySeatGroup]: [number, { [key: string]: number }] = this.screenService.countGeneralAdmissionTicketUsageBySeatGroup(
              this.screeningId,
              this.order?.screeningItems,
              ticketListGeneralAdmission
            );

            currentSelectedTicketsAmount += t;

            ticketListGeneralAdmission = ticketListGeneralAdmission.map((ticket) => {
              ticket.availableAmount += ticketUsageBySeatGroup[ticket.seatGroupId] || 0;
              return ticket;
            });
          }

          this.generalAdmissionMaxTicketsLimitWithSelectedTickets += currentSelectedTicketsAmount;

          if (this.generalAdmissionMaxTicketsLimitWithSelectedTickets > this.maxSelectedTicketsLimit) {
            this.generalAdmissionMaxTicketsLimitWithSelectedTickets = this.maxSelectedTicketsLimit;
          }

          observer.next(ticketListGeneralAdmission);
          observer.complete();
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        });
    });
  }

  public onSelectedTicketGeneralAdmission(ticket: TicketViewModel): void {
    if (!this.ticketList) {
      this.ticketList = new Array<TicketViewModel>();
    }

    const ticketCollection = this.ticketList.map((x) => x);
    ticketCollection.push(ticket);
    this.ticketList = ticketCollection;
    this.ticketsChanged = true;
  }

  public onDroppedTicketGeneralAdmission(ticket: TicketViewModel): void {
    if (!this.ticketList) {
      this.ticketList = new Array<TicketViewModel>();
    }

    const ticketCollection: Array<TicketViewModel> = this.ticketList.map((x) => x);
    let matchedFirstTicketTypeIndex: number | null = null;

    ticketCollection.find((element: TicketViewModel, index: number): boolean => {
      if (element.id === ticket.id) {
        matchedFirstTicketTypeIndex = index;
        return true;
      }

      return false;
    });

    if (matchedFirstTicketTypeIndex !== null) {
      ticketCollection.splice(matchedFirstTicketTypeIndex, 1);
    }

    this.ticketList = ticketCollection;
    this.ticketsChanged = this.ticketList.length !== 0;
  }

  public onGeneralAdmissionComponentInit(): void {
    if (this.screen.generalAdmission === true && this.order?.screeningItems) {
      const filteredOrderItems: Array<ScreeningItemViewModel> = this.order?.screeningItems.filter((x) => x.screeningId === this.screeningId);
      this.markMySelectedTicketGeneralAdmission(filteredOrderItems);
    }
  }

  public markMySelectedTicketGeneralAdmission(orderItems: Array<ScreeningItemViewModel>): void {
    const myTickets = orderItems.map((x) => x.ticketId);
    const optionalExtraFeesIdList = new Array<Array<string>>();

    for (const orderItem of orderItems) {
      for (const extraFeeIdentifier of orderItem.optionalExtraFees) {
        optionalExtraFeesIdList.push([orderItem.ticketId, extraFeeIdentifier]);
      }
    }

    this.generalAdmissionService.markMyTickets(myTickets);
    setTimeout(() => {
      this.initExtraFeeIdentifierList = optionalExtraFeesIdList;
      this.ticketsChanged = false;
    }, 500);
  }

  public onNavigationClick(event: NavigationEventType) {
    switch (event) {
      case NavigationEventType.PREVIOUS:
        this.onPreviousAction();
        break;
      case NavigationEventType.NEXT:
        this.onNextAction(NextActionType.NextStep);
        break;
    }
  }

  public onPreviousAction() {
    if (this.orderStateService.getItem(storageKey.backUrl)) {
      window.location.href = this.orderStateService.getItem(storageKey.backUrl);
    } else {
      this.router.navigate([this.navigationHelper.getPreviousRoute(this.route.snapshot)]);
    }
  }

  public onNextAction(nextAction: NextActionType) {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.orderDataProvider.patchItems(this.order?.cinemaId ?? this.cinemaId, this.order, this.getItems()).subscribe(
      (order: OrderViewModel) => {
        this.orderStateService.setOrder(order);
        this.router.navigate([this.navigationHelper.getNextRoute(this.route.snapshot)]);
      },
      (err) => {
        const hasError = err.error && err.error.error && err.error.error.code;
        const errorCode = hasError ? err.error.error.code : 0;
        this.router.navigate(['error', errorCode]);
      }
    );
  }

  getItems() {
    return this.ticketList.map((ticket) => {
      return Object.assign(new ScreeningItemViewModel(), {
        screeningId: this.screeningId,
        seatId: null,
        quantity: 1,
        ticketId: ticket ? ticket.id : null,
      });
    });
  }

  private patchOrderItems(): void {
    if (!this.ticketList) {
      return;
    }

    const items = this.ticketList.map((ticket) => {
      return Object.assign(new ScreeningItemViewModel(), {
        screeningId: this.screen.id,
        seatId: null,
        ticketId: ticket ? ticket.id : null,
      });
    });

    if (this.order?.screeningItems?.length && this.order?.screeningItems?.length !== items.length) {
      this.orderStateService.removeItem(storageKey.isExtraFeesSelected);
    }

    this.orderDataProvider.patchItems(this.order?.cinemaId ?? this.cinemaId, this.order, items).subscribe(
      (order) => {
        this.orderStateService.setOrder(order);
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
      (err) => {
        const hasError = err.error && err.error.error && err.error.error.code;
        const errorCode = hasError ? err.error.error.code : 0;
      }
    );
  }

  ngOnDestroy(): void {
    this.orderStateSubscription?.unsubscribe();
  }
}
