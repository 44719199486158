import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { appProjectName } from '../../app.const';
import { MessageModel } from '../model/message.model';
import { AppService } from '../service/app.service';

@Directive({
  selector: '[appFormErrors]',
})
export class FormErrorsDirective implements OnInit, OnChanges, OnDestroy {
  @Input('appFormErrors') public key: string = null;
  @Input() public errors: Observable<any>;
  private control: AbstractControl;
  private valueChangesSubscription: Subscription = Subscription.EMPTY;

  constructor(private fg: ControlContainer, private el: ElementRef, private renderer: Renderer2, private appService: AppService) {}

  get form() {
    return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null;
  }

  ngOnInit(): void {
    this.control = this.form.get(this.key);

    if (this.control) {
      this.valueChangesSubscription = this.control.valueChanges.subscribe(() => {
        if (this.errors && this.errors[this.key] != null) {
          this.clearErrors();
        }
      });
    }

    this.prepareErrors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareErrors();
  }

  prepareErrors(): void {
    this.clearErrors();
    if (this.errors && this.errors[this.key]) {
      this.addError(this.errors[this.key]);
    }
  }

  addError(error: MessageModel): void {
    const container = this.renderer.createElement('p');
    const text = this.renderer.createText((this.appService.isProject(appProjectName.BLUE) ? ' ' : '') + error.message);

    this.renderer.appendChild(container, text);
    this.renderer.appendChild(this.el.nativeElement, container);

    if (this.appService.isProject(appProjectName.BLUE)) {
      this.el.nativeElement.parentElement.classList.add('form-error');
    }
  }

  clearErrors() {
    this.el.nativeElement.innerHTML = '';
    this.el.nativeElement.parentElement.classList.remove('form-error');
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription !== Subscription.EMPTY) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
