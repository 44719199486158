import { AbstractViewModel } from "../../../abstract.view.model";
import { TicketSeatApiModel } from "../../../api-model/account-items/reservation/ticket-seat.api.model";

export class TicketSeatViewModel extends AbstractViewModel<TicketSeatApiModel> {
  public rowSymbol: string;
  public seatSymbol: string;
  public seatRowCoordinate: number;
  public seatColumnCoordinate: number;
  public seatGroupId: string;
  public seatGroupName: string;
  public groupStampId: string;

  constructor(
    protected apiModel: TicketSeatApiModel = new TicketSeatApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.rowSymbol = this.apiModel.rowSymbol;
    this.seatSymbol = this.apiModel.seatSymbol;
    this.seatRowCoordinate = this.apiModel.seatRowCoordinate;
    this.seatColumnCoordinate = this.apiModel.seatColumnCoordinate;
    this.seatGroupId = this.apiModel.seatGroupId;
    this.seatGroupName = this.apiModel.seatGroupName;
    this.groupStampId = this.apiModel.groupStampId;
  }

  toApiModel(): TicketSeatApiModel {
    return undefined;
  }
}
