import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IVoucher } from 'libs/core/src/lib/model/view-model/order/order.view.model';

@Component({
  selector: 'app-voucher-card-summary',
  templateUrl: './voucher-card-summary.component.html',
  styleUrls: ['./voucher-card-summary.component.scss'],
})
export class CustomVoucherCardSummaryComponent implements OnInit {
  @Input() voucher: IVoucher = null;
  @Output() voucherRemoveEvent = new EventEmitter<string>();
  @Input() formLocked = false;

  ngOnInit(): void {}

  onClickRemoveVoucher(event: Event): void {
    this.voucherRemoveEvent.emit(this.voucher.number);
  }
}
