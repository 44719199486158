import {Expose} from 'class-transformer';
import { UserHistoryOrderResponseModel } from './user-history-order.response.model';
import { UserMoviePrintResponseModel } from './user-movie-print.response.model';
import { UserMovieResponseModel } from './user-movie.response.model';
import { UserScreeningResponseModel } from './user-screening.response.model';
import { UserTicketResponseModel } from './user-ticket.response.model';

export class UserHistoryResponseModel {
  orders: UserHistoryOrderResponseModel[];
  screenings: UserScreeningResponseModel[];
  tickets: UserTicketResponseModel[];
  movies: UserMovieResponseModel[];
  moviePrints: UserMoviePrintResponseModel[];
  seats: UserSeatResponseModel[];
  fbItems: UserHistoryFbItems[];
}


export class UserHistoryFbItems {
  @Expose()
  id: string;
  @Expose()
  itemId: string;
  @Expose()
  name: string;
  @Expose()
  orderId: string;
  @Expose()
  pickupTime: number;
  @Expose()
  price: number;
  @Expose()
  quantity: number;
}

export class UserPaymentResponseModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
}

export class UserSeatResponseModel {
  @Expose()
  id: string;
  @Expose()
  symbol: string;
  @Expose()
  row: string;
  @Expose()
  seat: string;
}

export class UserExtraFeeResponseModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
  @Expose()
  price: number;
  @Expose()
  quantity: number;
}

export class UserMovieRattingResponseModel {
  @Expose()
  symbol: string;
  @Expose()
  value: string;
  @Expose()
  description: string;
}
export class UserMovieGenreResponseModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
}
export class UserScreenResponseModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
  @Expose()
  type: string;
  @Expose()
  feature: string;
  @Expose()
  number: number;
}
export class UserMovieTag {
  @Expose()
  orderNo: number;
  @Expose()
  symbol: string;
  @Expose()
  description: string;
}

