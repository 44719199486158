import { AbstractViewModel } from "../../abstract.view.model";
import { CardTypeGraphicTemplateApiModel } from "../../api-model/card/card-type-graphic-template.api.model";
import { UserCardTypeTemplateViewModel } from "../user-history/card-type/user-card-type-template.view.model";

export class CardTypeGraphicTemplateViewModel extends AbstractViewModel<CardTypeGraphicTemplateApiModel> {
  public templateId: string = null;
  public graphic: string = null;

  constructor(
    protected apiModel: CardTypeGraphicTemplateApiModel = new CardTypeGraphicTemplateApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.templateId = this.apiModel.templateId;
    this.graphic = this.apiModel.graphic;
  }

  toApiModel(): CardTypeGraphicTemplateApiModel {
    return undefined;
  }

  toUserCardTypeTemplateViewModel(extra?: UserCardTypeTemplateViewModel): UserCardTypeTemplateViewModel {
    const model = Object.assign(extra ?? new UserCardTypeTemplateViewModel(), {
      id: this.templateId,
      graphic: this.graphic
    });
    return model;
  }

}
