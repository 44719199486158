import { ISeatBlockedModel } from "libs/core/src/lib/interfaces";
import { AbstractViewModel } from "../../../abstract.view.model";
import { SeatBlockedApiModel } from "../../../api-model/screen/seat/seat-blocked.api.model";

export class SeatBlockedViewModel extends AbstractViewModel<SeatBlockedApiModel> implements ISeatBlockedModel {
  id: string;
  seatId: string;
  
  constructor(protected apiModel: SeatBlockedApiModel = new SeatBlockedApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.seatId = this.apiModel.seatId;
  }

  toApiModel(): SeatBlockedApiModel {
    return undefined;
  }
}
