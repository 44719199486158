import {Component, Input, OnInit} from '@angular/core';
import {DateTime} from 'luxon';
import { ScreenTag } from 'libs/core/src/lib/model/screen-tag.model';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';

@Component({
  template: ''
})
export class ScreenTagComponent implements OnInit {
  @Input() model: ScreenTag;
  @Input() premieredays: number = 7;
  @Input() prapremiereDays: number = 7;

  public labels: { value: string, isPremiere: boolean }[] = null;

  constructor(protected dateTimeService: DateTimeService) {
  }

  ngOnInit() {
    if (!this.model) {
      return;
    }

    let moviePremiere = this.model.premiereDate;

    if (moviePremiere) {
      let localNow = this.dateTimeService.convertToCinemaTimeZone(this.model.screeningDate) || DateTime.local();
      localNow = this.dateTimeService.convertToCinemaTimeZone(localNow);
      moviePremiere = this.dateTimeService.convertToCinemaTimeZone(moviePremiere);

      if (moviePremiere > localNow &&
        moviePremiere.minus({days: this.prapremiereDays}) < localNow) {
        this.labels = [{value: 'screen.movie.prapremiere', isPremiere: true}];
      } else if (moviePremiere < localNow &&
        moviePremiere.plus({days: this.premieredays}) >= localNow) {
        this.labels = [{value: 'screen.movie.premiere', isPremiere: true}];
      }
    }

    if (!this.labels && this.model.tags) {
      this.labels = this.model.tags.map((top) => {
        if (top.tags && top.tags.length > 0) {
          top.tags.map((tag) => {
            return {value: tag.symbol, isPremiere: false};
          });
        }
        return {value: top.symbol, isPremiere: false};
      });
    }
  }
}
