import {Expose, Transform} from 'class-transformer';
import {DateTime} from 'luxon';

export class UserCardApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  pointBalance: number;

  @Expose()
  valueBalance: number;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, {toClassOnly: true})
  issueDate: DateTime;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, {toClassOnly: true})
  expiryDate: DateTime;

  @Expose()
  layout: string;

  @Expose()
  typeId: string;

  @Expose()
  message: string;

  @Expose()
  templateId: string;

  @Expose()
  number: string;

  @Expose()
  status: number;

  @Expose()
  hidden: boolean;

  @Expose()
  graphic: string;
}
