import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { DefaultTicketStrategy } from "./default-ticket.strategy";

/**
 * Gets the last ticket from ticket list
 */
export class DefaultTicketLastStrategy implements DefaultTicketStrategy {
  public getDefaultTicket(ticketList: Array<TicketViewModel>): TicketViewModel | null {
    let lastSelectedTicket: TicketViewModel|null = null;
    for (const ticket of ticketList) {
      if (ticket && ticket.price) {
        lastSelectedTicket = ticket;
      }
    }

    return lastSelectedTicket;
  }
}
