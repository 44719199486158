import { Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { noop } from 'rxjs';
import { DropdownExpandDirection } from 'libs/core/src/lib/enum/dropdown-expand-direction.enum';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';

@Component({
  template: '',
})
export class DropdownComponent {
  DropdownExpandDirection = DropdownExpandDirection;
  _options: Array<any>;

  @Input() set options(value: Array<any>) {
    this._options = value;

    if (value?.length === 1) {
      this.selected = 0;
      this.onChangeCallback(value[this.selected]?.id);
      this.onTouchedCallback();
    }
  }

  @Input() className: string;
  @Input() isReadOnly = false;
  @Input() pristineMessage: string = ' ';
  @Input() canBeToggled = true;
  @Input() expandDirection = DropdownExpandDirection.Down;
  @Input() computeExpandDirection = false;
  @Input() dropdownItemTemplate: TemplateRef<any>;
  @Input() selectedItemTemplate: TemplateRef<any>;
  @Input() chevronStickedRight = true;
  @Input() beforeOptSelect = () => {
    return true;
  };
  @Output() optSelect = new EventEmitter();
  @Output() toggleClicked = new EventEmitter();

  @ViewChild('dropdownElement') dropdownElement: ElementRef;
  @ViewChild('dropdownWrapper') dropdownWrapper: ElementRef;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: unknown) => void = noop;

  private _selectedTemporary;
  private _selected;
  get selected(): number {
    return this._selected;
  }

  set selected(value: number) {
    this.pristine = value === null || value === -1;
    this._selected = value;
    this._selectedTemporary = value;
  }

  protected computedExpandDirection = DropdownExpandDirection.Down;

  isOpen = false;
  public pristine = true;

  key: string;
  isFocused: boolean;
  disabled = false;

  focusHandler() {
    this.isFocused = true;
  }

  focusOutHandler() {
    this.isFocused = false;
    this.isOpen = false;
    this.acceptSelected();
  }

  @HostListener('document:keydown', ['$event'])
  keyPressHandle(event: KeyboardEvent) {
    if (this.isFocused) {
      this.key = event.code;
      switch (this.key) {
        case 'Space':
          this.isOpen = true;
          break;
        case 'ArrowDown':
          if (this._options.length - 1 > this._selected) {
            this._selected = this._selected + 1;
          }
          break;
        case 'ArrowUp':
          if (this.selected > 0) {
            this._selected = this._selected - 1;
          }
          break;
        case 'Enter':
          this.acceptSelected();
          break;
      }
    }
  }

  private acceptSelected() {
    if (this._selectedTemporary != this._selected) {
      this.onChangeCallback(this._selected);
      this.onTouchedCallback();
      this.optSelect.emit(this._options[this._selected]);
    }
  }

  optionSelect(selectedOption: any, idx, e: MouseEvent) {
    e.stopPropagation();
    if (idx === this.selected) {
      return;
    }

    if (!this.beforeOptSelect()) {
      this.isOpen = false;
      return;
    }

    this.selected = idx;
    this.isOpen = false;
    this.onChangeCallback(selectedOption?.id);
    this.onTouchedCallback();
    this.optSelect.emit(selectedOption);
  }

  toggle(e: MouseEvent) {
    e.stopPropagation();
    this.selectDirection();

    if (!this._options || this._options.length <= 1) {
      return;
    }

    this.toggleClicked.emit(this._options[this.selected]);

    if (!this.canBeToggled) {
      return;
    }

    if (!this.isReadOnly) {
      const allElems = document.querySelectorAll('.dropdown-wrapper');
      for (let i = 0; i < allElems.length; i++) {
        allElems[i].classList.remove('is-open');
      }
      this.isOpen = !this.isOpen;
    } else {
      this.optSelect.emit(this._options[this.selected]);
    }
  }

  @HostListener('document: click', ['$event'])
  onClick() {
    this.isOpen = false;
    this.selectDirection();
  }

  writeValue(obj: string): void {
    if (obj !== '') {
      const index = this._options?.findIndex((y) => y.id === obj) ?? -1;
      this.selected = index < 0 ? null : index;
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isSelectable() {
    return (!this.selected || this._options.length > 1) && this.canBeToggled;
  }

  selectDirection() {
    if (MobileDeviceUtils.isMobile()) {
      this.computedExpandDirection = DropdownExpandDirection.Down;
      return;
    }

    if (!this.computeExpandDirection) {
      this.computedExpandDirection = this.expandDirection;
    } else if (
      this.computeExpandDirection &&
      this.dropdownWrapper?.nativeElement.getBoundingClientRect().top < this.dropdownElement?.nativeElement.offsetHeight
    ) {
      this.computedExpandDirection = DropdownExpandDirection.Down;
    } else {
      this.computedExpandDirection = DropdownExpandDirection.Up;
    }
  }
}
