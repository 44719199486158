import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FormValidator } from './form-validator';

export enum ControlValueFormValidatorType {
  ByValue,
  ByControlValue,
}

export enum ControlValueFormValidatorMode {
  Equals = 'equals',
  Contains = 'contains',
}

export class ControlValueFormValidator implements FormValidator {
  constructor(
    private sourceControlName: string,
    private target: string,
    private controlValueFormValidatorType: ControlValueFormValidatorType,
    private controlValueFormValidatorMode: ControlValueFormValidatorMode
  ) {}

  public getValidator(): ValidatorFn {
    let sourceFieldControl: FormControl;
    let targetFieldControl: FormControl;

    return (form: FormGroup) => {
      if (!form.value) {
        return null;
      }

      sourceFieldControl = form.get(this.sourceControlName) as FormControl;
      if (!targetFieldControl && this.controlValueFormValidatorType === ControlValueFormValidatorType.ByControlValue) {
        targetFieldControl = form.get(this.target) as FormControl;

        sourceFieldControl.valueChanges.subscribe(() => {
          targetFieldControl.updateValueAndValidity();
        });
      }

      switch (this.controlValueFormValidatorType) {
        case ControlValueFormValidatorType.ByValue:
          switch (this.controlValueFormValidatorMode) {
            case ControlValueFormValidatorMode.Equals:
              if (sourceFieldControl?.value && this.target && sourceFieldControl.value.toLowerCase() === this.target.toLowerCase()) {
                sourceFieldControl.setErrors({ [this.controlValueFormValidatorMode]: true });
              }
              break;
            case ControlValueFormValidatorMode.Contains:
              if (sourceFieldControl?.value && this.target && sourceFieldControl.value.toLowerCase().includes(this.target.toLowerCase())) {
                sourceFieldControl.setErrors({ [this.controlValueFormValidatorMode]: true });
              }
              break;
            default:
              break;
          }
          break;

        case ControlValueFormValidatorType.ByControlValue:
          switch (this.controlValueFormValidatorMode) {
            case ControlValueFormValidatorMode.Equals:
              if (sourceFieldControl?.value && targetFieldControl?.value && sourceFieldControl.value.toLowerCase() === targetFieldControl.value.toLowerCase()) {
                sourceFieldControl.setErrors({ [this.controlValueFormValidatorMode]: true });
              }
              break;
            case ControlValueFormValidatorMode.Contains:
              if (
                sourceFieldControl?.value &&
                targetFieldControl?.value &&
                sourceFieldControl.value.toLowerCase().includes(targetFieldControl.value.toLowerCase())
              ) {
                sourceFieldControl.setErrors({ [this.controlValueFormValidatorMode]: true });
              }
              break;
            default:
              break;
          }
          break;

        default:
          break;
      }
    };
  }
}
