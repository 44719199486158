import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute, Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { BackgroundService } from 'libs/core/src/lib/service/background.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';

@Component({
  selector: 'app-order-background-component',
  template: '',
})
export class BackgroundComponent implements OnInit, OnDestroy {
  private readonly deleteOrderOnWindowUnloadEnabled: boolean = false;
  private orderLifetimeCheckSubscription: Subscription = Subscription.EMPTY;
  private orderStateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private router: Router,
    private orderStateService: OrderStateService,
    private backgroundService: BackgroundService,
    private stepsService: StepsService,
    private navigationHelperService: NavigationHelperService,
    private dateTimeService: DateTimeService,
    protected route: ActivatedRoute,
    private countdownService: CountdownComponentService
  ) {
    this.deleteOrderOnWindowUnloadEnabled = this.environment.constants.deleteOrderOnWindowUnload;
    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {
        this.navigationHelperService.setCurrentUrl(event.url);
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:unload', ['$event'])
  public onWindowBeforeUnload(event: BeforeUnloadEvent): void {
    this.orderStateService.removePersonalTaxId();
    if (!this.deleteOrderOnWindowUnloadEnabled || !this.backgroundService.isDeleteOrderOnWindowUnloadEnabled()) {
      return;
    }

    this.orderStateService.removeOrderWithRequest(this.stepsService.FlowType === FlowType.Voucher);
  }

  public ngOnInit(): void {
    this.orderStateSubscription = this.orderStateService.state$.subscribe((order: OrderViewModel) => {
      if (order) {
        this.destroyOrderLifetimeSubscription();
        const ttl = order?.dateTimeToLive;

        this.orderLifetimeCheckSubscription = interval(1000).subscribe(() => {
          if (ttl && this.dateTimeService.isPastOrEqual(ttl)) {
            this.orderStateService.removeOrder();
            this.countdownService.destroy();
            this.destroyOrderLifetimeSubscription();

            if (this.navigationHelperService.shouldNavigateToErrorPage()) {
              this.router.navigate(['error', '5003']);
            }
          }
        });
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.orderStateSubscription !== Subscription.EMPTY) {
      this.orderStateSubscription.unsubscribe();
      this.orderStateSubscription = Subscription.EMPTY;
    }

    this.destroyOrderLifetimeSubscription();
  }

  private destroyOrderLifetimeSubscription(): void {
    if (this.orderLifetimeCheckSubscription !== Subscription.EMPTY) {
      this.orderLifetimeCheckSubscription.unsubscribe();
      this.orderLifetimeCheckSubscription = Subscription.EMPTY;
    }
  }
}
