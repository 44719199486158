import {Exclude, Expose} from 'class-transformer';
import { UserViewModel } from '../../../view-model/user/user.view.model';

@Exclude()
export class CardLoginPageModel {
  @Expose()
  public token: string;

  @Expose()
  public user: UserViewModel;
}
