import { AbstractViewModel } from "../../../abstract.view.model";
import { GaTicketExtraFeeApiModel } from "../../../api-model/screening/ga/ga-ticket-extra-fee.api.model";

export class GaTicketExtraFeeViewModel extends AbstractViewModel<GaTicketExtraFeeApiModel> {
  id: string;
  price: number;
  isOptional: boolean;
  name: string;

  constructor(protected apiModel: GaTicketExtraFeeApiModel = new GaTicketExtraFeeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.price = this.apiModel.price;
    this.isOptional = this.apiModel.isOptional;
    this.name = this.apiModel.name;
  }

  toApiModel(): GaTicketExtraFeeApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
