export function convertStringToEnumValue<T>(enumparam: T, value: string): T[keyof T] | undefined {
  const enumKeys = Object.keys(enumparam);
  const foundKey = enumKeys.find((key) => enumparam[key] === value);

  if (foundKey) {
    return enumparam[foundKey];
  }

  return undefined;
}
