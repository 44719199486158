import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAccountItem } from './account-items.component';

@Component({
  template: '',
})
export class AccountItemsVouchersListComponent implements OnInit {
  @Input() vouchers: IAccountItem[];

  @Output() itemSelected = new EventEmitter<IAccountItem>();

  constructor() {}

  ngOnInit() {}

  useItem(item) {
    this.itemSelected.emit(item);
  }
}
