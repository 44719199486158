import {Expose} from 'class-transformer';

export class EventItemApiModel {

  @Expose()
  movieId: string;

  @Expose()
  moviePrintId: string;

  @Expose()
  timeFrom: string;

  @Expose()
  timeTo: string;

  @Expose()
  movieName: string;

  @Expose()
  speakingType: string;

  @Expose()
  printType: string;

  @Expose()
  screenId: string;
}
