export enum SupportedDateTimeLocale {
  en_US = 'en-US',
  en_GB = 'en-GB',
  pl_PL = 'pl-PL',
  fr_CH = 'fr-CH',
  de_CH = 'de-CH',
  bg_BG = 'bg-BG',
  sq_XK = 'sq-XK',
  hr_HR = 'hr-HR',
  sr_Latn = 'sr-Latn',
}
