import { AbstractViewModel } from '../../abstract.view.model';
import { AuditoriumApiModel } from '../../api-model/auditorium/auditorium.view.model';

export class AuditoriumViewModel extends AbstractViewModel<AuditoriumApiModel> {
  id: string;
  name: string;
  number: string;
  background_image: string;
  background_image_web: string;

  constructor(protected apiModel: AuditoriumApiModel = new AuditoriumApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.number = this.apiModel.number;
    this.background_image = this.apiModel.background_image ? this.apiModel.background_image : null;
    this.background_image_web = this.apiModel.background_image_web ? this.apiModel.background_image_web : null;
  }

  toApiModel(): AuditoriumApiModel {
    return undefined;
  }
}
