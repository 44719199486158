import { storageKey } from "libs/core/src/app.const";

export class RequestHandlerModel {
  backUrl: string = null;
  lang: string = null;
  cinemaId: string = null;
  orderId: string = null;
  screeningId: string = null;
  sandboxPayment = false;

  constructor(params: any) {
    if (params.backUrl) {
      this.backUrl = params.backUrl;
    }

    if (params.lang) {
      this.lang = params.lang.toLowerCase();
    }

    if (params.cinemaId) {
      this.cinemaId = params.cinemaId.toLowerCase();
    }

    if (params.orderId) {
      this.orderId = params.orderId.toLowerCase();
    }

    if (params.screeningId) {
      this.screeningId = params.screeningId.toLowerCase();
    }

    if (params.sandboxPayment === 'true') {
      this.sandboxPayment = true;
    }
  }
}
