import { AbstractViewModel } from "../../../abstract.view.model";
import { EventItemApiModel } from "../../../api-model/account-items/reservation/event-item.api.model";

export class EventItemViewModel extends AbstractViewModel<EventItemApiModel> {
  public movieId: string;
  public moviePrintId: string;
  public movieName: string;
  public speakingType: string;
  public printType: string;
  public language: string;
  public release: string;
  public format: string;
  public resolution: string;
  public frameRate: string;
  public soundType: string;
  public subtitles: string;
  public subtitles2: string;

  constructor(
    protected apiModel: EventItemApiModel = new EventItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.movieId = this.apiModel.movieId;
    this.moviePrintId = this.apiModel.moviePrintId;
    this.movieName = this.apiModel.movieName;
    this.speakingType = this.apiModel.speakingType;
    this.printType = this.apiModel.printType;
    this.language = this.apiModel.language;
    this.release = this.apiModel.release;
    this.format = this.apiModel.format;
    this.resolution = this.apiModel.resolution;
    this.frameRate = this.apiModel.frameRate;
    this.soundType = this.apiModel.soundType;
    this.subtitles = this.apiModel.subtitles;
    this.subtitles2 = this.apiModel.subtitles2;
  }

  toApiModel(): EventItemApiModel {
    return undefined;
  }
}
