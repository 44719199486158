import { AbstractViewModel } from "../../abstract.view.model";
import { CanBeRefundItemExtraFeeApiModel } from "../../api-model/sales-document/can-be-refund-item-extra-fee.api.model";
import { CanBeRefundItemApiModel } from "../../api-model/sales-document/can-be-refund-item.api.model";
import { CanBeRefundItemExtraFeeViewModel } from "./can-be-refund-item-extra-fee.view.model";

export class CanBeRefundItemViewModel extends AbstractViewModel<CanBeRefundItemApiModel> {
  itemType: number;
  salesDocumentItemId: string;
  itemId: string;
  itemName: string;
  quantity: number;
  refundValue: number;
  ticketMaskNumber: string;
  isRefundable: boolean;
  extraFeeList: CanBeRefundItemExtraFeeApiModel[];

  constructor(protected apiModel: CanBeRefundItemApiModel = new CanBeRefundItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.itemType = this.apiModel.itemType;
    this.salesDocumentItemId = this.apiModel.salesDocumentItemId;
    this.itemId = this.apiModel.itemId;
    this.itemName = this.apiModel.itemName;
    this.quantity = this.apiModel.quantity;
    this.refundValue = this.apiModel.refundValue;
    this.ticketMaskNumber = this.apiModel.ticketMaskNumber;
    this.isRefundable = this.apiModel.isRefundable;
    this.extraFeeList = this.apiModel.extraFeeList ? this.apiModel.extraFeeList.map(extraFee => new CanBeRefundItemExtraFeeViewModel(extraFee)) : [];
  }

  toApiModel(): CanBeRefundItemApiModel {
    return undefined;
  }
}
