<h3 class="mb-3">{{'user.history.title' | translate}}</h3>

<div [smooth-in]="userHistory">
  <div class="row m-0 columns text-center p-2">
    <div class="col-2 text-left">
      {{'user.history.date' | translate}}
    </div>
    <div class="col-2">
      {{'user.history.cinema' | translate}}
    </div>
    <div class="col-3">
      {{'user.history.purchase' | translate}}
    </div>
    <div class="col-2">
      {{'user.history.pointsUsed' | translate}}
    </div>
    <div class="col-2">
      {{'user.history.pointsEarned' | translate}}
    </div>
    <div class="col-1 text-right">
      {{'user.history.spend' | translate}}
    </div>
  </div>

  <div *ngFor="let order of userHistory?.orders" class="data row m-0 columns text-center p-2">
    <div class="col-2 text-left">
      {{order.saleDate | dateTime:'DATE_HUMAN_COMA_MDY'}}
    </div>
    <div class="col-2">
      {{(getCinema(order.apiModel.cinemaId))?.name}}
    </div>
    <div class="col-3 d-flex flex-column">
      <div *ngFor="let ticket of getTickets(order.id) | keyvalue">
        {{ticket.value.length}}x {{ticket.value[0].name}}
      </div>
    </div>
    <div class="col-2">
      {{getOrderPoints(order,-1)}}
    </div>
    <div class="col-2">
      {{getOrderPoints(order,1)}}
    </div>
    <div class="col-1 text-right">
      {{order.apiModel.totalValue | number: '0.2-2'}}
    </div>
  </div>
</div>