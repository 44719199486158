<form [formGroup]="form" (submit)="onSubmit()">
    <p>{{ title }}</p>
    <div class="d-flex gap-1">
        <div class="w-75">
            <input type="email" formControlName="email" class="form-control w-100" [placeholder]="inputPlaceholder">
        </div>
        <div class="w-25">
            <button type="submit" class="btn btn-send center">
                <ng-template [ngIf]="!inProgress" [ngIfElse]="progress">
                    <span> {{ buttonLabel }} </span>
                </ng-template>
            </button>
        </div>
    </div>

    <div class="with-errors" *ngIf="!form.valid && form.dirty && formSubmitAttempt">
        <p *ngIf="form.get('email')?.errors?.required">
            {{ "errors.10003" | translate }}
        </p>
        <p *ngIf="form.get('email')?.errors?.pattern">
            {{ "errors.10102" | translate }}
        </p>
    </div>
</form>

<ng-template #progress>
    <span class="spinner"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>