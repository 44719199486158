import { Component, OnInit } from '@angular/core';
import { FooterComponent } from 'libs/shared/src/lib/component/layout/footer/footer.component';
import { environment } from 'apps/kinoteka/src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class CustomFooterComponent extends FooterComponent implements OnInit {
  env = environment;

  constructor() {
    super(environment);
  }

  ngOnInit() {}
}
