import { Component, OnInit } from '@angular/core';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-test-page-timezone',
  template: `<div
    style="background-color:#000;color:#FFF;width:100vw;height:50vh;margin:auto;padding:2rem;z-index:10001;"
    *ngIf="cinema"
  >
    <div>
      <h5>Your time</h5>
      <p>{{ dt }}, timezone: {{ dt.zone?.name }}</p>
      <p>{{ utc }}, timezone: {{ utc.zone?.name }}</p>
    </div>
    <div>
      <h5>Cinema {{ cinema?.name }} time</h5>
      <p>{{ dtInCinemaZone }}, timezone: {{ dtInCinemaZone.zone?.name }}</p>
      <p>{{ dtInCinemaZoneUtc }}, timezone: {{ dtInCinemaZoneUtc.zone?.name }}</p>
    </div>
    <div>
      <span>Set date to convert:</span
      ><input
        type="text"
        (change)="checkDate($event)"
      />
    </div>

    <div [innerHTML]="result"></div>
  </div>`,
})
export class TimeZoneTestPageComponent implements OnInit {
  public dt = DateTime.local();
  public utc = DateTime.utc();
  public dtInCinemaZone: DateTime = null;
  public dtInCinemaZoneUtc: DateTime = null;
  public cinema: CinemaViewModel = null;
  public result = '';

  constructor(protected dateTimeService: DateTimeService, private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.dateTimeService.cinemaLocal(this.cinema?.id).subscribe((s) => {
      this.dtInCinemaZone = s;
      this.dtInCinemaZoneUtc = this.dtInCinemaZone.toUTC();
    });
    this.loadingService.hideLoader('all');
  }

  public checkDate(event) {
    const dt = DateTime.fromISO(event.target.value, { setZone: true });
    const local = DateTime.fromISO(event.target.value, { setZone: false });
    const utc = dt.toUTC();

    this.result = `<p>${dt} , timezone: ${dt.zone.name}</p><p>${local} , timezone: ${local.zone.name}</p><p>${utc} , timezone: ${utc.zone.name}</p>`;
  }
}
