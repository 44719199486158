import {Expose, Type} from 'class-transformer';
import {EventItemApiModel} from './event-item.api.model';
import { TagGroupApiModel } from './tag-group.api.model';

export class EventApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  name: string;

  @Expose()
  @Type(() => EventItemApiModel)
  items: EventItemApiModel[];

  @Expose()
  @Type(() => TagGroupApiModel)
  tagGroups: TagGroupApiModel[];
}
