import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserCardTransferApiModel } from '../../../api-model/user-history/card-transfer/user-card-transfer.api.model';

export class UserCardTransferViewModel extends AbstractViewModel<UserCardTransferApiModel> {
  public id: string = null;
  public number: string = null;
  public name: string = null;
  public pictureUrl: string = null;
  public transferDate: DateTime = null;
  public recipientClientId: string = null;
  public recipientClientEmail: string = null;

  constructor(
    protected apiModel: UserCardTransferApiModel = new UserCardTransferApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.number = this.apiModel.number;
    this.name = this.apiModel.name;
    this.pictureUrl = this.apiModel.pictureUrl;
    this.transferDate = this.apiModel.transferDate;
    this.recipientClientId = this.apiModel.recipientClientId;
    this.recipientClientEmail = this.apiModel.recipientClientEmail;
  }

  toApiModel(): UserCardTransferApiModel {
    return undefined;
  }
}
