import {Expose } from 'class-transformer';
import { OccupancyLockGroupApiModel } from './occupancy-lock-group.api.model';

export class OccupancyApiModel {
  @Expose()
  occupiedSeats: string[];

  @Expose()
  lockGroups: OccupancyLockGroupApiModel[];

  @Expose()
  totalOccupied: number;

  @Expose()
  seatsLeft?: number;
}
