<h3 class="mb-3">{{ 'user.user-my-profile.upcomingBookings' | translate}}</h3>

<div class="coming-grid" [smooth-in]="userAccountItems">
  <ng-container *ngIf="upcomingScreenings.length > 0; else empty">
    <div *ngFor="let screening of upcomingScreenings; index as ind">

      <div class="row m-0">
        <div class="col-12 col-sm-4 col-lg-2 p-0">
          <img pimg style="aspect-ratio: 11/17;" [imageUrl]="screening.getPoster()" alt="">
        </div>
        <div class="col-12 col-sm-8 col-lg-10 d-flex flex-column justify-content-center px-1 px-sm-3">
          <ng-container *ngIf="!screening.isEvent()">
            <div class="pt-2 pb-1">
              <h5 class="m-0 mr-4 pr-2 d-inline light-opacity">
                {{ screening.movie.title }}
              </h5>
            </div>
            <h8 class="d-flex flex-column" style="gap: 0.5rem;">
              <span class="pr-2"> {{ screening.screen.name }}</span>
              <span>{{ screening.screening.screeningTimeFrom | dateTime : 'DATE_HUMAN_COMA_MDY' }}
                {{ screening.screening.screeningTimeFrom | dateTime: '12H' }}</span>
              <span>{{'user.upcoming.tickets' | translate}} {{ screening.tickets.length }}</span>
              <span>{{'user.upcoming.bookingNo' | translate}} {{ screening.bookingId }}</span>
            </h8>
          </ng-container>

          <!-- <i [ngClass]="screening.isReservation() ? 'pc-icon-cinema-seat' : 'pc-icon-ticket'"></i> -->
        </div>

        <!-- <div class="d-sm-none info pl-4">
          <ng-container *ngIf="!screening.isEvent()">
            <div class="py-2">
              <h7 class="m-0 light-opacity" [ngClass]="{'pr-6': showCountTickets}">
                {{ screening.movie.title }}
              </h7>
              <h8 class="m-0">
                <app-flag [lang]="screening.moviePrint.language" [release]="screening.moviePrint.release"></app-flag>
              </h8>
              <h7 class="m-0">{{ screening.region.region }}, {{ screening.cinema.name }} {{ screening.screen.number }}
                <span class="text-dark" *ngIf="screening.screen.feature">{{ screening.screen.feature }}</span>
              </h7>
              <h8 class="m-0">
                <span>{{ screening.screening.screeningTimeFrom | dateTime : 'DATE_HUMAN' : 2 }}</span>
                <span class="pl-4">{{ screening.screening.screeningTimeFrom | dateTime: '24H' }}</span>
              </h8>
            </div>
          </ng-container>
          <ng-container *ngIf="screening.isEvent()">
            <div class="py-2">
              <h7 class="m-0 light-opacity" [ngClass]="{'pr-6': showCountTickets}">
                {{ screening.event.eventName }}
              </h7>
              <h7 class="m-0">{{ screening.region.region }}, {{ screening.cinema.name }} {{ screening.screen.number }}
              </h7>
              <h8 class="m-0">
                <span>{{ screening.event.eventTimeFrom | dateTime : 'DATE_HUMAN' : 2 }}</span>
                <span class="pl-4">{{ screening.event.eventTimeFrom | dateTime: '24H' }}</span>
              </h8>
            </div>
          </ng-container>

          <h5 class="count px-3 py-1 font-weight-bold" *ngIf="showCountTickets">
            <i [ngClass]="screening.isReservation() ? 'pc-icon-cinema-seat' : 'pc-icon-ticket'"></i>
            {{ screening.tickets.length }}
          </h5>
        </div> -->
      </div>
    </div>

  </ng-container>
</div>

<ng-template #empty>
  <div class="d-flex flex-column">
    <span>{{'user.upcoming.empty' | translate}}</span>
    <a href="{{environment.homepageUrl}}">{{'user.upcoming.bookNow' | translate}}</a>
  </div>
</ng-template>