import { AfterContentChecked, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'libs/core/src/lib/prototypes/string.prototype';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { from, iif, of, Subscription } from 'rxjs';
import uniqBy from 'lodash-es/uniqBy';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { storageKey } from 'libs/core/src/app.const';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { LanguageViewModel } from 'libs/core/src/lib/model/view-model/language/language.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';

@Component({
  selector: 'app-header',
  template: '',
})
export class HeaderComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() languageList: LanguageViewModel[];

  public cinema: CinemaViewModel;
  public isLoaded = false;
  public routerName: string;
  public order: OrderViewModel = null;
  public cartEnabled = false;
  private cateringSaleEnabled: boolean;
  public cateringAvailableSubscription: Subscription = Subscription.EMPTY;
  private readonly removeOrderWhenUserLeavePage;
  showCountdown = true;
  public screen: ScreenViewModel = null;
  innerContent: string = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected navigationHelperService: NavigationHelperService,
    protected headerService: HeaderService,
    protected voucherService: VoucherService,
    protected authService: AuthStateService,
    protected cateringStateService: CateringStateService,
    protected appService: AppService,
    protected cinemaDataProvider: CinemaDataProvider
  ) {
    if (this.cateringAvailableSubscription === Subscription.EMPTY) {
      this.cateringAvailableSubscription = this.cateringStateService.state$.subscribe((value) => {
        this.cateringSaleEnabled = value;
      });
    }

    this.cartEnabled = environment.constants.cartEnabled;
    this.removeOrderWhenUserLeavePage = environment.screen.removeOrderWhenUserLeavePage;
  }

  get GetRouteCurrentUrl(): string {
    return this.router.url;
  }

  public onBrandClicked() {
    const siteUrl: string = this.environment.siteUrl;

    if (siteUrl !== '') {
      if (this.removeOrderWhenUserLeavePage) {
        this.orderStateService.removeOrder();
      }

      window.location.href = this.environment['siteUrl'].format(this.translateService.currentLang);
    }
  }

  public onSecondBrandClicked() {
    const siteUrlSecond: string = this.environment.siteUrlSecond;

    if (siteUrlSecond !== '' && siteUrlSecond) {
      window.location.href = (siteUrlSecond as any).format(this.translateService.currentLang);
    }
  }

  public ngOnInit() {
    this.cateringStateService.checkAvailability();

    this.orderStateService.state$
      .pipe(
        tap((order: OrderViewModel) => (this.order = order)),
        switchMap((order: OrderViewModel) => iif(() => !!order?.cinemaId, this.cinemaDataProvider.getCinemaById(order?.cinemaId), of(null)))
      )
      .subscribe((cinema: CinemaViewModel) => {
        this.cinema = cinema;
        this.isLoaded = true;
      });

    this.navigationHelperService.currentUrlChange$.subscribe((url) => {
      if (url && !this.canDisplayScreenMovieComponent(url.split('/')[0])) {
        this.screen = null;
      }
    });

    this.headerService.screenModelChange$.subscribe((screen) => {
      const currentPageIdentifier: string | null = this.navigationHelperService.getCurrentRoutePageIdentifier();
      this.screen = this.canDisplayScreenMovieComponent(currentPageIdentifier) ? screen : null;
    });

    this.headerService.innerContent$.subscribe((value) => (this.innerContent = value));
  }

  private canDisplayScreenMovieComponent(currentPageIdentifier) {
    return this.environment.constants.screenMovieComponentOnPage.indexOf(currentPageIdentifier) >= 0;
  }

  public ngAfterContentChecked(): void {
    let route = this.router.routerState?.root;
    if (route) {
      while (route.firstChild) {
        route = route.firstChild;
      }

      this.routerName = route.snapshot?.data['pageIdentify'];
    }
  }

  public isSummary(): boolean {
    return this.routerName === 'summary';
  }

  public goToBack() {
    switch (this.routerName) {
      case 'screen':
        if (this.removeOrderWhenUserLeavePage) {
          this.orderStateService.removeOrder();
        }

        this.navigationHelperService.goToRepertoire();
        break;
      case 'userAreaError':
      case 'error':
        this.navigationHelperService.goToRepertoire();
        break;
      case 'personalAndPayment':
        const itemsWithVoucher = this.order
          ? uniqBy(
              this.order.screeningItems.filter((it) => it.hasVoucher()),
              (x) => x.voucherNumber
            )
          : [];

        if (itemsWithVoucher.length > 0) {
          this.voucherService.loadingStatus = LoadingStatus.pending;
          from(itemsWithVoucher)
            .pipe(concatMap((voucher) => this.voucherService.removeVoucherFromOrder(this.order.cinemaId, this.order.id, voucher.voucherNumber)))
            .subscribe({
              complete: () => {
                this.voucherService.loadingStatus = LoadingStatus.success;
                this.navigateToPrevious();
              },
            });
        } else {
          this.navigateToPrevious();
        }
        break;
      default:
        this.navigateToPrevious();
        break;
    }
  }

  public goToRepertoire() {
    return this.navigationHelperService.goToRepertoire();
  }

  public onClickHomeIcon() {
    window.location.href = this.environment['siteUrl'].format(this.translateService.currentLang);
  }

  public onActionButtonClick(actionButtonIdentifier: string): void {
    this.headerService.performActionForButton(actionButtonIdentifier);
  }

  private navigateToPrevious() {
    if (this.navigationHelperService.canBackFromPaymentPage()) {
      let navigationBack = this.navigationHelperService.getPreviousRoute(this.route.children[0].snapshot);

      if (this.authService.isLogged()) {
        if (this.cateringSaleEnabled) {
          if (this.navigationHelperService.hasNewFoodUrl()) {
            navigationBack = 'addNewFoodandbeverage';
          }
        } else if (navigationBack === 'user-login') {
          navigationBack = this.navigationHelperService.getPreviousRouteForRoute(navigationBack.replace(/-./g, (x) => x.toUpperCase()[1]));
        }
      }

      if (navigationBack === 'foodandbeverage' && !this.cateringSaleEnabled) {
        navigationBack = this.navigationHelperService.getPreviousRouteForRoute(navigationBack.replace(/-./g, (x) => x.toUpperCase()[1]));
      }

      if (navigationBack) {
        if (navigationBack === 'screen') {
          this.navigateToScreen(navigationBack);
        } else {
          this.router.navigate([navigationBack]);
        }
      } else {
        window.history.back();
      }
    }
  }

  private navigateToScreen(navigationBack = null) {
    if (!navigationBack) {
      navigationBack = this.navigationHelperService.getPreviousRoute(this.route.children[0].snapshot);
    }

    this.router.navigate([navigationBack, this.orderStateService.getItem(storageKey.lastScreeningId)], {
      queryParams: {
        cinemaId: this.orderStateService.getItem(storageKey.chosenLocation),
      },
    });
  }

  ngOnDestroy(): void {
    if (this.cateringAvailableSubscription !== Subscription.EMPTY) {
      this.cateringAvailableSubscription.unsubscribe();
      this.cateringAvailableSubscription = Subscription.EMPTY;
    }
  }
}
