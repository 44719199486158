import { EventEmitter } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { AuthHelper } from '../auth-helper';
import { BaseLoginProvider } from '../entities/base-login-provider';
import { SocialUser } from '../entities/social-user';

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'APPLE';

  private readonly _socialUser = new BehaviorSubject<SocialUser | null>(null);
  public readonly changeUser = new EventEmitter<SocialUser | null>();

  private requestOptions = {
    scope: 'name email',
    state: 'init',
    nonce: 'lsi',
    usePopup: true,
    locale: '',
  };

  constructor(private env: any, private initOptions?: any) {
    super();
    this.checkSupportedLocales();

    this.requestOptions = {
      ...this.requestOptions,
      ...initOptions,
    };
    this._socialUser.pipe(skip(1)).subscribe(this.changeUser);
  }

  checkSupportedLocales() {
    // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
    if (!this.env.externalAuthProviders?.apple?.supportedLocales?.some((l) => l === this.initOptions.locale)) {
      this.initOptions.locale =
        this.env.externalAuthProviders?.apple?.supportedLocales?.filter((l) => l.substring(0, 2) === this.initOptions.locale.substring(0, 2))[0] ?? 'en_US';
    }
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.env.externalAuthProviders?.apple?.clientId) {
          resolve();
          return;
        }

        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
          console.info('AppleIDSignInOnSuccess:', event);
          if (event['detail']) {
            const socialUser = this.createSocialUser(event['detail'].authorization.id_token);
            this._socialUser.next(socialUser);
          }
        });

        document.addEventListener('AppleIDSignInOnFailure', (event) => {
          console.error('AppleIDSignInOnFailure:', event);
        });

        this.loadScript(
          AppleLoginProvider.PROVIDER_ID,
          `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${this.requestOptions.locale}/appleid.auth.js`,
          () => {
            AppleID.auth.init({
              clientId: this.env.externalAuthProviders.apple.clientId, //it MUST be a ServiceID from Apple
              scope: this.requestOptions.scope,
              redirectURI: `${window.location.origin}`, //it MUST be a url to frontpage the same as in Return URLs on Apple configuration page (slash/without on the end is important)
              state: this.requestOptions.state,
              nonce: this.requestOptions.nonce,
              usePopup: true,
            });

            resolve();
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  signIn(signInOptions?: any): Promise<SocialUser> {
    const options = { ...this.requestOptions, ...signInOptions };
    return new Promise(async (resolve, reject) => {
      try {
        const data = await AppleID.auth.signIn();
        const user = this.createSocialUser(data.authorization.id_token);
        resolve(user);
      } catch (error) {
        reject(error);
      }
    });
  }

  signInByApi(url: string): Promise<SocialUser> {
    return new Promise(async (resolve, reject) => {
      window.open(url, '_blank');
      window.addEventListener('message', async (event) => {
        const user = this.createSocialUser(event.data.id_token);
        if (user) {
          resolve(user);
        } else {
          reject();
        }
      });
    });
  }

  async signOut(): Promise<void> {
    this._socialUser.next(null);
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      if (this._socialUser.value) {
        resolve(this._socialUser.value);
      } else {
        reject(`No user is currently logged in with ${AppleLoginProvider.PROVIDER_ID}`);
      }
    });
  }

  private createSocialUser(idToken: string) {
    const user = new SocialUser();
    user.idToken = idToken;

    const payload = AuthHelper.decodeJwt(idToken);
    user.id = payload.sub;
    user.email = payload.email;
    user.name = payload.name;
    user.photoUrl = payload.picture;
    user.firstName = payload['first_name'];
    user.lastName = payload['last_name'];

    user.authToken = AuthHelper.toBase64({
      firstName: user.firstName,
      lastName: user.lastName,
      jwtToken: idToken,
    });

    return user;
  }
}
