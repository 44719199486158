import { Expose, Type } from 'class-transformer';
import { GenreApiModel } from '../genre/genre.api.model';
import { BannerApiModel } from '../banner.api.model';
import { RatingApiModel } from '../rating.api.model';
import { TagGroupApiModel } from '../tag-group.api.model';
import { BrightcoveVideoApiModel } from './brightcove-video.api.model';

export class MovieApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  description: string;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  distributor: string;

  @Expose()
  distributorId: string;

  @Expose()
  @Type(() => GenreApiModel)
  genres: GenreApiModel[];

  @Expose()
  trailers: string[];

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  posPosters: string[];

  @Expose()
  @Type(() => BannerApiModel)
  banners: BannerApiModel[];

  @Expose()
  @Type(() => RatingApiModel)
  ratings: RatingApiModel[];

  @Expose()
  title: string;

  @Expose()
  shortTitle: string;

  @Expose()
  originalTitle: string;

  @Expose()
  onlineTitle: string;

  @Expose()
  filmCast: string;

  @Expose()
  scenario: string;

  @Expose()
  isForChildren: boolean;

  @Expose()
  yearOfProduction: string;

  @Expose()
  director: string;

  @Expose()
  shortDescription: string;

  @Expose()
  premiereDate: string;

  @Expose()
  country: string;

  @Expose()
  originalLanguage: string;

  @Expose()
  originalTitleLanguage: string;

  @Expose()
  imdbId: string;

  @Expose()
  imdbRating: string;

  @Expose()
  type: string;

  @Expose()
  worldPremiereDate: string;

  @Expose()
  @Type(() => TagGroupApiModel)
  tagGroups: TagGroupApiModel[];

  @Expose()
  @Type(() => BrightcoveVideoApiModel)
  brightcoveVideos: BrightcoveVideoApiModel[];
}
