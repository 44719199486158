import { Injectable } from '@angular/core';
import { LanguageHttpService } from '../http/language.http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageViewModel } from '../model/view-model/language/language.view.model';
import { IProgramFilterItem } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class LanguageDataProvider {
  constructor(private httpService: LanguageHttpService) {}

  listViaApiModel(): Observable<LanguageViewModel[]> {
    return this.httpService.getLanguages().pipe(map((languages) => languages.map((language) => new LanguageViewModel(language))));
  }

  listOfFilterItems() {
    return this.httpService.getLanguages().pipe(map((items) => items.map((item) => ({ key: item.symbol, label: item.name } as IProgramFilterItem))));
  }

  findById(id: string): Observable<LanguageViewModel> {
    return this.findById(id).pipe(map((x) => Object.assign(new LanguageViewModel(), x)));
  }
}
