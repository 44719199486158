export enum QuickFilterType {
  COPY = 'copy',
  FOURDX = '4dx',
  FAMILY = 'family',
  EVENT = 'event',
  IMAX = 'imax',
  FIRSTLOUNGE = 'firstlounge',
  SCREENX = 'screenx',
  GOLDCLASS = 'goldclass',
  ULTIMATE = 'ultimate',
  EXTREME = 'extreme',
  KIDS = 'kids',
  DUBBED = 'dub',
  VIP = 'vip',
  SUBTITLES = 'subtitles',
  GTX = 'gtx',
  OPENCAPTION = 'opencaption',
  TAGGROUPS = 'taggroups',
  TAGS = 'tags',
  LANGUAGES = 'languages',
  CINEMAS = 'cinemas',
  GENRES = 'genres',
}

export enum QuickFilterGroup {
  CUSTOM = 0,
  PRINT_TYPE = 1,
  SCREEN_FEATURE = 2,
  MOVIE_PRINT = 3,
}
