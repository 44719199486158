export const appProjectName = {
  HELIOS: 'helios',
  SCHULMAN: 'schulman',
  MUVI: 'muvi',
  ARENA: 'arena',
  BLUE: 'blue',
  VIOLET_CROWN: 'violetcrown',
  LANDMARK: 'landmark',
  CINEPOLIS: 'cinepolis',
  ONEIL: 'oneil',
  CINESTAR: 'cinestar',
  CINELAND: 'cineland',
  MILFORD: 'milford',
  MILFORD_MAINSTREET: 'milford-mainstreet',
  MILFORD_WESTOWN: 'milford-westown',
  KINOTEKA: 'kinoteka',
  PROJECTOR: 'projector',
  CINEGRAND: 'cinegrand',
  MTSDVORANA: 'mts-dvorana',
};

export const APP_CONFIG_PATH = '/assets/config.json';

export const storageKey = {
  appConfig: 'positivecinema.app.config',
  order: 'positivecinema.order',
  chosenLocation: 'locationId',
  selectedPaymentMethod: 'selectedPaymentMethod',
  vouchers: 'vouchers',
  personalEmail: 'personalEmail',
  personalAgreements: 'personalAgreements',
  isExtraFeesSelected: 'isExtraFeesSelected',
  personalTaxId: 'personalTaxId',
  lang: 'lang',
  prevLang: 'prevLang',
  backUrl: 'backUrl',
  isDream: 'isDream',
  flowType: 'flow_type',
  flowData: 'flow_data',
  isEvent: 'isEvent',
  chosenDate: 'chosenDate',
  lastScreeningId: 'lastScreeningId',
  parentOrder: 'parentOrder',
  lastEventId: 'lastEventId',
  personalData: 'personalData',
  redirectImNappLa: 'redirectIm_napp_la',
  sessionScreeningService: 'SessionScreeningService',
  myAccountSelectedOption: 'myAccountSelectedOption',
  reservationId: 'res_id',
  reservationCinemaId: 'res_cid',
  reservationScreeningId: 'res_sid',
  reservationScreeningTimeTo: 'res_stt',
  membershipLevel: 'membershipLevel',
  filteredCinema: 'filteredCinemaId',
  mgmPromotionId: 'mgmPromotionId',
  embededPaymentUrl: 'embededPayment',
  orderAgreements: 'orderAgreements',
  cateringEnabled: 'cateringEnabled',
  cinemaDayOffset: 'cinemaDayOffset',
  selectedDate: 'selectedDate',
  timeStampName: 'secondsFromEpoch_',
  lastEpochName: 'lastEpochTime_',
  token: 'token',
  needCompleteOUD: 'needCompleteOUD',
  table: 'table',
  lastUrl: 'lastUrl',
};

export const storageDeprecatedKey = {
  orderId: 'orderId',
  chosenRegion: 'regionId',
  chosenCinema: 'cinemaId',
};

export const validationPattern = {
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,100}$/,
  phone: /^(?=.{0,15}$|\+.{0,15}$)[+]?[0-9]+(?:-[0-9]*)*$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const heliosValidationPattern = {
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W|_])[A-Za-z\d\W|_]{8,}$/,
  phone: /^(?=.{0,15}$)[+]?[0-9]{9}[0-9]{0,5}$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const oneilValidationPattern = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W]).{8,}$/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const cinestarValidationPattern = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,100}$/,
};

export const kinotekaValidationPattern = {
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W|_])[A-Za-z\d\W|_]{8,}$/,
  phone: /^(?=.{0,15}$)[+]?[0-9]{9}[0-9]{0,5}$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const identities = {
  ticketServiceFeeId: '0148384a-6c02-48d3-ab48-27b3b1af8414',
  loyaltyPointsPaymentType: '922b3024-5a2f-4ab1-a11f-f448257cf7f7',
};

export const cookieKey = {
  regionId: 'regionId',
  external_user_token: 'external_user_token',
  external_user_r_token: 'external_user_r_token',
};

export const types = {
  rewardsFlowItemType: 4,
};

export const cardType = {
  loyalty: { value: 1, name: 'Loyalty' },
  discount: { value: 2, name: 'Discount' },
  prepaid: { value: 4, name: 'Prepaid' },
  membership: { value: 16, name: 'Membership' },
  gift: { value: 32, name: 'Gift Card' },
};
