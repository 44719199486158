import { AbstractViewModel } from "../../abstract.view.model";
import { CanBeRefundItemExtraFeeApiModel } from "../../api-model/sales-document/can-be-refund-item-extra-fee.api.model";

export class CanBeRefundItemExtraFeeViewModel extends AbstractViewModel<CanBeRefundItemExtraFeeApiModel> {
  extraFeeItemId: string;
  extraFeeName: string;
  extraFeeQuantity: number;
  extraFeeRefundValue: number;
  isRefundable: boolean;

  constructor(protected apiModel: CanBeRefundItemExtraFeeApiModel = new CanBeRefundItemExtraFeeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.extraFeeItemId = this.apiModel.extraFeeItemId;
    this.extraFeeName = this.apiModel.extraFeeName;
    this.extraFeeQuantity = this.apiModel.extraFeeQuantity;
    this.extraFeeRefundValue = this.apiModel.extraFeeRefundValue;
    this.isRefundable = this.apiModel.isRefundable;
  }

  toApiModel(): CanBeRefundItemExtraFeeApiModel {
    return undefined;
  }
}
