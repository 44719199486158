import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, forkJoin, from, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash-es/cloneDeep';
import PaymentData = GooglePay.PaymentData;
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { GooglePay } from '../gpay/client/client';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { PaymentProviderConfigRequestModel } from 'libs/core/src/lib/model/request/payment-provider-config.request.model';
import { ApplePaySessionRequestModel } from 'libs/core/src/lib/model/request/applepay-session.request.model';
import { PaymentProviderPayMethodRequestModel } from 'libs/core/src/lib/model/request/payment-provider-pay-method.request.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { CreatePaymentRedirectUrlDto } from '../../../service/dto/create-payment-redirect-url.dto';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { ErrorPaymentProviderEvent } from '../../event/error-payment-provider.event';
import { NotSelectedPaymentProviderEvent } from '../../event/not-selected-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WaitPaymentProviderEvent } from '../../event/wait-payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentTotalPriceStatus, PaymentAuthMethod, PaymentCardNetwork, PaymentDataModel } from '../gpay/model/payment-data.model';
import { GooglePayService, CreatePaymentDataRequestDto } from '../gpay/service/google-pay.service';
import { PayUInternalPaymentMethodType } from './model/pay-u-internal-payment-method-type';
import { PayuExpressService } from './service/payu-express.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { FinancialHelper } from '@lib/core';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ApplePayService } from '../apay/service/apple-pay.service';

declare var OpenPayU: unknown;
declare var ApplePaySession: any | undefined;

@Component({
  selector: 'app-payment-provider-payu-component',
  templateUrl: './payu-payment-provider.component.html',
})
export class PayuPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  cardModel: {
    cardNumber: string;
    cardCVV: string;
    cardExpirationDate: string;
    cardExpirationYear: string;
    cardExpirationMonth: string;
  } = { cardNumber: '', cardCVV: '', cardExpirationDate: '', cardExpirationMonth: '', cardExpirationYear: '' };

  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public blikWaitSecondsForTransactionStep = 7;

  public cardWaitSecondsForTransactionStep = 4;

  public paymentMethod = PaymentMethodEnum;

  public readonly paymentProviderIdentifier: string = 'payu';

  /**
   * The collection of payByLinks methods
   */
  public payByLinkMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  /**
   * The collection of card methods
   */
  public cardMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  /**
   * Selected pay by link method by user
   */
  public selectedPayByLinkMethod: PaymentProviderPayMethodViewModel | null = null;

  /**
   * Selected card method
   */
  public selectedCardMethod: PaymentProviderPayMethodViewModel | null = null;

  /**
   * Selected payment provider pay method
   */
  public selectedPayMethodTypeCustom: string | null = null;

  public paymentProviderPayMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  public paymentProviderConfig: PaymentConfigViewModel | null;

  public form: FormGroup;
  public cardFormGroup: FormGroup;

  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;

  private order: OrderViewModel = null;
  private blikNumber: string = null;

  private readonly paymentChannel: string | null = null;
  public formSubmitAttempt = false;
  public formSubmitAttempt2 = false;

  private continueUrl: string;
  private isApplePaySessionDefined = false;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute,
    private payuExpressService: PayuExpressService,
    private applePayService: ApplePayService,
    private googlePayService: GooglePayService,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private formBuilder: FormBuilder
  ) {
    this.paymentChannel = this.environment.constants.paymentChannel;
    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.selectedPayMethodTypeCustom = state.type;
        this.selectedPayByLinkMethod = state.payByLink;
      } else {
        this.selectedPayMethodTypeCustom = null;
        this.selectedPayByLinkMethod = null;
      }
    });
  }

  public ngOnInit(): void {
    this.order = this.orderStateService.getOrder();

    this.events.next(new WorkPaymentProviderEvent());
    this.form = this.selectedPaymentTypeService.getPayuBlikFormGroup();

    this.cardFormGroup = this.selectedPaymentTypeService.getPayuCardFormGroup();

    this.cardFormGroup.valueChanges.subscribe((form) => {
      const { cardCVV, cardNumber, cardExpirationDate } = form;
      this.cardModel = {
        cardCVV: cardCVV,
        cardNumber: cardNumber,
        cardExpirationDate: cardExpirationDate,
        cardExpirationMonth: cardExpirationDate ? cardExpirationDate.substring(0, 2) : '',
        cardExpirationYear: cardExpirationDate
          ? cardExpirationDate.substring(2).length < 4
            ? '20' + cardExpirationDate.substring(2)
            : cardExpirationDate.substring(2)
          : '',
      };
    });
    this.initPaymentProviderWidget();

    if (this.applePayService.canMakePayments()) {
      this.isApplePaySessionDefined = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }
    this.form.reset();
    this.cardFormGroup.reset();
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    if (
      this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_PBL ||
      this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_GPAY ||
      this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_APAY ||
      this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CARD
    ) {
      window.location.href = paymentModel.plainPayload;
    }
    if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_BLIK) {
      const waitSeconds: number = this.blikWaitSecondsForTransactionStep;
      this.events.next(new WaitPaymentProviderEvent(waitSeconds));
      setTimeout(() => {
        const request: CreatePaymentRedirectUrlDto = new CreatePaymentRedirectUrlDto();
        request.paymentChannel = this.paymentChannel;
        request.order = this.order;
        request.route = this.route;
        window.location.href = this.paymentHelperService.createPaymentRedirectUrl(request, this.continueUrl);
      }, waitSeconds * 1000);
    }
  }

  splitExpirationDate() {
    this.cardModel.cardExpirationMonth = this.cardModel.cardExpirationDate.substring(0, 2);
    this.cardModel.cardExpirationYear = this.cardModel.cardExpirationDate.substring(2);
    if (this.cardModel.cardExpirationYear.length < 4) {
      this.cardModel.cardExpirationYear = '20' + this.cardModel.cardExpirationYear;
    }
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    this.continueUrl = event.continueUrl;
    return new Observable<PaymentPreInitModel>((subscriber) => {
      if (this.selectedPayMethodTypeCustom === null) {
        event.abort = true;
        this.events.emit(new NotSelectedPaymentProviderEvent());
        subscriber.next(event);
        subscriber.complete();
      }
      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_PBL) {
        if (!this.selectedPayByLinkMethod) {
          event.abort = true;
          this.events.emit(new NotSelectedPaymentProviderEvent());
        } else {
          event.intPayMethodType = PayUInternalPaymentMethodType.payByLink;
          event.intPayMethodValue = this.selectedPayByLinkMethod.id;
        }
        subscriber.next(event);
        subscriber.complete();
      }
      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_BLIK) {
        this.formSubmitAttempt = true;
        if (this.form.valid) {
          const blikTokenPayMethod: PaymentProviderPayMethodViewModel | null = this.getBLIKTokenPayMethod();
          const filteredInputBlikCode: string | null = this.sanitizeInput(this.form.get('blikCode').value);
          if (filteredInputBlikCode === null && blikTokenPayMethod !== null) {
            event.intPayMethodType = PayUInternalPaymentMethodType.blikToken;
            event.intPayMethodValue = blikTokenPayMethod.id;
          } else if (filteredInputBlikCode !== null) {
            event.intPayMethodType = PayUInternalPaymentMethodType.blikCode;
            event.intPayMethodValue = filteredInputBlikCode;
          } else {
            event.abort = true;
          }
        } else {
          event.abort = true;
        }
        subscriber.next(event);
        subscriber.complete();
      }
      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CARD) {
        this.formSubmitAttempt2 = true;

        if (this.selectedCardMethod !== null) {
          event.intPayMethodType = PayUInternalPaymentMethodType.cardToken;
          event.intPayMethodValue = this.selectedCardMethod.id;
          subscriber.next(event);
          subscriber.complete();
        } else {
          if (this.cardFormGroup.valid) {
            this.payuExpressService.getCardToken(this.paymentProviderConfig.salesPointId).subscribe({
              next: (token) => {
                event.intPayMethodType = PayUInternalPaymentMethodType.cardToken;
                event.intPayMethodValue = token;
                subscriber.next(event);
                subscriber.complete();
              },
              error: (e: HttpErrorResponse) => {
                console.error('error', e);
                this.events.next(new ErrorPaymentProviderEvent(e));
                event.abort = true;
              },
            });
          } else {
            event.abort = true;
            subscriber.next(event);
            subscriber.complete();
          }
        }
      }

      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_GPAY) {
        console.log('paymentProviderConfig', this.paymentProviderConfig);
        const googlePayClient: GooglePay.PaymentsClient = this.googlePayService.getClient(this.paymentProviderConfig.environment);
        const orderPrice: number = this.order.getPrice();
        const createPaymentRequestDataDto: CreatePaymentDataRequestDto = new CreatePaymentDataRequestDto();
        createPaymentRequestDataDto.merchantId = this.paymentProviderConfig.merchantId;
        createPaymentRequestDataDto.merchantName = this.paymentProviderConfig.merchantName;
        createPaymentRequestDataDto.gateway = 'payu';
        createPaymentRequestDataDto.gatewayMerchantId = this.paymentProviderConfig.salesPointId;
        createPaymentRequestDataDto.totalPriceStatus = PaymentTotalPriceStatus.FINAL;
        createPaymentRequestDataDto.totalPrice = FinancialHelper.fixedValue(orderPrice);
        createPaymentRequestDataDto.currencyCode = this.environment.constants.currency;
        createPaymentRequestDataDto.countryCode = this.environment.constants.country;
        createPaymentRequestDataDto.allowedCardAuthMethods = [PaymentAuthMethod.PAN_ONLY, PaymentAuthMethod.CRYPTOGRAM_3DS];
        createPaymentRequestDataDto.allowedCardMethods = [PaymentCardNetwork.VISA, PaymentCardNetwork.MASTERCARD];
        console.log('createPaymentRequestDataDto', cloneDeep(createPaymentRequestDataDto));
        const paymentDataRequest: PaymentDataModel = this.googlePayService.createPaymentDataRequest(createPaymentRequestDataDto);
        console.log('paymentDataRequest', cloneDeep(paymentDataRequest));
        googlePayClient
          .loadPaymentData(paymentDataRequest as any)
          .then((paymentData: PaymentData) => {
            console.log('paymentData', cloneDeep(paymentData));
            const token: string | null =
              paymentData &&
              paymentData.paymentMethodData &&
              paymentData.paymentMethodData.tokenizationData &&
              paymentData.paymentMethodData.tokenizationData.token
                ? paymentData.paymentMethodData.tokenizationData.token
                : null;
            event.intPayMethodType = PayUInternalPaymentMethodType.googlePayToken;
            event.intPayMethodValue = btoa(token as string);
            subscriber.next(event);
            subscriber.complete();
          })
          .catch((err) => {
            event.abort = true;
            this.events.next(new ErrorPaymentProviderEvent(err));
            subscriber.next(event);
            subscriber.complete();
          });
      }

      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_APAY) {
        const session = this.applePayService.getSession(this.paymentProviderConfig.merchantName, this.order.getPrice());

        session.onvalidatemerchant = () => {
          const applePaySessionRequestModel: ApplePaySessionRequestModel = new ApplePaySessionRequestModel(
            this.order.cinemaId,
            this.order.id,
            this.paymentProviderIdentifier
          );

          this.orderDataProvider.getApplePaySession(applePaySessionRequestModel).subscribe({
            next: (merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            },
            error: (error: any) => {
              event.abort = true;
              this.events.next(new ErrorPaymentProviderEvent(error));
              subscriber.next(event);
              subscriber.complete();

              session.abort();
            },
          });
        };

        session.onpaymentauthorized = (paymentEvent) => {
          session.completePayment({
            status: ApplePaySession.STATUS_SUCCESS
          });

          const token: string | null =
            paymentEvent && paymentEvent.payment && paymentEvent.payment.token && paymentEvent.payment.token.paymentData
              ? JSON.stringify(paymentEvent.payment.token.paymentData) : null;
          event.intPayMethodType = PayUInternalPaymentMethodType.applePayToken;
          event.intPayMethodValue = btoa(token as string);
          subscriber.next(event);
          subscriber.complete();
        };

        session.begin();
      }
    });
  }

  private getPayMethod(id: string) {
    return this.paymentProviderPayMethodCollection.find((x) => x.id && x.id.toLocaleLowerCase() === id);
  }

  private getTokenPayMethod(type: string) {
    return this.paymentProviderPayMethodCollection.find((x) => x.type && x.type.toLocaleLowerCase() === type);
  }

  public getBLIKPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('blik');
  }

  public getBLIKTokenPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getTokenPayMethod('blik');
  }

  public getCardPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('c');
  }

  public getGooglePayPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('ap');
  }

  public getApplePayPayMethod(): PaymentProviderPayMethodViewModel | null {
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      return this.isApplePaySessionDefined && this.getPayMethod('jp');
    }
  }

  public getPayByLinkPayMethodCollection(): Array<PaymentProviderPayMethodViewModel> {
    return this.paymentProviderPayMethodCollection.filter((x) => x.type === 'PBL' && x.id !== 'ap' && x.id !== 'c' && x.id !== 'blik' && x.id != 'jp');
  }

  public getCardMethodCollection(): Array<PaymentProviderPayMethodViewModel> {
    return this.paymentProviderPayMethodCollection.filter((x) => x.type && x.type.toLocaleLowerCase() === 'card_token' && x.isPreferred);
  }

  public onClickedPayByLinkMethod(paymentProviderPayMethod: PaymentProviderPayMethodViewModel): void {
    this.selectedPayByLinkMethod = paymentProviderPayMethod;
    const state = this.selectedPaymentTypeService.getState();
    this.selectedPaymentTypeService.setState({ ...state, payByLink: paymentProviderPayMethod });
  }

  public onClickedCardMethod(paymentProviderPayMethod: PaymentProviderPayMethodViewModel): void {
    if (this.selectedCardMethod !== paymentProviderPayMethod) {
      this.selectedCardMethod = paymentProviderPayMethod;
    } else {
      this.selectedCardMethod = null;
    }
  }

  private initPaymentProviderWidget(): void {
    const paymentProviderConfigRequest: PaymentProviderConfigRequestModel = new PaymentProviderConfigRequestModel(
      this.order.cinemaId,
      this.order.id,
      this.paymentProviderIdentifier,
      this.paymentChannel
    );

    const paymentProviderPayMethodRequest: PaymentProviderPayMethodRequestModel = new PaymentProviderPayMethodRequestModel(
      this.order.cinemaId,
      this.order.id,
      this.paymentProviderIdentifier
    );

    const fork = forkJoin([
      this.orderDataProvider.getPaymentProviderConfig(paymentProviderConfigRequest),
      this.orderDataProvider.getPaymentProviderPayMethodCollection(paymentProviderPayMethodRequest),
    ]);

    this.paymentProviderInitSubscription = fork.subscribe({
      next: ([paymentProviderConfigModel, paymentProviderPayMethodCollection]) => {
        this.paymentProviderConfig = paymentProviderConfigModel;
        this.paymentProviderPayMethodCollection = paymentProviderPayMethodCollection;

        this.payByLinkMethodCollection = this.getPayByLinkPayMethodCollection();
        this.cardMethodCollection = this.getCardMethodCollection();

        this.events.next(new DonePaymentProviderEvent());
      },
      error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
    });
  }

  private sanitizeInput(input: string | null): string | null {
    let c: string | null = input;
    if (c !== null) {
      c = input.trim();
    }
    return c;
  }

  canDisplayError(control: string) {
    const cfg = this.cardFormGroup.get(control);
    if (!cfg || !cfg.errors) return false;

    return !cfg.valid && ((cfg.touched && cfg.dirty) || this.formSubmitAttempt2);
  }
  canDisplayErrorGroup() {
    return !this.cardFormGroup.valid && ((this.cardFormGroup.touched && this.cardFormGroup.dirty) || this.formSubmitAttempt2);
  }

  get getDefaultPaymentImageUrl() {
    return `/assets/images/PAYU_LOGO_LIME.png`;
  }
}
