import { TicketViewModel } from '../../../../view-model/shared/ticket/ticket.view.model';
import { TicketListSortStrategy } from './ticket-list-sort.strategy';

export class TicketListSortPriceDescendingStrategy implements TicketListSortStrategy {
  public sort(ticketList: Array<TicketViewModel>): Array<TicketViewModel> {
    return ticketList?.sort((a, b) => {
      return b.price - a.price;
    });
  }
}
