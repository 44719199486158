import { Expose } from 'class-transformer';

export class VoucherRedeemDayOfScreeningApiModel {
  @Expose()
  dayOfWeek: number;

  @Expose()
  flgDayRedeemable: boolean;

  @Expose()
  redeemUpToMinutesBeforeScreening: number;
}
