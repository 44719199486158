import {Pipe, PipeTransform} from '@angular/core';
import sortBy from 'lodash-es/sortBy'

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(array: Array<any>, args?: any): any {
      return sortBy(array, args);
    }

}
