import { TicketViewModel } from '../../../../view-model/shared/ticket/ticket.view.model';
import { DefaultTicketStrategy } from './default-ticket.strategy';

/**
 * Gets the ticket by the lowest price
 */
export class DefaultTicketFirstPriceAscendingStrategy implements DefaultTicketStrategy {
  public getDefaultTicket(ticketList: Array<TicketViewModel>): TicketViewModel | null {
    const sorted: Array<TicketViewModel> = ticketList.sort((a, b) => {
      return a.price - b.price;
    });

    for (const ticket of sorted) {
      if (ticket && ticket.price) {
        return ticket;
      }
    }

    return null;
  }
}
