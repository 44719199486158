import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserEventItemApiModel } from '../../../api-model/user-history/event/user-event-item.api.model';

export class UserEventItemViewModel extends AbstractViewModel<UserEventItemApiModel> {
  public eventItemDateFrom: DateTime = null;
  public eventItemTimeTo: DateTime = null;
  public movieId: string = null;
  public moviePrintId: string = null;
  public movieName: string = null;
  public screenId: string = null;
  public moviePrintType: string = null;
  public movieSpeakingType: string = null;

  constructor(protected apiModel: UserEventItemApiModel = new UserEventItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.eventItemDateFrom = this.apiModel.eventItemDateFrom ? DateTime.fromISO(this.apiModel.eventItemDateFrom, { setZone: true }) : null;
    this.eventItemTimeTo = this.apiModel.eventItemTimeTo ? DateTime.fromISO(this.apiModel.eventItemTimeTo, { setZone: true }) : null;
    this.movieId = this.apiModel.movieId;
    this.moviePrintId = this.apiModel.moviePrintId;
    this.movieName = this.apiModel.movieName;
    this.screenId = this.apiModel.screenId;
    this.moviePrintType = this.apiModel.moviePrintType;
    this.movieSpeakingType = this.apiModel.movieSpeakingType;
  }

  toApiModel(): UserEventItemApiModel {
    return undefined;
  }
}
