import {Expose} from 'class-transformer';

export class ScreenApiModel {
  @Expose()
  name: string;

  @Expose()
  feature: string;

  @Expose()
  number: number;
}
