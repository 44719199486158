import { AbstractViewModel } from "../../abstract.view.model";
import { LanguageApiModel } from "../../api-model/language/language.api.model";

export class LanguageViewModel extends AbstractViewModel<LanguageApiModel> {
  id: string;
  name: string;
  symbol: string;
  default: boolean;

  constructor(protected apiModel: LanguageApiModel = new LanguageApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.symbol = this.apiModel.symbol;
    this.default = this.apiModel.default;
  }

  toApiModel(): LanguageApiModel {
    return undefined;
  }
}
