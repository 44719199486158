import { Component, Inject, Input, OnInit } from '@angular/core';
import { ENVIRONMENT_TOKEN, makeUrl } from '@lib/core';

@Component({
  selector: 'app-age-rating',
  templateUrl: './age-rating.component.html',
})
export class AgeRatingComponent implements OnInit {
  path: string;

  @Input() icon: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment) {}

  ngOnInit(): void {
    this.path = makeUrl(this.environment, `${this.environment.theme.assetsDir}/images/age-rating/${this.icon}.png`);
  }
}
