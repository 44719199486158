import {Expose} from 'class-transformer';

export class VoucherPositionApiModel {
  @Expose()
  ticketId: string;

  @Expose()
  ticketName: string;

  @Expose()
  articleId: string;

  @Expose()
  articleName: string;

  @Expose()
  price: number;

  @Expose()
  newPrice: number;

  @Expose()
  discount: number;
}
