<div [smooth-in]="cards.length > 0">
  <app-tabs (tabChange)="tabChange()">
    <ng-container *ngFor="let card of cards">
      <app-tab [title]="card.name" (activated)="selectedCard = card">
        <app-choice-slider [items]="findAvailableGraphic(card.id)"
                           [(selectedItem)]="selectedTemplate"></app-choice-slider>
      </app-tab>
    </ng-container>
  </app-tabs>

  <div class="row mb-2 mb-md-3 d-lg-flex px-3">
    <div class="col-12 col-md-6 preview w-360 rounded-lg d-flex justify-content-center align-items-start py-md-0">
      <img pimg [src]="selectedTemplate?.graphic" *ngIf="selectedTemplate" class="rounded" format="card"
           data-origin="{{selectedTemplate?.graphic}}">
    </div>

    <div class="col-12 col-md-6 px-5 py-3 py-lg-0 value-panel d-flex flex-column gap-2">
      <div class=" rounded my-2">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="m-0 font-weight-bold">
            {{ 'shared.value' | translate }}
          </h6>
          <div class="d-flex align-items-center">
            <h5 class="font-weight-bold mb-0 line-height-1">{{ environment.constants.currency }}</h5>
            <input type="text" class="text-center ml-2 form-control form-control-bigger" [(ngModel)]="value" mask="000"
                   [disabled]="possibleValues?.length > 0" [size]="2">
          </div>
        </div>
        <div *ngIf="possibleValues?.length === 0" class="text-center value-slider mt-2">
          <mv-slider [(value)]="value" [min]="defaultMin" [max]="defaultMax"></mv-slider>
          <div class="pt-4 d-flex justify-content-between align-items-center">
            <h6 class="m-0 font-weight-bold">
              {{ 'shared.quantity' | translate }}
            </h6>
            <div class="">
              <app-numeric-stepper (quantityEvent)="quantityEvent($event)"></app-numeric-stepper>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 rounded my-2">
        <h7 class="m-0">{{'gift-card.sendForm.title' | translate }}</h7>
        <form class="d-flex flex-column gap-2" [formGroup]="sendForm" (submit)="addToCart()">

          <div class="col form-check form-check-inline m-0">
            <label class="radio-container m-0 my-auto">{{'gift-card.sendForm.me' | translate }}
              <input type="radio" name="type" formControlName="type" [value]="sendGiftCardTypeEnum.ForMe">
              <span class="checkmark"></span>
            </label>
            <label class="radio-container ml-4 my-auto">{{'gift-card.sendForm.somebody' | translate }}
              <input type="radio" name="type" formControlName="type" [value]="sendGiftCardTypeEnum.ForSomebodyElse">
              <span class="checkmark"></span>
            </label>
            <div class="help-block with-errors text-right ml-auto"
                 *ngIf="sendForm.get('type').touched && sendForm.get('type').invalid">
              <p *ngIf="sendForm.get('type')?.errors?.required" class="m-0">
                {{ "errors.10015" | translate }}
              </p>
            </div>
          </div>

          <ng-container *ngIf="sendForm.get('type').value === 1">
            <span [innerHTML]="'gift-card.sendForm.description' | translate"></span>
          </ng-container>

          <ng-container *ngIf="sendForm.get('type').value === 2">
            <div>
              <div class="form-group required w-100">
                <div class="d-flex align-items-center">
                  <label for="email" class="m-0">{{ "login.form.email" | translate }}</label>
                  <div class="col-10 pr-0 ml-auto">
                    <input type="text" class="form-control" id="email" formControlName="email" letterOnly
                           excludepattern="[^A-Za-z]*" />
                  </div>
                </div>
                <div class="help-block with-errors col-12 text-right"
                     *ngIf="sendForm.get('email').touched && sendForm.get('email').invalid">
                  <p *ngIf="sendForm.get('email')?.errors?.required">
                    {{ "errors.10005" | translate }}
                  </p>
                  <p *ngIf="sendForm.get('email')?.errors?.invalid">
                    {{ "errors.10102" | translate }}
                  </p>
                </div>
              </div>

              <div class="form-group required w-100">
                <div class="d-flex align-items-center">
                  <label for="emailRepeat" class="m-0">{{ "personal.personal.form.emailRepeat" | translate }}</label>
                  <div class="col-10 pr-0 ml-auto">
                    <input type="text" class="form-control" id="emailRepeat" formControlName="emailRepeat" letterOnly
                           excludepattern="[^A-Za-z]*" />
                  </div>
                </div>
                <div class="help-block with-errors col-12 text-right"
                     *ngIf="sendForm.get('emailRepeat').touched && sendForm.get('emailRepeat').invalid">
                  <p *ngIf="sendForm.get('emailRepeat')?.errors?.required">
                    {{ "errors.10005" | translate }}
                  </p>
                  <p *ngIf="sendForm.get('emailRepeat')?.errors?.notMatch">
                    {{ "errors.10104" | translate }}
                  </p>
                </div>
              </div>

              <div class="form-group w-100">
                <div class="d-flex align-items-center">
                  <label for="message" class="m-0">{{ "gift-card.sendForm.message" | translate }}</label>
                  <div class="col-10 pr-0 ml-auto">
                    <textarea class="form-control" id="message" formControlName="message"
                              placeholder="{{ 'gift-card.sendForm.messagePlaceholder' | translate }}"
                              rows="3"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </form>
      </div>
      <div class="rounded bottom-row pt-2">
        <div class="d-flex justify-content-end gap-1">
          <button class="btn btn-next px-2 px-lg-4" (click)="goToCart()" *ngIf="orderWithCards()">
            {{ 'shared.goToCart' | translate }}
          </button>

          <button class="btn btn-next px-2 px-lg-4" (click)="addToCart()">
            {{ 'shared.addToCart' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-popup [isVisible]="error !== undefined">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{'gift-card.cannotAddCard' | translate }}</h4>
      <div class="question">
        {{error?.message}}
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="error = undefined">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>