import { AbstractViewModel } from './abstract.view.model';
import { PickupTimeApiModel } from './api-model/pickup-time.api.model';

export class PickupTimeViewModel extends AbstractViewModel<PickupTimeApiModel> {
  id: string;
  time: number;

  constructor(protected apiModel: PickupTimeApiModel = new PickupTimeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.time = this.apiModel.time;                
  }

  toApiModel(): PickupTimeApiModel {
    return undefined;
  }
}
