import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ScreeningApiModel } from '../../api-model/screening/screening.api.model';

export class ScreeningViewModel extends AbstractViewModel<ScreeningApiModel> {
  id: string;
  regionId: string;
  cinemaId: string;
  screenId: string;
  moviePrintId: string;
  movieExternalId: string;
  moviePrintExternalId: string;
  screeningTimeFrom: DateTime;
  screeningTimeTo: DateTime;
  screeningDuration: number;
  audience: number;
  generalAdmission: boolean;
  saleTimeTo: DateTime;
  reservationTimeTo: DateTime;
  isScreenSwapping: boolean;
  availabilityStatus: number;
  maxOccupancy: number;
  movieId: string;
  printType: string;
  speakingType: string;
  language: string;
  subtitles: string;
  subtitles2: string;
  soundType: string;
  release: string;
  format: string;
  resolution: string;
  frameRate: string;

  constructor(protected apiModel: ScreeningApiModel = new ScreeningApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.regionId = this.apiModel.regionId;
    this.cinemaId = this.apiModel.cinemaId;
    this.screenId = this.apiModel.screenId;
    this.moviePrintId = this.apiModel.moviePrintId;
    this.movieExternalId = this.apiModel.movieExternalId;
    this.moviePrintExternalId = this.apiModel.moviePrintExternalId;
    this.screeningTimeFrom = this.apiModel.screeningTimeFrom ? DateTime.fromISO(this.apiModel.screeningTimeFrom, { setZone: true }) : null;
    this.screeningTimeTo = this.apiModel.screeningTimeTo ? DateTime.fromISO(this.apiModel.screeningTimeTo, { setZone: true }) : null;
    this.screeningDuration = this.apiModel.screeningDuration;
    this.audience = this.apiModel.audience;
    this.generalAdmission = this.apiModel.generalAdmission;
    this.saleTimeTo = this.apiModel.saleTimeTo ? DateTime.fromISO(this.apiModel.saleTimeTo, { setZone: true }) : null;
    this.reservationTimeTo = this.apiModel.reservationTimeTo ? DateTime.fromISO(this.apiModel.reservationTimeTo, { setZone: true }) : null;
    this.isScreenSwapping = this.apiModel.isScreenSwapping;
    this.availabilityStatus = this.apiModel.availabilityStatus;
    this.maxOccupancy = this.apiModel.maxOccupancy;
    this.movieId = this.apiModel.movieId;
    this.printType = this.apiModel.printType;
    this.speakingType = this.apiModel.speakingType;
    this.language = this.apiModel.language;
    this.subtitles = this.apiModel.subtitles;
    this.subtitles2 = this.apiModel.subtitles2;
    this.soundType = this.apiModel.soundType;
    this.release = this.apiModel.release;
    this.format = this.apiModel.format;
    this.resolution = this.apiModel.resolution;
    this.frameRate = this.apiModel.frameRate;
  }

  toApiModel(): ScreeningApiModel {
    return undefined;
  }
}
