import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WrapMissingTranslationHandler } from 'libs/core/src/lib/handler/wrap-missing-translation-handler';
import { SharedLibraryModule } from 'libs/shared/src/public-api';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpLoaderFactory, SocialAuthServiceConfigFactory } from './config/config.provider';
import { CustomCoreComponent } from './component/core/core.component';
import { CustomLayoutComponent } from './component/layout/layout/layout.component';
import { CustomHeaderComponent } from './component/layout/header/header.component';
import { CustomCountdownComponent } from './component/countdown/countdown.component';
import { CustomFooterComponent } from './component/layout/footer/footer.component';
import { CustomScreeningPageComponent } from './page/screening/screening.component';
import { CustomScreeningSelectDateComponent } from './component/screening/select-date/select-date.component';
import { CustomScreeningSelectCinemaComponent } from './component/screening/select-cinema/select-cinema.component';
import { CustomScreeningListComponent } from './component/screening/list/list.component';
import { CustomScreeningListItemComponent } from './component/screening/list/component/item/item.component';
import { CustomScreenTagComponent } from './component/screen/screen-tag/screen-tag.component';
import { CustomStepsComponent } from './component/steps/steps.component';
import { CustomBasketPageComponent } from './page/basket/basket.component';
import { CustomBookMovieListComponent } from './component/book-movie-list/book-movie-list.component';
import { CustomSidebarComponent } from './component/layout/template/sidebar/sidebar.component';
import { CustomBasketListComponent } from './component/basket/basket-list/basket-list.component';
import { CustomOrderTicketsSummaryComponent } from './component/order/tickets-summary/tickets-summary.component';
import { CustomScreenNavigationComponent } from './component/screen/navigation/navigation.component';
import { CustomOrderSummaryPageComponent } from './page/order-summary/order-summary.component';
import { CustomOrderSummaryComponent } from './component/order/order-summary/order-summary.component';
import { CustomScreenPageComponent } from './page/screen/screen.component';
import { CustomMessagePageComponent } from './page/message/message.component';
import { CustomScreenSeatsComponent } from './component/screen/seats/seats.component';
import { CustomAppScreenSeatsMultiSeatComponent } from './component/screen/seats/component/multiseat/multiseat.component';
import { CustomScreenSeatsSeatComponent } from './component/screen/seats/component/seat/seat.component';
import { CustomScreenSeatsSelectedListComponent } from './component/seats-selected-list/seats-selected-list.component';
import { CustomScreenLegendComponent } from './component/screen/legend/legend.component';
import { CustomPaymentPageComponent } from './page/payment/payment.component';
import { CustomPersonalComponent } from './component/personal/personal.component';
import { CustomLoadingComponent } from './component/loading/loading.component';
import { CustomDropdownComponent } from './component/ui/dropdown/dropdown.component';
import { CustomPolicyComponent } from './component/policy/policy.component';
import { CustomUserStatusComponent } from './component/user/user-status/status.component';
import { CustomLoginComponent } from './component/login/login.component';
import { CustomPersonalInformationComponent } from './component/personal/personal-information/personal-information.component';
import { CustomMovieAttributesComponent } from './component/screening/movie-attributes/movie-attributes.component';
import { CustomPersonalPageComponent } from './page/personal/personal.component';
import { ToastrModule } from 'ngx-toastr';
import { CustomUserPageComponent } from './page/user/user-page.component';
import { CustomUserProfileComponent } from './component/user/user-profile/user-profile.component';
import { CustomUserLoyaltyComponent } from './component/user/user-loyalty/user-loyalty.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomUserUpcomingComponent } from './component/user/user-upcoming/user-upcoming.component';
import { CustomUserHistoryComponent } from './component/user/user-history/user-history.component';
import { CustomChecklistComponent } from './component/ui/checklist/checklist.component';
import { CustomVoucherCardComponent } from './component/voucher/voucher-card/voucher-card.component';
import { CustomVoucherDetailsComponent } from './component/voucher/voucher-details/voucher-details.component';
import { CustomVoucherSelectorComponent } from './component/voucher/voucher-selector/voucher-selector.component';
import { CustomVoucherCardSummaryComponent } from './component/voucher/voucher-card/component/voucher-card-summary/voucher-card-summary.component';
import { CustomVoucherCardInputComponent } from './component/voucher/voucher-card/component/voucher-card-input/voucher-card-input.component';
import { CustomDateComponent } from './component/ui/date/date.component';
import { CustomAccountItemsComponent } from './component/account-items/account-items.component';
import { CustomGiftCardsPageComponent } from './page/gift-cards/gift-cards-page.component';
import { CustomRefundPageComponent } from './page/refund/refund.component';
import { CustomRefundComponent } from './component/refund/refund.component';
import { CustomRefundModalComponent } from './component/modal/refund/refund/refund.component';
import { CustomRefundFailModalComponent } from './component/modal/refund/refund-fail/refund-fail.component';
import { CustomRefundSuccessModalComponent } from './component/modal/refund/refund-success/refund-success.component';
import { CustomModalComponent } from './component/modal/modal.component';
import { CustomVirtualPassComponent } from './component/order/order-virtual-pass/order-virtual-pass.component';
import { CustomLoyaltyInputComponent } from './component/loyalty/loyalty-input/loyalty-input.component';
import { CustomCardInputComponent } from './component/loyalty/card/card-input/card-input.component';
import { CustomVoucherInputComponent } from './component/loyalty/voucher/voucher-input/voucher-input.component';
import { CustomUserRegisterComponent } from './page/user/register/user-register.component';
import { CustomLoyaltyPaymentListComponent } from './component/loyalty/loyalty-payment-list/loyalty-payment-list.component';
import { CustomAccountItemsVouchersComponent } from './component/account-items/account-items-vouchers/account-items-vouchers.component';
import { CustomAccountItemsCardsComponent } from './component/account-items/account-items-cards/account-items-cards.component';
import { CustomAccountItemsVouchersListComponent } from './component/account-items/account-items-vouchers/account-items-vouchers-list/account-items-vouchers-list.component';
import { CustomAccountItemsCardsListComponent } from './component/account-items/account-items-cards/account-items-cards-list/account-items-cards-list.component';
import { CustomUserLoyaltyPopupsComponent } from './component/user/user-loyalty/user-loyalty-popups/user-loyalty-popups.component';
import { CustomWorldpayRedirectHubComponent } from './page/worldpay/worldpay-redirect-hub.component';
import { CustomSimpleCountdownComponent } from './component/simple-countdown/simple-countdown.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import { CustomTicketsPageComponent } from './page/tickets/tickets.component';
import { CustomTicketCountGeneralAdmissionComponent } from './component/screen/ticket-count-general-admission/ticket-count-general-admission.component';
import { CoreLibraryModule, storageKey } from '@lib/core';
import { DeviceService } from 'libs/core/src/lib/service/device/device.service';

function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}
defineLocales();

export function localeIdFactory(translate: TranslateService) {
  const locale = translate.currentLang || sessionStorage.getItem(storageKey.lang);
  const item =
    environment.constants.language.cultureMap.find((o) => o.key === locale) ||
    environment.constants.language.cultureMap.find((o) => o.key === environment.constants.language.default);
  return item?.value ?? locale;
}

@NgModule({
  declarations: [
    AppComponent,
    CustomCoreComponent,
    CustomLayoutComponent,
    CustomHeaderComponent,
    CustomCountdownComponent,
    CustomFooterComponent,
    CustomSidebarComponent,
    CustomScreeningPageComponent,
    CustomScreeningSelectDateComponent,
    CustomScreeningSelectCinemaComponent,
    CustomScreeningListComponent,
    CustomScreeningListItemComponent,
    CustomScreenTagComponent,
    CustomScreenPageComponent,
    CustomMessagePageComponent,
    CustomStepsComponent,
    CustomScreenNavigationComponent,
    CustomScreenSeatsComponent,
    CustomScreenSeatsSeatComponent,
    CustomAppScreenSeatsMultiSeatComponent,
    CustomScreenSeatsSelectedListComponent,
    CustomScreenLegendComponent,
    CustomBasketPageComponent,
    CustomBookMovieListComponent,
    CustomBasketListComponent,
    CustomOrderTicketsSummaryComponent,
    CustomPaymentPageComponent,
    CustomPersonalComponent,
    CustomOrderSummaryPageComponent,
    CustomOrderSummaryComponent,
    CustomLoadingComponent,
    CustomDropdownComponent,
    CustomPolicyComponent,
    CustomUserStatusComponent,
    CustomPersonalInformationComponent,
    CustomLoginComponent,
    CustomMovieAttributesComponent,
    CustomPersonalPageComponent,
    CustomUserPageComponent,
    CustomUserProfileComponent,
    CustomUserLoyaltyComponent,
    CustomUserUpcomingComponent,
    CustomUserHistoryComponent,
    CustomChecklistComponent,
    CustomVoucherCardComponent,
    CustomVoucherDetailsComponent,
    CustomVoucherSelectorComponent,
    CustomVoucherCardSummaryComponent,
    CustomVoucherCardInputComponent,
    CustomDateComponent,
    CustomAccountItemsComponent,
    CustomGiftCardsPageComponent,
    CustomModalComponent,
    CustomRefundComponent,
    CustomRefundPageComponent,
    CustomRefundModalComponent,
    CustomRefundFailModalComponent,
    CustomRefundSuccessModalComponent,
    CustomVirtualPassComponent,
    CustomLoyaltyInputComponent,
    CustomCardInputComponent,
    CustomVoucherInputComponent,
    CustomUserRegisterComponent,
    CustomLoyaltyPaymentListComponent,
    CustomAccountItemsVouchersComponent,
    CustomAccountItemsCardsComponent,
    CustomAccountItemsVouchersListComponent,
    CustomAccountItemsCardsListComponent,
    CustomUserLoyaltyPopupsComponent,
    CustomWorldpayRedirectHubComponent,
    CustomSimpleCountdownComponent,
    CustomTicketsPageComponent,
    CustomTicketCountGeneralAdmissionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreLibraryModule.forRootWithInterceptors(environment),
    SharedLibraryModule.forRoot(environment, [
      {
        provide: 'SocialAuthServiceConfig',
        useFactory: SocialAuthServiceConfigFactory,
      },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: WrapMissingTranslationHandler },
    }),
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [TranslateService],
    },
    DeviceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
