import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { FormControl } from '@angular/forms';
import orderBy from 'lodash-es/orderBy';
import { RecaptchaComponent } from 'ng-recaptcha';
import { storageKey } from 'libs/core/src/app.const';
import { VoucherErrorEnum } from 'libs/core/src/lib/model/component/basket/voucher-error.enum';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { CateringAggregationArticleViewModel } from 'libs/core/src/lib/model/catering/catering-aggregation.view.model';
import { ScreenTag } from 'libs/core/src/lib/model/screen-tag.model';
import { IVoucher, OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { SeatTranslatorService } from 'libs/core/src/lib/service/seat/seat-translator.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { VirtualCinemaService } from 'libs/core/src/lib/service/virtual-cinema/virtual-cinema.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { ENVIRONMENT_TOKEN, LocationStateService } from 'libs/core/src/public-api';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { OrderSummaryViewModel } from 'libs/core/src/lib/model/page/order/order-summary.view.model';
import { OrderSummaryBuilder } from 'libs/core/src/lib/model/page/order/order-summary.builder';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { OrderSummaryGiftCardItemViewModel } from 'libs/core/src/lib/model/page/order/order-summary-gift-card-item.view.model';
import { PaymentMethodViewModel } from 'libs/core/src/lib/model/view-model/order/payment-method/payment-method.view.model';
import { UseCardRequestModel } from 'libs/core/src/lib/model/request/use-card.request.model';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { FinancialHelper } from '@lib/core';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { cloneDeep } from 'lodash-es';

@Component({
  template: '',
})
export class OrderSummaryComponent implements OnInit {
  public orderSummaryView: OrderSummaryViewModel = null;
  public today = DateTime.local();
  public isDream = false;
  public voucherForm = new FormControl();
  public isLoaded = false;

  public taxId: string;
  public voucherErrorMessage: string = null;
  public screenTag: ScreenTag;
  public modalRef: BsModalRef;

  captchaResponse: string = null;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  @Input()
  public set screen(screen: ScreenViewModel) {
    this._screen = screen;
  }

  public get screen(): ScreenViewModel {
    return this._screen;
  }

  @Input() public isLastPage = false;
  @Input() buttonText: string;
  @Input() showSeatsByTicketType = false;
  @Input() public footerTemplate: TemplateRef<any> = null;
  @Input() public parentContext = '';
  @Input() public voucherErrors: VoucherErrorEnum[] = null;
  @Input() submitButtonShow = true;
  @Input() showSimpleTicketSummary = false;
  @Input() showCaptcha = false;
  @Input() pickupTime: string = null;

  @Output() nextClickButtonEvent = new EventEmitter();
  @Output() loadingEvent = new EventEmitter();
  @Output() checkVoucherItem = new EventEmitter();
  @Output() calculatedSummary = new EventEmitter<OrderSummaryViewModel>();

  private _screen: ScreenViewModel = null;
  private _screening: ScreeningViewModel = null;
  private _groupedSeats = [];
  private _showSubtitlesLabel = false;
  private _vouchers: IVoucher[];

  flowType: FlowType;
  flowTypeEnum: typeof FlowType = FlowType;

  public orderSummaryViewItems;

  private buildOrderSummaryViewDebouncer: Subject<void> = new Subject<void>();

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected translateService: TranslateService,
    protected virtualCinemaService: VirtualCinemaService,
    protected totalizerService: TotalizerService,
    protected orderStateService: OrderStateService,
    protected appService: AppService,
    protected voucherService: VoucherService,
    protected messageService: MessageService,
    protected dateTimeService: DateTimeService,
    protected modalService: BsModalService,
    protected loadingService: LoadingService,
    protected orderDataProvider: OrderDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected locationStateService: LocationStateService,
    protected stepsService: StepsService
  ) {
    this.flowType = stepsService.FlowType;
    this.buildOrderSummaryViewDebouncer.pipe(debounceTime(100)).subscribe(() => this.buildOrderSummaryView());
  }

  private _order: OrderViewModel = null;

  get order(): OrderViewModel {
    return this._order;
  }

  @Input()
  set order(value: OrderViewModel) {
    this._order = value;

    this.buildOrderSummaryViewDebouncer.next();
  }

  ticketList: TicketViewModel[] = [];
  screeningId: string | null;

  private _selectedVoucherList: Array<VoucherTypeModel> = null;
  get selectedVoucherList(): Array<VoucherTypeModel> {
    return this._selectedVoucherList;
  }

  @Input()
  set selectedVoucherList(value: Array<VoucherTypeModel>) {
    this._selectedVoucherList = value;
    this.buildOrderSummaryViewDebouncer.next();
  }

  private _selectedCateringArticleCombinationList: Array<CateringAggregationArticleViewModel> = null;
  get selectedCateringArticleCombinationList(): Array<CateringAggregationArticleViewModel> {
    return this._selectedCateringArticleCombinationList;
  }

  @Input()
  set selectedCateringArticleCombinationList(value: Array<CateringAggregationArticleViewModel>) {
    this._selectedCateringArticleCombinationList = value;
    this.buildOrderSummaryViewDebouncer.next();
  }

  @Input()
  set basketPageModel(value: BasketPageModel | null) {
    console.log('basketPageModel:', value);
    if (!value) {
      return;
    }

    this._screen = value.screen;
    this._screening = value.screening;
    this.screeningId = value.screening?.id ?? null;
    this.ticketList = value.tickets;

    this.buildOrderSummaryViewDebouncer.next();
  }

  @Input() public summaryPage = false;

  ngOnInit() {
    this.isDream = 'true' === localStorage.getItem(storageKey.isDream);
    this.setScreenTag();
    this.hasSubtitles();
    this.simpleTicketsData();
    this.loadingEvent.emit(true);

    this.taxId = this.orderStateService.getItem(storageKey.personalTaxId);
    this.orderStateService.removePersonalTaxId();

    this.orderStateService.voucher$.subscribe((data) => {
      if (this.voucherForm && !(data && data.length && this.voucherForm)) {
        this.voucherForm.reset();
      }
    });

    this.orderStateService.summaryState$.pipe(debounceTime(200)).subscribe(() => {
      this.buildOrderSummaryViewDebouncer.next();
    });

    this.orderStateService.state$.subscribe((value) => {
      if (value) {
        this._order = value;
      }

      this.buildOrderSummaryViewDebouncer.next();
    });
  }

  private setOrderIntoComponent(value) {
    this._order = value;
    if (!value) {
      return;
    }

    if (!this.pickupTime) {
      let pickupTime: DateTime;

      if (value.pickupTime) {
        pickupTime = value.pickupTime;
      } else if (value.fbItems.length > 0) {
        const cateringItem = value.fbItems.find((item) => Boolean(item.pickupTime));

        if (cateringItem) {
          pickupTime = cateringItem.pickupTime;
        }
      }

      this.pickupTime = pickupTime ? pickupTime.toFormat('hh:mm a') : null;
    }

    if (value && value.screeningItems) {
      const hasVouchers = value.screeningItems.some((it) => it.hasVoucher());
      if (!hasVouchers) {
        this.voucherForm.reset();
      }
    }
  }

  simpleTicketsData() {
    if (!this.summaryPage) {
      const seats = this.getSeats(this.order?.screeningItems);
      if (seats) {
        this._groupedSeats = seats;
      }
    }
  }

  getSeatsByTicketId(ticketId: string, vouchered: boolean = false) {
    const orderItemsByTicketId = this.order.screeningItems.filter((c) => c.ticketId === ticketId && c.hasVoucher() === vouchered);
    return this.getSeats(orderItemsByTicketId);
  }

  nextButtonClick() {
    if (this.captchaRef && !this.checkCaptcha()) {
      this.captchaRef.execute();
      return;
    }

    this.nextClickButtonEvent.emit();
  }

  checkCaptcha() {
    return this.captchaResponse && this.captchaResponse.length > 0;
  }

  captchaResolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (this.checkCaptcha()) {
      this.nextButtonClick();
    }
  }

  public getScreenName(): string {
    return this.orderSummaryView?.screen?.name?.toUpperCase().replace('- DREAM', '');
  }

  getTotalOptionalExtraFee(ext: string = ''): number {
    let sum = 0.0;
    this.orderSummaryView?.optionalExtraFees.forEach((item) => {
      sum += item.price * this.order.getCountOptionalFeeByGroupFee(item.id);
    });

    this.totalizerService.setOptionalExtraFeePrice(sum);
    return sum;
  }

  getTotalExtraFee(): number {
    let sum = 0.0;
    this.orderSummaryView?.requireExtraFees.forEach((item) => {
      sum += item.price * item.limit;
    });
    return sum;
  }

  hasSubtitles(): void {
    if (this.orderSummaryView?.screen != null) {
      this.orderSummaryView.screen.movieSubtitles.forEach((s) => {
        if (s.length > 0) {
          this._showSubtitlesLabel = true;
        }
      });
    }
  }

  submitVoucherForm(): void {
    const voucherNumber = this.voucherForm.value;
    this.voucherForm.reset();
    this.checkVoucherItem.emit(voucherNumber);
  }

  public buildOrderSummaryView(): void {
    const order = this._order ?? this.orderStateService.getOrder();
    if (!order) {
      return;
    }

    this._order = order;

    if (!this.pickupTime) {
      let pickupTime: DateTime;

      if (order.pickupTime) {
        pickupTime = order.pickupTime;
      } else if (order.fbItems.length > 0) {
        const cateringItem = order.fbItems.find((item) => Boolean(item.pickupTime));

        if (cateringItem) {
          pickupTime = cateringItem.pickupTime;
        }
      }

      this.pickupTime = pickupTime ? pickupTime.toFormat('hh:mm a') : null;
    }

    if (order && order.screeningItems) {
      const hasVouchers = order.screeningItems.some((it) => it.hasVoucher());
      if (!hasVouchers) {
        this.voucherForm.reset();
      }
    }

    const selectedVoucherList: Array<VoucherTypeModel> = this._selectedVoucherList ? this._selectedVoucherList : [];

    const selectedCateringArticleCombinationList: Array<CateringAggregationArticleViewModel> = this._selectedCateringArticleCombinationList
      ? this._selectedCateringArticleCombinationList.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      : new Array<CateringAggregationArticleViewModel>();

    const orderSummaryView = new OrderSummaryBuilder(
      this.environment,
      this.appService,
      this.translateService,
      this._order,
      this.ticketList,
      selectedVoucherList,
      selectedCateringArticleCombinationList,
      this.screeningId,
      this._screen,
      this._screening
    ).getOrderSummary();

    this.orderSummaryViewItems = orderSummaryView.items;
    this.orderSummaryView = orderSummaryView;

    const totalValue = this.order?.totalValue ?? 0;

    if (this.order && this.order.defaultExtraFees.length) {
      this.orderSummaryView.totalPrice += this.order.defaultExtraFees[0].defaultExtraFeePrice;
    }

    if (FinancialHelper.fixedValue(this.orderSummaryView.totalPrice + this.getTotalOptionalExtraFee()) === FinancialHelper.fixedValue(totalValue)) {
      this.totalizerService.setTotalPrice(this.orderSummaryView.totalPrice);
      this.totalizerService.setOptionalExtraFeePrice(this.getTotalOptionalExtraFee());
    }

    this.totalizerService.changeTicketPriceEmit((this.orderSummaryView.items ?? []).map((item) => item.ticket));
    this.calculatedSummary.emit(this.orderSummaryView);
  }

  private getSeats(orderItems: ScreeningItemViewModel[]) {
    if (!this._screen) {
      return [];
    }

    const seats: { key: string; value: SeatViewModel[] }[] = [];
    orderItems.forEach(({ seatId }) => {
      if (!seatId) {
        return;
      }

      const seat = this._screen.findSeatById(seatId);
      const seatsIncludeKey = seats.find((c) => c.key === seat.rowNumber);
      if (!seatsIncludeKey) {
        seats.push({ key: seat.rowNumber, value: [seat] });
      } else {
        seatsIncludeKey.value.push(seat);
      }
    });

    const skippingRomanDigitsInRows = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.value[0].rowNumber));
    const skippingRomanDigitsInCols = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.value[0].legendCol));

    seats.forEach((elem) => {
      elem.value.forEach((val) => {
        val.translateRow = SeatTranslatorService.translateValue(val.rowNumber, skippingRomanDigitsInRows);
        val.translateCol = SeatTranslatorService.translateValue(val.legendCol, skippingRomanDigitsInCols);
      });
      elem.value = orderBy(elem.value, ['translateRow', 'translateCol']);
    });

    return seats.sort(({ key: aKey }, { key: bKey }) => parseInt(aKey) - parseInt(bKey));
  }

  public get summaryText(): string {
    return this.orderSummaryView.orderVoucherItems.length > 0 ? 'order.ticketsSummary.grandTotal' : 'order.ticketsSummary.totalBrutto';
  }

  private setScreenTag() {
    if (!this.screen) {
      return;
    }

    this.screenTag = {
      tags: this.screen.movieTags,
      premiereDate: this.screen.moviePremiere,
      screeningDate: this.screen.screeningTimeFrom,
    };
  }

  public useVoucher() {
    this.isLoaded = false;
    this.voucherErrors = [];
    let isFetched = false;
    const order = this.orderStateService.getOrder();
    if (!order) {
      //TODO add error
      return;
    }

    return this.voucherService.assignVoucherToOrder(order.cinemaId, order.id, this.voucherForm.value).subscribe({
      next: (order) => {
        this._vouchers = this.orderStateService.getVouchers();
        if (isFetched) {
          this.isLoaded = true;
        }
      },
      error: (e) => {
        if (e?.error?.error) {
          switch (e.error.error.code) {
            case 0:
              this.voucherErrors = [VoucherErrorEnum.CANNOT_USE];
            case 460:
              this.voucherErrors = [VoucherErrorEnum.NOT_FOUND];
          }

          this.prepareError(e.error.error.code, MessageType.danger);
        }

        if (isFetched) {
          this.isLoaded = true;
        }
      },
    });
  }

  private prepareError(err: number, type: MessageType): void {
    this.messageService.add(new MessageModel(type, this.translateService.instant(`errors.${err}`, err)));
  }

  public removeGroupOfCards(card: OrderSummaryGiftCardItemViewModel) {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    const order = this.orderStateService.getOrder();
    this.orderDataProvider
      .removeItem(order.cinemaId, order.id, card.id)
      .pipe(switchMap(() => this.orderDataProvider.getOrder(order.cinemaId, order.id)))
      .subscribe({
        next: (res) => {
          this.orderStateService.setOrder(res);
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
        error: (e) => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
      });
  }

  removeInternalPaymentMethod(paymentMethod: PaymentMethodViewModel): void {
    const order = this.orderStateService.getOrder();
    if (!paymentMethod || !order || !order.id || !order.cinemaId) {
      return;
    }

    this.loadingService.showLoader(LoaderEnum.LOYALTY);

    const request = order.paymentMethods
      .filter((x) => x.cardId && x.cardId !== paymentMethod.cardId)
      .map((x) => new UseCardRequestModel(x.id, paymentMethod.id));

    this.cardDataProvider.useCards(order.cinemaId, order.id, request).subscribe({
      next: (res) => {
        this.orderStateService.setOrder(res);
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
      error: (error) => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
    });
  }

  removeUsedVoucher(voucher: IVoucher) {
    const order = this.orderStateService.getOrder();
    if (!voucher || !order || !order.id || !order.cinemaId) {
      return;
    }

    this.loadingService.showLoader(LoaderEnum.LOYALTY);

    this.voucherService.removeVoucherFromOrder(order.cinemaId, order.id, voucher.number).subscribe({
      next: (res) => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
    });
  }

  public getCinemaName(translateDefaultCinemaName: boolean): string {
    let defaultCinemaName: string =
      translateDefaultCinemaName && this._order?.cinemaId
        ? this.translateService.instant(this.locationStateService.getCinemaNameByCinemaId(this._order?.cinemaId).toTranslationKey('screen.cinema'))
        : this.locationStateService.getCinemaNameByCinemaId(this._order?.cinemaId);

    if (defaultCinemaName === this.locationStateService.getCinemaNameByCinemaId(this._order?.cinemaId).toTranslationKey('screen.cinema')) {
      defaultCinemaName = this.locationStateService.getCinemaNameByCinemaId(this._order?.cinemaId);
    }

    return this.virtualCinemaService.getVirtualCinemaName([{ screenId: this.screen.id }], defaultCinemaName);
  }

  phoneNumberFormat(phone: string): string {
    return phone
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{3})/g, '$1 ')
      .trim();
  }
}
