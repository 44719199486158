import {Injectable} from '@angular/core';
import { ScreeningCartBuilder } from './cart-builder/screening-cart.builder';
import { CartBuilder } from './cart.builder';

@Injectable({
  providedIn: 'root'
})
export class CartBuilderFactory {

  constructor(
    private screeningCartBuilder: ScreeningCartBuilder
  ) {
  }

  public get(type: string): CartBuilder {

    if (type === 'screening') {

      return this.screeningCartBuilder;
    }

    throw new Error('Unsupported builder \'' + type + '\'');
  }
}
