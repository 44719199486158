import {Expose, Type} from 'class-transformer';
import { CardTypeItemMembershipPriceApiModel } from './card-type-item-membership-price.api.model';

export class CardTypeItemApiModel {
  @Expose()
  id: string;

  @Expose()
  itemId: string;

  @Expose()
  typeId: string;

  @Expose()
  templateId: string;

  @Expose()
  chargeValue: number;

  @Expose()
  email: string;

  @Expose()
  message: string;

  @Expose()
  promotionId: string;

  @Expose()
  promotionName: string;

  @Expose()
  name: string;

  @Expose()
  quantity: number;

  @Expose()
  taxRate: number;

  @Expose()
  itemPrice: number;

  @Expose()
  cardId: string;

  @Expose()
  itemDefaultPriceLevelPrice: number;

  @Expose()
  itemEarnedPoints: number;

  @Expose()
  itemRedemptionPoints: number;

  @Expose()
  @Type(() => CardTypeItemMembershipPriceApiModel)
  membershipPrices: CardTypeItemMembershipPriceApiModel[] = [];
}
