import { panValue } from "libs/core/src/lib/tool/number/number";
import { AbstractViewModel } from "../../../abstract.view.model";
import { UserSeatApiModel } from "../../../api-model/user-history/seat/user-seat.api.model";

export class UserSeatViewModel extends AbstractViewModel<UserSeatApiModel> {
  public id: string = null;
  public symbol: string = null;
  public row: string = null;
  public seat: string = null;
  public groupId: string = null;
  public groupName: string = null;

  constructor(
    protected apiModel: UserSeatApiModel = new UserSeatApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.symbol = this.apiModel.symbol;
    this.row = this.apiModel.row;
    this.seat = this.apiModel.seat;
    this.groupId = this.apiModel.groupId;
    this.groupName = this.apiModel.groupName;
  }

  toApiModel(): UserSeatApiModel {
    return undefined;
  }

  public get seatPlace(): string {
    return `${panValue(this.row)}_${panValue(this.seat)}`;
  }
}
