import { map, of, switchMap, tap } from 'rxjs';
import { GoogleTagManagerServiceCore } from './google-tag-manager.service.core';
import { Injectable } from '@angular/core';
import { GoogleTagManagerItemEditMode } from './model/google-tag-manager-item-edit-mode.enum';
import { OrderStatus, OrderViewModel } from '../../model/view-model/order/order.view.model';
import { CanBeRefundViewModel } from '../../model/view-model/sales-document/can-be-refund.view.model';

@Injectable({
  providedIn: 'root',
})
export class CmsGoogleTagManagerService extends GoogleTagManagerServiceCore {
  protected fillTagWithOrderInformation(tag: any, order: OrderViewModel) {
    if (order?.screeningItems?.length) {
      tag.ecommerce.transaction_id = order.id;
      tag.ecommerce.currency = this.environment.constants.currency;
      tag.ecommerce.value = order.status === OrderStatus.FINISHED ? order.totalValue : order.valueToPay;
      tag.ecommerce.tax = order.status === OrderStatus.FINISHED ? order.totalTaxValue : undefined;
    }
  }

  protected fillTagWithItems(tag: any, items: any[]) {
    return of(items).pipe(
      map((items) => {
        return items.map((item) => {
          return {
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity,
            coupon: item.voucherNumber,
            promotion_id: item.promotionId,
            promotion_name: item.promotionName,
          };
        });
      }),
      tap((items) => (tag.ecommerce.items = items)),
      switchMap(() => of(tag))
    );
  }

  protected fillTagWithOrderItems(tag: any, currentOrder: OrderViewModel) {
    return of(this.getOrderItems(currentOrder)).pipe(
      map((items) => {
        return items.map((item) => {
          return {
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity,
            coupon: item.voucherNumber,
            promotion_id: item.promotionId,
            promotion_name: item.promotionName,
          };
        });
      }),
      tap((items) => (tag.ecommerce.items = items)),
      switchMap(() => of(tag))
    );
  }

  protected fillTagWithRefundItems(tag: any, canBeRefundViewModel: CanBeRefundViewModel) {
    return of(tag).pipe(
      tap((t) => {
        t.ecommerce.transaction_id = canBeRefundViewModel.salesDocumentId;
        t.ecommerce.currency = this.environment.constants.currency;
        t.ecommerce.value = canBeRefundViewModel.refundTotalValue;
        t.ecommerce.items = canBeRefundViewModel.itemList
          .filter((f) => f.isRefundable)
          .map((item) => {
            return {
              item_id: item.itemId,
              item_name: item.itemName,
              price: item.refundValue,
              quantity: item.quantity,
            };
          });
      })
    );
  }

  public editCart(previousOrder: OrderViewModel, currentOrder: OrderViewModel) {
    if (this.isConfigured()) {
      const orderChanges = this.getOrderChanges(previousOrder, currentOrder);

      orderChanges.forEach((value: any[], key: GoogleTagManagerItemEditMode) => {
        let tag: any;

        switch (key) {
          case GoogleTagManagerItemEditMode.Add:
            tag = { event: 'add_to_cart', ecommerce: {} };
            this.fillTagWithOrderInformation(tag, currentOrder);
            this.fillTagWithItems(tag, value).subscribe((t) => {
              this.pushOnDataLayer(t);
            });
            break;
          case GoogleTagManagerItemEditMode.Remove:
            tag = { event: 'remove_from_cart', ecommerce: {} };
            this.fillTagWithOrderInformation(tag, currentOrder);
            this.fillTagWithItems(tag, value).subscribe((t) => {
              this.pushOnDataLayer(t);
            });
            break;
          default:
            break;
        }
      });
    }
  }

  public viewCart(currentOrder: OrderViewModel) {
    if (this.isConfigured()) {
      let tag: any = { event: 'view_cart', ecommerce: {} };

      this.fillTagWithOrderInformation(tag, currentOrder);
      this.fillTagWithOrderItems(tag, currentOrder).subscribe(() => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public beginCheckout(currentOrder: OrderViewModel) {
    if (this.isConfigured()) {
      let tag: any = { event: 'begin_checkout', ecommerce: {} };

      this.fillTagWithOrderInformation(tag, currentOrder);
      this.fillTagWithOrderItems(tag, currentOrder).subscribe(() => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public purchase(currentOrder: OrderViewModel) {
    if (this.isConfigured()) {
      let tag: any = { event: 'purchase', ecommerce: {} };

      this.fillTagWithOrderInformation(tag, currentOrder);
      this.fillTagWithOrderItems(tag, currentOrder).subscribe(() => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public share(method: string, contentType: string, itemId: string) {
    if (this.isConfigured()) {
      let tag: any = { event: 'share', method: method, content_type: contentType, item_id: itemId };
      this.pushOnDataLayer(tag);
    }
  }

  public signUp(method?: string) {
    if (this.isConfigured()) {
      let tag: any = { event: 'sign_up', method: method };
      this.pushOnDataLayer(tag);
    }
  }

  public refund(canBeRefundViewModel: CanBeRefundViewModel) {
    if (this.isConfigured() && canBeRefundViewModel.refundToken) {
      this.resetDataLayer();
      let tag: any = { event: 'refund', ecommerce: {} };

      this.fillTagWithRefundItems(tag, canBeRefundViewModel).subscribe((t) => {
        this.pushOnDataLayer(t);
      });
    }
  }
}
