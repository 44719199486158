import {Expose, Type} from 'class-transformer';
import { PromotionConditionItemApiModel } from './promotion-condition-item.api.model';

export class PromotionConditionApiModel {
  @Expose()
  startDate: string;

  @Expose()
  expiryDate: string;

  @Expose()
  @Type(() => PromotionConditionItemApiModel)
  membershipItems: PromotionConditionItemApiModel[];
}
