import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { LanguageDataProvider } from 'libs/core/src/lib/data-provider/language.data-provider';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { CoreRequestHandlerService } from 'libs/core/src/lib/service/request-handler/core/core-request-handler.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { BaseComponent } from './base.component';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';

@Component({
  template: '',
})
export class CoreComponent extends BaseComponent implements OnInit {
  public loaderEnum: typeof LoaderEnum = LoaderEnum;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected route: ActivatedRoute,
    protected router: Router,
    protected requestHandler: CoreRequestHandlerService,
    protected cinemaDataProvider: CinemaDataProvider,
    protected languageDataProvider: LanguageDataProvider,
    protected translate: TranslateService,
    protected messageService: MessageService,
    protected bsLocaleService: BsLocaleService,
    protected loadingService: LoadingService,
    protected stateService: StateService
  ) {
    super(environment, route, router, translate, languageDataProvider, requestHandler, messageService, bsLocaleService, loadingService, stateService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscribeForCinemaContext();
  }

  protected subscribeForCinemaContext(): void {
    this.cinemaDataProvider.getCinemas().subscribe((cinemaList: Array<CinemaViewModel>) => {});
  }
}
