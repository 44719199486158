import {Expose} from 'class-transformer';

export class UserMovieRatingApiModel {
  @Expose()
  symbol: string;

  @Expose()
  value: string;

  @Expose()
  description: string;
}
