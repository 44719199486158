import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { storageKey } from 'libs/core/src/app.const';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  selector: 'app-page-home',
  template: '',
})
export class HomePageComponent implements OnInit {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (this.environment.startOnPlayground) {
        this.router.navigate(['playground']);
        return;
      }

      if (!params.screeningId) {
        this.router.navigate(['screening']);
      } else if (params.screeningId !== undefined) {
        this.router.navigate(['screen', params.screeningId]);
      } else {
        const location = this.stateService.getItem(storageKey.backUrl);
        window.location.href = location ? location : this.environment.backUrl;
      }
    });
  }
}
