import { AbstractViewModel } from "../../abstract.view.model";
import { SalesDocumentPaymentApiModel } from "../../api-model/sales-document/sales-document-payment.api.model";
import { PaymentRefundPaymentViewModel } from "./payment-refund-payment.view.model";

export class SalesDocumentPaymentViewModel extends AbstractViewModel<SalesDocumentPaymentApiModel> {
  id: string;
  name: string;
  maxRefundValue: number;
  transactionId: string;
  refundPaymentList: PaymentRefundPaymentViewModel[] = [];

  constructor(protected apiModel: SalesDocumentPaymentApiModel = new SalesDocumentPaymentApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.maxRefundValue = this.apiModel.maxRefundValue;
    this.transactionId = this.apiModel.transactionId;
    this.refundPaymentList = this.apiModel.refundPaymentList ? this.apiModel.refundPaymentList.map(refundPayment => new PaymentRefundPaymentViewModel(refundPayment)) : [];
  }

  toApiModel(): SalesDocumentPaymentApiModel {
    return undefined;
  }
}
