import { ScreenViewModel } from "libs/core/src/lib/model/view-model/screen/screen.view.model";

export class ScreenScreeningLinkModel {
  public screeningId: string;
  public screen: ScreenViewModel;
  public bookingId: string = null;
  public orderNumber: string = null;

  public constructor(screeningId: string, bookingId: string, orderNumber: string, screen: ScreenViewModel) {
    this.bookingId = bookingId;
    this.screen = screen;
    this.screeningId = screeningId;
    this.orderNumber = orderNumber;
  }
}
