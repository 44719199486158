import { AbstractViewModel } from "../../../abstract.view.model";
import { UserFbSubItemApiModel } from "../../../api-model/user-history/fb-item/user-fb-sub-item.api.model";

export class UserFbSubItemViewModel extends AbstractViewModel<UserFbSubItemApiModel> {
  public subItemId: string = null;
  public subItem: string = null;
  public subItemQuantity: number = null;
  public subItemTaxRate: number = null;
  public subItemPrice: number = null;

  constructor(
    protected apiModel: UserFbSubItemApiModel = new UserFbSubItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.subItemId = this.apiModel.subItemId;
    this.subItem = this.apiModel.subItem;
    this.subItemQuantity = this.apiModel.subItemQuantity;
    this.subItemTaxRate = this.apiModel.subItemTaxRate;
    this.subItemPrice = this.apiModel.subItemPrice;
  }

  toApiModel(): UserFbSubItemApiModel {
    return undefined;
  }
}
