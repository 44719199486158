import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserMovieApiModel } from '../../../api-model/user-history/movie/user-movie.api.model';
import { UserMovieGenreViewModel } from '../shared/user-movie-genre.view.model';
import { UserMoviePictureViewModel } from '../shared/user-movie-picture.view.model';
import { UserMovieRatingViewModel } from '../shared/user-movie-rating.view.model';
import { UserMovieTagViewModel } from '../shared/user-movie-tag.view.model';

export class UserMovieViewModel extends AbstractViewModel<UserMovieApiModel> {
  public id: string = null;
  public duration: string = null;
  public title: string = null;
  public description: string = null;
  public shortTitle: string = null;
  public filmAge: string = null;
  public ageDescription: string = null;
  public ageLongDescription: string = null;
  public posters: string[] = [];
  public genres: UserMovieGenreViewModel[] = null;
  public ratings: UserMovieRatingViewModel[] = null;
  public tags: UserMovieTagViewModel[] = null;
  public pictures: UserMoviePictureViewModel[] = null;
  public premiereDate: DateTime = null;

  constructor(
    protected apiModel: UserMovieApiModel = new UserMovieApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.title = this.apiModel.title;
    this.description = this.apiModel.description;
    this.shortTitle = this.apiModel.shortTitle;
    this.filmAge = this.apiModel.filmAge;
    this.ageDescription = this.apiModel.ageDescription;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.posters = this.apiModel.posters ? this.apiModel.posters.map(x => x) : [];
    this.genres = this.apiModel.genres ? this.apiModel.genres.map(genre => new UserMovieGenreViewModel(genre)) : [];
    this.ratings = this.apiModel.ratings ? this.apiModel.ratings.map(rating => new UserMovieRatingViewModel(rating)) : [];
    this.tags = this.apiModel.tags ? this.apiModel.tags.map(tag => new UserMovieTagViewModel(tag)) : [];
    this.pictures = this.apiModel.pictures ? this.apiModel.pictures.map(picture => new UserMoviePictureViewModel(picture)) : [];
    this.premiereDate = this.apiModel.premiereDate;
  }

  toApiModel(): UserMovieApiModel {
    return undefined;
  }
}
