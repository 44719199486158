import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RequestHandlerModel } from './request-handler.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { RequestHandlerServiceInterface } from './request-handler.service.interface';
import { DomainService } from '../domain.service';
import { AppService } from '../app.service';
import { storageKey } from 'libs/core/src/app.const';
import { StateService } from '../../state/state.service';

export class RequestHandlerService implements RequestHandlerServiceInterface {
  protected model: RequestHandlerModel = new RequestHandlerModel({});

  protected saveCurrentUrlToCookie = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected cookieService: CookieService,
    protected domainService: DomainService,
    protected appService: AppService,
    protected stateService: StateService
  ) {}

  public handle(params: RequestHandlerModel) {
    if (params.backUrl !== null) {
      this.model.backUrl = params.backUrl;
      this.stateService.setItem(storageKey.backUrl, params.backUrl);
    }

    if (params.lang !== null) {
      this.model.lang = params.lang;
      this.translate.use(params.lang);
    }

    if (params.sandboxPayment === true) {
      this.appService.enablePaymentSandbox();
    }
  }

  public handleNavigationEnd(event: NavigationEnd) {
    if (this.saveCurrentUrlToCookie && event['url'] && event['urlAfterRedirects']) {
      const expireDate: Date = new Date();
      expireDate.setDate(expireDate.getDate() + 1);

      this.cookieService.set('cpath', event.urlAfterRedirects, expireDate, '/', this.domainService.BaseDomain);
    }
  }
}
