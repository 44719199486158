import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H7Directive } from './directives/h7.directive';
import { H8Directive } from './directives/h8.directive';

@NgModule({
  declarations: [H7Directive, H8Directive],
  imports: [CommonModule],
  exports: [H7Directive, H8Directive],
})
export class HeadlineModule {}
