import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/refund/interface/form-data.builder.interface';
import { RefundComponent } from 'libs/shared/src/lib/component/refund/refund.component';
import { FormBuilder } from './form.builder';

@Component({
  selector: 'app-refund-component',
  templateUrl: './refund.component.html',
})
export class CustomRefundComponent extends RefundComponent implements OnInit {
  getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup {
    return new FormBuilder().getForm(formDataBuilderInterface);
  }
}
