<div class="seats-selected-list-container">
  <div class="seat-container flex" *ngFor="let seat of seatsSelected; let i = index">
    <div class="row seat-container-header">
      <div class="col">
        {{ 'book-movie-list.person-order.'+(i < 10 ? i+1 : "n") | translate | uppercase }}
      </div>
    </div>
    <div class="row flex pb-3">
      <div class="col-3 col-lg-6">
        <div class="row-head">{{ 'ticketssummary.row' | translate }}</div>
        <div class="row-body">{{ seat.rowSymbol}}</div>
      </div>
      <div class="col-3 col-lg-6">
        <div class="row-head">{{ 'ticketssummary.seat' | translate }}</div>
        <div class="row-body">{{ seat.symbol }}</div>
      </div>
      <div class="col-3 col-lg-6">
        <div class="row-head">{{ 'book-movie-list.amenities' | translate }}</div>
        <div class="row-body">{{ (seat.wheelchairSeat ? 'screen.legend.group.wheelchair' : 'screen.legend.group.none') | translate }}</div>
      </div>
      <div class="col col-lg-6"></div>
    </div>
  </div>
</div>
