import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserLoyaltyPopupTypeEnum } from 'libs/core/src/lib/model/enum/user-loyalty-popup-type';
import { LoyaltyService, UserLoyaltyPopupObject } from 'libs/core/src/lib/service/loyalty.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  selector: 'app-user-loyalty-popups',
  templateUrl: './user-loyalty-popups.component.html',
})
export class CustomUserLoyaltyPopupsComponent implements OnInit {
  userLoyaltyPopupEnum: typeof UserLoyaltyPopupTypeEnum = UserLoyaltyPopupTypeEnum;
  userLoyaltyPopupObject: UserLoyaltyPopupObject;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected loyaltyService: LoyaltyService) {}

  ngOnInit() {
    this.loyaltyService.errorState$.subscribe((s) => (this.userLoyaltyPopupObject = s));
  }

  goToHomepage() {
    window.location.href = this.environment.homepageUrl;
  }

  clear() {
    this.userLoyaltyPopupObject = null;
  }
}
