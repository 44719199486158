import { Product } from "./product";

export class ProductList {
  constructor(
    public id: number,
    public title: string,
    public products: Product[]
  ) {
  }
}
