import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { UserApiModel } from '../../api-model/user/user.api.model';

export class UserViewModel extends AbstractViewModel<UserApiModel> {
  id: string;
  email: string;
  login: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  phone: string;
  nick: string;

  birthday: DateTime;
  plainPassword: string;
  password: string;
  agreements: string[] = new Array<string>();
  gender: string;
  avatar: string;
  twoFactorAuthCode: string;

  constructor(protected apiModel: UserApiModel = new UserApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    if (!this.apiModel) {
      return;
    }

    this.id = this.apiModel.id;
    this.email = this.apiModel.email;
    this.login = this.apiModel.login;
    this.firstName = this.apiModel.firstName;
    this.lastName = this.apiModel.lastName;
    this.street = this.apiModel.street;
    this.streetNumber = this.apiModel.streetNumber;
    this.postalCode = this.apiModel.postalCode;
    this.city = this.apiModel.city;
    this.phone = this.apiModel.phone;
    this.nick = this.apiModel.nick;

    if (this.apiModel.birthday) {
      this.birthday = this.getFlatDate(this.apiModel.birthday);
    }

    this.password = this.apiModel.plainPassword;
    this.agreements = this.apiModel.agreements;
    this.gender = this.apiModel.gender;
    this.avatar = this.apiModel.avatar;
    this.twoFactorAuthCode = this.apiModel.twoFactorAuthCode;
  }

  toApiModel(): UserApiModel {
    const birthday = !!this.birthday ? this.birthday.toISODate() : null;

    return {
      id: this.id,
      email: this.email,
      login: this.login,
      firstName: this.firstName,
      lastName: this.lastName,
      street: this.street,
      streetNumber: this.streetNumber,
      postalCode: this.postalCode,
      city: this.city,
      phone: this.phone,
      nick: this.nick,
      birthday,
      plainPassword: this.password,
      agreements: this.agreements,
      gender: this.gender,
      avatar: this.avatar,
      twoFactorAuthCode: this.twoFactorAuthCode,
    };
  }

  fromForm(formValue: any): UserViewModel {
    this.email = formValue.email;
    this.firstName = formValue.firstName;
    this.gender = formValue.gender;
    this.lastName = formValue.lastName;
    this.phone = formValue.phone;
    this.birthday = this.getFlatDate(formValue.birthday);

    return this;
  }

  public get phone2fa(): string {
    return '';
    // switch (String(environment.projectName)) {
    //     case appProjectName.BLUE:
    //         return  this.phone.substring(0, 3) + this.phone.substring(this.phone.length === 13 ? 5 : 4);
    //     default:
    //         return '';
    // }
  }

  private getFlatDate(date: DateTime | string) {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = DateTime.fromISO(date.split('T')[0], { zone: 'utc' });
    }

    if (date.isValid && date.year !== 1900) {
      return date;
    } else {
      return null;
    }
  }
}
