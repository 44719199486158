import {Expose} from 'class-transformer';

export class EventItemApiModel {
  @Expose()
  movieId: string;

  @Expose()
  moviePrintId: string;

  @Expose()
  movieName: string;

  @Expose()
  speakingType: string;

  @Expose()
  printType: string;

  @Expose()
  language: string;

  @Expose()
  release: string;

  @Expose()
  format: string;

  @Expose()
  resolution: string;

  @Expose()
  frameRate: string;

  @Expose()
  soundType: string;

  @Expose()
  subtitles: string;

  @Expose()
  subtitles2: string;
}
