import { AbstractViewModel } from "../abstract.view.model";
import { RatingApiModel } from "../api-model/rating.api.model";

export class RatingViewModel extends AbstractViewModel<RatingApiModel> {
  cinemaGroupId: string;
  symbol: string;
  value: string;
  description: string;

  constructor(protected apiModel: RatingApiModel = new RatingApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.cinemaGroupId = this.apiModel.cinemaGroupId;
    this.symbol = this.apiModel.symbol;                
    this.value = this.apiModel.value;              
    this.description = this.apiModel.description;                   
  }

  toApiModel(): RatingApiModel {
    return undefined;
  }
}
