import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherRedeemDayOfWeekApiModel } from '../../api-model/voucher/voucher-redeem-day-of-week.api.model';

export class VoucherRedeemDayOfWeekViewModel extends AbstractViewModel<VoucherRedeemDayOfWeekApiModel> {
  dayOfWeek: number;
  flgDayRedeemable: boolean;

  constructor(protected apiModel: VoucherRedeemDayOfWeekApiModel = new VoucherRedeemDayOfWeekApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.dayOfWeek = this.apiModel.dayOfWeek;
    this.flgDayRedeemable = this.apiModel.flgDayRedeemable;
  }

  toApiModel(): VoucherRedeemDayOfWeekApiModel {
    return undefined;
  }
}
