import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class LeavePageGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private modalService: BsModalService) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const previousRoute = this.environment.pages[currentRoute.data.pageIdentify].navigation.previous;
    switch (currentRoute.data['pageIdentify']) {
      case 'personalAndPayment': {
        if (nextState.url.includes('/error')) {
          return true;
        }

        return component.canDeactivate ? component.canDeactivate() : true;
      }
      case 'foodandbeverage': {
        if (nextState.url.includes(previousRoute)) {
          return component.canDeactivate
            ? component.canDeactivate().pipe(
                tap((res) => {
                  if (res === false) {
                    window.history.pushState(null, null, nextState.url);
                  }
                })
              )
            : true;
        }
        break;
      }
      case 'basket': {
        if (nextState.url.includes(previousRoute) && !nextState.url.includes('NEW')) {
          return component.canDeactivate
            ? component.canDeactivate().pipe(
                tap((res) => {
                  if (res === false) {
                    window.history.pushState(null, null, nextState.url);
                  }
                })
              )
            : true;
        }
        break;
      }
      case 'payment': {
        return component.canDeactivate && !nextState.url.includes(this.environment.pages[currentRoute.data.pageIdentify].navigation.next)
          ? component.canDeactivate()
          : true;
      }
    }
    return true;
  }
}
