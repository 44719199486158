import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserFbItemApiModel } from '../../../api-model/user-history/fb-item/user-fb-item.api.model';
import { UserFbModifierViewModel } from './user-fb-modifier.view.model';
import { UserFbSubItemViewModel } from './user-fb-sub-item.view.model';

export class UserFbItemViewModel extends AbstractViewModel<UserFbItemApiModel> {
  public id: string = null;
  public orderId: string = null;
  public itemId: string = null;
  public name: string = null;
  public price: number = null;
  public quantity: number = null;
  public pickupTime: DateTime = null;
  public additionalOrderId: string = null;
  public additionalOrderDate: DateTime = null;
  public voucherNumber: string = null;
  public voucherName: string = null;
  public voucherBatchName: string = null;
  public modifiers: UserFbModifierViewModel[] = [];
  public subItems: UserFbSubItemViewModel[] = [];

  constructor(
    protected apiModel: UserFbItemApiModel = new UserFbItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.orderId = this.apiModel.orderId;
    this.itemId = this.apiModel.itemId;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.pickupTime = this.apiModel.pickupTime;
    this.additionalOrderId = this.apiModel.additionalOrderId;
    this.additionalOrderDate = this.apiModel.additionalOrderDate;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.voucherName = this.apiModel.voucherName;
    this.voucherBatchName = this.apiModel.voucherBatchName;
    this.modifiers = this.apiModel.modifiers.map(modifier => new UserFbModifierViewModel(modifier));
    this.subItems = this.apiModel.subItems.map(subItem => new UserFbSubItemViewModel(subItem));

  }

  toApiModel(): UserFbItemApiModel {
    return undefined;
  }
}
