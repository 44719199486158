import { Component, OnDestroy, OnInit } from '@angular/core';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { ScreeningSelectDateComponent } from 'libs/shared/src/lib/component/screening/select-date/select-date.component';

@Component({
  selector: 'app-screening-select-date',
  templateUrl: './select-date.component.html',
})
export class CustomScreeningSelectDateComponent extends ScreeningSelectDateComponent implements OnInit, OnDestroy {
  constructor(protected translateService: TranslateService) {
    super(environment, translateService);
    const culture = environment.constants.language.cultureMap.find((o) => o.key === this.translateService.currentLang);
    this.firstDayOfWeek = getLocaleFirstDayOfWeek(culture?.value || this.translateService?.currentLang);
  }
}
