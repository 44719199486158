import { AbstractViewModel } from "../../../abstract.view.model";
import { UserMovieGenreApiModel } from "../../../api-model/user-history/shared/user-movie-genre.api.model";

export class UserMovieGenreViewModel extends AbstractViewModel<UserMovieGenreApiModel> {
  public id: string = null;
  public name: string = null;

  constructor(
    protected apiModel: UserMovieGenreApiModel = new UserMovieGenreApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
  }

  toApiModel(): UserMovieGenreApiModel {
    return undefined;
  }
}
