export enum LoginState {
  onLogin = 'onLogin',
  LogInOK = 'LoginOk',
  LogInFault = 'LogInFault',
  accountInactive = 'accountInactive',
  accountNotFound = 'accountNotFound',
  passwordInvalid = 'passwordInvalid',
  onRegister = 'onRegister',
  onForgotPassword = 'onForgotPassword',
  onContinueAsGuest = 'onContinueAsGuest'
}
