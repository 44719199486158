<ng-container *ngIf="currentVideo">
  <ng-container [ngSwitch]="playerType">
    <ng-container *ngSwitchCase="'standalone'">
      <vg-player (onPlayerReady)="onPlayerReady($event)" class="position-relative">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-controls vgFor="singleVideo" [vgAutohide]="true" [vgAutohideTime]="2" class="justify-content-between">
          <section class="d-flex">
            <vg-play-pause></vg-play-pause>
            <vg-time-display vgFor="singleVideo" [vgProperty]="'current'" [vgFormat]="'mm:ss'"></vg-time-display>
          </section>

          <section class="d-flex flex-grow-1" *ngIf="!currentVideo.isAd()">
            <vg-scrub-bar vgFor="singleVideo" [vgSlider]="true">
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>
          </section>

          <section class="d-flex">
            <vg-time-display vgFor="singleVideo" [vgProperty]="'left'" [vgFormat]="'mm:ss'"></vg-time-display>
            <vg-mute></vg-mute>
            <vg-volume *ngIf="!mobileDeviceUtils.isMobile()"></vg-volume>
            <vg-fullscreen></vg-fullscreen>
          </section>
        </vg-controls>

        <video #media [vgMedia]="media" [src]="currentVideo?.source" id="singleVideo" preload="auto" crossorigin
               [poster]="thumbnail">
        </video>

        <div *ngIf="currentVideo.isAd() && (currentVideo.playState === videoPlayState.Playing || currentVideo.playState === videoPlayState.Paused)"
             class="skip-button" (click)="nextVideo()" [ngStyle]="{'z-index': canSkipVideo() ? '999' : '1'}">
          <span>
            {{ canSkipVideo()
            ? ('videoPlayer.skip' | translate)
            : ('videoPlayer.wait' | translate : { value: (((currentVideo.canBeSkipped() ? currentVideo?.skipTime : api.duration) - api?.currentTime) | number : '1.0-0') })}}
          </span>
        </div>
      </vg-player>
    </ng-container>

    <ng-container *ngSwitchCase="'embeded'">
      <div [innerHTML]="embeddedPlayer" id="embeded-player"></div>
    </ng-container>

  </ng-container>

  <div *ngIf="embeddedPlayer" class="trailer-bar d-flex align-items-center justify-content-end pr-3 pr-md-5">
    <span> {{ 'details.trailer' | translate }} </span>
    <div class="d-flex">
      <ng-container *ngFor="let trailer of trailers | orderBy: 'key'">
        <button class="btn" [ngClass]="{'btn-link': trailer.key === currentVideo.key}" *ngIf="trailer.value"
                (click)="changeTrailerLanguage()">{{ ('layout.header.languagechange.' + trailer.key) | translate }}</button>
      </ng-container>
    </div>
  </div>
</ng-container>