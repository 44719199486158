import {Injectable} from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { GaTicketViewModel } from 'libs/core/src/lib/model/view-model/screening/ga/ga-ticket.view.model';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  /**
   * Builds OrderItemCollection for general admission screening
   */
  public buildItemCollectionForGeneralAdmission(ticketList: Array<TicketViewModel>, screeningId: string, configOrder: OrderViewModel): Array<ScreeningItemViewModel> {
    return ticketList.map((ticket: TicketViewModel) => {
      const item = new ScreeningItemViewModel();
      item.screeningId = screeningId;
      item.seatId = null;
      item.quantity = 1;
      item.ticketId = ticket.id;

      const t = configOrder.screeningItems.find(x => x.seatId === ticket.seatId);
      item.optionalExtraFees = t.optionalExtraFees.map(x => x);

      return item;

    }) as Array<ScreeningItemViewModel>;
  }

  public countGeneralAdmissionTicketUsage(items: Array<ScreeningItemViewModel>): { [key: string]: number } {
    const itemsGroupByTicketId: any = {};

    items.forEach(x => {
      if (!itemsGroupByTicketId[x.ticketId]) {
        itemsGroupByTicketId[x.ticketId] = 1;
      } else {
        itemsGroupByTicketId[x.ticketId]++;
      }
    });

    return itemsGroupByTicketId;
  }

  /**
   * Counts GA ticket usage grouping by seat group.
   * Returns the array where the first element is the total number of used tickets
   * and second element is the object which contains about ticket usage by each seat group
   */
  public countGeneralAdmissionTicketUsageBySeatGroup(screeningId: string,
                                                     items: Array<ScreeningItemViewModel>,
                                                     ticketListGeneralAdmission: Array<GaTicketViewModel>): [number, { [key: string]: number }] {
    const ticketUsageBySeatGroup: { [key: string]: number } = {};
    let currentSelectedTicketsAmount = 0;

    items.filter(x => x.screeningId === screeningId).forEach(orderItem => {
      const gaTicket: GaTicketViewModel | undefined = ticketListGeneralAdmission.find(x => x.id === orderItem.ticketId);

      if (gaTicket) {
        ticketUsageBySeatGroup[gaTicket.seatGroupId] = ticketUsageBySeatGroup[gaTicket.seatGroupId] === undefined ?
          1 : (ticketUsageBySeatGroup[gaTicket.seatGroupId] + 1);
        currentSelectedTicketsAmount++;
      }
    });

    return [currentSelectedTicketsAmount, ticketUsageBySeatGroup];
  }

  /**
   * Takes into account already used tickets by order in ticket availability
   */
  public recalculateGATicketAvailability(ticketList: Array<GaTicketViewModel>, order: OrderViewModel): Array<GaTicketViewModel> {
    const recalculatedTickets: Array<GaTicketViewModel> = new Array<GaTicketViewModel>();

    for (const ticket of ticketList) {
      const clonedTicket: GaTicketViewModel = cloneDeep<GaTicketViewModel>(ticket);
      const foundItemsUsingTicket: Array<ScreeningItemViewModel> = order.screeningItems
        .filter(orderItemElement => orderItemElement.ticketId === ticket.id);

      clonedTicket.availableAmount += foundItemsUsingTicket.length;
    }

    return recalculatedTickets;
  }
}
