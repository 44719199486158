import {Expose } from 'class-transformer';

export class SeparatorApiModel {
  @Expose()
  rowNumber: number;

  @Expose()
  enabled: boolean;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  tax: number;

}
