<app-popup [isVisible]="true" *ngIf="message !== null">
  <h4 header [innerHTML]="message.title" class="m-0"></h4>
  <div class="row">
    <div class="col-12">

      <div class="question" [innerHTML]="message.content"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="goToRepertoire()">
        {{ message.button | uppercase }} </button>
    </div>
  </div>
</app-popup>