import {Injectable} from '@angular/core';
import uniq from 'lodash-es/uniq';
import { CartScreeningModel } from '../../model/cart-screening.model';
import { CartTicketItemModel } from '../../model/cart-ticket-item.model';
import { CartModel } from '../../model/cart.model';
import { CartBuilder } from '../cart.builder';

@Injectable({
  providedIn: 'root'
})
export class ScreeningCartBuilder implements CartBuilder {


  public build(cartItemCollection: Array<CartTicketItemModel>): CartModel {

    const cartModel: CartModel = new CartModel();

    cartModel.ticketItems = cartItemCollection;

    const screeningIdentifierCollection: Array<string> = uniq(cartItemCollection.map(x => {
      return x.screeningId;
    }));


    for (const screeningIdentifier of screeningIdentifierCollection) {

      const screeningCartItemList: Array<CartTicketItemModel> = cartItemCollection.filter(x => {
        return x.screeningId === screeningIdentifier;
      });

      const screeningCartModel: CartScreeningModel = new CartScreeningModel();
      screeningCartModel.screeningId = screeningIdentifier;
      screeningCartModel.items = screeningCartItemList;

      cartModel.screeningItems.push(screeningCartModel);

    }

    return cartModel;
  }


}
