import { Component, OnInit } from '@angular/core';
import { PersonalInformationComponent } from 'libs/shared/src/lib/component/personal/personal-information/personal-information.component';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class CustomPersonalInformationComponent extends PersonalInformationComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
