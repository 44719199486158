import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import { FormValidator } from './form-validator';

export class TheSameFormValidator implements FormValidator {
  constructor(
    private sourceFieldName: string,
    private targetFieldName: string,
    private constraintName: string = 'notSame'
  ) {
  }

  public getValidator(): ValidatorFn {
    let sourceFieldControl: FormControl;
    let targetFieldControl: FormControl;

    return (form: FormGroup) => {
      if (!form.value) {
        return null;
      }

      if (!targetFieldControl) {
        targetFieldControl = form.get(this.targetFieldName) as FormControl;
        sourceFieldControl = form.get(this.sourceFieldName) as FormControl;
        sourceFieldControl.valueChanges.subscribe(() => {
          targetFieldControl.updateValueAndValidity();
        });
      }

      if (sourceFieldControl.value !== targetFieldControl.value) {
        targetFieldControl.setErrors({[this.constraintName]: true});
      }
    };
  }
}
