import {DateTime} from 'luxon';
import {MoviePrintViewModel} from './movie-print.view.model';
import { TagGroupViewModel } from '../../tag-group.view.model';
import { AbstractViewModel } from '../../../abstract.view.model';
import { MovieApiModel } from '../../../api-model/account-items/reservation/movie.api.model';

export class MovieViewModel extends AbstractViewModel<MovieApiModel> {
  public id: string;
  public duration: number;
  public ageLongDescription: string;
  public releaseDate: DateTime;
  public posters: string[] = [];
  public pictures: string[] = [];
  public title: string;
  public tagGroups: TagGroupViewModel[] = [];
  public moviePrint?: MoviePrintViewModel;

  constructor(
    protected apiModel: MovieApiModel = new MovieApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.releaseDate = this.apiModel.releaseDate ? DateTime.fromISO(this.apiModel.releaseDate) : null;
    this.posters = this.apiModel.posters ? this.apiModel.posters : [];
    this.pictures = this.apiModel.pictures ? this.apiModel.pictures : [];
    this.title = this.apiModel.title;
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map(tagGroup => new TagGroupViewModel(tagGroup)) : [];
    this.moviePrint = this.apiModel.moviePrint ? new MoviePrintViewModel(this.apiModel.moviePrint) : null;
  }

  toApiModel(): MovieApiModel {
    return undefined;
  }
}
