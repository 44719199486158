import { forkJoin, from, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { storageKey } from '../../app.const';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { TranslationService } from '../service/translation.service';
import { XOR } from '../tool/number/number';
import { CardHelper, MembershipCardInterface } from './card.helper';
import { AuthStateService } from '../state/auth.state.service';
import { OrderStateService } from '../state/order.state.service';
import { StateService } from '../state/state.service';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { CardTypeMembershipExtensionFeeViewModel } from '../model/view-model/card/card-type-membership-extension-fee.view.model';
import { OrderMembershipUpdateRequestModel } from '../model/request/order-membership-update.request.model';
import { CardTypeMembershipUpgradeFeeViewModel } from '../model/view-model/card/card-type-membership-upgrade-fee.view.model';
import { LocationStateService } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  private order: OrderViewModel;
  private orderStateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private cardDataProvider: CardDataProvider,
    private orderDataProvider: OrderDataProvider,
    private cardHelper: CardHelper,
    private translationService: TranslationService,
    private authStateService: AuthStateService,
    private orderStateService: OrderStateService,
    private stateService: StateService
  ) {
    this.orderStateSubscription = this.orderStateService.state$.subscribe((order) => {
      this.order = order;
    });
  }

  public updateOrderMembershipCard(): Observable<OrderViewModel> {
    if (this.authStateService.userIsLoggedAndTokenIsValid() && this.order) {
      return this.cardHelper.findMembership(this.order.cinemaId).pipe(
        mergeMap((membership) => {
          if (this.order.id === null) {
            this.stateService.removeItem(storageKey.orderAgreements);
          }

          if (this.order && membership?.card) {
            return this.cardDataProvider.assignCardToOrder(this.order.cinemaId, this.order.id, membership.card.id);
          } else {
            return of(this.order);
          }
        })
      );
    }

    return of(this.order);
  }

  public extendMembershipCard(
    order: OrderViewModel,
    membership: MembershipCardInterface,
    cardTypeMembershipExtensionFee: CardTypeMembershipExtensionFeeViewModel
  ): Observable<OrderViewModel> {
    const cinemaId = order?.cinemaId;
    return this.createOrderIfNeeded(order, cinemaId, (order: OrderViewModel) => of(order), true).pipe(
      switchMap((order) => {
        const itemsToRemove = order.cardTypeItems?.filter((x) => membership.cardType.membershipUpgradeFeeList.find((y) => y.name === x.name)) ?? [];

        if (itemsToRemove.length > 0) {
          return from(itemsToRemove).pipe(
            mergeMap((x) => this.orderDataProvider.removeItem(cinemaId, order.id, x.id)),
            last()
          );
        }

        return of(order);
      }),
      switchMap((order) => {
        return this.orderDataProvider.membershipUpdate(
          cinemaId,
          order.id,
          new OrderMembershipUpdateRequestModel(membership.card.number, cardTypeMembershipExtensionFee.feeId)
        );
      })
    );
  }

  public upgradeMembershipCard(
    order: OrderViewModel,
    membership: MembershipCardInterface,
    cardTypeMembershipUpgradeFee: CardTypeMembershipUpgradeFeeViewModel
  ): Observable<OrderViewModel> {
    const cinemaId = order.cinemaId;
    return of(order).pipe(
      switchMap((order) => {
        const itemsToRemove =
          order.cardTypeItems?.filter(
            (x) =>
              membership.cardType.membershipUpgradeFeeList.find((y) => y.name === x.name) ||
              membership.cardType.membershipExtensionFeeList.find((y) => y.name === x.name)
          ) ?? [];

        if (itemsToRemove.length > 0) {
          return from(itemsToRemove).pipe(
            mergeMap((x) => this.orderDataProvider.removeItem(cinemaId, order.id, x.id)),
            last()
          );
        }

        return of(order);
      }),
      switchMap((order) => {
        return this.orderDataProvider.membershipUpdate(
          cinemaId,
          order.id,
          new OrderMembershipUpdateRequestModel(membership.card.number, cardTypeMembershipUpgradeFee.feeId)
        );
      })
    );
  }

  public createOrderIfNeeded(
    order: OrderViewModel,
    cinemaId: string,
    callback: (orderModel: OrderViewModel) => Observable<OrderViewModel>,
    assignMembership: boolean = false,
    isRewardsOperation: boolean = false
  ): Observable<OrderViewModel> {
    const orderExist = order?.id ?? null;
    if (orderExist === null) {
      this.stateService.removeItem(storageKey.orderAgreements);
    }

    return forkJoin({
      membership: iif(
        () => this.authStateService.userIsLoggedAndTokenIsValid() && assignMembership && orderExist === null,
        this.cardHelper.findMembership(cinemaId),
        of(null)
      ),
      order: iif(() => orderExist === null, this.orderDataProvider.create(cinemaId).pipe(map((res) => new OrderViewModel(cinemaId, res))), of(order)),
    }).pipe(
      mergeMap((res) => {
        if (res.membership && res.membership.card) {
          return this.cardDataProvider.assignCardToOrder(cinemaId, res.order.id, res.membership.card.id);
        }

        return of(res.order);
      }),
      mergeMap((order) => {
        if (order.getCount() > 0 && XOR(isRewardsOperation, order.isRewardsTransaction)) {
          return throwError({ message: this.translationService.instant('rewards.combineTransaction'), order: order });
        }

        if (callback) {
          return callback(order).pipe(catchError((error) => throwError({ error: error, order: order })));
        }

        return of(null);
      })
    );
  }

  createIfNeeded(cinemaId: string): Observable<OrderViewModel> {
    if (!this.order) {
      return forkJoin({
        order: this.orderDataProvider.create(cinemaId).pipe(map((x) => new OrderViewModel(cinemaId, x))),
        card: this.authStateService.userIsLoggedAndTokenIsValid() ? this.cardHelper.findCard(cinemaId) : of(null),
      }).pipe(
        switchMap((res) => {
          return res.card?.card ? this.cardDataProvider.assignCardToOrder(cinemaId, res.order.id, res.card?.card?.id) : of(res.order);
        })
      );
    } else {
      return of(this.order);
    }
  }

  update(cinemaId: string, order: OrderApiModel): Observable<OrderViewModel> {
    console.log('#update in helper');
    return forkJoin({
      order: this.orderDataProvider.update(cinemaId, order),
      card: this.authStateService.userIsLoggedAndTokenIsValid() ? this.cardHelper.findCard(this.order.cinemaId) : of(null),
    }).pipe(
      switchMap((res) => {
        return res.card?.card ? this.cardDataProvider.assignCardToOrder(this.order.cinemaId, res.order.id, res.card?.card?.id) : of(res.order);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.orderStateSubscription !== Subscription.EMPTY) {
      this.orderStateSubscription.unsubscribe();
      this.orderStateSubscription = Subscription.EMPTY;
    }
  }
}
