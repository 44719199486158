import { Component } from '@angular/core';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { AccountItemsCardsListComponent } from 'libs/shared/src/lib/component/account-items/account-items-cards-list.component';

@Component({
  selector: 'app-account-items-cards-list',
  templateUrl: './account-items-cards-list.component.html',
})
export class CustomAccountItemsCardsListComponent extends AccountItemsCardsListComponent {
  env = environment;

  constructor() {
    super(environment);
  }
}
