import { FormControl, FormGroup, Validators } from '@angular/forms';
import { kinotekaValidationPattern } from '@lib/core';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/refund/interface/form-data.builder.interface';
import { FormBuilderInterface } from 'libs/shared/src/lib/component/refund/interface/form.builder.interface';

export class FormBuilder implements FormBuilderInterface {
  public getForm(data: FormDataBuilderInterface): FormGroup {
    return new FormGroup({
      bookingId: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.pattern(kinotekaValidationPattern.email)]),
    });
  }
}
