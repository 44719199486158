import { Component } from '@angular/core';
import { PolicyComponent } from 'libs/shared/src/lib/component/policy/policy.component';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
})
export class CustomPolicyComponent extends PolicyComponent {
  constructor() {
    super();
  }
}
