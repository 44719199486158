import { Component, ContentChildren, EventEmitter, OnInit, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements OnInit {
  @Output() tabChange = new EventEmitter();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngOnInit(): void {}

  ngAfterViewChecked() {
    let activeTabs = this.tabs?.filter((tab) => tab.active);

    if (!activeTabs?.length) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: any) {
    if (!tab || tab?.active) {
      return;
    }

    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    this.tabChange.emit();
  }
}
