import {Expose, Type} from 'class-transformer';
import { PaymentRefundPaymentApiModel } from './payment-refund-payment.api.model';

export class SalesDocumentPaymentApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  maxRefundValue: number;

  @Expose()
  transactionId: string;

  @Expose()
  @Type(() => PaymentRefundPaymentApiModel)
  refundPaymentList: PaymentRefundPaymentApiModel[];
}
