import { AbstractViewModel } from "../../abstract.view.model";
import { PromotionConditionItemApiModel } from "../../api-model/member-get-member/promotion-condition-item.api.model";

export class PromotionConditionItemViewModel extends AbstractViewModel<PromotionConditionItemApiModel> {
  id: string;
  membershipType: number;
  cardTypeId: string;

  toApiModel(): PromotionConditionItemApiModel {
    return undefined;
  }

  constructor(protected apiModel: PromotionConditionItemApiModel = new PromotionConditionItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.membershipType = this.apiModel.membershipType;
    this.cardTypeId = this.apiModel.cardTypeId;
  }
}
