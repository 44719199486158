import {Injectable} from '@angular/core';
import { GooglePay } from '../client/client';
import { PaymentAuthMethod, PaymentCardNetwork, PaymentCardOptions, PaymentDataModel, PaymentMerchantInfoModel, PaymentMethod, PaymentMethodType, PaymentTotalPriceStatus, PaymentTransactionInfoModel, TokenizationSpecification, TokenizationSpecificationPaymentGatewayOptions, TokenizationSpecificationType } from '../model/payment-data.model';
import PaymentOptions = GooglePay.PaymentOptions;

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePayService {

  /**
   * Creates payment data request
   * @param dataRequestDto
   */
  public createPaymentDataRequest(dataRequestDto: CreatePaymentDataRequestDto): PaymentDataModel {

    const paymentDataModel: PaymentDataModel = new PaymentDataModel();
    paymentDataModel.apiVersion = 2;
    paymentDataModel.apiVersionMinor = 0;

    const allowedPaymentMethods: Array<PaymentMethod> = new Array<PaymentMethod>();

    if (dataRequestDto.allowedCardAuthMethods && dataRequestDto.allowedCardMethods) {

      const cardPaymentMethod: PaymentMethod = new PaymentMethod();
      cardPaymentMethod.type = PaymentMethodType.CARD;

      const cardPaymentMethodOptions: PaymentCardOptions = new PaymentCardOptions();
      cardPaymentMethodOptions.allowedAuthMethods = dataRequestDto.allowedCardAuthMethods;
      cardPaymentMethodOptions.allowedCardNetworks = dataRequestDto.allowedCardMethods;

      cardPaymentMethod.parameters = cardPaymentMethodOptions;

      const tokenization: TokenizationSpecification = new TokenizationSpecification();
      tokenization.type = TokenizationSpecificationType.PAYMENT_GATEWAY;

      const tokenizationParameters: TokenizationSpecificationPaymentGatewayOptions =
        new TokenizationSpecificationPaymentGatewayOptions();

      tokenizationParameters.gateway = dataRequestDto.gateway;
      tokenizationParameters.gatewayMerchantId = dataRequestDto.gatewayMerchantId;

      tokenization.parameters = tokenizationParameters;

      cardPaymentMethod.tokenizationSpecification = tokenization;

      allowedPaymentMethods.push(cardPaymentMethod);

    }

    paymentDataModel.allowedPaymentMethods = allowedPaymentMethods;

    const transactionInfo: PaymentTransactionInfoModel = new PaymentTransactionInfoModel();
    transactionInfo.countryCode = dataRequestDto.countryCode;
    transactionInfo.currencyCode = dataRequestDto.currencyCode;
    transactionInfo.totalPriceStatus = dataRequestDto.totalPriceStatus;
    transactionInfo.totalPrice = dataRequestDto.totalPrice;

    paymentDataModel.transactionInfo = transactionInfo;

    const merchantInfo: PaymentMerchantInfoModel = new PaymentMerchantInfoModel();
    merchantInfo.merchantId = dataRequestDto.merchantId;
    merchantInfo.merchantName = dataRequestDto.merchantName;

    return paymentDataModel;
  }

  public getClient(environment: string|null): GooglePay.PaymentsClient {
    const env = environment || 'PRODUCTION';
    return new google.payments.api.PaymentsClient({
      environment: env
    } as PaymentOptions);
  }

}

export class CreatePaymentDataRequestDto {

  public merchantId: string;

  public merchantName: string;

  public gateway: string;

  public gatewayMerchantId: string;

  public totalPrice: string;

  public currencyCode: string;

  public countryCode: string;

  public totalPriceStatus: PaymentTotalPriceStatus;

  public allowedCardAuthMethods: Array<PaymentAuthMethod>;

  public allowedCardMethods: Array<PaymentCardNetwork>;

}
