import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { storageKey } from 'libs/core/src/app.const';
import { TargetApiEnum } from 'libs/core/src/lib/enum/target-api.enum';
import { MovieViewModel } from 'libs/core/src/lib/model/view-model/movie/movie.view.model';
import {
  AuditoriumAdapter,
  CategoryAdapter,
  EventAdapter,
  LikeAdapter,
  MediaAdapter,
  MovieAdapter,
  PostAdapter,
  ProductListAdapter,
} from '../wp-model/adapters';
import { PostRequest } from '../wp-model/request/post-request';
import { CategoryRequest } from '../wp-model/request/category-request';
import { Post } from '../wp-model/post';
import { Category } from '../wp-model/category';
import { Media } from '../wp-model/media';
import { Like } from '../wp-model/like';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { ProductList } from '../wp-model/product-list';
import { SlideRequest } from '../wp-model/request/slide-request';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { EventViewModel } from 'libs/core/src/lib/model/view-model/event/event.view.model';
import { AuditoriumViewModel } from 'libs/core/src/lib/model/view-model/auditorium/auditorium.view.model';
import { WeekdayApiModel } from '../wp-model/weekday';

@Injectable({
  providedIn: 'root',
})
export class WordpressHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private postAdapter: PostAdapter,
    private categoryAdapter: CategoryAdapter,
    private mediaAdapter: MediaAdapter,
    private likeAdapter: LikeAdapter,
    private movieAdapter: MovieAdapter,
    private eventAdapter: EventAdapter,
    private productListAdapter: ProductListAdapter,
    private auditoriumAdapter: AuditoriumAdapter,
    private stateService: StateService
  ) {}

  private getContextLang(): string {
    return this.stateService.getItem(storageKey.lang);
  }

  private getSeparator(query: string) {
    return query.length ? '&' : '?';
  }

  getPostsByCategories(categories: string, postRequest: PostRequest) {
    const categoryRequest = { slug: categories ? categories.split(',') : '' } as CategoryRequest;
    return this.getCategories(categoryRequest).pipe(
      switchMap((categories) => {
        if (categories && categories.length) {
          postRequest.categories = categories.map((o) => o.id);
          return this.getPosts(postRequest, false);
        } else {
          of(null);
        }
      }),
      map((posts) => {
        if (posts) {
          posts.forEach((post) => {
            this.getMedia(post.featured_media)
              .pipe(
                catchError(() => {
                  return of(null);
                }),
                tap((media) => (post.media = media))
              )
              .subscribe();
          });
        }

        return posts;
      })
    );
  }

  getPosts(options: PostRequest, useLang: boolean): Observable<Post[]> {
    const uri = { url: `/wp/v2/${options.type}`, query: '' };
    if (options.page) {
      uri.query += this.build(uri.query, 'page', options.page);
    }
    if (options.perPage) {
      uri.query += this.build(uri.query, 'per_page', options.perPage);
    }
    if (options.offset) {
      uri.query += this.build(uri.query, 'offset', options.offset);
    }
    if (options.order) {
      uri.query += this.build(uri.query, 'order', options.order);
    }
    if (options.orderby) {
      uri.query += this.build(uri.query, 'orderby', options.orderby);
    }
    if (options.categories) {
      if (typeof options.categories === 'string') {
        uri.query += this.build(uri.query, 'categories', options.categories);
      } else {
        uri.query += this.build(uri.query, 'categories', options.categories.join(','));
      }
    }
    if (useLang) {
      uri.query += this.build(uri.query, 'lang', this.getContextLang());
    }

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((data: any[]) => data.map((item) => this.postAdapter.adapt(item))));
  }

  getPost(slug: string, type: string, useLang: boolean): Observable<Post[]> {
    const uri = { url: `/wp/v2/${type}`, query: `?slug=${slug}` };
    if (useLang) {
      uri.query += this.build(uri.query, 'lang', this.getContextLang());
    }

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((data: any[]) => data.map((item) => this.postAdapter.adapt(item))));
  }

  getCategories(options: CategoryRequest): Observable<Category[]> {
    const uri = { url: `/wp/v2/categories`, query: '' };
    if (options.page) {
      uri.query += this.build(uri.query, 'page', options.page);
    }
    if (options.perPage) {
      uri.query += this.build(uri.query, 'per_page', options.perPage);
    }
    if (options.order) {
      uri.query += this.build(uri.query, 'order', options.order);
    }
    if (options.orderby) {
      uri.query += this.build(uri.query, 'orderby', options.orderby);
    }
    if (options.slug) {
      uri.query += this.build(uri.query, 'slug', options.slug.join(','));
    }

    return this.http
      .get<Category[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((data: any[]) => data.map((item) => this.categoryAdapter.adapt(item))));
  }

  getMedia(id: number) {
    return this.http
      .get<Media>(`/wp/v2/media/${id}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.mediaAdapter.adapt(item)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 86400000,
  })
  getCinemas() {
    return this.http.get<Post[]>(`/wp/v2/cinema`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 86400000,
  })
  getRegions(): Observable<Post[]> {
    return this.http.get<Post[]>(`/wp/v2/region`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  getMemberships() {
    return this.http.get<any[]>(`/wp/v2/membership`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  getPostMovieById(id: string): Observable<Post> {
    const uri = { url: `/wp/v2/movie/${id}`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.postAdapter.adapt(item)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  getMovies() {
    const uri = { url: `/wp/v2/movie`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http.get<Post[]>(`${uri.url}${uri.query}`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  getMovieById(id: string): Observable<MovieViewModel> {
    const uri = { url: `/wp/v2/movie/${id}`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.movieAdapter.adapt(item)));
  }

  getSuggestedMovies(movieId: string): Observable<MovieViewModel[]> {
    const uri = { url: `/wp/v2/movie/${movieId}/recommended`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((items) => items.map((item) => this.movieAdapter.adapt(item))));
  }

  getPromotedMovies(): Observable<MovieViewModel[]> {
    const uri = { url: `/wp/v2/movie`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(
        map((items) => items.map((item) => this.movieAdapter.adapt(item))),
        map((movie) => {
          return movie.filter((f) => f.promote_outside_schedule);
        })
      );
  }

  getLikes(id: string, isEvent = false) {
    return this.http
      .get<Like>(`/wp/v2/${isEvent ? 'event' : 'movie'}/${id}/like`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.likeAdapter.adapt(item)));
  }

  toggleLike(id: string, isEvent = false): Observable<Like> {
    return this.http
      .patch(`/wp/v2/${isEvent ? 'event' : 'movie'}/${id}/toggle-like`, null, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.likeAdapter.adapt(item)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  getEvents() {
    const uri = { url: `/wp/v2/event`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http.get<Post[]>(`${uri.url}${uri.query}`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  getEventById(id: string) {
    const uri = { url: `/wp/v2/event/${id}`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.eventAdapter.adapt(item)));
  }

  getPromotedEvents(): Observable<EventViewModel[]> {
    const uri = { url: `/wp/v2/event`, query: '' };
    uri.query += this.build(uri.query, 'lang', this.getContextLang());

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(
        map((items) => items.map((item) => this.eventAdapter.adapt(item))),
        map((event) => {
          return event.filter((f) => f.promote_outside_schedule);
        })
      );
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: WordpressHttpService.cacheBuster$,
    maxAge: 86400000,
  })
  getConfig(): Observable<KeyValue<string>[]> {
    return this.http
      .get(`/wp/v2/config`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((res) => plainToInstance(KeyValue, res as Object[], { strategy: 'excludeAll' })));
  }

  getSlider(options: SlideRequest): Observable<Post[]> {
    const uri = {
      url: options.slider ? `/wp/v2/slide/current` : '/wp/v2/slide',
      query: '',
    };

    if (options.slider) {
      uri.query += this.build(uri.query, 'slider', options.slider);
    }

    if (options.regions) {
      options.regions.forEach((id) => {
        uri.query += this.build(uri.query, 'regions[]', id);
      });
    }

    return this.http
      .get<Post[]>(`${uri.url}${uri.query}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((data: any[]) => data.map((item) => this.postAdapter.adapt(item))));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getPackages() {
    return this.http.get<any[]>(`/wp/v2/package`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  getProductsListById(id: string): Observable<ProductList> {
    return this.http
      .get<Post>(`/wp/v2/product_list/${id}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.productListAdapter.adapt(item)));
  }

  getAuditoriumById(id: string): Observable<AuditoriumViewModel> {
    return this.http
      .get<Post>(`/wp/v2/auditorium/${id}`, {
        headers: { targetApi: TargetApiEnum.WP_API },
      })
      .pipe(map((item) => this.auditoriumAdapter.adapt(item)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getWeekdays(): Observable<WeekdayApiModel[]> {
    return this.http.get<WeekdayApiModel[]>(`/wp/v2/weekday`, {
      headers: { targetApi: TargetApiEnum.WP_API },
    });
  }

  private build(query: string, param: string, value) {
    return `${this.getSeparator(query)}${param}=${value}`;
  }
}
