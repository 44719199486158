import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { UserAreaService } from '../service/user-area.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorGuard implements CanActivate {

  private readonly userAreaRoutePrefix: string = 'user-area';

  constructor(
    private router: Router,
    private userAreaService: UserAreaService
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (state.url.startsWith('/error') && this.userAreaService.isUserAreaEnabled === true) {
      const userAreaErrorPageUrl: string = '/' + this.userAreaRoutePrefix + state.url;
      this.router.navigate([userAreaErrorPageUrl]);
      return false;
    }

    return true;
  }
}
