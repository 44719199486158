import { Expose } from 'class-transformer';

export class UseCardRequestModel {
  constructor(cardId: string, typeId: string = null) {
    this.cardId = cardId;
    this.typeId = typeId;
  }

  @Expose()
  public cardId: string;

  @Expose()
  public typeId: string;
}
