import {Inject, Injectable} from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable({
  providedIn: 'root'
})
export class CmsHelper {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
  }

  public get canUseCms() {
    return this.environment['cms'];
  }

  public getRouteFor(naviParam: string) {
    return this.canUseCms && this.environment['cms']['routes'] && this.environment['cms']['routes'][naviParam] ? this.environment['cms']['routes'][naviParam] : null;
  }
}
