export class Media {
    constructor(
        public id: number,
        public slug: string,
        public link: string,
        public alt_text: string,
        public mime_type: string,
        public source_url: string,

        public width: number,
        public height: number,
        public file: string,

        public medium: MediaDetailsSize,
        public thumbnail: MediaDetailsSize,
        public full: MediaDetailsSize
      ) {}
}

export class MediaDetailsSize {
    constructor(
        public file: string,
        public width: number,
        public height: number,
        public mime_type: string,
        public source_url: string
      ) {}
}
