import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import trimStart from 'lodash-es/trimStart';
import { PhoneDefinitionModel } from '../model/phone-definition.model';
import { PhoneService } from './phone.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { DataConfig } from '../model/config.model';

@Injectable({
  providedIn: 'root',
})
export class PhoneDefinitionService {
  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private http: HttpClient, private phoneService: PhoneService) {}

  public getDefinitions(targetTranslation: string): Observable<Array<PhoneDefinitionModel>> {
    return this.http.get(this.environment.internationalPhone.definitionUrl).pipe(
      map((response: DataConfig) => {
        const phoneDefinitionList: Array<PhoneDefinitionModel> = new Array<PhoneDefinitionModel>();
        for (const prefixConfig of response.prefixes) {
          const phoneDefinitionModel: PhoneDefinitionModel = new PhoneDefinitionModel();
          phoneDefinitionModel.prefix = prefixConfig.prefix;
          phoneDefinitionModel.code = prefixConfig.code;

          const translations = {};
          for (const langKey of Object.keys(response.translations)) {
            if (response.translations[langKey][prefixConfig.code]) {
              translations[langKey] = response.translations[langKey][prefixConfig.code];

              if (targetTranslation && targetTranslation.toLocaleLowerCase().trim() === langKey.toLocaleLowerCase().trim()) {
                phoneDefinitionModel.countryTitle = translations[langKey];
              }
            }
          }

          phoneDefinitionModel.translations = translations;
          phoneDefinitionList.push(phoneDefinitionModel);
        }

        return phoneDefinitionList;
      })
    );
  }

  public getPhoneDefinitionBasedOnPhoneNumber(phoneDefinitionList: Array<PhoneDefinitionModel>, phoneNumber: string): PhoneDefinitionModel {
    phoneNumber = trimStart(phoneNumber, '+');
    let definitions: Array<PhoneDefinitionModel> = new Array<PhoneDefinitionModel>();

    for (const item of phoneDefinitionList) {
      definitions.push(item);
    }

    definitions = definitions.sort((a: PhoneDefinitionModel, b: PhoneDefinitionModel) => {
      return b.prefix.length - a.prefix.length;
    });

    // try to extract prefix from standard format

    const decomposed: { prefix: string; phoneNumber: string } | null = this.phoneService.decomposePhoneNumber(phoneNumber);

    if (decomposed) {
      const phoneDefinition: PhoneDefinitionModel | null = definitions.find((x) => x.prefix === decomposed.prefix);

      return phoneDefinition ? phoneDefinition : null;
    }

    for (const phoneDefinition of definitions) {
      if (phoneNumber && phoneNumber.startsWith(phoneDefinition.prefix)) {
        return phoneDefinition;
      }
    }

    return null;
  }
}
