import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeMembershipExtensionFeeApiModel } from '../../api-model/card/card-type-membership-extension-fee.api.model';
import { ExtensionTypeEnum } from '../../enum/extension-type.enum';

export class CardTypeMembershipExtensionFeeViewModel extends AbstractViewModel<CardTypeMembershipExtensionFeeApiModel> {
  public feeId: string = null;
  public name: string = null;
  public extensionTime: number = null;
  public extensionType: ExtensionTypeEnum | null = null;
  public feeValue: number = null;

  constructor(protected apiModel: CardTypeMembershipExtensionFeeApiModel = new CardTypeMembershipExtensionFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.feeId = this.apiModel.feeId;
    this.name = this.apiModel.name;
    this.extensionTime = this.apiModel.extensionTime;
    this.extensionType = this.apiModel.extensionType === 0 ? ExtensionTypeEnum.ADD_TIME : ExtensionTypeEnum.OVERLAP_TIME;
    this.feeValue = this.apiModel.feeValue;
  }

  toApiModel(): CardTypeMembershipExtensionFeeApiModel {
    return undefined;
  }
}
