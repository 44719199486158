import { Observable } from 'rxjs';
import uniqBy from 'lodash-es/uniqBy';
import { Inject, Injectable } from '@angular/core';
import { ScreeningAvailabilityStrategyContext } from 'libs/core/src/lib/model/strategy/screening-availability/screening-availability.strategy-context';
import { MovieCopyScheduleViewModel, MovieScheduleViewModel, ScreeningScheduleViewModel } from '../model/movie-schedule.view-model';
import { ScreeningModel } from 'libs/core/src/lib/model/screening.model';
import { ScreenTag } from 'libs/core/src/lib/model/screen-tag.model';
import { compareDateTime } from 'libs/core/src/lib/date/date.helper';
import { ENVIRONMENT_TOKEN, makeUrl } from 'libs/core/src/public-api';
import { EventViewModel } from 'libs/core/src/lib/model/view-model/event/event.view.model';
import { MovieViewModel } from 'libs/core/src/lib/model/view-model/movie/movie.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class MovieScheduleViewModelBuilder {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  public build(
    movieCopyList: Array<MoviePrintViewModel>,
    screeningAvailabilityStrategyContext: ScreeningAvailabilityStrategyContext,
    eventCopyList: EventViewModel[] = null
  ): Observable<Array<MovieScheduleViewModel>> {
    const availableMovieCollection: Array<MovieViewModel> = uniqBy(
      movieCopyList.map((x) => x.movie),
      'id'
    );
    const movieScheduleCollection: Array<MovieScheduleViewModel> = availableMovieCollection.map((movie: MovieViewModel) => {
      const movieScheduleViewModel: MovieScheduleViewModel = new MovieScheduleViewModel();
      movieScheduleViewModel.movieId = movie.id;
      movieScheduleViewModel.movieTitle = movie.title;
      movieScheduleViewModel.movieShortTitle = movie.shortTitle;
      movieScheduleViewModel.duration = movie.duration;
      movieScheduleViewModel.posters = movie.posters ? movie.posters.map((x) => x) : [];
      movieScheduleViewModel.pictures = movie.pictures ? movie.pictures.map((x) => x) : [];
      movieScheduleViewModel.genres = movie.genres ? movie.genres.map((x) => x) : [];
      movieScheduleViewModel.rating = movie.ratings ? movie.ratings.map((x) => x) : [];
      movieScheduleViewModel.shortDescription = movie.shortDescription || null;
      movieScheduleViewModel.description = movie.description || null;
      movieScheduleViewModel.yearOfProduction = movie.yearOfProduction || null;
      movieScheduleViewModel.premiereDate = movie.premiereDate || null;
      movieScheduleViewModel.worldPremiereDate = movie.worldPremiereDate || null;

      const moviePosterPlaceholder = makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);
      let poster = (movieScheduleViewModel.posters?.length ? movieScheduleViewModel.posters[0] : null) || undefined;
      if (poster === undefined || poster === null || poster === '') {
        poster = moviePosterPlaceholder;
      }
      movieScheduleViewModel.isPosterDefault = poster === moviePosterPlaceholder;
      movieScheduleViewModel.poster = poster;
      movieScheduleViewModel.picture = movie.pictures[0] || '';

      const availableMovieCopyCollection: Array<MoviePrintViewModel> = uniqBy(
        movieCopyList.filter((x) => x.movie.id === movie.id),
        'id'
      );
      movieScheduleViewModel.movieCopyCollection = availableMovieCopyCollection.map((movieCopy: MoviePrintViewModel) => {
        const movieCopyScheduleViewModel: MovieCopyScheduleViewModel = new MovieCopyScheduleViewModel();
        movieCopyScheduleViewModel.id = movieCopy.id;
        movieCopyScheduleViewModel.release = movieCopy.release;
        movieCopyScheduleViewModel.movieLanguage = movieCopy.language;
        movieCopyScheduleViewModel.subtitles = movieCopy.subtitles;
        movieCopyScheduleViewModel.subtitles2 = movieCopy.subtitles2;
        movieCopyScheduleViewModel.printType = movieCopy.printType;

        movieCopyScheduleViewModel.screenings = movieCopy.screenings
          .map((screening: ScreeningViewModel) => {
            const screeningScheduleViewModel: ScreeningScheduleViewModel = new ScreeningScheduleViewModel();
            screeningScheduleViewModel.id = screening.id;
            screeningScheduleViewModel.timeFrom = screening.screeningTimeFrom;
            screeningScheduleViewModel.timeTo = screening.screeningTimeTo;
            screeningScheduleViewModel.screeningDuration = screening.screeningDuration;
            screeningScheduleViewModel.screenId = screening.screenId;
            screeningScheduleViewModel.generalAdmission = screening.generalAdmission;
            screeningScheduleViewModel.isScreenSwapping = screening.isScreenSwapping;
            screeningScheduleViewModel.availabilityStatus = screening.availabilityStatus;
            screeningScheduleViewModel.reservationTimeTo = screening.reservationTimeTo;
            screeningScheduleViewModel.saleTimeTo = screening.saleTimeTo;

            return screeningScheduleViewModel;
          })
          .sort(this.dateCompareFunction)
          .filter((screening) => {
            return screeningAvailabilityStrategyContext.isAvailable(screening.availabilityStatus);
          });

        return movieCopyScheduleViewModel;
      });

      const screenTag = {
        tags: movie.tagGroups,
        premiereDate: movie.premiereDate,
      } as ScreenTag;

      if (
        movieScheduleViewModel.movieCopyCollection &&
        movieScheduleViewModel.movieCopyCollection.length > 0 &&
        movieScheduleViewModel.movieCopyCollection[0].screenings &&
        movieScheduleViewModel.movieCopyCollection[0].screenings.length > 0
      ) {
        screenTag.screeningDate = movieScheduleViewModel.movieCopyCollection[0].screenings[0].timeFrom;
      }
      movieScheduleViewModel.screenTag = screenTag;

      return movieScheduleViewModel;
    });

    if (eventCopyList !== null) {
      eventCopyList.forEach((event: EventViewModel) => {
        const movieScheduleViewModel: MovieScheduleViewModel = new MovieScheduleViewModel();
        movieScheduleViewModel.movieId = event.id;
        movieScheduleViewModel.movieTitle = event.name;
        movieScheduleViewModel.description = event.description || null;
        movieScheduleViewModel.posters = event.posters ? event.posters.map((x) => x) : [];
        movieScheduleViewModel.genres = event.genres ? event.genres.map((x) => x) : [];
        movieScheduleViewModel.rating = event.ratings ? event.ratings.map((x) => x) : [];
        movieScheduleViewModel.screenTag = {} as ScreenTag;

        let poster = (movieScheduleViewModel.posters?.length ? movieScheduleViewModel.posters[0] : null) || undefined;
        if (poster === undefined || poster === null || poster === '') {
          poster = makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);
        }
        movieScheduleViewModel.isPosterDefault = poster === makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);
        movieScheduleViewModel.poster = poster;
        movieScheduleViewModel.picture = '';

        const movieCopyScheduleViewModel: MovieCopyScheduleViewModel = new MovieCopyScheduleViewModel();
        movieCopyScheduleViewModel.id = event.id;
        movieCopyScheduleViewModel.name = event.name;
        movieCopyScheduleViewModel.default = undefined;
        movieCopyScheduleViewModel.description = event.description;
        movieCopyScheduleViewModel.posters = event.posters ? event.posters.map((x) => x) : [];

        const eventScreeningModel = new ScreeningScheduleViewModel();
        eventScreeningModel.id = event.screeningId;
        eventScreeningModel.name = event.name;
        eventScreeningModel.timeFrom = event.timeFrom;
        eventScreeningModel.timeTo = event.timeTo;
        eventScreeningModel.availabilityStatus = event.availabilityStatus;
        eventScreeningModel.reservationTimeTo = event.reservationTimeTo;
        eventScreeningModel.saleTimeTo = event.saleTimeTo;
        eventScreeningModel.isEvent = true;

        movieCopyScheduleViewModel.screenings = [eventScreeningModel].sort(this.dateCompareFunction).filter((screening) => {
          return screeningAvailabilityStrategyContext.isAvailable(screening.availabilityStatus);
        });

        movieScheduleViewModel.movieCopyCollection = [movieCopyScheduleViewModel];
        movieScheduleCollection.push(movieScheduleViewModel);
      });
    }

    return new Observable((subscriber) => {
      subscriber.next(movieScheduleCollection);
      subscriber.complete();
    });
  }

  private dateCompareFunction(a: ScreeningScheduleViewModel, b: ScreeningScheduleViewModel): number {
    return compareDateTime(a.timeFrom, b.timeFrom);
  }
}
