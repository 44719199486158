import { AbstractViewModel } from "../../abstract.view.model";
import { BrightcoveVideoApiModel } from "../../api-model/movie/brightcove-video.api.model";

export class BrightcoveVideoViewModel extends AbstractViewModel<BrightcoveVideoApiModel> {
  videoId: string;
  videoUrl: string;
 
  constructor(
    protected apiModel: BrightcoveVideoApiModel = new BrightcoveVideoApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.videoId = this.apiModel.videoId;
    this.videoUrl = this.apiModel.videoUrl;
  }

  toApiModel(): BrightcoveVideoApiModel {
    return undefined;
  }
}
