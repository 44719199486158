import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';
import { GtagProviderParametersInterface } from './interface/gtag-provider-parameters.interface';

@Component({
  selector: 'app-gtag-component',
  templateUrl: './gtag.component.html'
})
export class GtagComponent extends AbstractTagProviderComponent implements OnInit {
  public constructor( protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {
    super( renderer,  _document);
  }

  public ngOnInit(): void {
    const gTagKey: string = (this.parameters as GtagProviderParametersInterface).key;
    if (!gTagKey) {
      throw new Error(`GTAG: key is required!'`);
    }

    this.addElement(this.getGtManagerLibraryScriptElement(gTagKey), this._document.body);
    this.addElement(this.getGtagManagerScriptElement(gTagKey), this._document.head);
  }

  protected getGtManagerLibraryScriptElement(gTagKey: string): HTMLElement {
    return this.createScriptExternalElement('https://www.googletagmanager.com/gtag/js?id=' + gTagKey, { async: true });
  }

  protected getGtagManagerScriptElement(gTagKey: string): HTMLElement {
    return this.createScriptElement(`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gTagKey}');`);
  }
}
