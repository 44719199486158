import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { GoogleTagManagerItemEditMode } from './model/google-tag-manager-item-edit-mode.enum';
import { OrderViewModel } from '../../model/view-model/order/order.view.model';

declare const fbq: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerServiceCore {
  protected environment = inject(ENVIRONMENT_TOKEN);

  protected browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  protected isConfigured(): boolean {
    return document.getElementById('gtm_script') ? true : false;
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  protected resetDataLayer() {
    const dataLayer = this.getDataLayer();
    if (typeof fbq === 'function') {
      fbq('track', 'Purchase', { currency: 'USD', value: 30.0 });
    }
    dataLayer.push(function () {
      this.reset();
    });
  }

  protected pushOnDataLayer(tag: any): void {
    if (tag) {
      const dataLayer = this.getDataLayer();
      console.log('GTM Tag', tag);
      dataLayer.push(tag);
    }
  }

  protected getOrderChanges(previousOrder: OrderViewModel, currentOrder: OrderViewModel): Map<GoogleTagManagerItemEditMode, any[]> {
    let result = new Map<GoogleTagManagerItemEditMode, any[]>();

    if (!previousOrder && currentOrder) {
      const items = this.getOrderItems(currentOrder);
      if (items && items.length > 0) {
        result.set(GoogleTagManagerItemEditMode.Add, items);
      }
      return result;
    }

    return this.getDifferences(previousOrder, currentOrder);
  }

  protected getOrderItems(currentOrder: OrderViewModel) {
    return [
      ...(currentOrder?.screeningItems?.filter((s) => s.ticketId) ?? []),
      ...(currentOrder?.fbItems ?? []),
      ...(currentOrder?.voucherItems ?? []),
      ...(currentOrder?.cardItems ?? []),
    ]
      .filter((f) => f)
      .map((i) => i as any);
  }

  private getDifferences(previousOrder: OrderViewModel, currentOrder: OrderViewModel) {
    let result = new Map<GoogleTagManagerItemEditMode, any[]>();

    this.concatMapValues(
      result,
      this.getArrayDifferences(previousOrder?.screeningItems ?? [], currentOrder?.screeningItems ?? [], (item1, item2) => {
        return !item1['ticketId'] && item2['ticketId'];
      })
    );
    this.concatMapValues(result, this.getArrayDifferences(previousOrder?.fbItems ?? [], currentOrder?.fbItems ?? []));
    this.concatMapValues(result, this.getArrayDifferences(previousOrder?.voucherItems ?? [], currentOrder?.voucherItems ?? []));
    this.concatMapValues(result, this.getArrayDifferences(previousOrder?.cardItems ?? [], currentOrder?.cardItems ?? []));

    return result;
  }

  private getArrayDifferences(array1: any[], array2: any[], additionalPredicate: (i1, i2) => boolean = () => true) {
    let result = new Map<GoogleTagManagerItemEditMode, any[]>();

    array1.forEach((item1) => {
      if (!array2.some((item2) => item2['id'] === item1['id'] && !additionalPredicate(item2, item1))) {
        result.set(
          GoogleTagManagerItemEditMode.Remove,
          result.get(GoogleTagManagerItemEditMode.Remove) ? result.get(GoogleTagManagerItemEditMode.Remove).concat([item1]) : [item1]
        );
      }
    });

    array2.forEach((item2) => {
      if (!array1.some((item1) => item1['id'] === item2['id'] && !additionalPredicate(item1, item2))) {
        result.set(
          GoogleTagManagerItemEditMode.Add,
          result.get(GoogleTagManagerItemEditMode.Add) ? result.get(GoogleTagManagerItemEditMode.Add).concat([item2]) : [item2]
        );
      }
    });

    return result;
  }

  private concatMapValues<T, K>(source: Map<T, K[]>, map: Map<T, K[]>) {
    map.forEach((value, key) => {
      source.set(key, source.get(key) ? source.get(key).concat(value) : value);
    });
  }
}
