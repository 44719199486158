import { Pipe, PipeTransform } from '@angular/core';

export enum TimeParseFormatIn {
  minutes = 'M',
  seconds = 'S',
}

@Pipe({
  name: 'timeParse',
})
export class TimeParsePipe implements PipeTransform {
  transform(value: number, formatIn: string, formatOut: string, hourSymbol?: string, minuteSymbol?: string): string {
    if (!value) {
      return '';
    }

    value = Math.floor(value);

    if (formatIn === 'M') {
      value *= 60;
    }

    switch (formatOut) {
      case 'MINUTES_SECONDS': {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }
      case 'HOURS_MINUTES': {
        const { hours, minutes } = this.getHoursAndMinutes(value);
        if (hourSymbol && minuteSymbol) {
          return `${String(hours)}${hourSymbol} ${String(minutes).padStart(2, '0')}${minuteSymbol}`;
        }
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      }
      default:
        return String(value);
    }
  }

  private getHoursAndMinutes(value: number): { hours: number; minutes: number } {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    return { hours, minutes };
  }
}
