import { Inject, Injectable, Injector } from '@angular/core';
import { StateService } from './state.service';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { TokenStorageService } from '../service/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, filter, finalize, forkJoin, of, take, tap } from 'rxjs';
import { LocationModel } from '../model/location.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { storageKey } from '../../app.const';
import { Settings } from 'luxon';
import { LocationType } from '../enum/location-type.enum';
import { RegionDataProvider } from '../data-provider/region.data-provider';
import { CinemaDataProvider } from '../data-provider/cinema.data-provider';

@Injectable({
  providedIn: 'root',
})
export class LocationStateService extends StateService {
  private locationAutoSelection = false;

  private location: BehaviorSubject<LocationModel> = new BehaviorSubject<LocationModel>(null);
  public location$: Observable<LocationModel | null>;

  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private locations: LocationModel[] = [];
  private currentLocation!: LocationModel;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected tokenStorageService: TokenStorageService,
    protected cookieService: CookieService,
    protected injector: Injector,
    protected regionDataProvider: RegionDataProvider,
    protected cinemaDataProvider: CinemaDataProvider
  ) {
    super(tokenStorageService, cookieService);
    this.location$ = this.location.asObservable();

    forkJoin({
      regions: this.regionDataProvider.getRegions(),
      cinemas: this.cinemaDataProvider.getCinemas(),
    })
      .pipe(
        take(1),
        tap((result) => {
          this.locations = this.createLocations(result.regions, result.cinemas);
          this.loadLocationFromStorage();
        }),
        finalize(() => this.initialDataFetched$.next(true))
      )
      .subscribe();
  }

  loadLocationFromStorage() {
    this.trySetLocation(this.getItem(storageKey.chosenLocation));
  }

  private trySetLocation(locationId: string) {
    if (locationId) {
      this.setLocationById(locationId);
    } else {
      if (this.locationAutoSelection) {
        const locationId = this.getDefaultLocationId();
        this.setLocationById(locationId);
      }
    }
  }

  private getDefaultLocationId(): string {
    const defaultId = this.environment.constants.defaultCinemaId || this.environment.constants.defaultRegionId;
    this.locations.find((l) => l.id === defaultId);
    return this.locations.find((l) => l.id === defaultId)?.id || this.locations[0]?.id;
  }

  private getDefaultCinemaIdByRegionId(regionId: string): string {
    const region = this.locations.find((l) => l.id === regionId)?.model as RegionViewModel;
    if (region) {
      return region.defaultCinemaId || region.cinemaIds[0];
    }

    console.error('Region not found');
    return null;
  }

  private setLocationById(locationId?: string) {
    const location = this.getLocationById(locationId);

    if (location) {
      this.setItem(storageKey.chosenLocation, location.id);
    } else {
      this.removeItem(storageKey.chosenLocation);
    }

    if (location instanceof CinemaViewModel) {
      Settings.defaultZoneName = location.timezone;
    }

    this.currentLocation = location;
    this.location.next(location);
  }

  public setLocation$(location: LocationModel) {
    return of(location).pipe(
      tap(() => {
        this.setLocationById(location?.id);
      })
    );
  }

  public setLocationByRegionId$(regionId: string) {
    return of(regionId).pipe(
      tap(() => {
        this.setLocationById(regionId);
      })
    );
  }

  public setLocationByCinemaId$(cinemaId: string) {
    return of(cinemaId).pipe(
      tap(() => {
        const cinema = this.locations.find((l) => l.id === cinemaId);
        if (cinema) {
          this.setLocationById(cinema.id);
        } else {
          const region = this.findRegionByCinemaId(cinemaId);
          this.setLocationById(region?.id);
        }
      })
    );
  }

  private getLocationById(locationId: string): LocationModel {
    return this.locations.find((l) => l.id === locationId);
  }

  public initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  public getCinemaId(): string {
    switch (this.currentLocation?.type) {
      case LocationType.Region:
        return this.getDefaultCinemaIdByRegionId(this.currentLocation.id);
      case LocationType.Cinema:
        return this.currentLocation.id;
      default:
        return this.getDefaultLocationId();
    }
  }

  public getCinemaById(cinemaId: string): CinemaViewModel {
    return (
      (this.locations.find((l) => l.id === cinemaId)?.model as CinemaViewModel) || this.findRegionByCinemaId(cinemaId)?.cinemas.find((c) => c.id === cinemaId)
    );
  }

  public findRegionByCinemaId(cinemaId: string) {
    return this.locations
      .filter((l) => l.type === LocationType.Region)
      .map((l) => l.model as RegionViewModel)
      .find((r) => r.cinemaIds.includes(cinemaId));
  }

  public getCinemas() {
    const currentLocation = this.getCurrentLocation();
    if (currentLocation) {
      return currentLocation.isRegion() ? (currentLocation.model as RegionViewModel).cinemas : [this.getCinemaById(currentLocation.id)];
    }

    return null;
  }

  public getCurrentLocation() {
    return this.currentLocation;
  }

  public getLocations() {
    return this.locations.filter((l) => l.type === LocationType.Cinema);
  }

  private createLocations(regions: RegionViewModel[], cinemas: CinemaViewModel[]) {
    let result: LocationModel[] = [];

    cinemas.forEach((cinema) => {
      result.push(new LocationModel(cinema));
    });

    return result;
  }

  public getCinemaNameByCinemaId(cinemaId: string) {
    return this.locations.find((l) => l.id === cinemaId)?.name;
  }
}
