<div class="dropdown-wrapper {{className}} {{isFocused? 'focus':''}}"
     [ngClass]="{'is-open':isOpen, 'border-0': _options.length === 1}" *ngIf="_options"
     (contextmenu)="$event.stopPropagation();">

  <ng-template [ngIf]="_options.length > 1" [ngIfElse]="single">
    <div class="box" (click)="toggle($event)">
      <ng-container [ngTemplateOutlet]="selectedItemTemplateDefault"
                    [ngTemplateOutletContext]="{class: disabled ? '' : 'dropdown-selectable'}">
      </ng-container>
    </div>
  </ng-template>
  <ul class="dropdown-options dropdown_menu dropdown_menu--animated dropdown_menu-1" *ngIf="_options && !disabled">
    <ng-container>
      <li class="d-flex gap-05 dropdown_item-{{i}}" id="li{{i}}" *ngFor="let option of _options; let i = index"
          [class.active]="selected === i" (click)="optionSelect(option, i, $event)">
        <ng-container [ngTemplateOutlet]="dropdownItemTemplate || dropdownItemTemplateDefault"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #dropdownItemTemplateDefault let-option="option">
<!--  <span class="col-8 col-lg-9 p-0 text-truncate">{{option.name}}</span>-->
  <span class="col-8 col-lg-9 p-0 text-truncate">{{option.name.length > 20 ? option.name.substring(0, 18)+'...' : option.name}}</span>
  <span class="col-4 col-lg-3 p-0 font-weight-bold">{{option.price?.toFixed(2) | money}}</span>
</ng-template>

<ng-template #single>
  <div class="pt-2 pt-sm-0">
    <ng-container [ngTemplateOutlet]="selectedItemTemplateDefault" [ngTemplateOutletContext]="{class: ''}">
    </ng-container>
  </div>
</ng-template>

<ng-template #selectedItemTemplateDefault let-class="class">
  <div class="dropdown {{class}} gap-05">
    <ng-container *ngIf="_options[selected] else pristineTemplate">
      <ng-container [ngTemplateOutlet]="selectedItemTemplate || selectedItemTemplateDefaultContent"
                    [ngTemplateOutletContext]="{option: _options[selected]}"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #selectedItemTemplateDefaultContent>
  <span class="text-truncate"
        [ngClass]="{'font-weight-bold dropdown-price': _options[selected].price == 0}">{{_options[selected].name}}&nbsp;</span>
</ng-template>

<ng-template #pristineTemplate>
  <span style="opacity: 0.4">{{pristineMessage}}</span>
</ng-template>
