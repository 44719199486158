import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TargetApiEnum } from '../enum/target-api.enum';
import { AuthStateService } from '../state/auth.state.service';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(private authStateService: AuthStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authStateService.getToken();

    if (authToken !== null) {
      req = req.clone({
        headers: req.headers.set(this.getHeaderName(req.headers.get('targetApi')), 'Bearer ' + authToken),
      });
    }

    return next.handle(req);
  }

  private getHeaderName(target: string): string {
    switch (target) {
      case TargetApiEnum.WP_API:
        return 'restauthorization';
      default:
        return 'Authorization';
    }
  }
}
