<div class="d-flex flex-column align-items-center virtual-pass-container text-center">
  <div *ngIf="isSafari && isAppleEnabled" class="col-12 col-md-6 col-lg-4 col-xl-3 p-0">
    <button class="btn btn-pay btn-pay-apple-wallet w-100" (click)="onClickedButton($event, virtualPassProvider.APPLE)">
      <img class="pointer" [src]="makeUrl('/assets/images/apple_wallet.svg')">
    </button>
  </div>
  <div *ngIf="!isSafari && isGoogleEnabled" class="col-12 col-md-6 col-lg-4 col-xl-3 p-0 pt-2">
    <button class="btn btn-pay btn-pay-google-pay w-100" (click)="onClickedButton($event, virtualPassProvider.GOOGLE)">
      <img class="pointer" [src]="makeUrl('/assets/images/google-pay.png')"> |
      {{ 'order.virtualpass.buttons.googlePay' | translate }}
    </button>
  </div>
</div>