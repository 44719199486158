import { TicketViewModel } from '../../view-model/shared/ticket/ticket.view.model';
import { ScreeningViewModel } from '../../view-model/screening/screening.view.model';
import { MovieViewModel } from '../../view-model/movie/movie.view.model';
import { MoviePrintViewModel } from '../../view-model/movie/movie-print.view.model';
import { ScreeningItemViewModel } from '../../view-model/order/screening-item/screening-item.view.model';
import { AgreementViewModel } from '../../view-model/agreement/agreement.view.model';
import { EventViewModel } from '../../view-model/event/event.view.model';
import { ScreenViewModel } from '../../view-model/screen/screen.view.model';

export class BasketPageModel {
  public movieCopy: MoviePrintViewModel;
  public movie: MovieViewModel;
  public screen: ScreenViewModel;
  public screening: ScreeningViewModel;
  public tickets: TicketViewModel[];
  public agreements: AgreementViewModel[];
  public screeningId: string;
  public event: EventViewModel;

  findTicketsByScreeningItemId(screeningItemId: string): Array<TicketViewModel> {
    return this.tickets.filter((x) => {
      return x.screeningItemId === screeningItemId;
    });
  }

  findTicketsBySeatId(seatId: string): Array<TicketViewModel> {
    return this.tickets.filter((x) => {
      return x.seatId === seatId;
    });
  }

  findTicketByScreeningItemIdAndTicketId(screeningItemId: string, ticketId: string): TicketViewModel | undefined {
    const a = this.tickets.filter((x) => {
      return x.screeningItemId === screeningItemId && x.id === ticketId;
    });

    return a !== undefined ? a[0] : undefined;
  }

  /**
   * Get tickets and apply changes if orderItem contains voucher
   * @return TicketModel[]
   */
  findTicketsByScreeningItemIdAndApplyVoucherChanges(item: ScreeningItemViewModel): Array<TicketViewModel> {
    if (item.hasVoucher()) {
      const ticketModel = new TicketViewModel();
      ticketModel.id = item.ticketId;
      ticketModel.seatId = item.seatId;
      ticketModel.price = item.ticketPrice;
      ticketModel.isVouchered = true;
      ticketModel.name = item.name;
      ticketModel.screeningItemId = item.id;

      return [ticketModel];
    } else {
      return this.tickets.filter((x) => x.screeningItemId === item.id);
    }
  }
}
