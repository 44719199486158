<div class="order-summary-container">
  <div class="container">

    <div class="main-container" *ngIf="loadingService.loadingStatus && loadingService.loadingStatus !== 'pending'">
      <ng-container *ngIf="screenMap">
        <div class="d-flex flex-column pt-2" *ngIf="loadingService.loadingStatus === 'success'; else loading">
          <ng-container [ngSwitch]="order.status">
            <div class="pt-5 pb-5">
              <ng-container *ngSwitchCase="4">
                <h7 class="text-center font-weight-bold transaction-successful m-0">{{
                      'order.summary.head.success.part1' | translate }}</h7>
                <ng-container *ngIf="order.bookingId">
                  <p class="transaction-details text-center"> {{'order.summary.head.title' | translate}}:
                    <strong>{{order.bookingId}}</strong>
                  </p>
                  <p class="transaction-details m-0 text-center">{{'order.summary.head.subtitle' | translate}}</p>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="5">
                <h7 class="text-center font-weight-bold transaction-failed m-0">
                  {{ 'order.summary.head.failed' |  translate }}
                </h7>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <h7 class="text-center font-weight-bold transaction-pending m-0">
                  {{ 'order.summary.head.pending' | translate }}
                </h7>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="col-12">
            <app-sidebar>
              <div sidebar-title>
                {{ 'order.ticketsSummary.title' | translate | uppercase}}
              </div>
              <div sidebar-body>
                <app-order-order-summary [order]="order" [selectedVoucherList]="selectedVoucherList"
                                         [basketPageModel]="basketPageModel" [isLastPage]="true"
                                         [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList">
                </app-order-order-summary>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="mt-4" *ngIf="!order?.isOnlyGiftCardOrder()">
          <app-order-virtual-pass [bookingId]="order?.bookingId"
                                  (loadingStatus)="generatingVirtualPass = $event"></app-order-virtual-pass>
        </div>

        <div class="row">
          <div class="col-12 mt-4 d-flex justify-content-center justify-content-sm-end">
            <button class="btn btn-primary" (click)="backToMain()">
              {{ 'order.ticketsSummary.backToMain' | translate }}
            </button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<app-loading *ngIf="generatingVirtualPass"></app-loading>