
export class ScreenStateModel {

  public showSeatGroupPopups: { [ key: string ]: boolean } = {};

  public screeningId: string|null = null;



}
