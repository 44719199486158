import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherPurchaseApiModel } from '../../api-model/voucher/voucher-purchase.api.model';
import { VoucherRedeemDayOfScreeningViewModel } from './voucher-redeem-day-of-screening.view.model';
import { VoucherRedeemDayOfWeekViewModel } from './voucher-redeem-day-of-week.view.model';
import { VoucherPurchaseItemViewModel } from './voucher-purchase-item.view.model';
import { IItemShopCard } from '../../../interfaces';

export class VoucherPurchaseViewModel extends AbstractViewModel<VoucherPurchaseApiModel> {
  id: string;
  itemId: string;
  itemPrice: number;
  name: string;
  description: string;
  note: string;
  taxRate: number;
  groupId: string;
  groupName: string;
  flgVoucherType: number;
  flgVoucherUseType: number;
  usesNumber: number;
  numberPerScreening: number;
  numberPerDay: number;
  numberPerWeek: number;
  numberPerMonth: number;
  pictureUrl: string;
  flgValidForSpecialProjects: boolean;
  batchId: string;
  batchName: string;
  batchDescription: string;
  batchNote: string;
  batchUnlimited: boolean;
  batchQuantityLeft: number;
  batchPictureUrl: string;
  batchValidFor: number;
  batchValidForType: number;
  batchFlgEndOfMonth: boolean;
  batchValidToDate: DateTime;
  dayOfWeekForRedeemList: VoucherRedeemDayOfWeekViewModel[] = [];
  dayOfScreeningForRedeemList: VoucherRedeemDayOfScreeningViewModel[] = [];
  items: VoucherPurchaseItemViewModel[] = [];
  availableInCinemas: string[];

  constructor(protected apiModel: VoucherPurchaseApiModel = new VoucherPurchaseApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.itemId = this.apiModel.itemId;
    this.itemPrice = this.apiModel.itemPrice;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.note = this.apiModel.note;
    this.taxRate = this.apiModel.taxRate;
    this.groupId = this.apiModel.groupId;
    this.groupName = this.apiModel.groupName;
    this.flgVoucherType = this.apiModel.flgVoucherType;
    this.flgVoucherUseType = this.apiModel.flgVoucherUseType;
    this.usesNumber = this.apiModel.usesNumber;
    this.numberPerScreening = this.apiModel.numberPerScreening;
    this.numberPerDay = this.apiModel.numberPerDay;
    this.numberPerWeek = this.apiModel.numberPerWeek;
    this.numberPerMonth = this.apiModel.numberPerMonth;
    this.pictureUrl = this.apiModel.pictureUrl;
    this.flgValidForSpecialProjects = this.apiModel.flgValidForSpecialProjects;
    this.batchId = this.apiModel.batchId;
    this.batchName = this.apiModel.batchName;
    this.batchDescription = this.apiModel.batchDescription;
    this.batchNote = this.apiModel.batchNote;
    this.batchUnlimited = this.apiModel.batchUnlimited;
    this.batchQuantityLeft = this.apiModel.batchQuantityLeft;
    this.batchPictureUrl = this.apiModel.batchPictureUrl;
    this.batchValidFor = this.apiModel.batchValidFor;
    this.batchValidForType = this.apiModel.batchValidForType;
    this.batchFlgEndOfMonth = this.apiModel.batchFlgEndOfMonth;
    this.batchValidToDate = this.apiModel.batchValidToDate ? DateTime.fromISO(this.apiModel.batchValidToDate) : null;
    this.dayOfWeekForRedeemList = this.apiModel.dayOfWeekForRedeemList.map((model) => new VoucherRedeemDayOfWeekViewModel(model));
    this.dayOfScreeningForRedeemList = this.apiModel.dayOfScreeningForRedeemList.map((model) => new VoucherRedeemDayOfScreeningViewModel(model));
    this.items = this.apiModel.items.map((model) => new VoucherPurchaseItemViewModel(model));
    this.availableInCinemas = this.apiModel.availableInCinemas;
  }

  toApiModel(): VoucherPurchaseApiModel {
    return undefined;
  }

  toItemShopModel() {
    return {
      id: this.itemId,
      name: this.name,
      description: this.description,
      cost: this.itemPrice,
      image: this.batchPictureUrl,
      maxQuantity: this.batchQuantityLeft ?? 99,
      groupId: this.groupId,
      groupName: this.groupName,
    } as IItemShopCard;
  }
}
