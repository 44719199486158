<div class="container d-flex flex-column justify-content-between" id="myTab" role="tablist">
  <div class="d-flex flex-column" [style]="'flex-grow:1'">
    <ul class="nav justify-content-end my-2">
      <li class="nav-item d-flex align-items-center" role="presentation">
        <a class="nav-link" [ngClass]="fragment === 'home' ? 'active' : ''" [routerLink]="['/user']" fragment="home">
          {{ 'user.user-my-profile.home' | translate }}
        </a>
      </li>
      <li class="nav-item d-flex align-items-center" role="presentation">
        <a class="nav-link" [ngClass]="fragment === 'profile' ? 'active' : ''" [routerLink]="['/user']"
           fragment="profile"> {{ 'user.user-my-profile.profile' | translate }} </a>
      </li>
      <li class="nav-item d-flex align-items-center" role="presentation">
        <a class="nav-link" [ngClass]="fragment === 'history' ? 'active' : ''" [routerLink]="['/user']"
           fragment="history"> {{ 'user.user-my-profile.history' | translate }} </a>
      </li>
    </ul>

    <div class="d-flex flex-column justify-content-between" [style]="'flex-grow:1'">
      <ng-container [ngSwitch]=" fragment">

        <ng-container *ngSwitchCase="userPanelTabEnum.Profile">
          <app-user-profile></app-user-profile>
        </ng-container>

        <ng-container *ngSwitchCase="userPanelTabEnum.Home">
          <app-user-loyalty></app-user-loyalty>
        </ng-container>

        <ng-container *ngSwitchCase="userPanelTabEnum.History">
          <app-user-history></app-user-history>
        </ng-container>
      </ng-container>
    </div>
  </div>

</div>