import { VideoType } from '../enum/video-type.enum';
import { AdModel } from './ad.model';
import { TrailerViewModel } from './view-model/movie/trailer.view.model';
import { VideoPlayState } from '../enum/video-play-state.enum';

export class VideoModel {
  playState: VideoPlayState;
  autoPlay: boolean;

  constructor(public model: any) {}

  get videoType() {
    switch (true) {
      case this.model instanceof AdModel:
        return VideoType.Ad;
      case this.model instanceof TrailerViewModel:
        return VideoType.Trailer;
      default:
        break;
    }
  }

  get source() {
    switch (true) {
      case this.model instanceof AdModel:
        return decodeURI(encodeURI(this.model.src));
      case this.model instanceof TrailerViewModel:
        return decodeURI(encodeURI(this.model.value));
      default:
        break;
    }
  }

  get skipTime() {
    switch (true) {
      case this.model instanceof AdModel:
        return this.model.skipTime;
      case this.model instanceof TrailerViewModel:
        return 0;
      default:
        break;
    }
  }

  canBeSkipped() {
    switch (true) {
      case this.model instanceof AdModel:
        return this.model.canBeSkipped();
      case this.model instanceof TrailerViewModel:
        return true;
      default:
        break;
    }
  }

  isAd() {
    return this.model instanceof AdModel;
  }
}
