import { Component, Inject, OnInit } from '@angular/core';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  public state: Observable<OrderViewModel>;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected orderStateService: OrderStateService) {}

  ngOnInit() {
    this.state = this.orderStateService.state$;
  }
}
