export class Membership {
  constructor(
    public cardTypeId: string,
    public voucherId: string,
    public voucherItemId: string,
    public canReserve: boolean,
    public linkToDescription: string,
  ) {
  }
}
