import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { ScreeningRequestModel } from '../model/request/screening.request.model';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';
import { OccupancyApiModel } from '../model/api-model/screening/occupancy/occupancy.api.model';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { SeparatorApiModel } from '../model/api-model/screening/separator/separator.api.model';
import { GaTicketApiModel } from '../model/api-model/screening/ga/ga-ticket.api.model';

@Injectable({
  providedIn: 'root',
})
export class ScreeningHttpService {
  constructor(private http: HttpClient) {}

  getScreenings(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/screening`, {
      params: {
        dateTimeFrom: model.isoDateTimeFrom,
        dateTimeTo: model.isoDateTimeTo,
        movieId: model.movieId,
      },
    });
  }

  getRegionScreenings(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/region/${model.regionId}/screening`, {
      params: {
        dateTimeFrom: model.isoDateTimeFrom,
        dateTimeTo: model.isoDateTimeTo,
        movieId: model.movieId,
      },
    });
  }

  getCinemaScreenings(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/cinema/${model.cinemaId}/screening`, {
      params: {
        dateTimeFrom: model.isoDateTimeFrom,
        dateTimeTo: model.isoDateTimeTo,
      },
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getCinemaScreening(cinemaId: string, id: string): Observable<ScreeningApiModel> {
    return this.http.get<ScreeningApiModel>(`/cinema/${cinemaId}/screening/${id}`);
  }

  getCinemaScreeningOccupancy(cinemaId: string, id: string): Observable<OccupancyApiModel> {
    return this.http.get<OccupancyApiModel>(`/cinema/${cinemaId}/screening/${id}/occupancy`);
  }

  getCinemaScreeningTickets(cinemaId: string, id: string, seatIds: string[]): Observable<TicketApiModel[]> {
    return this.http.get<TicketApiModel[]>(`/cinema/${cinemaId}/screening/${id}/tickets?seatIds=${seatIds.join(',')}`);
  }

  getCinemaMovieScreenings(cinemaId: string, movieId: string, dateTimeFrom: string, dateTimeTo: string): Observable<ScreeningApiModel[]> {
    return this.http
      .get<ScreeningApiModel[]>(`/cinema/${cinemaId}/movie/${movieId}/screening?dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}`)
      .pipe(map((result) => plainToInstance(ScreeningApiModel, result as object[])));
  }

  getCinemaScreeningGaTickets(cinemaId: string, screeningId: string): Observable<GaTicketApiModel[]> {
    return this.http.get<GaTicketApiModel[]>(`/cinema/${cinemaId}/screening/${screeningId}/ga/tickets`);
  }

  @Cacheable({ maxAge: 3600000 })
  getCinemaScreeningSeparators(cinemaId: string, id: string): Observable<SeparatorApiModel[]> {
    return this.http.get<SeparatorApiModel[]>(`/cinema/${cinemaId}/screening/${id}/separators`);
  }
}
