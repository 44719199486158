<div class="m-0">
  <div class="p-0 px-0">
    <div class="text-center col-12 p-0">
      <app-steps [step]="1"></app-steps>
    </div>
  </div>

  <div class="container mt-4">
    <div class="main-container">
      <div class="row" [smooth-in]="!this.loadingService.isLoading(LoaderEnum.MAIN)">
        <div class="col-12 col-md-3">
          <app-book-movie-list [screen]="screen" [cinemaId]="cinemaId"></app-book-movie-list>
        </div>
        <div class="col-12 col-md-9 col-screen">
          <app-message [custom]="true"></app-message>
          <div class="col-12 m-3" [smooth-in]="!this.loadingService.isLoading(LoaderEnum.MAIN)">
            <app-simple-countdown></app-simple-countdown>
          </div>
          <app-sidebar>
            <div sidebar-title>
              {{ 'order.title' | translate }}
            </div>
            <div class="cart-sidebar" sidebar-body>
              <app-screen-ticket-count-general-admission *ngIf="screen?.generalAdmission" [limit]="getTicketsLimit()"
                                                         [currency]="environment.constants.currency"
                                                         [ticketList]="availableTicketListGeneralAdmission"
                                                         (ticketSelectedEventEmitter)="onSelectedTicketGeneralAdmission($event)"
                                                         (ticketDroppedEventEmitter)="onDroppedTicketGeneralAdmission($event)"
                                                         (onReadyEventEmitter)="onGeneralAdmissionComponentInit()"
                                                         [selectedTickets]="orderTickets">
              </app-screen-ticket-count-general-admission>
            </div>
          </app-sidebar>
        </div>
      </div>

      <div class="row" [smooth-in]="!this.loadingService.isLoading(LoaderEnum.MAIN)">
        <div class="col-12 my-4 mx-2">
          <app-screen-navigation (navigationEvent)="onNavigationClick($event)" [screeningId]="screeningId"
                                 [isBackButtonVisible]="false"
                                 [isNextButtonDisabled]="!ticketList || 0 === ticketList.length" [totalPrice]="0.00">
          </app-screen-navigation>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="container">
  <div class="row no-gutters">
    <div class="text-center col-12">
      <app-steps [step]="2"></app-steps>
    </div>
  </div>

  <div class="row" [smooth-in]="!this.loadingService.isLoading(LoaderEnum.MAIN)">
    <div class="col-12 col-md-3">
      <app-book-movie-list [screen]="screen" [cinema]="orderState?.cinema"></app-book-movie-list>
    </div>
    <div class="col-12 col-md-9 col-screen">
      <app-message [custom]="true"></app-message>
      <ng-template #space>
        <div class="p-2 m-2"></div>
      </ng-template>

      <app-screen-ticket-count-general-admission *ngIf="screen?.generalAdmission" [limit]="getTicketsLimit()"
                                                 [currency]="environment.constants.currency"
                                                 [ticketList]="availableTicketListGeneralAdmission"
                                                 (ticketSelectedEventEmitter)="onSelectedTicketGeneralAdmission($event)"
                                                 (ticketDroppedEventEmitter)="onDroppedTicketGeneralAdmission($event)"
                                                 (onReadyEventEmitter)="onGeneralAdmissionComponentInit()"
                                                 [selectedTickets]="orderTickets">
      </app-screen-ticket-count-general-admission>

    </div>
  </div>

  <div class="row" [smooth-in]="!this.loadingService.isLoading(LoaderEnum.MAIN)">
    <div class="col-12">
      <app-screen-navigation (navigationEvent)="onNavigationClick($event)" [ScreeningId]="screeningId"
                             [isBackButtonDisabled]="true"
                             [isNextButtonDisabled]="!ticketList || 0 === ticketList.length" [totalPrice]="0.00">
      </app-screen-navigation>
    </div>
  </div>
</div> -->