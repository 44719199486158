import {Expose} from 'class-transformer';

export class UserScreenApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  type: string;

  @Expose()
  feature: string;

  @Expose()
  number: number;
}
