<div class="card">
  <div class="movie-poster">
    <img pimg [imageUrl]="posterUrl" [alt]="movieSchedule.movieTitle" />
  </div>
  <div>
    <div class="movie-head">
      <div class="movie-title">
        {{ movieSchedule.movieTitle }} <app-screen-tag [model]="movieSchedule.screenTag"></app-screen-tag>
      </div>
      <div class="col-12 xcol-xl-16 flex-row movie-rating p-0 mt-3">
        {{ movieSchedule?.duration }} min - Rated {{ movieSchedule?.rating[0]?.value }}
      </div>
      <div class="col-12 xcol-xl-16 p-0">
        <app-movie-attributes [release]="movieSchedule.movieCopyCollection[0]?.release"></app-movie-attributes>
      </div>
      <div class="movie-description mt-3">
        {{ movieSchedule.description }}
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let movieCopySchedule of movieSchedule.movieCopyCollection">
        <div class="col-12 xcol-xl-16 flex-row"
             *ngIf="showOnlyGeneralAdmission === false || (showOnlyGeneralAdmission === true && movieCopySchedule.hasAnyGeneralAdmission())">
          <b class="d-block mt-3">{{ movieCopySchedule.printType }}</b>
          <div class="movie-screenings">
            <ng-container *ngFor="let screening of movieCopySchedule.screenings">
              <div class="movie-screening"
                   *ngIf="showOnlyGeneralAdmission === false || (showOnlyGeneralAdmission === true && screening.generalAdmission === true)">
                <button class="btn m-0 p-0 w-100 card-box-shadow"
                        [disabled]="isPastScreening(screening) || screening.availabilityStatus === 0"
                        (click)="onSelect(screening)">
                  {{screening.timeFrom | dateTime: '24H'}} {{ screening.duration }}
                  {{ screening.generalAdmission && showOnlyGeneralAdmission == false ? 'GA' : '' }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>