import { AbstractViewModel } from '../../../abstract.view.model';
import { GaTicketApiModel } from '../../../api-model/screening/ga/ga-ticket.api.model';
import { GaTicketExtraFeeViewModel } from './ga-ticket-extra-fee.view.model';

export class GaTicketViewModel extends AbstractViewModel<GaTicketApiModel> {
  id: string;
  name: string;
  price: number;
  availableAmount: number;
  seatGroupName: string;
  seatGroupId: string;
  extraFees: GaTicketExtraFeeViewModel[];
 
  constructor(protected apiModel: GaTicketApiModel = new GaTicketApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.availableAmount = this.apiModel.availableAmount;
    this.seatGroupName = this.apiModel.seatGroupName;
    this.seatGroupId = this.apiModel.seatGroupId;
    this.extraFees = this.apiModel.extraFees?.map(model => new GaTicketExtraFeeViewModel(model));
  }

  toApiModel(): GaTicketApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
