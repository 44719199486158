export enum ScreeningAvailabilityStatus {
    /**
     * Only visible
     */
    ForPreview = 0,
  
    /**
     * Available only for sale
     */
    ForSale = 1,
  
    /**
     * Available only for reservation
     */
    ForReservation = 2,
  
    /**
     * Available only for sale and reservation
     */
    ForSaleAndReservation = 3,
  }