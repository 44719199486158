import { TicketViewModel } from "../../../../view-model/shared/ticket/ticket.view.model";
import { TicketListSortNoneStrategy } from "./ticket-list-sort-none.strategy";
import { TicketListSortPriceAscendingStrategy } from "./ticket-list-sort-price-ascending.strategy";
import { TicketListSortPriceDescendingStrategy } from "./ticket-list-sort-price-descending.strategy";
import { TicketListSortStrategy } from "./ticket-list-sort.strategy";

export enum TicketListSortStrategyType {

  None = 'none',

  PriceAscending = 'price_asc',

  PriceDescending = 'price_desc'

}

export class TicketListSortStrategyContext implements TicketListSortStrategy {

  private strategy: TicketListSortStrategy = null;

  constructor(strategy: TicketListSortStrategyType) {

    if (strategy === TicketListSortStrategyType.None) {

      this.strategy = new TicketListSortNoneStrategy();
    } else if (strategy === TicketListSortStrategyType.PriceAscending) {

      this.strategy = new TicketListSortPriceAscendingStrategy();
    } else if (strategy === TicketListSortStrategyType.PriceDescending) {

      this.strategy = new TicketListSortPriceDescendingStrategy();
    } else {

      throw new Error(`Unknown ticket list sort strategy '${strategy}'`);
    }

  }

  public sort(ticketList: Array<TicketViewModel>): Array<TicketViewModel> {

    return this.strategy.sort(ticketList);
  }

}
