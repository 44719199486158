import { Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ENVIRONMENT_TOKEN } from '../../../injection.tokens';
import { AppService } from '../../../service/app.service';
import { SeatTranslatorService } from '../../../service/seat/seat-translator.service';
import { ModifierTypeEnum } from '../../enum/modifier-type.enum';
import { OrderViewModel } from '../../view-model/order/order.view.model';
import { ScreeningItemExtraFeeViewModel } from '../../view-model/order/screening-item/screening-item-extra-fee.view.model';
import { ScreeningItemViewModel } from '../../view-model/order/screening-item/screening-item.view.model';
import { ScreenViewModel } from '../../view-model/screen/screen.view.model';
import { ScreeningViewModel } from '../../view-model/screening/screening.view.model';
import { TicketExtraFeeViewModel } from '../../view-model/shared/ticket/ticket-extra-fee.view.model';
import { TicketViewModel } from '../../view-model/shared/ticket/ticket.view.model';
import { VoucherTypeModel } from '../../voucher-type.model';
import { CateringAggregationArticleViewModel } from '../../catering/catering-aggregation.view.model';
import { OrderSummaryArticleCombinationItemViewModel } from './order-summary-article-combination-item.view.model';
import { OrderSummaryGiftCardItemViewModel } from './order-summary-gift-card-item.view.model';
import { OrderSummaryItemViewModel } from './order-summary-item.view.model';
import { OrderSummaryPersonalViewModel } from './order-summary-personal.view.model';
import { OrderSummaryVoucherItemViewModel } from './order-summary-voucher-item.view.model';
import { OrderSummaryViewModel } from './order-summary.view.model';
import { VoucherPurchaseViewModel } from '../../view-model/voucher/voucher-purchase.view.model';
import { VoucherItemViewModel } from '../../view-model/order/voucher-item/voucher-item.view.model';

export class OrderSummaryBuilder {
  private _model: OrderSummaryViewModel = new OrderSummaryViewModel();
  private skippingRomanDigitsInRows = false;
  private skippingRomanDigitsInCols = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private appService: AppService,
    private translateService: TranslateService,
    private order: OrderViewModel,
    private ticketList: Array<TicketViewModel>,
    private voucherTypeList: Array<VoucherTypeModel>,
    private cateringArticleCombinationList: Array<CateringAggregationArticleViewModel>,
    private screeningId: string,
    private screen: ScreenViewModel = null,
    private screening: ScreeningViewModel = null
  ) {}

  getOrderSummary(): OrderSummaryViewModel {
    if (this.screen && this.screen.seats) {
      const seats = this.screen.seats;
      this.skippingRomanDigitsInRows = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.rowNumber));
      this.skippingRomanDigitsInCols = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.legendCol));
    }

    console.log('getOrderSummary order: ', this.order);
    if (this.order) {
      let itemsWithoutVoucher: Array<ScreeningItemViewModel> = this.order.screeningItems.filter((x) => !x.hasVoucher());
      let itemsWithVoucher = this.order.screeningItems.filter((x) => x.hasVoucher());

      if (this.screeningId) {
        itemsWithoutVoucher = itemsWithoutVoucher.filter((x) => x.screeningId === this.screeningId);
        itemsWithVoucher = itemsWithVoucher.filter((x) => x.screeningId === this.screeningId);
      }

      console.log('itemsWithoutVoucher: ', itemsWithoutVoucher, 'itemsWithVoucher:', itemsWithVoucher);

      this._model.items = [];
      this.fillModelItems(itemsWithoutVoucher, 0);
      this.fillModelItems(itemsWithVoucher, 1);

      console.log('items:', this._model.items);

      this.fillVoucherItems(this.order.voucherItems);
    }

    this.sortSeats();

    if (this.voucherTypeList) {
      this.voucherTypeList.forEach((voucherItem) => {
        let summaryItem: OrderSummaryVoucherItemViewModel = this._model.voucherItems.find((x) => x.voucher.id === voucherItem.id);

        if (!summaryItem) {
          summaryItem = new OrderSummaryVoucherItemViewModel();
          summaryItem.voucher = voucherItem;
          summaryItem.quantity = 1;
          this._model.voucherItems.push(summaryItem);
        } else {
          summaryItem.quantity++;
        }

        this._model.taxPrice += voucherItem.taxPrice;
        this._model.totalPrice += voucherItem.price;
        this._model.subTotalPrice += voucherItem.price;
      });
    }

    this.order?.cardItems?.forEach((card) => {
      const taxRate = this.order.cardTypeItems.find((type) => type.id === card.cardTypeId)?.taxRate ?? 0;

      this._model.giftCardItems.push(new OrderSummaryGiftCardItemViewModel(card));
      this._model.taxPrice += card.value - card.value / (1.0 + taxRate / 100.0);
      this._model.totalPrice += card.value;
      this._model.subTotalPrice += card.value;
    });

    if (this.cateringArticleCombinationList) {
      for (const article of this.cateringArticleCombinationList) {
        let summaryItem: OrderSummaryArticleCombinationItemViewModel = this._model.cateringItems.find(
          (x) =>
            x.articleCombination.selectedCombinationHash === article.selectedCombinationHash &&
            x.articleCombination.voucherNumber === article.voucherNumber &&
            x.articleCombination.voucherName === article.voucherName
        );

        if (!summaryItem) {
          summaryItem = new OrderSummaryArticleCombinationItemViewModel();
          summaryItem.articleCombination = article;
          summaryItem.price = article.price;
          summaryItem.unitPriceWithSeparatedItems = summaryItem.price;
          summaryItem.unitPriceWithoutSeparatedItems = summaryItem.price;
          summaryItem.quantity = article.selectedQuantity;
          const selectedModifierItemsName: Array<string> = new Array<string>();

          article.modifierArticleList.forEach((modifier) => {
            let selectedModifierItemName = '';

            modifier.itemCollection.forEach((modifierItem) => {
              selectedModifierItemName = modifierItem.quantity > 1 ? `${modifierItem.name} (x${modifierItem.quantity})` : modifierItem.name;

              if (modifier.type === ModifierTypeEnum.NO_TYPE) {
                selectedModifierItemName = `${this.translateService.instant('catering.article.modifier.type.no_type')} ${selectedModifierItemName}`;
              }

              if (!modifier.separateItem) {
                selectedModifierItemsName.push(selectedModifierItemName);
              } else {
                summaryItem.unitPriceWithSeparatedItems += modifierItem.price * modifierItem.quantity;
              }
            });
          });

          article.subArticleList.forEach((subArticle) => {
            let selectedSubArticleItemName = subArticle.name;

            subArticle.replacementList.forEach((subArticleReplacer) => {
              selectedSubArticleItemName = subArticleReplacer.name;
            });

            selectedModifierItemsName.push(selectedSubArticleItemName);
          });

          selectedModifierItemsName.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });

          summaryItem.description = selectedModifierItemsName.join('\u200e, ');
          summaryItem.price *= article.selectedQuantity;

          this._model.cateringItems.push(summaryItem);
        } else {
          summaryItem.price += summaryItem.unitPriceWithoutSeparatedItems * article.selectedQuantity;
          summaryItem.quantity += article.selectedQuantity;
        }
        this._model.totalPrice += summaryItem.unitPriceWithSeparatedItems * article.selectedQuantity;
      }
    }

    this._model.personal = new OrderSummaryPersonalViewModel(this.order);

    if (this.screen) {
      this._model.screen = this.screen;
    }

    if (this.screening) {
      this._model.screening = this.screening;
    }

    this._model.valueToPay = this.order.valueToPay;
    return this._model;
  }

  private fillModelItems(orderItems: ScreeningItemViewModel[], itemsType: number) {
    const firstPredicate = (ticketItem, item) => ticketItem.screeningItemId === item.id;
    const secondPredicate = (ticketItem, item) => ticketItem.screeningItemId === item.id && ticketItem.id === item.ticketId;

    const ticketList = this.ticketList ?? [];

    console.log('ticketList:', ticketList);
    console.log('orderItems:', orderItems);

    const items = itemsType === 0 ? this._model.items : this._model.orderVoucherItems;
    console.log('items', items);

    orderItems.forEach((item: ScreeningItemViewModel) => {
      const ticketItem = ticketList.find((ticket) => secondPredicate(ticket, item)) || ticketList.find((ticket) => firstPredicate(ticket, item));
      console.log('createdFromSalesDocument:', this.order.createdFromSalesDocument);

      const clonedTicketItem: TicketViewModel = ticketItem ? cloneDeep(ticketItem) : new TicketViewModel();
      if (this.order.createdFromSalesDocument) {
        clonedTicketItem.name = item.name;
        clonedTicketItem.price = item.price;
        clonedTicketItem.oldPrice = item.price;
        clonedTicketItem.voucherName = item.voucherName;
        clonedTicketItem.voucherNumber = item.voucherNumber;
        clonedTicketItem.isVouchered = item.hasVoucher();
        clonedTicketItem.row = item.row;
        clonedTicketItem.seat = item.seat;
        clonedTicketItem.extraFees = item.extraFees.map((extraFee: ScreeningItemExtraFeeViewModel) => {
          const ticketExtraFeeModel: TicketExtraFeeViewModel = new TicketExtraFeeViewModel();

          ticketExtraFeeModel.isOptional = extraFee.isOptional;
          ticketExtraFeeModel.name = extraFee.name;
          ticketExtraFeeModel.price = extraFee.price;
          ticketExtraFeeModel.id = extraFee.name;
          ticketExtraFeeModel.extraFeeId = extraFee.name;

          return ticketExtraFeeModel;
        });
      } else {
        clonedTicketItem.oldPrice = clonedTicketItem.price;

        if (item.hasVoucher()) {
          clonedTicketItem.id = item.ticketId;
          clonedTicketItem.oldPrice = item.price;
          clonedTicketItem.voucherNumber = item.voucherNumber;
          clonedTicketItem.name = item.name;
          clonedTicketItem.voucherName = item.voucherName;
          clonedTicketItem.isVouchered = true;
          clonedTicketItem.price = item.ticketPrice;
        }

        clonedTicketItem.extraFees = clonedTicketItem.extraFees.map((ef) => {
          const foundExtraFeeInItem = item.extraFees.find((efi) => efi.id === ef.extraFeeId);
          if (foundExtraFeeInItem) {
            ef.price = foundExtraFeeInItem.price;
          }

          return ef;
        });
      }

      clonedTicketItem.seatId = item.seatId;

      let summaryItem = items.find(
        (i) =>
          i.ticket.name === clonedTicketItem.name &&
          i.ticket.id === clonedTicketItem.id &&
          i.ticket.price === clonedTicketItem.price &&
          i.ticket.voucherNumber === clonedTicketItem.voucherNumber &&
          i.ticket.voucherName === clonedTicketItem.voucherName &&
          i.ticket.extraFees.some((ef) => clonedTicketItem.extraFees.some((e) => ef.extraFeeId === e.extraFeeId && ef.price === e.price))
      );
      console.log('summaryItem', summaryItem);

      let allItemSummary = this._model.allItems.find(
        (i) =>
          i.ticket.name === clonedTicketItem.name &&
          i.ticket.id === clonedTicketItem.id &&
          i.ticket.price === clonedTicketItem.price &&
          i.ticket.voucherNumber === clonedTicketItem.voucherNumber &&
          i.ticket.voucherName === clonedTicketItem.voucherName &&
          i.ticket.extraFees.some((ef) => clonedTicketItem.extraFees.some((e) => ef.extraFeeId === e.extraFeeId && ef.price === e.price))
      );
      console.log('allItemSummary', allItemSummary);

      if (summaryItem === undefined) {
        summaryItem = new OrderSummaryItemViewModel(clonedTicketItem, 1);
        items.push(summaryItem);
      } else {
        summaryItem.quantity += 1;
      }

      if (this.screen && this.screen.pseats) {
        summaryItem.ticketSymbols.push(this.generateSeatNumber(clonedTicketItem.seatId));
      }

      if (allItemSummary === undefined) {
        allItemSummary = new OrderSummaryItemViewModel(clonedTicketItem, 1);
        this._model.allItems.push(allItemSummary);
      } else {
        allItemSummary.quantity += 1;
      }

      this._model.taxPrice += clonedTicketItem.priceWithExtraFee - clonedTicketItem.priceWithExtraFee / (1.0 + clonedTicketItem.tax / 100.0);
      this._model.totalPrice += clonedTicketItem.priceWithExtraFee;
      this._model.subTotalPrice += allItemSummary.ticket.priceWithExtraFee;
    });

    console.log('### push items', items, this._model.items);
    //this._model.items.push(...items);
  }

  private fillVoucherItems(voucherItems: VoucherItemViewModel[]) {
    voucherItems.forEach((item: VoucherItemViewModel) => {
      const model: OrderSummaryVoucherItemViewModel = new OrderSummaryVoucherItemViewModel();
      model.quantity = item.quantity;
      const voucherType: VoucherTypeModel = new VoucherTypeModel();
      voucherType.id = item.id;
      voucherType.itemId = item.itemId;
      voucherType.name = item.itemName;
      voucherType.price = item.itemPrice;
      voucherType.taxPrice;
      voucherType.taxRate = item.itemTaxRate;
      model.voucher = voucherType;

      this._model.voucherItems.push(model);

      this._model.totalPrice += model.voucher.price;
      this._model.subTotalPrice += model.voucher.price;
    });
  }

  private generateSeatNumber(seatId: string): (string | number)[] {
    const seat = this.screen.findSeatByIdFlat(seatId);
    return seat
      ? [
          seat.rowNumber,
          seat.legendCol,
          SeatTranslatorService.translateValue(seat.rowNumber, this.skippingRomanDigitsInRows),
          SeatTranslatorService.translateValue(seat.legendCol, this.skippingRomanDigitsInCols),
        ]
      : [];
  }

  private sortSeats(): void {
    this._model.items.forEach((elem) => {
      elem.ticketSymbols.sort((row, seat) => {
        // @ts-ignore
        return row[2] - seat[2] || row[3] - seat[3];
      });
    });
  }
}
