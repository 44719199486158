<div class="m-0">
  <div class="p-0 px-0">
    <div class="text-center col-12 p-0">
      <app-steps [step]="2"></app-steps>
    </div>
  </div>

  <div class="container mt-4">
    <div class="main-container">
      <ng-container *ngIf="basketPageModel">
        <div class="row d-block d-md-none">
          <div class="col-12 m-3">
            <app-simple-countdown></app-simple-countdown>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-3">
            <app-book-movie-list [screen]="basketPageModel?.screen" [cinemaId]="order?.cinemaId"
                                 [movieCopy]="basketPageModel?.movieCopy"></app-book-movie-list>
          </div>
          <div class="col-12 col-md-9">
            <div class="row d-none d-md-block">
              <div class="col-12">
                <app-simple-countdown></app-simple-countdown>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-lg-6 mt-4">
                    <app-sidebar>
                      <div sidebar-title>
                        {{ 'screen.seatsSelectedList.label' | translate  }}
                      </div>
                      <div sidebar-body>
                        <ng-container *ngIf="order">
                          <app-basket-list [basketPageModel]="basketPageModel" [orderModel]="order"
                                           [vouchers]="vouchers" (changeEvent)="onChangedSeatTicket($event)">
                          </app-basket-list>
                        </ng-container>
                      </div>
                    </app-sidebar>
                  </div>
                  <div class="col-12 col-lg-6 mt-4">
                    <app-sidebar>
                      <div sidebar-title>
                        {{ 'order.title' | translate }}
                      </div>
                      <div sidebar-body>
                        <app-order-tickets-summary [order]="order" [ticketList]="ticketList"
                                                   [couldChangeOptionalFees]="true"
                                                   [screeningId]="basketPageModel.screening?.id">
                        </app-order-tickets-summary>
                      </div>
                    </app-sidebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <app-screen-navigation (navigationEvent)="onNavigationClick($event)"
                                   [screeningId]="basketPageModel.screening?.id"
                                   [isNextButtonDisabled]="!ticketList || 0 === ticketList.length" [totalPrice]="0.00">
            </app-screen-navigation>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-popup [isVisible]="warningModalVisibled">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="'popups.deliver_to_seat_question' | translate"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-6">
      <button class="btn btn-lg btn-default" (click)="onModalClick('skip')">{{ 'popups.buttons.decline' | translate
        }}</button>
    </div>
    <div class="col-6">
      <button class="btn btn-lg btn-primary" (click)="onModalClick('continue')">{{ 'popups.buttons.accept' | translate
        }}</button>
    </div>
  </div>
</app-popup>