import { AbstractViewModel } from '../../../abstract.view.model';
import { UserScreenApiModel } from '../../../api-model/user-history/screen/user-screen.api.model';
import 'libs/core/src/lib/prototypes/string.prototype';

export class UserScreenViewModel extends AbstractViewModel<UserScreenApiModel> {
  public id: string = null;
  public name: string = null;
  public type: string = null;
  public feature: string = null;
  public number: number = null;

  constructor(protected apiModel: UserScreenApiModel = new UserScreenApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.type = this.apiModel.type;
    this.feature = this.apiModel.feature?.correctSpaceAfterCommas();
    this.number = this.apiModel.number;
  }

  toApiModel(): UserScreenApiModel {
    return undefined;
  }
}
