import {DateTime} from 'luxon';
import { CartItem } from './cart-item';

export class CartTicketItemModel implements CartItem {

  public screeningItemId: string;

  public screeningId: string|null = null;

  public saleTimeTo: DateTime|null = null;

  public reservationTimeTo: DateTime|null = null;

  public ticketId: string|null = null;

  public ticketName: string|null = null;

  public quantity = 0;

  public movieId: string|null = null;

  public movieTitle: string|null = null;

  public movieDescription: string|null = null;

  public screeningTimeFrom: DateTime|null = null;

  public screeningTimeTo: DateTime|null = null;

  public screeningDuration = 0;

  public movieLanguage: string|null = null;

  public movieSubtitles: Array<string>|null = null;

  public moviePrintType: string|null = null;

  public screenId: string = null;

  public screenName: string = null;

  public screenFeature: string|null = null;

  public screenNumber: number|null = null;

  public screenType: string|null = null;

  public screenDefaultSeatDescription: string|null = null;

  public screenDefaultSeatGroupName: string|null = null;

  public moviePosterCollection: Array<string> = new Array<string>();

  public price = 0.0;


  public getMoviePoster(): string|null {

    return this.moviePosterCollection[0] ? this.moviePosterCollection[0] : null;
  }

  public getMovieTitle(): string {

    return this.movieTitle ? this.movieTitle : '';
  }
}
