import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { storageKey } from 'libs/core/src/app.const';
import { Observable, Subject, Subscription } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { AuthStateService } from '../../state/auth.state.service';
import { CateringStateService } from '../../state/catering.state.service';
import { UserAreaService } from '../user-area.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderStateService } from '../../state/order.state.service';
import { StepsService } from '../steps.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NavigationHelperService {
  private _paymentStarted = false;
  private _paymentStarting = false;
  private _isNewFoodAndBeverageUrl = false;
  private cateringSaleEnabled: boolean;
  private giftCardTransaction: boolean;

  private currentUrl = new Subject<string>();
  currentUrlChange$: Observable<string>;

  public hasNewFoodUrl(): boolean {
    return this._isNewFoodAndBeverageUrl;
  }

  public set paymentStarted(value) {
    this._paymentStarted = value;
  }

  public get paymentStarted() {
    return this._paymentStarted;
  }

  public set newFoodAndBeverageUrl(value) {
    this._isNewFoodAndBeverageUrl = value;
  }

  public get newFoodAndBeverageUrl() {
    return this._isNewFoodAndBeverageUrl;
  }

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private router: Router,
    private userAreaService: UserAreaService,
    private cateringStateService: CateringStateService,
    private authStateService: AuthStateService,
    private orderStateService: OrderStateService,
    private stepsService: StepsService
  ) {
    this.currentUrlChange$ = this.currentUrl.asObservable();

    this.cateringStateService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.cateringSaleEnabled = value;
      console.log('cateringStateService', this.cateringSaleEnabled);
    });

    this.orderStateService.state$.pipe(untilDestroyed(this)).subscribe((order) => {
      this.giftCardTransaction = order?.isOnlyGiftCardOrder();
    });

    this.cateringStateService.checkAvailability();
  }

  public setCurrentUrl(url: string) {
    this.currentUrl.next(url);
  }

  public getPreviousRouteForRoute(routeName: string): string {
    let val = this.environment.pages[routeName]['navigation']['previous'];
    if (typeof val === 'function') {
      val = val({
        env: this.environment,
        cateringSaleEnabled: this.cateringSaleEnabled,
        giftCardTransaction: this.giftCardTransaction,
        userLogin: this.authStateService.isLogged(),
        flowType: this.stepsService.FlowType,
      });
    }

    console.log(`#getPreviousRouteForRoute ${routeName} -> ${val}`);
    return val;
  }

  public getNextRouteForRoute(routeName: string): string {
    let val = this.environment.pages[routeName]['navigation']['next'];
    if (typeof val === 'function') {
      val = val({
        env: this.environment,
        cateringSaleEnabled: this.cateringSaleEnabled,
        orderIsInSession: this.orderIsInSession(),
        userLogin: this.authStateService.isLogged(),
      });
    }

    console.log(`#getNextRouteForRoute ${routeName} -> ${val}`);
    return val;
  }

  public getPreviousRoute(route: ActivatedRouteSnapshot): string {
    return this.getPreviousRouteForRoute(route.data.pageIdentify);
  }

  public getNextRoute(route: ActivatedRouteSnapshot): string {
    return this.getNextRouteForRoute(route.data.pageIdentify);
  }

  private orderIsInSession(): boolean {
    return Boolean(this.authStateService.getItem(storageKey.order));
  }

  public getRouteFor(routeName: string, route: ActivatedRouteSnapshot): string {
    return this.environment.pages[route.data.pageIdentify]['navigation']['target'][routeName];
  }

  public getNextRouteFor(routeName: string): string {
    return this.environment.pages[routeName]['navigation']['next'];
  }

  public goToRepertoire() {
    const backUrl = this.authStateService.getItem(storageKey.backUrl);
    window.location.href = backUrl ? backUrl : this.environment.backUrl;
  }

  public redirectToError(errorCode: string): void {
    const errorPage: string = this.userAreaService.isUserAreaEnabled === true ? 'user-area/error' : 'error';

    this.router.navigate([errorPage, errorCode]);
  }

  public getCurrentRoutePageIdentifier(): string | null {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.snapshot.data['pageIdentify'] || null;
  }

  get paymentStarting(): boolean {
    return this._paymentStarting;
  }

  set paymentStarting(value: boolean) {
    this._paymentStarting = value;
  }

  public canBackFromPaymentPage() {
    return !this.paymentStarted;
  }

  public prepareCorrectPreviousPage(route: ActivatedRouteSnapshot) {
    const previous = this.getPreviousRoute(route);
    let cinemaId: string = null;
    let lastScreeningId: string = null;
    const url = [previous];

    if (previous === 'screen') {
      cinemaId = this.authStateService.getItem(storageKey.chosenLocation);
      lastScreeningId = this.authStateService.getItem(storageKey.lastScreeningId);

      if (cinemaId && lastScreeningId) {
        url.push(lastScreeningId);
      } else {
        const location =
          this.authStateService.getItem(storageKey.backUrl) !== null ? this.authStateService.getItem(storageKey.backUrl) : this.environment.backUrl;
        window.history.replaceState(null, null, location);
        return;
      }
    }

    const previousUrl = window.location.origin + '/#' + this.router.createUrlTree(url, { queryParams: { cinemaId: cinemaId } });
    window.history.replaceState(null, null, previousUrl);
  }

  shouldNavigateToErrorPage(): boolean {
    const routeIdentifier: string = this.getCurrentRoutePageIdentifier();
    return !(this.environment?.constants?.excludeTtlErrorOnPages?.indexOf(routeIdentifier) >= 0);
  }
}
