import { DateTime } from 'luxon';
import { IScreeningOptions } from '../../interfaces';
import { ScreeningType } from '../../enum/screening-type';

export class ScreeningRequestModel {
  movieId?: string;

  constructor(
    public regionId?: string,
    public cinemaId?: string,
    public dateTimeFrom?: DateTime,
    public dateTimeTo?: DateTime,
    public options?: IScreeningOptions
  ) {
    this.movieId = options?.type === ScreeningType.MOVIE ? options.id : null;
    if (!dateTimeTo) {
      dateTimeTo = dateTimeFrom;
    }
  }

  public get isoDateTimeFrom() {
    return this.dateTimeFrom.toISO({ includeOffset: false });
  }
  public get isoDateTimeTo() {
    return this.dateTimeTo.toISO({ includeOffset: false });
  }
}
