import { IScreenColModel } from "../../../interfaces";
import { AbstractViewModel } from "../../abstract.view.model";
import { ScreenColApiModel } from "../../api-model/screen/screen-col.api.model";

export class ScreenColViewModel extends AbstractViewModel<ScreenColApiModel> implements IScreenColModel {
  id: string;
  coordinate: number;
  
  constructor(protected apiModel: ScreenColApiModel = new ScreenColApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.coordinate =  this.apiModel.coordinate;
  }

  toApiModel(): ScreenColApiModel {
    return undefined;
  }
  
  toString(): string {
    return this.coordinate.toString();
  }
}
