import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { makeUrl } from '../../function/custom-function';

@Directive({ selector: '[lazyimg]' })
export class LazyImgDirective implements OnInit {
  @Input() placeholder = '';
  @Input() placeholderOnError = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: any, private elementRef: ElementRef) {}

  ngOnInit(): void {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports && this.elementRef) {
      this.placeholderOnError = this.placeholderOnError || this.environment.constants.moviePosterPlaceholderOnError;
      const placeholder = this.placeholder || makeUrl(this.environment, this.environment.constants.moviePosterPlaceholder);

      this.elementRef.nativeElement.onload = function (event: any) {
        event?.target?.classList?.remove('img-load-init');
        event?.target?.classList?.add('img-load-completed');
      };

      this.elementRef?.nativeElement?.classList?.add('img-load-init');
      this.elementRef?.nativeElement?.setAttribute('loading', 'lazy');
      this.elementRef?.nativeElement?.setAttribute(
        'onerror',
        this.placeholderOnError ? `this.onerror=null;this.src='${placeholder}';` : `this.style.display='none'`
      );
    }
  }
}
