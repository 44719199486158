import {Expose} from 'class-transformer';

export class RatingApiModel {

  @Expose()
  cinemaGroupId: string;

  @Expose()
  symbol: string;

  @Expose()
  value: string;

  @Expose()
  description: string;

}
