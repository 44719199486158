import { AbstractViewModel } from '../../../abstract.view.model';
import { OccupancyApiModel } from '../../../api-model/screening/occupancy/occupancy.api.model';
import { OccupancyLockGroupViewModel } from './occupancy-lock-group.view.model';

export class OccupancyViewModel extends AbstractViewModel<OccupancyApiModel> {
  occupiedSeats: string[];
  lockGroups: OccupancyLockGroupViewModel[];
  totalOccupied: number;
  seatsLeft?: number;
  
  constructor(protected apiModel: OccupancyApiModel = new OccupancyApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.occupiedSeats = this.apiModel.occupiedSeats;
    this.lockGroups = this.apiModel.lockGroups.map(model => new OccupancyLockGroupViewModel(model));
    this.totalOccupied = this.apiModel.totalOccupied;
    this.seatsLeft = this.apiModel.seatsLeft;
  }

  toApiModel(): OccupancyApiModel {
    return undefined;
  }

}
