import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { TranslationService } from 'libs/core/src/lib/service/translation.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { UserStatusComponent } from 'libs/shared/src/lib/component/user/user-status/status.component';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { SocialAuthService } from 'libs/shared/src/lib/module/auth/public-api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-status',
  templateUrl: './status.component.html',
})
export class CustomUserStatusComponent extends UserStatusComponent implements OnInit {
  public displayLoginPanel = false;
  public modalRef: BsModalRef;

  constructor(
    protected authStateService: AuthStateService,
    protected socialAuthService: SocialAuthService,
    protected translationService: TranslationService,
    protected orderStateService: OrderStateService,
    protected modalService: BsModalService,
    protected loadingService: LoadingService,
    protected router: Router
  ) {
    super(environment, authStateService, socialAuthService, translationService, orderStateService, loadingService, router);
    this.logoutRedirectPage = environment.homepageUrl;
  }

  ngOnInit(): void {
    this.authStateService.state$.subscribe((authState) => {
      if (authState?.token) {
        this.modalService.hide();
      }
    });
  }

  public showLoginPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'login-modal' });
  }
}
