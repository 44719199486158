import { Component } from '@angular/core';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { PersonalModel } from 'libs/core/src/lib/model/personal.model';
import { PersonalPageComponent } from 'libs/shared/src/lib/page/personal/personal.component';

export enum PersonalStateEnum {
  Initial = 'initial',
  Success = 'success',
  Failed = 'failed',
}

@Component({
  selector: 'app-page-personal',
  templateUrl: './personal.component.html',
})
export class CustomPersonalPageComponent extends PersonalPageComponent {
  onPersonalFormEvent(personalData: PersonalModel): void {
    if (personalData) {
      const updateModel = this.order.toApiModel();
      updateModel.userEmail = personalData.email;
      updateModel.userFirstName = personalData.firstname;
      updateModel.userLastName = personalData.lastname;
      updateModel.userPhone = personalData.phone;

      this.orderDataProvider.update(this.order.cinemaId, updateModel).subscribe();
    }
  }

  public onNavigationClick(event: string) {
    switch (event) {
      case 'previous':
        this.router.navigate([this.navigationHelper.getPreviousRoute(this.route.snapshot)]);
        break;
      case 'next':
        this.loadingService.showLoader(LoaderEnum.NEXT_BUTTON);
        this.putOrderAgreements().subscribe();

        if (this.personalComponent) {
          this.personalComponent.onSubmitObservable().subscribe({
            next: (result) => {
              this.loadingService.hideLoader(LoaderEnum.NEXT_BUTTON);
              if (result) {
                this.router.navigate([this.navigationHelper.getNextRoute(this.route.snapshot)]);
              }
            },
            complete: () => {
              this.loadingService.hideLoader(LoaderEnum.NEXT_BUTTON);
            },
          });
        }
        break;
    }
  }
}
