import {Expose } from 'class-transformer';

export class SeatBlockedApiModel {

  @Expose()
  id: string;

  @Expose()
  seatId: string;

}


