import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {plainToInstance} from 'class-transformer';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { VirtualCinemaConfig, VirtualCinemaModel } from './model/virtual-cinema-config';

@Injectable({
  providedIn: 'root'
})
export class VirtualCinemaService {
  private virtualCinemaConfig: VirtualCinemaConfig = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private translateService: TranslateService
  ) {
    let virtualCinemaSourceConfig: object = this.environment.virtualCinema || null;

    if (!virtualCinemaSourceConfig) {

      virtualCinemaSourceConfig = {};
    }

    this.virtualCinemaConfig = plainToInstance(VirtualCinemaConfig, virtualCinemaSourceConfig as Object, {
      strategy: 'excludeAll'
    });

    this.virtualCinemaConfig.list.forEach(virtualCinema => {

      if (virtualCinema.translate === true && virtualCinema.name) {

        virtualCinema.name = this.translateService.instant(virtualCinema.name.toTranslationKey('screen.cinema'));
      }
    });

  }

  /**
   * Gets virtual cinema by constraints
   * @param constraintCollection
   */
  public getVirtualCinema(constraintCollection: Array<object>): VirtualCinemaModel|null {

    if (this.virtualCinemaConfig.enabled === false) {

      return null;
    }

    for (const virtualCinema of this.virtualCinemaConfig.list) {

      if (virtualCinema.checkConstraint(constraintCollection)) {
        return virtualCinema;
      }

    }

    return null;
  }

  /**
   * Gets the name of virtual cinema by constraints
   * @param constraintCollection
   * @param defaultCinemaName
   */
  public getVirtualCinemaName(constraintCollection: Array<object>, defaultCinemaName: string|null = null): string|null {

    const virtualCinema: VirtualCinemaModel|null = this.getVirtualCinema(constraintCollection);

    return virtualCinema ? virtualCinema.name : defaultCinemaName;
  }
}




