import { Injectable } from '@angular/core';
import { WordpressDataProvider } from '../data-provider/wordpress.data-provider';
import { WeekdayViewModel } from '../wp-model/weekday';
import { DateTime } from 'luxon';
import { CmsHelper } from '../helper/cms.helper';

@Injectable({
  providedIn: 'root',
})
export class WeekdayService {
  private weekdays: WeekdayViewModel[] = [];

  public constructor(private wordpressDataProvider: WordpressDataProvider, private cmsHelper: CmsHelper) {
    if (this.cmsHelper.canUseCms) {
      this.wordpressDataProvider.getWeekdays().subscribe((weekdays) => (this.weekdays = weekdays));
    }
  }

  public getCustomDayName(date: DateTime): string {
    const weekdays = this.weekdays.filter((item) => item.day_of_week === date.weekday);
    if (weekdays?.length) {
      const dateString = date.toFormat('yyyy-MM-dd');
      const r = weekdays.find((weekday) => !weekday.excluded.includes(dateString) && (!weekday.included.length || weekday.included.includes(dateString)));
      if (r) {
        return r.translation;
      }
    }

    return '';
  }
}
