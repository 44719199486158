import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';

@Injectable({
  providedIn: 'root',
})
export class CinemaHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    cacheBusterObserver: CinemaHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getCinemas(): Observable<CinemaApiModel[]> {
    return this.http.get<CinemaApiModel[]>('/cinema').pipe(
      map((result) => {
        return plainToInstance(CinemaApiModel, result as object[]);
      })
    );
  }

  @Cacheable({
    cacheBusterObserver: CinemaHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getCinemaById(id: string): Observable<CinemaApiModel> {
    return this.http.get<CinemaApiModel>(`/cinema/${id}`).pipe(
      map((result) => {
        return plainToInstance(CinemaApiModel, result as object);
      })
    );
  }
}
