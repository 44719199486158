<h3>{{'login.welcomeBack' | translate}} {{user.firstName}}!</h3>

<div class="d-flex flex-column p-3 points">
  <h3 class="m-0">{{'user.user-my-profile.loyalty_benefits_rewards' | translate}}</h3>
  <span class="mt-2 mb-4">{{'user.user-my-profile.loyalty_description' | translate}}</span>

  <span style="font-size: 0.7rem;">
    {{'user.user-my-profile.loyalty_card_number' | translate | uppercase}}
  </span>
  <h3 class="mb-1">
    {{findLoyaltyCard()?.number ?? '...'}}
  </h3>
  <span style="font-size: 0.7rem;">
    {{'user.user-my-profile.loyalty_points' | translate | uppercase}}
  </span>
  <h3 class="mb-1">
    {{findLoyaltyCard()?.pointsBalance ?? '...'}}
  </h3>
  <span style="font-size: 0.7rem;">
    {{'user.user-my-profile.collected_points' | translate | uppercase}}
  </span>
  <h3 class="mb-1">
    {{findLoyaltyCard()?.collectedPoints ?? '...'}}
  </h3>
</div>

<div class="mt-5">
  <app-user-upcoming [accountItems]="accountItems"></app-user-upcoming>
</div>

<div class="mt-5 mb-5">
  <app-account-items [title]="'user.user-my-profile.accountItemsTitle' | translate"
                     [availableTypes]="['voucher','card']" (itemChangeState)="useItem($event)">
  </app-account-items>
</div>

<app-user-loyalty-popups></app-user-loyalty-popups>