import {Expose} from 'class-transformer';

export class UserPreferencesApiModel {
  @Expose()
  cinemaId: string;

  @Expose()
  cinemaName: string;

  @Expose()
  languageId: string;

  @Expose()
  languageSymbol: string;

  @Expose()
  speakingTypeId: string;

  @Expose()
  speakingTypeName: string;
}
