import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MovieScheduleViewModel } from './model/movie-schedule.view-model';
import { ScreeningAvailabilityStrategyContext } from 'libs/core/src/lib/model/strategy/screening-availability/screening-availability.strategy-context';
import { storageKey } from 'libs/core/src/app.const';
import { ScreeningRequestModel } from 'libs/core/src/lib/model/request/screening.request.model';
import { MovieCopyRequestModel } from 'libs/core/src/lib/model/request/movie-copy.request.model';
import { MovieRequestModel } from 'libs/core/src/lib/model/request/movie.request.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { MovieScheduleViewModelBuilder } from './builder/movie-schedule-view-model.builder';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { EventDataProvider } from 'libs/core/src/lib/data-provider/event.data-provider';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { EventViewModel } from 'libs/core/src/lib/model/view-model/event/event.view.model';
import { EventRequestModel } from 'libs/core/src/lib/model/request/event.request.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { Title } from '@angular/platform-browser';

@Component({
  template: '',
})
export class ScreeningPageComponent implements OnInit, OnDestroy {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  public cinemaList: CinemaViewModel[];
  public cinema: CinemaViewModel = null;
  public dateSelected: DateTime;
  public movieCopyList: MoviePrintViewModel[];
  public eventList: EventViewModel[];
  public movieScheduleViewModelCollection: Array<MovieScheduleViewModel> = new Array<MovieScheduleViewModel>();
  public showOnlyGeneralAdmission = false;
  private readonly defaultCinemaId: string | null = null;
  private readonly defaultStartDate: DateTime = null;
  private screeningAvailabilityStrategyContext: ScreeningAvailabilityStrategyContext = null;
  public showCinemaSwitcher: boolean;
  private queryCinemaId: string = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected cinemaDataProvider: CinemaDataProvider,
    protected screeningDataProvider: ScreeningDataProvider,
    protected eventDataProvider: EventDataProvider,
    protected countdownComponentService: CountdownComponentService,
    protected movieScheduleViewModelBuilder: MovieScheduleViewModelBuilder,
    protected orderStateService: OrderStateService,
    protected route: ActivatedRoute,
    protected loadingService: LoadingService,
    protected title: Title
  ) {
    this.defaultCinemaId = this.environment.constants.defaultCinemaId;
    this.showOnlyGeneralAdmission = this.environment.pages.screening.showOnlyGeneralAdmission;
    const defaultStartDate: string | null = this.environment.pages.screening.defaultStartDate;
    this.showCinemaSwitcher = this.environment.constants['showCinemaSwitcher'];

    if (defaultStartDate) {
      this.defaultStartDate = DateTime.fromISO(defaultStartDate);
    }

    this.screeningAvailabilityStrategyContext = new ScreeningAvailabilityStrategyContext(ScreeningAvailabilityStrategyContext.SALE_STRATEGY);
  }

  ngOnInit() {
    this.title.setTitle(this.environment.metadata.title);
    this.orderStateService.clearSessionStorage();
    this.countdownComponentService.hide();

    this.setDate();
    this.queryCinemaId = this.route.snapshot.queryParamMap.get('cinemaId');

    this.cinemaDataProvider.getCinemas().subscribe((cinemaList: CinemaViewModel[]) => {
      this.cinemaList = cinemaList;
      this.setCinema(cinemaList);
      this.prepareList();
    });
  }

  onDateChange(date: DateTime) {
    this.setDate(date);
    this.prepareList();
  }

  onCinemaChange(cinema: CinemaViewModel) {
    this.cinema = cinema;
    this.prepareList();
  }

  setDate(chosenDate: DateTime = null) {
    if (!chosenDate) {
      const chosenDateString = this.orderStateService.getItem(storageKey.chosenDate);
      if (chosenDateString !== null) {
        chosenDate = DateTime.fromISO(chosenDateString);
      } else {
        chosenDate = this.defaultStartDate === null ? DateTime.local() : this.defaultStartDate;
      }
    }

    this.dateSelected = chosenDate;
    this.orderStateService.setItem(storageKey.chosenDate, chosenDate.toISODate());
  }

  setCinema(cinemaList: CinemaViewModel[]) {
    const cinemaId = this.queryCinemaId ?? this.orderStateService.getCinemaId() ?? this.defaultCinemaId;
    const switcherCinemaId = this.queryCinemaId || this.defaultCinemaId;
    let cinema: CinemaViewModel = null;

    if (cinemaId !== null) {
      cinema = cinemaList.find((x) => x.id === cinemaId);
    }

    if (!cinema && cinemaList.length > 0) {
      cinema = cinemaList[0];
    }

    this.cinema = cinema;

    if (this.showCinemaSwitcher === false && (this.isNullOrEmpty(switcherCinemaId) || !cinemaList.some((c) => c.id === switcherCinemaId))) {
      this.showCinemaSwitcher = true;
    }
  }

  prepareList() {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    const from = this.dateSelected.startOf('day');
    const to = this.dateSelected.endOf('day');

    const screeningRequestModel = new ScreeningRequestModel(null, this.cinema?.id, from, to);
    const movieCopyRequestModel = new MovieCopyRequestModel(this.cinema.id, from, to);
    const movieRequestModel = new MovieRequestModel(this.cinema.id, from, to);
    const eventRequestModel = new EventRequestModel(this.cinema.id, null, from, to);

    let eventScreenObservable: Observable<EventViewModel[]> = of([]);
    if (this.environment.constants.eventScreenEnabled) {
      eventScreenObservable = this.eventDataProvider.getCinemaEventList(eventRequestModel);
    }
    forkJoin([this.screeningDataProvider.list(screeningRequestModel, movieCopyRequestModel, movieRequestModel), eventScreenObservable])
      .pipe(
        flatMap(([movieCopyList, eventList]: [MoviePrintViewModel[], EventViewModel[]]) => {
          this.movieCopyList = movieCopyList;
          this.eventList = eventList;
          return this.movieScheduleViewModelBuilder.build(movieCopyList, this.screeningAvailabilityStrategyContext, eventList);
        })
      )
      .subscribe({
        next: (movieScheduleViewModelCollection) => {
          this.movieScheduleViewModelCollection = movieScheduleViewModelCollection;
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
        error: (e) => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
      });
  }

  private isNullOrEmpty(value: any, length: number = null) {
    return !(value !== null && value !== undefined && (value as string) !== '' && (!length || value.length >= length));
  }

  ngOnDestroy(): void {}
}
