import { Expose, Type } from 'class-transformer';
import { CanBeRefundItemApiModel } from './can-be-refund-item.api.model';

export class CanBeRefundApiModel {
  @Expose()
  executionStatus: number;

  @Expose()
  executionMessage: string;

  @Expose()
  salesDocumentId: string;

  @Expose()
  refundToken: string;

  @Expose()
  refundTotalValue: number;

  @Expose()
  @Type(() => CanBeRefundItemApiModel)
  itemList: CanBeRefundItemApiModel[];
}
