import { AbstractViewModel } from "../../abstract.view.model";
import { RefundPaymentApiModel } from "../../api-model/sales-document/refund-payment.api.model";

export class RefundPaymentViewModel extends AbstractViewModel<RefundPaymentApiModel> {
    paymentTypeId: string;
    cardId: string;
    refundValue: number;
    transactionId: string;

    constructor(protected apiModel: RefundPaymentApiModel = new RefundPaymentApiModel()
    ) {
        super(apiModel);
        this.fromApiModel();
    }

    protected fromApiModel(): void {
        this.paymentTypeId = this.apiModel.paymentTypeId;
        this.cardId = this.apiModel.cardId;
        this.refundValue = this.apiModel.refundValue;
        this.transactionId = this.apiModel.transactionId;
    }

    toApiModel(): RefundPaymentApiModel {
        return undefined;
  }
}