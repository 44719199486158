export enum MyAccountPageEnum {
  OVER_VIEW = 'overView',
  TICKETS = 'tickets',
  RESERVATIONS = 'reservations',
  VOUCHERS_PASSES = 'vouchersPasses',
  VOUCHERS_FLAT_RATES = 'vouchersFlatRates',
  VOUCHERS = 'vouchers',
  GIFT_CARDS = 'giftCards',
  PREPAID_CARDS = 'prepaidCards',
  CLUB_REWARDS = 'clubRewards',
  WATCH_LIST = 'watchList',
  SETTINGS = 'settings',
  REWARDS = 'rewards',
  MEMBER_GET_MEMBER = 'memberGetMember',
  TRANSACTIONS = 'transactions',
}
