<div class="row">
  <div class="col-4 pr-0" [class.error]="hasError">
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="day" (optSelect)="onDayChange($event)" [options]="days"
                  [pristineMessage]="'personal.register.day' | translate" [disabled]="disabled">
    </app-dropdown>
  </div>
  <div class="col-4 px-1" [class.error]="hasError">
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="month" (optSelect)="onMonthChange($event)" [options]="months"
                  [pristineMessage]="'personal.register.month' | translate" [disabled]="disabled">
    </app-dropdown>
  </div>
  <div class="col-4 pl-0" [class.error]="hasError">
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="year" (optSelect)="onYearChange($event)" [options]="years"
                  [pristineMessage]="'personal.register.year' | translate" [disabled]="disabled">
    </app-dropdown>
  </div>
</div>

<ng-template #dropdownItemTemplate let-option="option">
  {{option?.value}}
</ng-template>

<ng-template #selectedItemTemplate let-option="option">
  {{option?.value}}
</ng-template>