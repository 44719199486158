import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import trimEnd from 'lodash-es/trimEnd';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';
import { BasysProviderParameters } from './basys-provider-parameters';

@Component({
  selector: 'app-basys-tag-component',
  templateUrl: './basys.component.html',
  styleUrls: [
    './basys.component.scss'
  ]
})
export class BasysComponent extends AbstractTagProviderComponent implements OnInit {
  constructor(protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {
    super(renderer, _document);
  }

  public ngOnInit(): void {
    let baseHost: string|null = (this.parameters as BasysProviderParameters).host;
    baseHost = trimEnd(baseHost, '/');

    this.addElement(
      this.createScriptExternalElement(`${baseHost}/tokenizer/tokenizer.js`, {}),
      this._document.head
    );
  }
}
