<div class="main-container container pt-3 px-0 m-auto">
  <h3 class="mb-3">{{'gift-card.title' | translate}}</h3>

  <ng-container *ngIf="checkCanDisplayInTabs() else noTabsTemplate">
    <app-tabs>
      <app-tab [title]="'gift-card.online-tab' | translate">
        <app-gift-card-online [cards]="onlineCards" [graphicItems]="onlineGraphicItems"></app-gift-card-online>
      </app-tab>
      <app-tab [title]="'gift-card.physical-tab' | translate">
        <app-gift-card-physical [cards]="physicalCards" [graphicItems]="physicalGraphicItems"></app-gift-card-physical>
      </app-tab>
    </app-tabs>
  </ng-container>

  <ng-template #noTabsTemplate>
    <app-gift-card-online [cards]="onlineCards" [graphicItems]="onlineGraphicItems"
                          *ngIf="onlineCards?.length > 0"></app-gift-card-online>
    <app-gift-card-physical [cards]="physicalCards" [graphicItems]="physicalGraphicItems"
                            *ngIf="physicalCards?.length > 0"></app-gift-card-physical>
  </ng-template>
</div>