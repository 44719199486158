import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class AuditoriumApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  number: string;

  @Expose()
  background_image: string;

  @Expose()
  background_image_web: string;
}
