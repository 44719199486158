import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../message.service';
import { AppService } from '../app.service';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { OrderStatus, OrderViewModel } from '../../model/view-model/order/order.view.model';
import { appProjectName } from 'libs/core/src/app.const';
import { OrderStateService } from '../../state/order.state.service';
import { MessageModel, MessageType } from '../../model/message.model';
import { DateTimeService } from '../datetime.service';

@Injectable({
  providedIn: 'root',
})
export class OrderValidatorService {
  public errors = [];
  private readonly paymentDebug: boolean;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) environment: any,
    private router: Router,
    private messageService: MessageService,
    private orderStateService: OrderStateService,
    private translate: TranslateService,
    private appService: AppService,
    private dateTimeService: DateTimeService
  ) {
    this.paymentDebug = environment.constants.paymentDebug === undefined ? false : environment.constants.paymentDebug;
  }

  validate(order: OrderViewModel) {
    this.errors = [];

    if (DateTime.local() > this.dateTimeService.convertToCinemaTimeZone(order.dateTimeToLive) && !this.paymentDebug) {
      this.errors.push(new MessageModel(MessageType.danger, this.translate.instant('errors.103'), '103'));
    }

    if (this.appService.isProject(appProjectName.HELIOS) && this.orderStateService.getPaymentMethod()) {
      this.errors.push(new MessageModel(MessageType.danger, this.translate.instant('errors.200'), '200'));
    }

    return this.errors.length <= 0;
  }

  validateWarrnings(order: OrderViewModel) {
    this.errors = [];

    if (order !== null) {
      if (order.status === OrderStatus.STARTED && !this.paymentDebug) {
        this.errors.push(new MessageModel(MessageType.danger, this.translate.instant('errors.105'), '105'));
      }

      if (order.status === OrderStatus.FINISHED) {
        this.errors.push(new MessageModel(MessageType.danger, this.translate.instant('errors.106'), '106'));
      }
    }

    return this.errors.length <= 0;
  }

  handleErrors() {
    // Koszyk wygasł
    const error = this.errors.find((e) => e.code === '103' || e.code === '200');

    if (error) {
      // this.messageService.add(error);
      this.orderStateService.removeOrder();
      this.router.navigate(['error', error.code]);
    }
  }

  handleWarrnings() {
    // Koszyk wygasł
    const error = this.errors.find((e) => e.code === '105' || e.code === '106');

    if (error) {
      // this.messageService.add(error);
      // this.orderStateService.removeOrder();
      this.router.navigate(['error', error.code]);
    }
  }
}
