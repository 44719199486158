import {Expose} from 'class-transformer';

export class UserFbSubItemApiModel {
  @Expose()
  subItemId: string;

  @Expose()
  subItem: string;

  @Expose()
  subItemQuantity: number;

  @Expose()
  subItemTaxRate: number;

  @Expose()
  subItemPrice: number;
}
