import { AbstractViewModel } from "../../../abstract.view.model";
import { UserMovieTagApiModel } from "../../../api-model/user-history/shared/user-movie-tag.api.model";

export class UserMovieTagViewModel extends AbstractViewModel<UserMovieTagApiModel> {
  public orderNo: number = null;
  public symbol: string = null;
  public description: string = null;

  constructor(
    protected apiModel: UserMovieTagApiModel = new UserMovieTagApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.orderNo = this.apiModel.orderNo;
    this.symbol = this.apiModel.symbol;
    this.description = this.apiModel.description;
  }

  toApiModel(): UserMovieTagApiModel {
    return undefined;
  }
}
