export enum MessageType {
  success = 'success',
  warning = 'warning',
  info = 'info',
  danger = 'danger'
}

export class MessageModel {
  type: MessageType = null;
  code: string = null;
  message: string = null;
  excluded: boolean = false;

  constructor(type: MessageType, message: string, code?: string) {
    this.type = type;
    this.code = code;
    this.message = message;
  }
}
