import { Injectable } from '@angular/core';
import { OperatorFunction, concat, interval, startWith, takeWhile, ignoreElements, of, takeUntil, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RxjsHelper {
  // public executeAfter(intervalMilliseconds: number, condition: () => boolean, observable: Observable<any>) {
  //   return concat(
  //     interval(intervalMilliseconds).pipe(
  //       startWith(null),
  //       takeWhile(condition),
  //       takeUntil(timer(5000)),
  //       tap(() => console.log(11)),
  //       ignoreElements()
  //     ),
  //     of(null).pipe(
  //       switchMap(() =>
  //         iif(
  //           condition,
  //           observable,
  //           throwError(() => console.log('error'))
  //         )
  //       )
  //     )
  //   );
  // }

  public executeAfter(intervalMilliseconds: number, condition: () => boolean, operatorFunction: OperatorFunction<any, any>, maxWaitTime: number = 5000) {
    return concat(
      interval(intervalMilliseconds).pipe(startWith(null), takeWhile(condition), takeUntil(timer(maxWaitTime)), ignoreElements()),
      of(null).pipe(operatorFunction)
    );
  }
}
