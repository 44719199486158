import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { CartTicketItemModel } from '../model/cart-ticket-item.model';
import { CartItemBuilder } from './cart-item.builder';
import { GaTicketViewModel } from 'libs/core/src/lib/model/view-model/screening/ga/ga-ticket.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';

@Injectable({
  providedIn: 'root'
})
export class OrderItemCartTicketItemBuilder implements CartItemBuilder<ScreeningItemViewModel> {
  constructor() {
  }

  public build(source: ScreeningItemViewModel,
               movieCopy: MoviePrintViewModel,
               gaTicketCollection: Array<GaTicketViewModel>,
               screen: ScreenViewModel,
               screening: ScreeningViewModel): Observable<CartTicketItemModel> {

    const screeningId: string = source.screeningId;
    const cartItem: CartTicketItemModel = new CartTicketItemModel();
    cartItem.screeningId = screeningId;
    cartItem.saleTimeTo = screening.saleTimeTo;
    cartItem.reservationTimeTo = screening.reservationTimeTo;
    cartItem.screeningItemId = source.id;
    cartItem.ticketId = source.ticketId;
    cartItem.quantity = source.quantity;
    cartItem.price = source.price;

    if (source.extraFees) {
      source.extraFees.forEach(x => {
        cartItem.price += x.price;
      });
    }

    cartItem.movieId = movieCopy.movie.id;
    cartItem.movieTitle = movieCopy.movie.title;
    // cartItem.movieDescription = movieCopy.description;
    cartItem.moviePosterCollection = movieCopy.movie.posters;

    cartItem.screeningTimeFrom = movieCopy.screenings[0].screeningTimeFrom;
    cartItem.screeningTimeTo = movieCopy.screenings[0].screeningTimeTo;
    cartItem.screeningDuration = movieCopy.screenings[0].screeningDuration;
    cartItem.movieLanguage = movieCopy.language;
    cartItem.movieSubtitles = [movieCopy.subtitles];

    cartItem.screenId = screen.id;
    cartItem.screenName = screen.name;
    cartItem.screenFeature = screen.feature;
    cartItem.screenNumber = screen.number;
    cartItem.screenDefaultSeatDescription = screen.defaultSeatGroupDescription;
    cartItem.screenDefaultSeatGroupName = screen.defaultSeatGroupName;
    cartItem.moviePrintType = movieCopy.printType;

    const gaTicket: GaTicketViewModel | undefined = gaTicketCollection.find(x => x.id === source.ticketId);

    if (gaTicket) {
      cartItem.ticketName = gaTicket.name;
    }

    return of(cartItem);
  }
}
