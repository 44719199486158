import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { appProjectName } from 'libs/core/src/app.const';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable } from 'rxjs';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';

@Component({
  selector: 'app-payment-provider-worldpay-component',
  templateUrl: './worldpay-payment-provider.component.html',
})
export class WorldPayPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'worldpay';
  public paymentProviderConfig: PaymentConfigViewModel | null;
  public resourceUrl: string = null;
  public returnUrl: string = null;
  private readonly paymentChannel: string | null = null;

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected appService: AppService) {
    this.paymentChannel = environment.constants.paymentChannel;
  }

  public ngOnInit(): void {}
  public ngOnDestroy(): void {}

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
      preInitPaymentEvent.continueUrl = event['continueUrl'];

      subscriber.next(preInitPaymentEvent);
      subscriber.complete();
    });
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    this.resourceUrl = paymentModel.plainPayload;
    this.events.next(new WorkPaymentProviderEvent());

    if (!this.appService.isProject(appProjectName.ONEIL)) {
      window.location.href = paymentModel.plainPayload;
    }
  }

  public onLoad() {
    this.events.next(new DonePaymentProviderEvent());
  }
}
