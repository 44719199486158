import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {classToPlain, plainToInstance} from 'class-transformer';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { VoucherItemApiModel } from '../model/api-model/order/voucher-item/voucher-item.api.model';
import { OrderHttpService } from './order.http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderVoucherItemHttpService {
  constructor(
    private http: HttpClient
  ) {
  }

  patch(cinemaId: string, orderId: string, items: Array<VoucherItemApiModel>): Observable<Array<VoucherItemApiModel>> {
    const clonedItems: Array<VoucherItemApiModel> = cloneDeep<Array<VoucherItemApiModel>>(items);
    const plainClonedItems = classToPlain<Object>(clonedItems, {strategy: 'excludeAll'});

    return this.http.patch<Array<VoucherItemApiModel>>(`/cinema/${cinemaId}/order/${orderId}/voucheritem`, plainClonedItems).pipe(
      tap(res => OrderVoucherItemHttpService.cacheModify(orderId, res)),
      map(res => {
        return plainToInstance(VoucherItemApiModel, res as Object[], {strategy: 'excludeAll'});
      })
    );
  }

  plainPatch(cinemaId: string, orderId: string, itemId: string, quantity: number = 1): Observable<OrderApiModel> {
    return this.http.patch<Array<VoucherItemApiModel>>(`/cinema/${cinemaId}/order/${orderId}/voucheritem`, [{
      itemId: itemId,
      quantity: quantity
    }]).pipe(
      tap(res => OrderVoucherItemHttpService.cacheModify(orderId, res)),
      map(res => {
        return plainToInstance(OrderApiModel, res as Object, {strategy: 'excludeAll'});
      })
    );
  }

  delete(cinemaId: string, orderId: string, itemId: string, voucherNumber: string): Observable<OrderApiModel> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        voucherNumber: voucherNumber
      }
    };

   return this.http.delete(`/cinema/${cinemaId}/order/${orderId}/item/${itemId}/voucher`, options).pipe(
      tap(res => OrderVoucherItemHttpService.cacheModify(orderId, res)),
      map(res => {
        return plainToInstance(OrderApiModel, res as Object, {strategy: 'excludeAll'});
      })
    );
  }

  private static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find(p => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response,
        {
          ...responseData
        });

      return data;
    });
  }
}
