import {Expose} from 'class-transformer';

export class OrderMembershipPriceApiModel {
  @Expose()
  isAppliedOnOrder: boolean;

  @Expose()
  membershipLevel: number;

  @Expose()
  membershipCardTypeId: string;

  @Expose()
  membershipTotalValue: number;
}
