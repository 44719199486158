import { AbstractViewModel } from '../../abstract.view.model';
import { RefundApiModel } from '../../api-model/sales-document/refund.api.model';
import { RefundPaymentViewModel } from './refund-payment.view.model';

export class RefundViewModel extends AbstractViewModel<RefundApiModel> {
  refundTotalValue: number;
  paymentTypeList: RefundPaymentViewModel[] = [];

  constructor(protected apiModel: RefundApiModel = new RefundApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.refundTotalValue = this.apiModel.refundTotalValue;
    this.paymentTypeList = this.apiModel.paymentTypeList.map(x => new RefundPaymentViewModel(x));
  }

  toApiModel(): RefundApiModel {
    return undefined;
  }
}
