import {Expose, Type} from 'class-transformer';

export class CateringApiModel {

  @Expose()
  @Type(() => CateringArticleGroupApiModel)
  groupList = new Array<CateringArticleGroupApiModel>();

  @Expose()
  @Type(() => CateringArticleApiModel)
  articleList = new Array<CateringArticleApiModel>();

}

export class CateringArticleGroupApiModel {

  @Expose()
  id: string;

  @Expose()
  name ?: string;

  @Expose()
  description ?: string;

  @Expose()
  parentGroupId ?: string;

  @Expose()
  graphicUrl ?: string;

}

export class CateringArticleApiModel {

  @Expose()
  id: string;

  @Expose()
  groupId ?: string;

  @Expose()
  name ?: string;

  @Expose()
  price: number;

  @Expose()
  taxRate: number;

  @Expose()
  description ?: string;

  @Expose()
  isLocked: boolean;

  @Expose()
  graphicUrl ?: string;

  @Expose()
  @Type(() => CateringArticleApiModel)
  subArticleList = new Array<CateringArticleApiModel>();

  @Expose()
  @Type(() => CateringArticleApiModel)
  replacementList = new Array<CateringArticleApiModel>();

  @Expose()
  @Type(() => CateringArticleModifierApiModel)
  modifierList = new Array<CateringArticleModifierApiModel>();

}

export class CateringArticleModifierApiModel {

  @Expose()
  id: string;

  @Expose()
  type ?: string;

  @Expose()
  name ?: string;

  @Expose()
  isRequired: boolean;

  @Expose()
  multiChoice: boolean;

  @Expose()
  multiChoiceMax: number;

  @Expose()
  multiChoiceMin: number;

  @Expose()
  separateItem: boolean;

  @Expose()
  @Type(() => CateringArticleItemModifierApiModel)
  itemList = new Array<CateringArticleItemModifierApiModel>();

}

export class CateringArticleItemModifierApiModel {

  @Expose()
  id: string;

  @Expose()
  name ?: string;

  @Expose()
  description ?: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  relatedItemList= new Array<string>();
}
