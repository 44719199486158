import { Injectable } from '@angular/core';
import { KeyValue, MyAccountPageEnum, WordpressDataProvider } from '@lib/core';
import { BehaviorSubject, Observable, filter, finalize, map, take, tap } from 'rxjs';
import { MyAccountOption } from '../webcomponent/my-account/model/my-account-option';

@Injectable({
  providedIn: 'root',
})
export class WebComponentOptionsService {
  public static defaultOption = MyAccountPageEnum.OVER_VIEW;
  public availableOptions: MyAccountOption[] = [
    { page: MyAccountPageEnum.OVER_VIEW, useInMenu: true, order: 1 },
    { page: MyAccountPageEnum.TICKETS, useInMenu: true, order: 2 },
    { page: MyAccountPageEnum.RESERVATIONS, useInMenu: true, order: 3 },
    { page: MyAccountPageEnum.VOUCHERS, useInMenu: true, order: 4 },
    { page: MyAccountPageEnum.GIFT_CARDS, useInMenu: true, order: 5 },
    { page: MyAccountPageEnum.PREPAID_CARDS, useInMenu: true, order: 6 },
    { page: MyAccountPageEnum.CLUB_REWARDS, useInMenu: true, order: 7 },
    { page: MyAccountPageEnum.WATCH_LIST, useInMenu: true, order: 8 },
    { page: MyAccountPageEnum.SETTINGS, useInMenu: true, order: 9 },
    { page: MyAccountPageEnum.VOUCHERS_PASSES, useInMenu: true, order: 10 },
    { page: MyAccountPageEnum.MEMBER_GET_MEMBER, useInMenu: false, order: 11 },
    { page: MyAccountPageEnum.VOUCHERS_FLAT_RATES, useInMenu: false, order: 12 },
    { page: MyAccountPageEnum.REWARDS, useInMenu: false, order: 13 },
    { page: MyAccountPageEnum.TRANSACTIONS, useInMenu: false, order: 14 },
  ];

  private options: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public options$: Observable<MyAccountPageEnum[]>;

  private menus: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public menus$: Observable<MyAccountPageEnum[]>;

  public wpConfig: KeyValue<string>[] = [];
  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private wordpressDataProvider: WordpressDataProvider) {
    this.options$ = this.options.asObservable();
    this.menus$ = this.menus.asObservable();

    this.wordpressDataProvider
      .getConfig()
      .pipe(
        tap((config) => {
          this.wpConfig = config;

          this.availableOptions.forEach((option) => {
            option.useInMenu = this.getWpOption(option.page) == 1;
          });

          this.options.next(this.availableOptions.map((opt) => opt.page));

          this.menus.next(
            this.availableOptions
              .filter((o) => o.useInMenu)
              .sort((a, b) => a.order - b.order)
              .map((opt) => opt.page)
          );
        }),
        finalize(() => this.initialDataFetched$.next(true))
      )
      .subscribe();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  public getWpOption$(key: string, defaultValue?: string, asFloat: boolean = false): Observable<string | number> {
    return this.initialDataFetched().pipe(
      map(() => {
        const value = this.wpConfig.find((x) => x.key === key);

        if (value) {
          if (asFloat) {
            return Number.parseFloat(value.value) || Number.parseFloat(defaultValue);
          }

          return value.value.toLocaleString();
        }

        return value ? value.value : defaultValue;
      })
    );
  }

  public getWpOption(key: string, defaultValue?: string, asFloat: boolean = false): string | number {
    const value = this.wpConfig.find((x) => x.key === key);

    if (value) {
      if (asFloat) {
        return Number.parseFloat(value.value) || Number.parseFloat(defaultValue);
      }

      return value.value.toLocaleString();
    }

    return value ? value.value : defaultValue;
  }
}
