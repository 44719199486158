import {Expose, Transform, Type} from 'class-transformer';
import { DateTime } from 'luxon';
import { VoucherPositionApiModel } from './voucher-position.api.model';

export class VoucherApiModel {
  @Expose()
  voucherName: string;

  @Expose()
  voucherType: string;

  @Expose()
  voucherDescription: string;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, {toClassOnly: true})
  voucherExpiryDate: DateTime;

  @Expose()
  voucherNote: string;

  @Expose()
  voucherNumber: string;

  @Expose()
  batchVouchersID: string;

  @Expose()
  flgVoucherType: boolean;

  @Expose()
  flgActive: boolean;

  @Expose()
  flgHidden: boolean;

  @Expose()
  voucherUsesNumber: number;

  @Expose()
  numberPerScreening: number;

  @Expose()
  numberPerDay: number;

  @Expose()
  numberPerWeek: number;

  @Expose()
  numberPerMonth: number;

  @Expose()
  usesLeft: number;

  @Expose()
  usesPerScreeningLeft: number;

  @Expose()
  usesPerDayLeft: number;

  @Expose()
  usesPerWeekLeft: number;

  @Expose()
  usesPerMonthLeft: number;

  @Expose()
  flgRealizationDay: boolean;

  @Expose()
  flgValidityDay: boolean;

  @Expose()
  realizationHoursBeforeScreening: number;

  @Expose()
  flgProjectsSpecial: boolean;

  @Expose()
  flgMultiuse: boolean;

  @Expose()
  voucherGroupId: string;

  @Expose()
  voucherGroupName: string;

  @Expose()
  @Type(() => VoucherPositionApiModel)
  positions: VoucherPositionApiModel[] = [];
}