import { EventEmitter, Injectable } from '@angular/core';
import { concat, forkJoin, merge, Observable, of, ReplaySubject, throwError } from 'rxjs';
import uniq from 'lodash-es/uniq';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { CartModel } from '../model/cart.model';
import { CartBuilderFactory } from '../builder/cart-builder.factory';
import { OrderItemCartTicketItemBuilder } from '../builder/order-item-cart-ticket-item-builder.service';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { CartTicketItemModel } from '../model/cart-ticket-item.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { CartScreeningModel } from '../model/cart-screening.model';
import { CollisionItemModel } from './model/collision-item.model';
import { hookRange } from 'libs/core/src/lib/tool/number/number';
import { GaTicketViewModel } from 'libs/core/src/lib/model/view-model/screening/ga/ga-ticket.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public onRemovedScreening: EventEmitter<string> = new EventEmitter<string>();
  public cartState: Observable<CartModel | null>;
  private state: ReplaySubject<CartModel | null> = new ReplaySubject<CartModel | null>(1, 400);
  private lastCartModel: CartModel | null = null;
  private cachedMovieCopy: Map<string, MoviePrintViewModel> = new Map<string, MoviePrintViewModel>();
  private cachedGaTickets: Map<string, Array<GaTicketViewModel>> = new Map<string, Array<GaTicketViewModel>>();
  private cachedScreen: Map<string, ScreenViewModel> = new Map<string, ScreenViewModel>();
  private cachedScreening: Map<string, ScreeningViewModel> = new Map<string, ScreeningViewModel>();

  constructor(
    private cartBuilderFactory: CartBuilderFactory,
    private orderItemCartTicketItemBuilder: OrderItemCartTicketItemBuilder,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private screeningDataProvider: ScreeningDataProvider,
    private screenDataProvider: ScreenDataProvider
  ) {
    this.cartState = this.state.asObservable();
  }

  public buildCartFromOrder(order: OrderViewModel | null): Observable<CartModel | null> {
    if (order === null) {
      this.lastCartModel = null;
      this.state.next(null);

      return of(null);
    }

    const chain: Array<Observable<CartTicketItemModel>> = [];
    const cartItemCollection: Array<CartTicketItemModel> = new Array<CartTicketItemModel>();
    const screeningIdentifierCollection: Array<string> = uniq(order.screeningItems.map((x) => x.screeningId));
    const forkCollection: Array<Observable<[GaTicketViewModel[], MoviePrintViewModel, ScreenViewModel, ScreeningViewModel]>> =
      screeningIdentifierCollection.map((x) => {
        return forkJoin([
          this.cachedGaTickets.has(x) ? of(this.cachedGaTickets.get(x)) : this.screeningDataProvider.getTicketListGeneralAdmission(order.cinemaId, x),
          this.cachedMovieCopy.has(x) ? of(this.cachedMovieCopy.get(x)) : this.screeningDataProvider.findById(order.cinemaId, x),
          this.cachedScreen.has(x) ? of(this.cachedScreen.get(x)) : this.screenDataProvider.findScreenByScreeningId(order.cinemaId, x),
          this.cachedScreening.has(x) ? of(this.cachedScreening.get(x)) : this.screeningDataProvider.findScreeningByIdViaApiModel(order.cinemaId, x),
        ]);
      });

    return new Observable<CartModel>((subscriber) => {
      merge(...forkCollection).subscribe({
        next: ([gaTickets, movieCopy, screenModel, screeningModel]) => {
          order.screeningItems.forEach((x) => {
            if (x.screeningId === (movieCopy as MoviePrintViewModel).screenings[0].id) {
              if (!this.cachedGaTickets.has(x.screeningId)) {
                this.cachedGaTickets.set(x.screeningId, gaTickets);
              }

              if (!this.cachedMovieCopy.has(x.screeningId)) {
                this.cachedMovieCopy.set(x.screeningId, movieCopy);
              }

              if (!this.cachedScreen.has(x.screeningId)) {
                this.cachedScreen.set(x.screeningId, screenModel);
              }

              if (!this.cachedScreening.has(x.screeningId)) {
                this.cachedScreening.set(x.screeningId, screeningModel);
              }

              chain.push(this.orderItemCartTicketItemBuilder.build(x, movieCopy, gaTickets, screenModel, screeningModel));
            }
          });
        },
        error: (e) => {
          subscriber.error(e);
          subscriber.complete();
        },
        complete: () => {
          concat(...chain).subscribe({
            next: (x) => cartItemCollection.push(x),
            error: (e) => {
              subscriber.error(e);
              subscriber.complete();
            },
            complete: () => {
              const cart = this.cartBuilderFactory.get('screening').build(cartItemCollection);

              this.lastCartModel = cart;
              this.state.next(cart);

              subscriber.next(cart);
              subscriber.complete();
            },
          });
        },
      });
    });
  }

  public getCart(): CartModel | null {
    return this.lastCartModel;
  }

  /**
   * Checks if screening is already added to cart
   */
  public isScreeningAddedToCart(screeningId: string): boolean {
    if (this.lastCartModel === null) {
      return false;
    }

    const foundElement: CartScreeningModel | undefined = this.lastCartModel.screeningItems.find((item) => item.screeningId === screeningId);

    return !!foundElement;
  }

  /**
   * Removes screening from cart
   */
  public removeScreening(screeningId: string): Observable<OrderViewModel> {
    const isScreeningAddedToCart = this.isScreeningAddedToCart(screeningId);

    if (isScreeningAddedToCart === false) {
      return throwError(new Error('Screening is not added to cart'));
    }

    const order = this.orderStateService.getOrder();
    return this.orderDataProvider.removeScreening(order, order.cinemaId, screeningId, true).pipe(
      map((x: OrderViewModel) => {
        const isScreeningRemoved: boolean = x.screeningItems.filter((item) => item.screeningId === screeningId).length === 0;

        if (isScreeningRemoved === true) {
          this.onRemovedScreening.next(screeningId);
        }

        return x;
      })
    );
  }

  /**
   * Finds the cart ticket item which collides with given time range
   */
  public findCartScreeningItemsCollisions(screeningTimeFrom: DateTime, screeningTimeTo: DateTime): Array<CollisionItemModel> {
    if (!this.lastCartModel) {
      return new Array<CollisionItemModel>();
    }

    const screeningTimeFromTimestamp: number = screeningTimeFrom.toMillis();
    const screeningTimeToTimestamp: number = screeningTimeTo.toMillis();
    const matchedCollisionCollection: Array<CollisionItemModel> = new Array<CollisionItemModel>();

    this.lastCartModel.screeningItems.forEach((cartScreening) => {
      cartScreening.items.forEach((cartTicketItem) => {
        const targetScreeningTimeFromTimestamp: number | null = cartTicketItem.screeningTimeFrom ? cartTicketItem.screeningTimeFrom.toMillis() : null;
        const targetScreeningTimeToTimestamp: number | null = cartTicketItem.screeningTimeTo ? cartTicketItem.screeningTimeTo.toMillis() : null;

        if (hookRange([screeningTimeFromTimestamp, screeningTimeToTimestamp], [targetScreeningTimeFromTimestamp, targetScreeningTimeToTimestamp])) {
          if (!matchedCollisionCollection.find((x) => x.screeningId === cartTicketItem.screeningId)) {
            matchedCollisionCollection.push(
              new CollisionItemModel(cartTicketItem.screeningId, cartTicketItem.movieTitle, cartTicketItem.screeningTimeFrom, cartTicketItem.screeningTimeTo)
            );
          }
        }
      });
    });

    return matchedCollisionCollection;
  }
}
