import { AbstractViewModel } from '../../abstract.view.model';
import { CinemaApiModel } from '../../api-model/cinema/cinema.api.model';

export class CinemaViewModel extends AbstractViewModel<CinemaApiModel> {
  public id: string = null;
  public name: string = null;
  public groupId: string = null;
  public city: string = null;
  public province: string = null;
  public zipcode: string = null;
  public street: string = null;
  public latitude: number = null;
  public longitude: number = null;
  public phone: string = null;
  public reservationPhone: string = null;
  public description: string = null;
  public url1: string = null;
  public url2: string = null;
  public numberOfScreens: number = null;
  public numberOfSeats: number = null;
  public numberOfDisabledSeats: number = null;
  public graphics: string[] = null;
  public email: string = null;
  public reservationGroupPhone: string = null;
  public fax: string = null;
  public isAnyDreamScreen: boolean = null;
  public timezone: string = null;

  public regionId: string = null;
  public active: boolean = null;

  public webUrl: string = null;
  public mobileUrl: string = null;

  constructor(protected apiModel: CinemaApiModel = new CinemaApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.groupId = this.apiModel.groupId;
    this.city = this.apiModel.city;
    this.province = this.apiModel.province;
    this.zipcode = this.apiModel.zipcode;
    this.street = this.apiModel.street;
    this.latitude = this.apiModel.latitude;
    this.longitude = this.apiModel.longitude;
    this.phone = this.apiModel.phone;
    this.reservationPhone = this.apiModel.reservationPhone;
    this.description = this.apiModel.description;
    this.url1 = this.apiModel.url1;
    this.url2 = this.apiModel.url2;
    this.numberOfScreens = this.apiModel.numberOfScreens;
    this.numberOfSeats = this.apiModel.numberOfSeats;
    this.numberOfDisabledSeats = this.apiModel.numberOfDisabledSeats;
    this.graphics = this.apiModel.graphics;
    this.email = this.apiModel.email;
    this.reservationGroupPhone = this.apiModel.reservationGroupPhone;
    this.fax = this.apiModel.fax;
    this.isAnyDreamScreen = this.apiModel.isAnyDreamScreen;
    this.timezone = this.apiModel.timezone;
  }

  toApiModel(): CinemaApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  get slug(): string {
    if (!this.name) return '';

    const searchRegExp = /\s/g;
    return this.name.toLowerCase().replace(searchRegExp, '-');
  }
}
