import remove from 'lodash-es/remove';
import uniqBy from 'lodash-es/uniqBy';
import { ScreenApiModel } from '../../model/api-model/screen/screen.api.model';
import { SeatGroupViewModel } from '../../model/view-model/screen/seat/seat-group.view.model';
import { ScreenElementViewModel } from '../../model/view-model/screen/screen-element.view.model';
import { SeatViewModel } from '../../model/view-model/screen/seat/seat.view.model';
import { ScreenViewModel } from '../../model/view-model/screen/screen.view.model';
import { SeatGroupSalesMode } from '../../enum/seat-group-sales-mode.enum';
import { getCoordinates, seatInGroupComparer } from '../../function/screen.functions';

export class ClientCmsScreenHelper {
  public static mapFromScreenModel(model: ScreenApiModel) {
    //this.createFakeAuditorium(model, 16, 10); // only for auditorium size test
    const mappedRows = model.rows.map((x) => ({ rowNum: x.coordinate, legendSymbol: x.legend }));
    const screenModelGroups = uniqBy(model.groups, (x) => x.id);
    const screenModelScreenElements = model.screenElements;
    const result = new ScreenViewModel(model);

    if (typeof result.feature === 'string') {
      result.feature = result.feature.split(',').join(', ');
    }

    result.groups = screenModelGroups?.map((el) =>
      Object.assign(new SeatGroupViewModel(), {
        id: el.id,
        name: el.name,
        type: el.type,
        description: el.description,
        displayMode: el.displayMode,
        salesMode: el.salesMode,
        seatIds: el.seatIds,
        color: el.color,
      })
    );

    result.screenElements = screenModelScreenElements?.map((el) =>
      Object.assign(new ScreenElementViewModel(), { id: el.id, name: el.name, seatIds: el.seatIds })
    );

    // Ustawienie wartości dla miejsc
    const pseats: SeatViewModel[][] = Array.from({ length: model.rows.length }, () => Array.from({ length: model.cols.length }, () => null));
    model.seats.forEach((element) => {
      const coords = getCoordinates(model, element);
      const seat = new SeatViewModel(element).adaptScreenModel(model, coords.row, coords.col);
      pseats[coords.y][coords.x] = seat;
    });

    result.pseats = pseats;
    result.rowSymbols = result.pseats.map((row, y) => mappedRows.find((x) => x.rowNum === y).legendSymbol);
    result.pseats.forEach((row) => {
      const symbolOfFirstSeat = row.find((x) => x.symbol.length > 0)?.symbol;
      const rowSort = isNaN(Number(symbolOfFirstSeat)) ? 1 : Number(symbolOfFirstSeat) > 1 ? -1 : 1;

      row.forEach((seat) => {
        const seatGroups = result.groups.filter((group) => group.seatIds.includes(seat.id));
        const salesTogether = seatGroups.some((group) => group.salesMode == SeatGroupSalesMode.Together);
        if (seatGroups && salesTogether) {
          const seatsGroup = row.filter((x) => seatGroups.some((g) => g.seatIds.includes(x.id)));
          const seatsGroupSorted = seatsGroup.sort(seatInGroupComparer(rowSort));

          seatsGroupSorted[0].groupConnectedSeats = [];
          let sub = 0;
          seatsGroup.forEach((seatInGroup) => {
            if (seatInGroup.id != seatsGroupSorted[0].id) {
              const element = model.seats.find((x) => x.id === seatInGroup.id);
              const coords = getCoordinates(model, element);
              const s = new SeatViewModel(element).adaptScreenModel(model, coords.row, coords.col);
              s.sub = sub++;

              seatsGroupSorted[0].groupConnectedSeats.push(s);
              seatInGroup.id = undefined;
            }
          });
        }
      });

      remove(row, (x) => x.id === undefined);
    });

    console.log(result);
    return result;
  }
}
