import {Injectable} from '@angular/core';
import trimStart from 'lodash-es/trimStart';
import { PhoneDefinitionModel } from '../model/phone-definition.model';

export interface ComposeOptions {
  trimLeadingZeros?: boolean;
  phoneDefinition: PhoneDefinitionModel;
  phoneNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  /**
   * The char that separates prefix code and phone number
   */
  public readonly PHONE_SEPARATOR = '-';

  /**
   * Composes phone number
   * @params options
   */
  public composePhoneNumber(options: ComposeOptions): string | null {
    if (!options.phoneDefinition) {
      throw new Error('Definition is required');
    }

    if (!options.phoneNumber) {
      return null;
    }

    let phoneNumber: string = options.phoneNumber;

    phoneNumber = trimStart(phoneNumber, '0');

    if (phoneNumber.length <= 0) {
      return null;
    }

    return `${options.phoneDefinition.prefix}${this.PHONE_SEPARATOR}${phoneNumber}`;
  }

  /**
   * Decomposes phone number
   */
  public decomposePhoneNumber(phoneNumber: string): { prefix: string, phoneNumber: string } | null {
    const splinted: Array<string> = phoneNumber.split(this.PHONE_SEPARATOR, 2);

    if (splinted.length !== 2) {
      return null;
    }

    let prefix: string | null = splinted[0] || null;
    let pNumber: string | null = splinted[1] || null;

    if (!prefix || !pNumber) {
      return null;
    }

    prefix = prefix.toString();
    pNumber = pNumber.toString();

    if (prefix.length === 0 || pNumber.length === 0) {
      return null;
    }

    return {
      prefix: prefix,
      phoneNumber: pNumber
    };
  }
}
