import { CardItemViewModel } from '../../view-model/order/card-item/card-item.view.model';

export class OrderSummaryGiftCardItemViewModel {
  id: string;
  name: string;
  cardTypeId: string;
  price: number;
  quantity = 0;

  constructor(card: CardItemViewModel) {
    this.id = card?.id;
    this.name = card?.name;
    this.cardTypeId = card?.cardTypeId;
    this.price = card?.value;
    this.quantity = card?.quantity;
  }
}
