export enum CountdownStateEnum {
  CLEAR = 'clear',
  RESTART = 'restart',
  START = 'start',
  RESUME = 'resume',
  STOP = 'stop',
  DESTROY = 'destroy',
  HIDDEN = 'hidden',
  VISIBLE = 'unvisible',
}
