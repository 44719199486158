import {Expose } from 'class-transformer';

export class OccupancyLockGroupApiModel {
  @Expose()
  name: string;

  @Expose()
  lockedSeats: string[];

}
