import { AbstractViewModel } from "../../../abstract.view.model";
import { UserExtraFeeApiModel } from "../../../api-model/user-history/ticket/user-extra-fee.api.model";

export class UserExtraFeeViewModel extends AbstractViewModel<UserExtraFeeApiModel> {
  public id: string = null;
  public name: string = null;
  public price: number = null;
  public quantity: number = null;

  constructor(
    protected apiModel: UserExtraFeeApiModel = new UserExtraFeeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
  }

  toApiModel(): UserExtraFeeApiModel {
    return undefined;
  }
}
