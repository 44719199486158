import { AbstractViewModel } from "../abstract.view.model";
import { TagGroupApiModel } from "../api-model/tag-group.api.model";
import { TagViewModel } from "./tag.view.model";

export class TagGroupViewModel extends AbstractViewModel<TagGroupApiModel> {
  symbol: string;
  description: string;
  tags: TagViewModel[];

  constructor(protected apiModel: TagGroupApiModel = new TagGroupApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.symbol = this.apiModel.symbol;                 
    this.description = this.apiModel.description;   
    this.tags =  this.apiModel.tags.map(tag => new TagViewModel(tag));            
  }

  toApiModel(): TagGroupApiModel {
    return undefined;
  }

}
