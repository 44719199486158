import { Expose, Type } from 'class-transformer';
import { UserMovieGenreApiModel } from '../shared/user-movie-genre.api.model';
import { UserMoviePictureApiModel } from '../shared/user-movie-picture.api.model';
import { UserMovieRatingApiModel } from '../shared/user-movie-rating.api.model';
import { UserMovieTagApiModel } from '../shared/user-movie-tag.api.model';
import { UserEventItemApiModel } from './user-event-item.api.model';

export class UserEventApiModel {
  @Expose()
  screeningId: string;

  @Expose()
  cinemaId: string;

  @Expose()
  eventId: string;

  @Expose()
  eventName: string;

  @Expose()
  eventTimeFrom: string;

  @Expose()
  eventTimeTo: string;

  @Expose()
  eventDescription: string;

  @Expose()
  posters: string[];

  @Expose()
  @Type(() => UserMoviePictureApiModel)
  pictures: UserMoviePictureApiModel[];

  @Expose()
  @Type(() => UserMovieGenreApiModel)
  genres: UserMovieGenreApiModel[];

  @Expose()
  @Type(() => UserMovieRatingApiModel)
  ratings: UserMovieRatingApiModel[];

  @Expose()
  @Type(() => UserMovieTagApiModel)
  tags: UserMovieTagApiModel[];

  @Expose()
  @Type(() => UserEventItemApiModel)
  eventItems: UserEventItemApiModel[];
}
