import {Expose } from 'class-transformer';
import { GaTicketExtraFeeApiModel } from './ga-ticket-extra-fee.api.model';

export class GaTicketApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  availableAmount: number;

  @Expose()
  seatGroupName: string;

  @Expose()
  seatGroupId: string;

  @Expose()
  extraFees: GaTicketExtraFeeApiModel[];

}
