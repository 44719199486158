import {Expose} from 'class-transformer';

export class UserVoucherApiModel {
  @Expose()
  batchVoucherID: string;

  @Expose()
  voucherID: string;

  @Expose()
  voucherNumber: string;

  @Expose()
  voucherName: string;

  @Expose()
  voucherDescription: string;

  @Expose()
  voucherPrice: number;

  @Expose()
  voucherFlgUnlimited: boolean;

  @Expose()
  voucherUsageLeft: number;

  @Expose()
  voucherExpiryDate: string;

  @Expose()
  voucherNote: string;

  @Expose()
  flgMultiUse: boolean;

  @Expose()
  flgActive: boolean;

  @Expose()
  flgHidden: boolean;

  @Expose()
  voucherGroupId: string;

  @Expose()
  voucherGroupName: string;
}
