<div class="row">
    <div class="d-flex col-8 col-md-5 col-lg-8 pr-0">
        <div class="form-group required m-0 w-100">
            <input type="text" class="form-control w-100 h-100" [disabled]="formLocked" [(ngModel)]="value"
                   (ngModelChange)="onChange($event)" [placeholder]="'voucher.input.label' | translate" />
        </div>
    </div>
    <div class="col-4">
        <button class="btn btn-next w-100" [disabled]="formLocked"
                (click)="submit()">{{ 'voucher.input.button' | translate }}</button>
    </div>
    <div class="col-12 mt-2 errors" *ngIf="errors && errors.length > 0">
        <div class="error m-0" *ngFor="let error of errors">
            {{ ('voucher.input.error.' + error) | translate }}
        </div>
    </div>
</div>
