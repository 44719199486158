import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChoiceSliderItem } from 'libs/core/src/lib/component/choice-slider/model/choice-slider-item';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { SendGiftCardTypeEnum } from 'libs/core/src/lib/enum/send-gift-card-type.enum';
import { SelectedGiftCard } from 'libs/core/src/lib/model/page/gift-card/selected-gift-card';
import { PurchaseCardTypeRequestModel } from 'libs/core/src/lib/model/request/purchase-card-type.request.model';
import { CardTypeViewModel } from 'libs/core/src/lib/model/view-model/card/card-type.view.model';
import { GiftCardService } from 'libs/core/src/lib/service/gift-card.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { TheSameFormValidator } from 'libs/core/src/lib/tool/form/validator/the-same.form-validator';
import { ENVIRONMENT_TOKEN, validationPattern } from 'libs/core/src/public-api';
import { Observable, of } from 'rxjs';

const DEFAULT_MIN = 5;
const DEFAULT_MAX = 500;

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardPurchaseComponent implements OnInit {
  sendGiftCardTypeEnum: typeof SendGiftCardTypeEnum = SendGiftCardTypeEnum;
  error;
  selectedCard: CardTypeViewModel;
  selectedQuantity = 1;
  possibleValues: number[];
  defaultMin = DEFAULT_MIN;
  defaultMax = DEFAULT_MAX;
  envelopes = false;
  sendForm: FormGroup;

  _value = 5;
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = Math.min(Math.max(value, this.defaultMin), this.defaultMax);
    this.changeDetector.markForCheck();
  }

  _selectedTemplate: ChoiceSliderItem;
  get selectedTemplate(): ChoiceSliderItem {
    return this._selectedTemplate;
  }
  set selectedTemplate(value: ChoiceSliderItem) {
    this.selectTemplate(value);
  }

  @Input() cards: CardTypeViewModel[];
  @Input() graphicItems: Map<string, Array<ChoiceSliderItem>> = new Map<string, Array<ChoiceSliderItem>>();
  @Output() selected = new EventEmitter<SelectedGiftCard>();

  private selectTemplate(value: ChoiceSliderItem) {
    this._selectedTemplate = value;
    this.selectedCard = this.cards.filter((f) => f.batchList.some((s) => s.id === value?.key))?.[0] ?? this.selectedCard;

    if (!value) {
      this._selectedTemplate = this.graphicItems.get(this.selectedCard?.id)[0];
    }

    this.possibleValues = [].concat(
      ...this.cards
        .filter((s) => s.id === this.selectedCard?.id)
        .filter((c) => c.batchList.find((b) => (b.id = this._selectedTemplate?.key)))
        .map((x) => x.batchList.filter((v) => v.paymentValue).map((b) => b.paymentValue))
    );

    if (this.possibleValues?.length > 0) {
      this.defaultMin = Math.min(...this.possibleValues);
      this.defaultMax = Math.max(...this.possibleValues);
    } else {
      this.defaultMin = DEFAULT_MIN;
      this.defaultMax = DEFAULT_MAX;
    }

    this.emitSelectedCard();
  }

  getAddress(): any {}
  reset() {
    this.value = this.defaultMin;
    this.envelopes = false;
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected giftCardService: GiftCardService,
    protected authStateService: AuthStateService,
    protected loadingService: LoadingService,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createSendForm();
  }

  createSendForm() {
    this.sendForm = new FormGroup(
      {
        type: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(validationPattern.email)]),
        emailRepeat: new FormControl('', [Validators.required]),
        message: new FormControl(''),
      },
      {
        validators: [new TheSameFormValidator('email', 'emailRepeat', 'notMatch').getValidator()],
      }
    );

    this.sendForm.get('type').valueChanges.subscribe((val) => {
      if (val === SendGiftCardTypeEnum.ForMe) {
        this.sendForm.get('email').reset();
        this.sendForm.get('email').disable();
        this.sendForm.get('emailRepeat').reset();
        this.sendForm.get('emailRepeat').disable();
        this.sendForm.get('message').reset();
        this.sendForm.get('message').disable();
      } else {
        this.sendForm.get('email').enable();
        this.sendForm.get('emailRepeat').enable();
        this.sendForm.get('message').enable();
      }
    });
  }

  findAvailableGraphic(cardId: string): ChoiceSliderItem[] {
    return this.graphicItems.get(cardId);
  }

  emitSelectedCard(): void {
    this.reset();

    this.selected.emit({
      card: this.selectedCard,
      value: this.value,
      graphic: this.selectedTemplate?.graphic,
      quantity: this.selectedQuantity,
      envelops: this.envelopes,
    } as SelectedGiftCard);
  }

  tabChange() {
    this.selectedTemplate = null;
    if (this.sendForm) {
      this.sendForm.reset();
    }
  }

  quantityEvent(quantity: number) {
    this.selectedQuantity = quantity;
    this.emitSelectedCard();
  }

  valueChange(value: any): void {
    this.value = Math.min(Math.max(value.newValue, this.defaultMin), this.defaultMax);
    this.changeDetector.detectChanges();
  }

  addToCart() {
    this.sendForm.markAllAsTouched();

    if (this.sendForm.invalid) {
      return;
    }

    this.loadingService.showLoader(LoaderEnum.MAIN);
    this.beforeAddToCart().subscribe((canAdd) => {
      if (!canAdd) {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        return;
      }

      this.giftCardService.addToCart(this.getPurchaseModel(), this.envelopes).subscribe({
        next: (s) => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
          this.sendForm.reset();
        },
        error: (e) => {
          this.error = e;
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
        complete: () => this.loadingService.hideLoader(LoaderEnum.MAIN),
      });
    });
  }

  beforeAddToCart(): Observable<boolean> {
    return of(true);
  }

  getPurchaseModel(): PurchaseCardTypeRequestModel {
    const model = new PurchaseCardTypeRequestModel();

    model.batchId = this.selectedTemplate.key;
    model.cardTypeId = this.selectedCard.id;
    model.chargeValue = this.value;
    model.quantity = this.selectedQuantity;
    model.email = this.authStateService.getUser() ? this.authStateService.getUser().email : null;

    if (this.sendForm?.valid && this.sendForm.get('type').value === SendGiftCardTypeEnum.ForSomebodyElse) {
      model.email = this.sendForm.get('email').value;
      model.message = this.sendForm.get('message').value;
    }

    const address = this.getAddress();

    if (address) {
      model.shippingAddress = address;
    }

    return model;
  }

  orderWithCards() {
    const order = this.orderStateService.getOrder();
    return order?.cardItems?.length > 0;
  }

  goToCart() {
    this.router.navigate(['personal']);
  }
}
