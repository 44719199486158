import { Component, ComponentFactory, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PaymentMethodViewModel } from 'libs/core/src/lib/model/view-model/order/payment-method/payment-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { Observable, Subscription } from 'rxjs';
import { PaymentProviderStateService } from '../service/payment-provider-state.service';
import { PaymentProviderEvent } from './event/payment-provider.event';
import { PaymentProviderComponentFactory } from './factory/payment-provider.component.factory';
import { PaymentPreInitModel } from './model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from './provider/payment-provider.component.interface';
import { PaymentProviderStateEnum } from './model/payment-provider-status.enum';

@Component({
  selector: 'app-payment-proxy-component',
  templateUrl: './payment-proxy.component.html',
})
export class PaymentProxyComponent implements OnInit, OnDestroy {
  @ViewChild('providerContainer', { read: ViewContainerRef, static: true })
  public providerContainer: ViewContainerRef;

  @Input() public paymentMethod: PaymentMethodViewModel;
  @Output() public providerEvent = new EventEmitter<PaymentProviderEvent>();
  @Output() public hideLoader = new EventEmitter();
  @Output() public showLoader = new EventEmitter();

  public componentRef: ComponentRef<PaymentProviderComponentInterface>;
  private eventsSubscription: Subscription = Subscription.EMPTY;

  public get paymentInitialized(): boolean {
    return this.selectedPaymentTypeService.getProperComponent(this.paymentMethod?.identifier)?.instance?.paymentInitialized;
  }

  public get paymentInProgress(): boolean {
    return (
      this.selectedPaymentTypeService.getProperComponent(this.paymentMethod?.identifier)?.instance?.paymentProviderState === PaymentProviderStateEnum.InProgress
    );
  }

  public constructor(private providerComponentFactory: PaymentProviderComponentFactory, private selectedPaymentTypeService: PaymentProviderStateService) {}

  public ngOnInit(): void {
    this.showLoader.emit();
    this.createComponent(this.paymentMethod?.identifier);
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  public onInitPayment(paymentMethod: PaymentMethodViewModel): Observable<any> {
    return this.selectedPaymentTypeService.getProperComponent(paymentMethod?.identifier).instance.onInitPayment(paymentMethod);
  }

  public onPostInitPayment(paymentModel: PaymentViewModel, identifier: string): void {
    this.selectedPaymentTypeService.getProperComponent(identifier).instance.onPostInitPayment(paymentModel);
  }

  public onPreInitPayment(parameters: PaymentPreInitModel, identifier: string): Observable<PaymentPreInitModel> {
    return this.selectedPaymentTypeService.getProperComponent(identifier).instance.onPreInitPayment(parameters);
  }

  private createComponent(providerType: string): void {
    const componentFactory: ComponentFactory<PaymentProviderComponentInterface> = this.providerComponentFactory.getFactory(providerType);
    const componentRef: ComponentRef<PaymentProviderComponentInterface> = this.providerContainer.createComponent(componentFactory);

    this.eventsSubscription = componentRef.instance.events.subscribe((x) => {
      this.providerEvent.next(x);
    });
    this.selectedPaymentTypeService.addAvailablePaymentProvider(componentRef, providerType);
    this.registerComponent(componentRef);
  }

  private registerComponent(componentRef: ComponentRef<PaymentProviderComponentInterface>): void {
    this.componentRef = componentRef;
    this.providerContainer.insert(componentRef.hostView);
    this.hideLoader.emit();
  }

  private destroyComponent(): void {
    if (this.eventsSubscription !== Subscription.EMPTY) {
      this.eventsSubscription.unsubscribe();
    }
    this.eventsSubscription = Subscription.EMPTY;
    this.componentRef.destroy();
  }
}
