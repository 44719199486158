import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { ScreenheadApiModel } from '../model/api-model/screen/screen-head.api.model';

@Injectable({
  providedIn: 'root',
})
export class ScreenheadHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: ScreenheadHttpService.cacheBuster$,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getScreenHeads(cinemaId: string): Observable<ScreenheadApiModel[]> {
    return this.http.get<ScreenheadApiModel[]>(`/cinema/${cinemaId}/screenhead`);
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: ScreenheadHttpService.cacheBuster$,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getScreenHead(cinemaId: string, screenId: string): Observable<ScreenheadApiModel> {
    return this.http.get<ScreenheadApiModel>(`/cinema/${cinemaId}/screenhead/${screenId}`);
  }
}
