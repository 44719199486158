import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[smooth-in]' })
export class SmoothInDirective {
  @Input('smooth-in') set option(val: boolean) {
    if (val) {
      this.renderer.addClass(this.el.nativeElement, 'smooth-in-completed');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'smooth-in-completed');
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {
    renderer.addClass(el.nativeElement, 'smooth-in');
  }
}
