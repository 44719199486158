import {Exclude, Expose, Transform} from 'class-transformer';

@Exclude()
export class CardLoginRequestModel {
  @Expose({ groups: ['default'] })
  public number: string;

  @Expose({ groups: ['default'] })
  public email: string;

  @Expose({ toPlainOnly: true, groups: ['login'] })
  @Transform(({value, obj}) => obj.number)
  public username: string;

  @Expose({ toPlainOnly: true, groups: ['login'] })
  @Transform(({value, obj}) => obj.email)
  public password: string;
}
