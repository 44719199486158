import {Exclude, Expose, Type} from 'class-transformer';

@Exclude()
export class RefundTransactionPaymentResponseModel {

  @Expose()
  public paymentTypeId: string;

  @Expose()
  public cardId: string;

  @Expose()
  public refundValue: number;

  @Expose()
  public transactionId: string;
}

@Exclude()
export class RefundTransactionResponseModel {
  @Expose()
  public executionStatus: string;
  @Expose()
  public executionMessage: string;
  @Expose()
  public refundTotalValue: number;

  @Expose()
  @Type(() => RefundTransactionPaymentResponseModel)
  public paymentTypeList: RefundTransactionPaymentResponseModel[];
}


@Exclude()
export class RefundPaymentResponse {
  @Expose()
  public id: string;

  @Expose()
  public name: string;
}

@Exclude()
export class RefundTransactionPaymentResponse {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public maxRefundValue: number;

  @Expose()
  public transactionId: string;

  @Expose()
  @Type(() => RefundPaymentResponse)
  public refundPaymentList: RefundPaymentResponse[];
}