import { AbstractViewModel } from "../../../abstract.view.model";
import { TicketExtraFeeApiModel } from "../../../api-model/shared/ticket/ticket-extra-fee.api.model";

export class TicketExtraFeeViewModel extends AbstractViewModel<TicketExtraFeeApiModel> {
  id: string;
  price: number;
  isOptional: boolean;
  name: string;
  extraFeeId: string;
  defaultPriceLevelPrice: number;

  screeningItemId: string;
  isAdded = false;

  constructor(protected apiModel: TicketExtraFeeApiModel = new TicketExtraFeeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.price = this.apiModel.price;
    this.isOptional = this.apiModel.isOptional;
    this.name = this.apiModel.name;
    this.extraFeeId = this.apiModel.extraFeeId;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
  }

  toApiModel(): TicketExtraFeeApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
