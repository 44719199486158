import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserPanelTab } from 'libs/core/src/lib/enum/user-panel-tab.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class UserPageComponent implements OnInit {
  protected userPanelTabEnum: typeof UserPanelTab = UserPanelTab;
  protected fragment = UserPanelTab.Profile;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected loadingService: LoadingService,
    protected authStateService: AuthStateService,
    protected orderStateService: OrderStateService,
    protected route: ActivatedRoute,
    protected navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment as UserPanelTab;
    });
  }

  logout() {
    this.authStateService.logout();
    this.orderStateService.removeOrder();
    window.location.href = this.environment.homepageUrl;
  }

  back(): void {
    this.navigationService.back();
  }
}
