<div class="sidebar">
    <div class="sidebar-header p-3">
        <ng-content select="[sidebar-title]"></ng-content>
    </div>
    <div class="sidebar-body p-3">
        <ng-content select="[sidebar-body]"></ng-content>
    </div>
    <div class="sidebar-footer">
        <ng-content select="[sidebar-footer]"></ng-content>
    </div>
</div>
