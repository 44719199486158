import { Expose } from 'class-transformer';

export class CardApiModel {
  @Expose()
  id: string;

  @Expose()
  cardTypeId: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  message: string;

  @Expose()
  picture: string;

  @Expose()
  number: string;

  @Expose()
  flgSubscription: boolean;

  @Expose()
  type: number;

  @Expose()
  valueBalance: number;

  @Expose()
  pointsBalance: number;

  @Expose()
  expiryDate: string;

  @Expose()
  typeDescriptions: string[];

  @Expose()
  nextPointsToExpire: number;

  @Expose()
  nextPointsExpiryDate: string;

  @Expose()
  collectedPoints: number;
}
