import {Expose} from 'class-transformer';

export class CardTypeItemMembershipPriceApiModel {
  @Expose()
  isAppliedOnOrder: boolean;

  @Expose()
  membershipLevel: number;

  @Expose()
  membershipCardTypeId: string;

  @Expose()
  membershipItemPrice: number;

  @Expose()
  membershipChargeValue: number;
}
