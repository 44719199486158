import {Expose } from 'class-transformer';

export class ScreenheadApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  type: string;

  @Expose()
  feature: string;

  @Expose()
  number: number;

  @Expose()
  screenGroupId: string;

  @Expose()
  ffaNumber: string;

  @Expose()
  proCinema: string;
}


