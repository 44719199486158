import {Expose} from 'class-transformer';

export class UserSeatApiModel {
  @Expose()
  id: string;

  @Expose()
  symbol: string;

  @Expose()
  row: string;

  @Expose()
  seat: string;

  @Expose()
  groupId: string;

  @Expose()
  groupName: string;
}
