export const componentTagNameKey = Symbol('ComponentTagName');

/**
 * To perserve class name though mangling.
 * @example
 * @ComponentTagName('pc-customer')
 * class Customer {}
 * @param className
 */
export function ComponentTagName(className: string): ClassDecorator {
  return (Reflect as any).metadata(componentTagNameKey, className);
}

/**
 * @example
 * const type = Customer;
 * getComponentTagName(type); // 'pc-customer'
 * @param type
 */
export function getComponentTagName(type: Function): string {
  return (Reflect as any).getMetadata(componentTagNameKey, type);
}

/**
 * @example
 * const instance = new Customer();
 * getInstanceTagName(instance); // 'pc-customer'
 * @param instance
 */
export function getInstanceTagName(instance: Object): string {
  return (Reflect as any).getMetadata(componentTagNameKey, instance.constructor);
}
