import { AbstractViewModel } from "../../abstract.view.model";
import { ScreenheadApiModel } from "../../api-model/screen/screen-head.api.model";
import { ScreenApiModel } from "../../api-model/screen/screen.api.model";

export class ScreenheadViewModel extends AbstractViewModel<ScreenheadApiModel> {
  id: string;
  name: string;
  type: string;
  feature: string;
  number: number;
  screenGroupId: string;
  ffaNumber: string;
  proCinema: string;

  constructor(protected apiModel: ScreenheadApiModel = new ScreenheadApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name =  this.apiModel.name;
    this.type = this.apiModel.type;
    this.feature = this.apiModel.feature;
    this.number = this.apiModel.number;
    this.screenGroupId = this.apiModel.screenGroupId;
    this.ffaNumber = this.apiModel.ffaNumber;
    this.proCinema = this.apiModel.proCinema;
  }

  toApiModel(): ScreenApiModel {
    return undefined;
  }

  toString(): string {
    return `${this.name}`;
  }
}
