import {Expose} from 'class-transformer';
import {MoviePrintApiModel} from '../movie/movie-print.api.model';
import {MovieApiModel} from '../movie/movie.api.model';
import {TagGroupApiModel} from '../tag-group.api.model';

export class ReservationMovieApiModel extends MovieApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  title: string;

  @Expose()
  premiereDate: string;

  @Expose()
  tagGroups: TagGroupApiModel[];

  @Expose()
  moviePrint: MoviePrintApiModel;
}
