import { PaymentProviderEvent } from "./payment-provider.event";

export class ErrorPaymentProviderEvent extends PaymentProviderEvent {

  public originError: Error = null;

  constructor(originError: Error) {
    super();
    this.originError = originError;
  }


}
