import { AbstractViewModel } from "../../../abstract.view.model";
import { UserCardTypeTemplateApiModel } from "../../../api-model/user-history/card-type/user-card-type-template.api.model";

export class UserCardTypeTemplateViewModel extends AbstractViewModel<UserCardTypeTemplateApiModel> {
  public id: string = null;
  public graphic: string = null;

  constructor(
    protected apiModel: UserCardTypeTemplateApiModel = new UserCardTypeTemplateApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.graphic = this.apiModel.graphic;
  }

  toApiModel(): UserCardTypeTemplateApiModel {
    return undefined;
  }
}
