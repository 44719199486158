import {Expose} from 'class-transformer';

export class UserAuthRequestModel {
  @Expose()
  public token: string;

  @Expose()
  public state: string|null;

  @Expose()
  public redirect_uri: string|null;
}
