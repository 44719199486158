import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GtmProviderParametersInterface } from './interface/gtm-provider-parameters.interface';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';

@Component({
  selector: 'app-gtm-component',
  template: '',
})
export class GtmComponent extends AbstractTagProviderComponent implements OnInit {
  public constructor(protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {
    super(renderer, _document);
  }

  public ngOnInit(): void {
    const gTagKey: string = (this.parameters as GtmProviderParametersInterface).key;
    if (!gTagKey) {
      throw new Error(`GTM: key is required!'`);
    }

    const commentBefore1 = this.createComment(' Google Tag Manager (noscript) ');
    const noScript = this.getGTMNoScriptElement(gTagKey);
    const commentAfter1 = this.createComment(' End Google Tag Manager (noscript) ');

    this.prependElement(commentAfter1, this._document.body);
    this.prependElement(noScript, this._document.body, commentAfter1);
    this.prependElement(commentBefore1, this._document.body, noScript);

    const commentBefore2 = this.createComment(' Google Tag Manager ');
    const script = this.getGTMScriptElement(gTagKey);
    const commentAfter2 = this.createComment(' End Google Tag Manager ');

    this.addElement(commentBefore2, this._document.head);
    this.addElement(script, this._document.head);
    this.addElement(commentAfter2, this._document.head);
  }

  protected getGTMNoScriptElement(gTagKey: string): HTMLElement {
    const iframe = this.renderer.createElement('iframe');
    iframe['src'] = `https://www.googletagmanager.com/ns.html?id=${gTagKey}`;
    iframe['height'] = 0;
    iframe['style'] = 'display:none;visibility:hidden';

    return this.createScriptElement(iframe, true);
  }

  protected getGTMScriptElement(gTagKey: string): HTMLElement {
    let element: HTMLElement = this.createScriptElement(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gTagKey}');`);
    element.setAttribute('id', 'gtm_script');

    return element;
  }
}
