import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/kinoteka/src/environments/environment';
import { ScreenSeatsSeatComponent } from 'libs/shared/src/lib/component/screen/seats/component/seat/seat.component';

@Component({
  selector: 'app-screen-seats-seat',
  templateUrl: './seat.component.html',
})
export class CustomScreenSeatsSeatComponent extends ScreenSeatsSeatComponent implements OnInit, DoCheck, AfterViewInit {
  constructor(protected translateService: TranslateService) {
    super(environment, translateService);
  }
}
