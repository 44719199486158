<div class="m-0">
  <div class="p-0 px-0">
    <div class="text-center col-12 p-0">
      <app-steps [step]="order?.isOnlyGiftCardOrder() || stepsService.FlowType === 'tickets' ? 2 : 3"></app-steps>
    </div>
  </div>

  <div class="container mt-4">
    <div class="main-container">
      <ng-container *ngIf="basketPageModel">
        <div class="row d-block d-md-none">
          <div class="col-12 m-3">
            <app-simple-countdown></app-simple-countdown>
          </div>
        </div>

        <div [smooth-in]="!loadingService.isLoading(loaderEnum.MAIN)">
          <div class="row">
            <div class="col-12" [class]="basketPageModel?.screen ? 'col-md-3': 'd-none'">
              <app-book-movie-list [screen]="basketPageModel?.screen"
                                   [cinemaId]="order?.cinemaId"></app-book-movie-list>
            </div>
            <div class="col-12" [class]="basketPageModel?.screen ? 'col-md-9': ''">
              <div class="row d-none d-md-block">
                <div class="col-12">
                  <app-simple-countdown></app-simple-countdown>
                </div>
              </div>
              <div class="row" [class]="basketPageModel?.screen ? '': 'd-flex justify-content-center'">
                <div class="row rgap-1 mt-4 basket-container">
                  <!-- [class]="basketPageModel?.screen ? 'col-lg-6': 'col-12'" -->
                  <div class="col-12 col-lg-6">
                    <app-sidebar>
                      <div sidebar-title>
                        {{ 'basket.title' | translate }}
                      </div>
                      <div class="cart-sidebar" sidebar-body>
                        <ng-container *ngIf="order && basketPageModel">
                          <app-order-order-summary [basketPageModel]="basketPageModel"
                                                   buttonText="{{'personal.personal.form.submit'|translate}}"
                                                   [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList"
                                                   [order]="order" [submitButtonShow]="false">
                          </app-order-order-summary>
                        </ng-container>
                      </div>
                    </app-sidebar>
                  </div>

                  <div class="col-12 col-lg-6">
                    <app-sidebar>
                      <div sidebar-title>
                        {{ 'personal.title' | translate }}
                      </div>
                      <div sidebar-body>
                        <div class="row" *ngIf="order">
                          <div class="col-12">
                            <app-personal #personalComponent [order]="order" [formErrors]="personalComponentFormErrors"
                                          (onSubmitEvent)="onPersonalFormEvent($event)"
                                          [feeLabel]="_feeAgreementCheckbox">
                            </app-personal>
                          </div>
                        </div>
                      </div>
                    </app-sidebar>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-4 mx-2">
              <app-screen-navigation (navigationEvent)="onNavigationClick($event)"
                                     [screeningId]="basketPageModel.screening?.id"
                                     [isNextButtonDisabled]="loadingService.isLoading(loaderEnum.NEXT_BUTTON)"
                                     [isLoadingData]="loadingService.isLoading(loaderEnum.NEXT_BUTTON)">
              </app-screen-navigation>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>



<!-- <app-popup [isVisible]="paymentErrorMessage">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="paymentErrorMessage"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <input type="submit" class="btn btn-lg btn-primary" value="{{ 'screen.popup.close_button' | translate }}"
             (click)="onClickedCloseModal()" />
    </div>
  </div>
</app-popup> -->