import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChecklistComponent } from 'libs/shared/src/lib/component/ui/checklist/checklist.component';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomChecklistComponent),
      multi: true,
    },
  ],
})
export class CustomChecklistComponent extends ChecklistComponent implements OnInit {
  constructor(protected translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {}
}
