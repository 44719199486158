import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import { ZoomAbleService } from './service/zoom-able.service';

@Directive({
  selector: '[zoom-able]'
})
export class ZoomAbleDirective implements OnInit, OnDestroy {

  @Input()
  public set classOutZoom(value: string|null) {

    if (!value) {
      return;
    }

    const classList: Array<string> = value.split(' ');

    classList.forEach(x => {
      this.classOutZoomList.push(x.replace(' ', ''));
    });

  }

  @Input()
  public set classInZoom(value: string|null) {

    if (!value) {
      return;
    }

    const classList: Array<string> = value.split(' ');

    classList.forEach(x => {
      this.classInZoomList.push(x.replace(' ', ''));
    });

  }


  public constructor(
    private element: ElementRef,
    private breakpointListener: BreakpointObserver,
    private zoomAbleService: ZoomAbleService
  ) {

  }

  private classOutZoomList: Array<string> = new Array<string>();

  private classInZoomList: Array<string> = new Array<string>();

  @Input()
  public widthOffset = 0;

  @Input()
  public zoomId: string;

  @Input()
  public zoomOutOnClickOutside = false;

  private breakpointSubscription: Subscription = Subscription.EMPTY;

  private zoomInSubscription: Subscription = Subscription.EMPTY;

  private zoomOutSubscription: Subscription = Subscription.EMPTY;

  private currentScale: number|null = null;

  private sourceElementHeight: number|null = null;

  private sourceElementWidth: number|null = null;

  public startX = 0;
  public startY = 0;
  public diffX = 0;
  public diffY = 0;
  public drag = false;

  public ngOnInit(): void {

    this.classInZoomList.push('zoomed');

    const minWidthList: Array<number> = [
      100,
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000,
      1100,
      1200,
      1300,
      1400,
      1500,
      1600
    ];

    const breakpoints: Array<string> = minWidthList.map(x => {
      return `(min-width: ${x}px)`;
    });

    this.zoomInSubscription = this.zoomAbleService.zoomIn$
      .subscribe(state => {

        if (this.zoomId !== state.zoomId) {
          return;
        }

        if (!state.match) {
          return;
        }

        this.transformScale(null, false);
        this.updateElementClass(false);

        this.zoomAbleService.zoomOut$.emit({zoomId: this.zoomId, match: false, scale: this.currentScale});

    });

    this.zoomOutSubscription = this.zoomAbleService.zoomOut$
      .subscribe(state => {

        if (this.zoomId !== state.zoomId) {
          return;
        }

        if (!state.match) {
          return;
        }

        this.transformScale(this.currentScale, false);
        this.updateElementClass(true);


        this.zoomAbleService.zoomIn$.emit({ zoomId: this.zoomId, match: false, scale: this.currentScale });
      });


    this.breakpointSubscription = this.breakpointListener
      .observe(breakpoints)
      .subscribe((state: BreakpointState) => {

        if (this.sourceElementHeight === null || this.sourceElementWidth) {
          this.updateElementClass(false);
        }

        if (this.sourceElementHeight === null) {

          this.sourceElementHeight = Math.round((this.element.nativeElement as HTMLElement).clientHeight * 1.08);
        }

        if (this.sourceElementWidth === null) {

          this.sourceElementWidth = Math.round((this.element.nativeElement as HTMLElement).clientWidth * 1.21);
        }


        if (state.matches) {

          this.zoomAbleService.zoom$.emit({ zoomId: this.zoomId, match: true });

          const elementWidth = this.sourceElementWidth;
          const windowWidth = window.window.innerWidth;

          const isMatchesToZoom = elementWidth > windowWidth;

          if (isMatchesToZoom === true) {

            this.currentScale = windowWidth / (elementWidth);
            this.transformScale(this.currentScale);
            this.updateElementClass(true);
          } else {

            this.currentScale = null;
            this.transformScale(this.currentScale);
            this.updateElementClass(false);
            this.zoomAbleService.zoom$.emit({ zoomId: this.zoomId, match: false });
          }
        } else {

          this.zoomAbleService.zoom$.emit({ zoomId: this.zoomId, match: false });

        }
      });

  }

  public ngOnDestroy(): void {

    if (this.breakpointSubscription !== Subscription.EMPTY) {
      this.breakpointSubscription.unsubscribe();
      this.breakpointSubscription = Subscription.EMPTY;
    }

    if (this.zoomInSubscription !== Subscription.EMPTY) {
      this.zoomInSubscription.unsubscribe();
      this.zoomInSubscription = Subscription.EMPTY;
    }

    if (this.zoomOutSubscription !== Subscription.EMPTY) {
      this.zoomOutSubscription.unsubscribe();
      this.zoomOutSubscription = Subscription.EMPTY;
    }
  }

  @HostListener('click')
  public onClick(): void {

    this.transformScale(null);
    this.updateElementClass(false);
  }

  @HostListener('mousedown', [ '$event' ])
  public onMouseDown(e: MouseEvent): void {

    this.startX = e.clientX + this.element.nativeElement.scrollLeft;
    this.startY = e.clientY + this.element.nativeElement.scrollTop;
    this.diffX = 0;
    this.diffY = 0;
    this.drag = true;

  }

  @HostListener('mouseup')
  public onMouseUp(): void {


    const request: number|null = null;

    this.drag = false;

    this.element.nativeElement.scrollLeft += this.diffX;
    this.element.nativeElement.scrollTop += this.diffY;

   /*let start = 1,
      animate = () => {
        var step = Math.sin(start);
        if (step <= 0) {
          window.cancelAnimationFrame(request);
        } else {
          this.element.nativeElement.scrollLeft += this.diffX * step;
          this.element.nativeElement.scrollTop += this.diffY * step;
          start -= 0.02;
          request = window.requestAnimationFrame(animate);
        }
      };
    animate();*/

  }

  @HostListener('mousemove', [ '$event' ])
  public onMouseMove(e: MouseEvent): void {


    if (this.drag === true) {


      this.diffX = (this.startX - (e.clientX + this.element.nativeElement.scrollLeft));
      this.diffY = (this.startY - (e.clientY + this.element.nativeElement.scrollTop));
      this.element.nativeElement.scrollLeft += this.diffX;
      this.element.nativeElement.scrollTop += this.diffY;

    }

  }

  @HostListener('document:click', [ '$event.target' ])
  public onClickOutside(targetElement: HTMLElement): void {

    if (!this.zoomOutOnClickOutside) {
      return;
    }

    let clickedInside = this.element.nativeElement.contains(targetElement);

    if (targetElement.classList.contains('zoom-able-layer')) {
      clickedInside = true;
    }

    if (!clickedInside) {
      this.transformScale(this.currentScale);

      this.updateElementClass(this.currentScale === null ? false : true);
    }
  }

  private transformScale(scale: number|null, emitZoom: boolean = true): void {

    if (scale === null) {
      (this.element.nativeElement as HTMLElement).style.transform = null;
      (this.element.nativeElement as HTMLElement).style.height = 'unset';

      if (emitZoom) {
        this.zoomAbleService.zoomOut$.emit({zoomId: this.zoomId, match: false, scale: null});
      }
    } else {
      (this.element.nativeElement as HTMLElement).style.transform = 'scale(' + scale.toString() + ')';
      (this.element.nativeElement as HTMLElement).style.height  = Math.round(this.sourceElementHeight * scale).toString() + 'px';

      if (emitZoom) {
        this.zoomAbleService.zoomIn$.emit({zoomId: this.zoomId, match: false, scale: scale});
      }
    }

  }

  private updateElementClass(zoomEnabled: boolean): void {

    const nativeElement: HTMLElement = this.element.nativeElement;

    if (zoomEnabled === true) {
      nativeElement.classList.remove(... this.classOutZoomList);
      nativeElement.classList.add(... this.classInZoomList);
    } else {
      nativeElement.classList.remove(... this.classInZoomList);
      nativeElement.classList.add(... this.classOutZoomList);
    }

  }


}
