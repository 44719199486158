import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { kinotekaValidationPattern } from 'libs/core/src/app.const';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { GenreDataProvider } from 'libs/core/src/lib/data-provider/genre.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoginHelper } from 'libs/core/src/lib/helper/login.helper';
import { CheckListItemModel } from 'libs/core/src/lib/model/checklistitem.model';
import { GenderService } from 'libs/core/src/lib/service/gender.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { UserProfileComponent } from 'libs/shared/src/lib/component/user/user-profile/user-profile.component';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class CustomUserProfileComponent extends UserProfileComponent {
  favouriteCinemas: CheckListItemModel[];
  favouriteGenres: CheckListItemModel[];

  constructor(
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected authStateService: AuthStateService,
    protected cinemaDataProvider: CinemaDataProvider,
    protected genreDataProvider: GenreDataProvider,
    protected genderService: GenderService
  ) {
    super(userDataProvider, loadingService, authStateService, cinemaDataProvider);

    this.createForm();

    this.user$.subscribe(() => {
      this.onAfterInit().subscribe((res) => {
        this.favouriteCinemas = this.generateChecklistItemList(res.cinemas, res.favouriteCinemas);
        this.favouriteGenres = this.generateChecklistItemList(res.genres, res.favouriteGenres);

        this.profileForm.setValue({
          gender: this.user.gender,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          favouriteCinemas: this.favouriteCinemas,
          favouriteGenres: this.favouriteGenres,
          birthday: this.user.birthday,
        });

        if (this.user.birthday && this.user.birthday?.year !== 1900) {
          this.profileForm.controls['birthday'].disable();
        }

        this.loadingService.hideLoader(LoaderEnum.MAIN);
      });
    });
  }

  onAfterInit(): Observable<any> {
    return forkJoin({
      cinemas: this.cinemaDataProvider.getCinemas(),
      genres: this.genreDataProvider.listViaApiModel(),
      favouriteCinemas: this.userDataProvider.getFavouriteCinemaList(),
      favouriteGenres: this.userDataProvider.getFavouriteGenreList(),
    });
  }

  generateChecklistItemList(all: any[], favourites: any[]): any {
    const checkList = all.map((a) => new CheckListItemModel(a.id, a.name));
    checkList.forEach((c) => (c.checked = favourites?.some((f) => f.id === c.id)));
    return checkList;
  }

  createForm() {
    this.profileForm = new FormGroup({
      gender: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(kinotekaValidationPattern.email)]),
      phone: new FormControl('', [Validators.pattern(kinotekaValidationPattern.phone)]),
      favouriteCinemas: new FormControl(''),
      favouriteGenres: new FormControl(''),
      birthday: new FormControl(''),
    });
  }

  public onSelect(event: any, prefName: string) {
    this.profileForm.controls['gender'].setValue(event.id);
  }
}
