import {Expose, Transform} from 'class-transformer';
import {DateTime} from 'luxon';

export class UserVoucherTransferApiModel {
  @Expose()
  id: string;

  @Expose()
  number: string;

  @Expose()
  name: string;
  
  @Expose()
  batchName: string;
  
  @Expose()
  pictureUrl: string;

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  transferDate: DateTime;

  @Expose()
  recipientClientId: string;

  @Expose()
  recipientClientEmail: string;

  @Expose()
  groupId: string;

  @Expose()
  groupName: string;
}
