import {Expose} from 'class-transformer';

export class ReservationItemApiModel {
  @Expose()
  id: string;

  @Expose()
  seatId: string;
  
  @Expose()
  row: string;

  @Expose()
  seat: string;
}