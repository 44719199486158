import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { FieldJumperInterface } from '../interfaces';

export enum KEY_CODE {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  SHIFT = 'Shift',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
}

@Directive({
  selector: 'input[field-jumper]',
})
export class FieldJumperDirective {
  @Input('focus-on') focusOn: { prev: string; next: string };
  @Input() condition: FieldJumperInterface = { length: 2 };

  constructor(private _el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === KEY_CODE.TAB) {
      return;
    }

    this.check(event);
  }

  @HostListener('keydown', ['$event'])
  public onInputChange(event: KeyboardEvent) {
    this.check(event);
  }

  @HostListener('paste', ['$event'])
  public onPaste(event) {
    event.preventDefault();
  }

  private check(event: any) {
    const value = this._el.nativeElement.value;
    let elem;

    if (
      ((!event.shiftKey && event.key === KEY_CODE.TAB) || (!this.isExcludedKey(event.key) && this.condition.length && value.length >= this.condition.length)) &&
      this.focusOn.next
    ) {
      console.log(this.focusOn.next);
      elem = this.renderer.selectRootElement(this.focusOn.next);
    } else if (event.shiftKey && event.key === KEY_CODE.TAB && this.focusOn.prev) {
      console.log(this.focusOn.prev);
      elem = this.renderer.selectRootElement(this.focusOn.prev);
    }

    if (elem) {
      console.log('focusing on', elem);
      elem.focus();
    }
  }

  private isExcludedKey(key: string): boolean {
    return isNaN(parseInt(key, 10));
  }
}
