import {Exclude, Expose, Type} from 'class-transformer';

@Exclude()
export class CanBeRefundedExtraFeeResponse {
  @Expose()
  public extraFeeItemId: string;
  @Expose()
  public extraFeeName: string;
  @Expose()
  public extraFeeQuantity: number;
  @Expose()
  public extraFeeRefundValue: number;
  @Expose()
  public isRefundable: boolean;
}

@Exclude()
export class CanBeRefundedItemResponse {
  @Expose()
  public itemType: number;
  @Expose()
  public salesDocumentItemId: string;
  @Expose()
  public itemId: string;
  @Expose()
  public itemName: string;
  @Expose()
  public quantity: number;
  @Expose()
  public refundValue: number;
  @Expose()
  public ticketMaskNumber: string;
  @Expose()
  public isRefundable: boolean;
  @Expose()
  @Type(() => CanBeRefundedExtraFeeResponse)
  public extraFeeList: CanBeRefundedExtraFeeResponse[];
}

@Exclude()
export class CanBeRefundedResponse {
  @Expose()
  public executionStatus: number;
  @Expose()
  public salesDocumentId: string;
  @Expose()
  public refundToken: string;
  @Expose()
  public refundTotalValue: number;
  @Expose()
  @Type(() => CanBeRefundedItemResponse)
  public itemList: CanBeRefundedItemResponse[];
}

