import {Expose } from 'class-transformer';

export class ScreenElementApiModel {

  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  seatIds: string[];

}


