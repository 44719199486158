import { Injectable, Injector, NgZone, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BasketExtraFeeService {
  public ticketsChanged$ = new BehaviorSubject<boolean>(false);

  public ticketsChange() {
    this.ticketsChanged$.next(true);
  }
}
