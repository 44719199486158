import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  private _isDeleteOrderOnWindowUnloadEnabled = true;

  /**
   * Checks if delete order on window unload event is enabled
   */
  public isDeleteOrderOnWindowUnloadEnabled(): boolean {
    return this._isDeleteOrderOnWindowUnloadEnabled;
  }

  /**
   * Changes the state if delete order on window unload event is enabled
   * @param isEnabled
   */
  public changeDeleteOrderOnWindowUnloadState(isEnabled: boolean): void {
    this._isDeleteOrderOnWindowUnloadEnabled = isEnabled === true;
  }
}
