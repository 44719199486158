import { AbstractViewModel } from "../../abstract.view.model";
import { OrderAliasApiModel } from "../../api-model/order/order-alias.api.model";

export class OrderAliasViewModel extends AbstractViewModel<OrderAliasApiModel> {
  cinemaId: string;
  salesDocumentId: string;
  bookingId: string;

  constructor(protected apiModel: OrderAliasApiModel = new OrderAliasApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.cinemaId = this.apiModel.cinemaId;
    this.salesDocumentId = this.apiModel.salesDocumentId;
    this.bookingId = this.apiModel.bookingId;
  }

  toApiModel(): OrderAliasApiModel {
    return undefined;
  }
}
