import { Component, OnInit } from '@angular/core';

@Component({
  template: ''
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
