import { Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export class UserScreeningApiModel {
  @Expose()
  id: string;

  @Expose()
  cinemaID: string;

  @Expose()
  screenId: string;

  @Expose()
  moviePrintID: string;

  @Expose()
  moviePrintExternalID: string;

  @Expose()
  screeningTimeFrom: string;

  @Expose()
  screeningTimeTo: string;

  @Expose()
  generalAdmission: number;
}
