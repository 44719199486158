import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { LegendItemType } from 'libs/core/src/lib/enum/legend-item-type.enum';
import { LegendModel } from 'libs/core/src/lib/model/legend.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { SimpleLegendService } from 'libs/core/src/lib/service/legend/legend.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class ScreenLegendComponent implements OnInit {
  @Input() screen: ScreenViewModel = null;

  protected legendItemType: typeof LegendItemType = LegendItemType;
  public legends: Array<LegendModel>;
  public seats: Array<LegendModel>;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment, protected legendService: SimpleLegendService) {}

  ngOnInit() {
    this.seats = this.legendService.getSeats();
    this.legends = this.legendService.getLegend(this.screen);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.legends = this.legendService.getLegend(changes.screen.currentValue);
  }
}
