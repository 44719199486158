import { DateTime } from 'luxon';
import { TransferDirection } from './enum/transfer-direction.enum';
import { CinemaViewModel } from './view-model/cinema/cinema.view.model';
import { RegionViewModel } from './view-model/region/region.view.model';
import { UserEventViewModel } from './view-model/user-history/event/user-event.view.model';
import { UserMoviePrintViewModel } from './view-model/user-history/movie-print/user-movie-print.view.model';
import { UserMovieViewModel } from './view-model/user-history/movie/user-movie.view.model';
import { UserScreenViewModel } from './view-model/user-history/screen/user-screen.view.model';
import { UserScreeningViewModel } from './view-model/user-history/screening/user-screening.view.model';
import { UserTicketViewModel } from './view-model/user-history/ticket/user-ticket.view.model';

export class ScreeningWithTickets {
  public tickets: Array<UserTicketViewModel> = [];
  public screening: UserScreeningViewModel;
  public movie: UserMovieViewModel;
  public moviePrint: UserMoviePrintViewModel;
  public event: UserEventViewModel;
  public region: RegionViewModel;
  public cinema: CinemaViewModel;
  public screen: UserScreenViewModel;

  public reservationId: string;
  public orderId: string;
  public bookingId: string;

  public isEvent(): boolean {
    return Boolean(this.event && !this.screening && !this.movie);
  }

  public isReservation(): boolean {
    return Boolean(this.reservationId);
  }

  public hasIncomingTickets(): boolean {
    return Boolean(this.tickets.some((o) => o.transferred === TransferDirection.IN));
  }

  public hasOutgoingTickets(): boolean {
    return Boolean(this.tickets.some((o) => o.transferred === TransferDirection.OUT));
  }

  public hasOnlyIncomingTickets(): boolean {
    return Boolean(this.tickets.every((o) => o.transferred === TransferDirection.IN));
  }

  public getPoster(): string {
    const posters = this.isEvent() ? this.event.posters : this.movie.posters;
    return posters?.length ? posters[0] : null;
  }

  get screeningTimeTo(): DateTime {
    return this.isEvent() ? this.event.eventTimeTo : this.screening.screeningTimeTo;
  }

  get screeningTimeFrom(): DateTime {
    return this.isEvent() ? this.event?.eventTimeFrom : this.screening?.screeningTimeFrom;
  }
}
