import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FacebookProviderParametersInterface} from './interface/facebook-provider-parameters.interface';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';

@Component({
  selector: 'app-facebook-tag-component',
  templateUrl: './facebook.component.html',
  styleUrls: [
    './facebook.component.scss'
  ]
})
export class FacebookComponent extends AbstractTagProviderComponent implements OnInit {
  constructor(protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {
    super(renderer, _document);
  }

  public ngOnInit(): void {
    const pixelId: string | null = (this.parameters as FacebookProviderParametersInterface).pixelId;
    if (!pixelId) {
      throw new Error(`FbPixel: pixelId is required!'`);
    }

    this.addElement(this.getFbPixelManagerLibraryScriptElement(pixelId), this._document.head);
    this.addElement(this.getFbPixelManagerScriptElement(pixelId), this._document.head);
  }

  protected getFbPixelManagerLibraryScriptElement(pixelId: string): HTMLElement {
    return this.createScriptElement(`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>`, true);
  }

  protected getFbPixelManagerScriptElement(pixelId: string): HTMLElement {
    return this.createScriptElement(`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pixelId}');`);
  }
}
