<div [formGroup]="dateTime">
  <div class="d-flex align-items-center gap-1">
    <div class="align-self-center">
      <input id="date" type="text" class="form-control" [placeholder]="placeholder" bsDatepicker formControlName="date"
             [bsValue]="initial" [minDate]="min" style="width: 120px;" />
    </div>
    <timepicker id="time" formControlName="time" [min]="min" [max]="max" [showMeridian]="showMeridian"
                [showSpinners]="showSpinners" [readonlyInput]="isDisabled">
    </timepicker>
  </div>
</div>