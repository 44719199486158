<div class="panel-head">
  <label class="radio-container">
    <input type="radio" name="pay_method" (click)="paymentProviderClick()" [checked]="selected"
           [ngClass]="{'selected': selected}" [value]="paymentProvider">
    <span class="label">{{properTranslation}}</span>
    <span class="checkmark" [class.active]="selected" [class.noActive]="!selected"></span>
  </label>
  <div class="image">
    <img *ngIf="hasImage" [src]="paymentMethod.imageUrl" />
    <img *ngIf="!hasImage && paymentImgUrl" [src]="paymentImgUrl" />
  </div>
</div>
<ng-content></ng-content>