import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { StepsComponent } from 'libs/shared/src/lib/component/steps/steps.component';
import { environment } from 'apps/kinoteka/src/environments/environment';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
})
export class CustomStepsComponent extends StepsComponent implements OnInit, AfterViewChecked {
  constructor(
    protected translator: TranslateService,
    protected titleService: Title,
    protected stepsService: StepsService,
    protected cateringStateService: CateringStateService,
    protected appService: AppService
  ) {
    super(environment, translator, titleService, stepsService, cateringStateService, appService);
  }

  override addCateringOffset(): number {
    return this.step;
  }
}
