import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import { InternationalPhoneConfig } from './international-phone-config';
import { PhoneDefinitionModel } from './model/phone-definition.model';
import { PhoneDefinitionService } from './service/phone-definition.service';
import { ComposeOptions, PhoneService } from './service/phone.service';

@Component({
  template: ''
})
export class InternationalPhoneComponent implements OnInit, AfterViewInit {
  @Input() public config: InternationalPhoneConfig = null;
  @Input() public formLocked = false;
  @ViewChild('foregroundInput', { read: ViewContainerRef, static: true }) public foregroundInput: ViewContainerRef;
  @ViewChild('backgroundInput', { read: ViewContainerRef, static: true }) public backgroundInput: ViewContainerRef;
  public _formGroup: FormGroup = null;
  public currentPhoneNumberInput: string = null;
  public _defaultPhoneNumber: string = null;

  @Input()
  public set formGroup(value: FormGroup) {
    this._formGroup = value;
    this._defaultPhoneNumber = this._formGroup.get(this.config.inputControlName).value;
  }

  public definitions: Array<PhoneDefinitionModel> = new Array<PhoneDefinitionModel>();
  public selectedDefinition: PhoneDefinitionModel = null;
  @Output() public phoneDefinitionChanged: EventEmitter<PhoneDefinitionModel> = new EventEmitter<PhoneDefinitionModel>();
  @Output() public phoneNumberChanged: EventEmitter<string> = new EventEmitter<string>();

  public constructor(
    private phoneDefinitionService: PhoneDefinitionService,
    private translationService: TranslateService,
    private phoneService: PhoneService
  ) {
  }

  public ngOnInit(): void {
    this.phoneDefinitionService
      .getDefinitions(this.translationService.currentLang)
      .subscribe((definitions: Array<PhoneDefinitionModel>) => {
        this.definitions = definitions;
        this.selectedDefinition = this.definitions[0] || null;
        this.phoneDefinitionChanged.next(this.selectedDefinition);

        if (this._defaultPhoneNumber) {
          this.initDefaultPhoneNumber(this._defaultPhoneNumber);
        }
      });
  }

  public ngAfterViewInit(): void {
    if (this.foregroundInput) {
      const nativeElement: HTMLElement = this.foregroundInput.element.nativeElement;

      if (this.config.maxLength) {
        nativeElement.setAttribute('maxlength', this.config.maxLength.toString());
      }

      if (this.config.inputClass) {
        const classTokenList: Array<string> = this.config.inputClass
          .split(' ')
          .map(x => x.trim())
        ;

        nativeElement.classList.add(...classTokenList);
      }

      if (this.config.inputControlName) {
        nativeElement.setAttribute('name',
          `${this.config.inputControlName}-reflection`
        );
      }
    }

    if (this.backgroundInput) {
      const nativeElement: HTMLElement = this.backgroundInput.element.nativeElement;

      if (this.config.inputIdentifier) {
        nativeElement.setAttribute('id', this.config.inputIdentifier);
      }

      if (this.config.inputControlName) {
        nativeElement.setAttribute('name', this.config.inputControlName);
        nativeElement.setAttribute('formControlName', this.config.inputControlName);
      }
    }
  }

  public onSelectDefinition(event: Event, definition: PhoneDefinitionModel) {
    event.preventDefault();
    this.definitionChanged(definition);
  }

  public onPhoneValueChanged(phone: string) {
    if (!this._formGroup) {
      return;
    }

    this.currentPhoneNumberInput = phone;
    this.phoneNumberChanged.next(this.currentPhoneNumberInput);
    const composeOptions: ComposeOptions = {
      trimLeadingZeros: this.config.trimLeadingZeros,
      phoneDefinition: this.selectedDefinition,
      phoneNumber: this.currentPhoneNumberInput
    };

    this._formGroup.get(this.config.inputControlName).setValue(
      this.phoneService.composePhoneNumber(composeOptions)
    );
  }

  private definitionChanged(definition: PhoneDefinitionModel) {
    this.selectedDefinition = definition;
    this.phoneDefinitionChanged.next(definition);
    this.onPhoneValueChanged(this.currentPhoneNumberInput);
  }

  private initDefaultPhoneNumber(value: string) {
    if (!this._formGroup) {
      return;
    }

    const phoneDefinition: PhoneDefinitionModel = this.phoneDefinitionService
      .getPhoneDefinitionBasedOnPhoneNumber(this.definitions, value)
    ;

    if (phoneDefinition === null) {
      return;
    }

    value = value.replace(phoneDefinition.prefix, '');
    value = value.replace('+', '');
    value = value.replace('-', '');

    this.currentPhoneNumberInput = value;
    this.selectedDefinition = phoneDefinition;

    this.onPhoneValueChanged(value);
    this.phoneDefinitionChanged.next(phoneDefinition);
  }
}
