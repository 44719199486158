import {Expose} from 'class-transformer';

export class UserFbModifierItemApiModel {
  @Expose()
  modifierItemId: string;

  @Expose()
  modifierItem: string;

  @Expose()
  modifierItemQuantity: number;

  @Expose()
  modifierItemTaxRate: number;

  @Expose()
  modifierItemPrice: number;

  @Expose()
  primaryModifierItemId: string;
}
