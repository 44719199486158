import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { ReservationApiModel } from '../../../api-model/account-items/reservation/reservation.api.model';
import { ScreenApiModel } from '../../../api-model/account-items/reservation/screen.api.model';
import { EventViewModel } from './event.view.model';
import { MovieViewModel } from './movie.view.model';
import { ScreenViewModel } from './screen.view.model';
import { ScreeningViewModel } from './screening.view.model';
import { TicketViewModel } from './ticket.view.model';

export class ReservationViewModel extends AbstractViewModel<ReservationApiModel> {
  public id: string;
  public expiryDate: DateTime;
  public cinemaId: string;
  public movie: MovieViewModel;
  public event: EventViewModel;
  public isPurchased: boolean;
  public salesDocumentId: string;
  public bookingId: string;
  public isTransferred: boolean;
  public tickets: TicketViewModel[] = [];
  public screening: ScreeningViewModel;
  public screen: ScreenApiModel;

  constructor(
    protected apiModel: ReservationApiModel = new ReservationApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.cinemaId = this.apiModel.cinemaId;
    this.movie = this.apiModel.movie ? new MovieViewModel(this.apiModel.movie) : null;
    this.event = this.apiModel.event ? new EventViewModel(this.apiModel.event) : null;
    this.isPurchased = this.apiModel.isPurchased;
    this.salesDocumentId = this.apiModel.salesDocumentId;
    this.bookingId = this.apiModel.bookingId;
    this.isTransferred = this.apiModel.isTransferred;
    this.tickets = this.apiModel.tickets ? this.apiModel.tickets.map(ticket => new TicketViewModel(ticket)) : [];
    this.screening = this.apiModel.screening ? new ScreeningViewModel(this.apiModel.screening) : null;
    this.screen = this.apiModel.screen ? new ScreenViewModel(this.apiModel.screen) : null;
  }

  toApiModel(): ReservationApiModel {
    return undefined;
  }
}
