import {Expose } from 'class-transformer';

export class GaTicketExtraFeeApiModel {
  @Expose()
  id: string;

  @Expose()
  price: number;

  @Expose()
  isOptional: boolean;

  @Expose()
  name: string;
}
