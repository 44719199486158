import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter';
import { EventViewModel } from '../event/event.view.model';
import { ScreeningViewModel } from './screening.view.model';

@Injectable({
  providedIn: 'root',
})
export class ScreeningViewModelAdapter implements Adapter<ScreeningViewModel> {
  adapt(event: EventViewModel): ScreeningViewModel {
    return Object.assign(new ScreeningViewModel(), {
      id: event.screeningId,
      screeningDuration: event.duration,
      availabilityStatus: event.availabilityStatus,
      reservationTimeTo: event.reservationTimeTo,
      saleTimeTo: event.saleTimeTo,
      screeningTimeFrom: event.screeningTimeFrom,
      screeningTimeTo: event.reservationTimeTo,
      generalAdmission: event.generalAdmission,
    });
  }
}
