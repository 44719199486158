import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { MovieRequestModel } from '../model/request/movie.request.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { makeHttpParams } from './http.helper';

@Injectable({
  providedIn: 'root',
})
export class MovieHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  //via API model
  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: MovieHttpService.cacheBuster$,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getMovies(model: MovieRequestModel): Observable<MovieApiModel[]> {
    let params = { dateTimeFrom: model.isoDateTimeFrom, dateTimeTo: model.isoDateTimeTo };
    if (model.cinema) {
      params = Object.assign(params, { cinemaId: model.cinema });
    }
    if (model.premiere) {
      params = Object.assign(params, { premiere: model.premiere });
    }

    return this.http.get<MovieApiModel[]>(`/movie`, { params: makeHttpParams(model) });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: MovieHttpService.cacheBuster$,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  getMovie(id: string): Observable<MovieApiModel> {
    return this.http.get<MovieApiModel>(`/movie/${id}`);
  }
}
