import { AbstractViewModel } from "../../../abstract.view.model";
import { TicketApiModel } from "../../../api-model/account-items/reservation/ticket.api.model";
import { TicketExtraFeeViewModel } from "./ticket-extra-fee.view.model";
import { TicketSeatViewModel } from "./ticket-seat.view.model";

export class TicketViewModel extends AbstractViewModel<TicketApiModel> {
  public salesDocumentItemId: string;
  public name: string;
  public price: number;
  public ticketNumber: string;
  public seats: TicketSeatViewModel[] = [];
  public extraFees: TicketExtraFeeViewModel[] = [];

  constructor(
    protected apiModel: TicketApiModel = new TicketApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.salesDocumentItemId = this.apiModel.salesDocumentItemId;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.ticketNumber = this.apiModel.ticketNumber;
    this.seats = this.apiModel.seats ? this.apiModel.seats.map(seat => new TicketSeatViewModel(seat)) : [];
    this.extraFees = this.apiModel.extraFees ? this.apiModel.extraFees.map(extraFee => new TicketExtraFeeViewModel(extraFee)) : [];
  }

  toApiModel(): TicketApiModel {
    return undefined;
  }
}
