import {Expose, Type} from 'class-transformer';
import { TagGroupTagApiModel } from './tag-group-tag.api.model';

export class TagGroupApiModel {
  @Expose()
  symbol: string;

  @Expose()
  description: string;

  @Expose()
  @Type(() => TagGroupTagApiModel)
  tags: TagGroupTagApiModel[];
}
