import {Expose, Type} from 'class-transformer';
import { MoviePrintApiModel } from './movie-print.api.model';
import { TagGroupApiModel } from './tag-group.api.model';

export class MovieApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  title: string;

  @Expose()
  @Type(() => MoviePrintApiModel)
  moviePrint: MoviePrintApiModel;

  @Expose()
  @Type(() => TagGroupApiModel)
  tagGroups: TagGroupApiModel[];
}
