import {Expose, Type} from 'class-transformer';
import { ProgramEmailApiModel } from './program-email.api.model';

export class ProgramApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  info: string;

  @Expose()
  cashierMessage: string;

  @Expose()
  url: string;

  @Expose()
  startDate: string;

  @Expose()
  expiryDate: string;

  @Expose()
  qtyLeftToRecommend: number;

  @Expose()
  @Type(() => ProgramEmailApiModel)
  recommendedEmails: ProgramEmailApiModel[];
}