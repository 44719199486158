import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderDataProvider } from '../../data-provider/order.data-provider';
import { CateringAggregationBuilder } from '../../model/catering/catering-aggregation.builder';
import {
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleModifierItemViewModel,
} from '../../model/catering/catering-aggregation.view.model';
import { CinemaViewModel } from '../../model/view-model/cinema/cinema.view.model';
import { FbItemSubArticleViewModel } from '../../model/view-model/order/fb-item/fb-item-sub-article.view.model';
import { FbItemViewModel } from '../../model/view-model/order/fb-item/fb-item.view.model';
import { OrderViewModel } from '../../model/view-model/order/order.view.model';
import { AppService } from '../app.service';
import { OrderCateringService, SelectedModifierItemMap } from './order-catering.service';

@Injectable({
  providedIn: 'root',
})
export class CateringService {
  constructor(private orderCateringService: OrderCateringService, private orderDataProvider: OrderDataProvider, private appService: AppService) {}

  public buildSelectedCateringArticleCombinationList(
    cinemaId: string,
    order: OrderViewModel,
    sourceCombinationList: Array<CateringAggregationArticleViewModel>
  ): Observable<Array<CateringAggregationArticleViewModel>> {
    sourceCombinationList = sourceCombinationList || [];

    return this.orderDataProvider.getCatering(cinemaId, order.id).pipe(
      map((catering) => {
        const cateringAggregation: CateringAggregationViewModel = new CateringAggregationBuilder(catering).build();
        const articleCombinationList: Array<CateringAggregationArticleViewModel> = sourceCombinationList;
        const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(cateringAggregation, order.fbItems);

        if (Object.keys(selectedMap).length > 0) {
          for (const articleId of Object.keys(selectedMap)) {
            for (const mapping of selectedMap[articleId]) {
              const articleCombination: CateringAggregationArticleViewModel = this.buildSelectedArticle(
                mapping.article,
                mapping.selectedQuantity,
                mapping.selectedModifierItemMap,
                mapping.selectedSubArticleMap
              );

              articleCombinationList.push(articleCombination);
            }
          }
        }

        return articleCombinationList;
      })
    );
  }

  /**
   * Builds article combination
   */
  public buildSelectedArticle(
    sourceArticle: CateringAggregationArticleViewModel,
    articleQuantity: number,
    selectedModifierItemMap: Map<string, Map<string, boolean>>,
    selectedSubArticleMap: Array<FbItemSubArticleViewModel> | Map<string, Map<string, boolean>>
  ): CateringAggregationArticleViewModel {
    if (!sourceArticle) {
      return null;
    }

    const article: CateringAggregationArticleViewModel = cloneDeep(sourceArticle);
    article.subArticleList = [];
    article.replacementList = [];
    article.modifierArticleList = [];

    if (selectedSubArticleMap instanceof Array) {
      selectedSubArticleMap.forEach((orderedSubArticle) => {
        const subArticle = new CateringAggregationArticleViewModel();
        subArticle.id = orderedSubArticle.articleId;
        subArticle.selectedQuantity = orderedSubArticle.quantity;
        subArticle.name = orderedSubArticle.name;
        subArticle.taxRate = orderedSubArticle.taxRate;
        subArticle.price = orderedSubArticle.price;

        article.subArticleList.push(subArticle);
      });
    } else {
      selectedSubArticleMap.forEach((subArticleReplacerMap, subArticleId) => {
        const sourceArticleModifier = sourceArticle.subArticleList.find((element) => element.id === subArticleId);

        if (!sourceArticleModifier) {
          return;
        }

        const articleReplacement = cloneDeep(sourceArticleModifier);

        articleReplacement.replacementList = articleReplacement.replacementList.filter((subArticleReplacer) => {
          return subArticleReplacerMap.has(subArticleReplacer.id) && subArticleReplacerMap.get(subArticleReplacer.id) === true;
        });

        article.subArticleList.push(articleReplacement);
      });
    }

    selectedModifierItemMap.forEach((modifierItemMap, modifierId) => {
      const sourceArticleModifier: CateringAggregationArticleModifierViewModel | undefined = sourceArticle.modifierArticleList.find(
        (element) => element.id === modifierId
      );

      if (!sourceArticleModifier) {
        return;
      }

      const articleModifier: CateringAggregationArticleModifierViewModel = cloneDeep(sourceArticleModifier);

      articleModifier.itemCollection = articleModifier.itemCollection.filter((articleModifierItem) => {
        return modifierItemMap.has(articleModifierItem.id) && modifierItemMap.get(articleModifierItem.id) === true;
      });

      if (articleModifier.itemCollection.length) {
        article.modifierArticleList.push(articleModifier);
      }
    });

    article.selectedQuantity = articleQuantity;
    article.selectedCombinationHash = this.calculateArticleCombinationHash(article);

    return article;
  }

  public buildSelectedArticleFromSalesDocument(sourceArticle: FbItemViewModel): CateringAggregationArticleViewModel {
    const article: FbItemViewModel = cloneDeep(sourceArticle);
    const articleViewModel: CateringAggregationArticleViewModel = new CateringAggregationArticleViewModel();

    articleViewModel.name = article.name;
    articleViewModel.price = article.price;
    articleViewModel.voucherName = article.voucherName;
    articleViewModel.voucherNumber = article.voucherNumber;
    articleViewModel.selectedQuantity = article.quantity;
    articleViewModel.subArticleList = article.subArticleList.map((subArticle) => {
      const cateringAggregationArticleViewModel: CateringAggregationArticleViewModel = new CateringAggregationArticleViewModel();
      cateringAggregationArticleViewModel.name = subArticle.name;
      cateringAggregationArticleViewModel.price = subArticle.price;
      cateringAggregationArticleViewModel.selectedQuantity = subArticle.quantity;

      return cateringAggregationArticleViewModel;
    });
    articleViewModel.modifierArticleList.push(new CateringAggregationArticleModifierViewModel());
    articleViewModel.modifierArticleList[0].name = 'Virtual modifier';
    articleViewModel.modifierArticleList[0].itemCollection = article.modifierItemList.map((modifier) => {
      const cateringAggregationArticleModifierItemViewModel: CateringAggregationArticleModifierItemViewModel =
        new CateringAggregationArticleModifierItemViewModel();
      cateringAggregationArticleModifierItemViewModel.name = modifier.modifierName; // or .modifierItemName or .itemName
      cateringAggregationArticleModifierItemViewModel.price = modifier.price;
      cateringAggregationArticleModifierItemViewModel.quantity = modifier.quantity;

      return cateringAggregationArticleModifierItemViewModel;
    });

    articleViewModel.modifierArticleList[0].itemCollection = orderBy(articleViewModel.modifierArticleList[0].itemCollection, ['name', 'price']);
    articleViewModel.subArticleList = orderBy(articleViewModel.subArticleList, ['name', 'price']);
    articleViewModel.selectedCombinationHash = this.calculateArticleCombinationHashForSalesDocument(articleViewModel);

    return articleViewModel;
  }

  private calculateArticleCombinationHashForSalesDocument(article: CateringAggregationArticleViewModel): string {
    let hash = article.name;
    hash += article.price;

    article.modifierArticleList.forEach((articleModifier) => {
      articleModifier.itemCollection.forEach((articleModifierItem) => {
        hash += articleModifierItem.name;
        // hash += articleModifierItem.price;
      });
    });

    article.subArticleList.forEach((articleReplacement) => {
      hash += articleReplacement.name;
      // hash += articleModifierItem.price;
    });

    return hash;
  }

  public recalculateArticleCombinationHash(article: CateringAggregationArticleViewModel): void {
    article.selectedCombinationHash = this.calculateArticleCombinationHash(article);
  }

  private calculateArticleCombinationHash(article: CateringAggregationArticleViewModel): string {
    let hash = article.id;
    hash += article.price; // needed for promotions or vouchers

    article.modifierArticleList.forEach((articleModifier) => {
      hash += articleModifier.id;

      articleModifier.itemCollection.forEach((articleModifierItem) => {
        hash += articleModifierItem.id;
      });
    });

    article.subArticleList.forEach((articleReplacement) => {
      hash += articleReplacement.id;

      articleReplacement.replacementList.forEach((subArticleReplacer) => {
        hash += subArticleReplacer.id;
      });
    });

    return hash;
  }
}
