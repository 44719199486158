import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(input: Array<any>, property?: string, sep = ', '): string {
    return input?.map((value) => (property ? value[property] : value)).join(sep);
  }
}
