import { Component, OnInit } from '@angular/core';
import { LayoutComponent } from 'libs/shared/src/lib/component/layout/layout/layout.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class CustomLayoutComponent extends LayoutComponent implements OnInit {
  constructor() {
    super();
  }
}
