import { DateTime } from 'luxon';
import { CinemaApiModel } from '../api-model/cinema/cinema.api.model';

export class MovieRequestModel {
  constructor(public cinema?: string, public dateTimeFrom?: DateTime, public dateTimeTo?: DateTime, public premiere?: string) {
    if (!dateTimeTo) {
      dateTimeTo = dateTimeFrom;
    }
  }

  public get isoDateTimeFrom() {
    return this.dateTimeFrom.toISO({ includeOffset: false });
  }
  public get isoDateTimeTo() {
    return this.dateTimeTo.toISO({ includeOffset: false });
  }
}
