import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bereakText',
})
export class BereakTextPipe implements PipeTransform {
  transform(value: string, char: string = '\n', type: 'replace' | 'after' | 'before' = 'replace', renderAsHtml: boolean = false): string {
    if (!value) {
      return value;
    }

    const separator = (type === 'after' ? char : '') + '<br>' + (type === 'before' ? char : '');
    const replacedValue = value.split(char).join(separator);

    if (renderAsHtml) {
      return replacedValue;
    }

    return replacedValue.split('<br>').join('\n');
  }
}
