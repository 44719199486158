import {Expose} from 'class-transformer';

export class UserCardTypeTypeApiModel {
  @Expose()
  number: number;

  @Expose()
  name: string;

  @Expose()
  description: string;
}
