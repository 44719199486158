import { Component } from '@angular/core';
import { GeneralAdmissionService } from 'libs/shared/src/lib/component/screen/ticket-count-general-admission/service/general-admission.service';
import { TicketCountGeneralAdmissionComponent } from 'libs/shared/src/lib/component/screen/ticket-count-general-admission/ticket-count-general-admission.component';

@Component({
  selector: 'app-screen-ticket-count-general-admission',
  templateUrl: './ticket-count-general-admission.component.html',
})
export class CustomTicketCountGeneralAdmissionComponent extends TicketCountGeneralAdmissionComponent {
  constructor(protected generalAdmissionService: GeneralAdmissionService) {
    super(generalAdmissionService);
  }
}
