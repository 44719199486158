import {Exclude, Expose, Type} from 'class-transformer';

@Exclude()
export class CanBeRefundedSalesDocumentItemRequest {
  @Expose()
  public id: string;

  @Expose()
  public quantity: number;
}

@Exclude()
export class CanBeRefundedRequest {
  constructor(emailValue: string) {
    this.email = emailValue;
  }

  @Expose()
  public email: string;

  @Expose()
  public ticketNumber: string;

  @Expose()
  public salesDocumentId: string;

  @Expose()
  public bookingId: string;

  @Expose()
  @Type(() => CanBeRefundedSalesDocumentItemRequest)
  public salesDocumentItemList: CanBeRefundedSalesDocumentItemRequest[];

  @Expose()
  public ticketNumberList: string[];
}

