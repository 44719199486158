import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { AuthStateService } from '../state/auth.state.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private authStateService: AuthStateService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authStateService.userIsLoggedAndTokenIsValid()) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
