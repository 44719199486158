import {Expose} from 'class-transformer';

export class VirtualPassApiModel {
  @Expose()
  provider: string;

  @Expose()
  passUrl: string;

  @Expose()
  screeningId: string;

  @Expose()
  screeningItemId: string;

  @Expose()
  ticketNumber: string;
}
