import { LocationType } from '../enum/location-type.enum';
import { CinemaViewModel } from './view-model/cinema/cinema.view.model';
import { RegionViewModel } from './view-model/region/region.view.model';

export class LocationModel {
  type: LocationType;

  constructor(public model: RegionViewModel | CinemaViewModel) {
    this.setType();
  }

  protected setType(): void {
    switch (true) {
      case this.model instanceof RegionViewModel:
        this.type = LocationType.Region;
        break;
      case this.model instanceof CinemaViewModel:
        this.type = LocationType.Cinema;
        break;
      default:
        break;
    }
  }

  get id() {
    return this.model?.id;
  }

  get name() {
    return this.model?.name;
  }

  get cinemaId() {
    let result: string;

    switch (this.type) {
      case LocationType.Region:
        result = null;
        break;
      case LocationType.Cinema:
        result = (this.model as CinemaViewModel).id;
        break;
      default:
        break;
    }
    return result;
  }

  get regionId() {
    let result: string;

    switch (this.type) {
      case LocationType.Region:
        result = this.id;
        break;
      case LocationType.Cinema:
        result = (this.model as CinemaViewModel).regionId;
        break;
      default:
        break;
    }
    return result;
  }

  get url() {
    return this.model?.webUrl;
  }

  public isRegion() {
    return this.model instanceof RegionViewModel;
  }
}
