import { Inject, Injectable } from '@angular/core';
import { storageKey } from '../../app.const';
import { FlowType } from '../model/component/steps/flow-type.model';
import { StateService } from '../state/state.service';
import { OrderStateService } from '../state/order.state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { INavigationStep } from 'libs/shared/src/lib/component/steps/navigation-steps.component';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StepsService {
  private currentStepIndex: number = 0;
  private steps: INavigationStep[];
  private _flowType: FlowType = FlowType.Standard;

  private flowTypeSubject: Subject<FlowType> = new Subject<FlowType>();
  public flowType$: Observable<FlowType> = this.flowTypeSubject.asObservable();

  public set FlowType(value: FlowType) {
    this._flowType = value;
    this.steps = null;
    this.stateService.setItem(storageKey.flowType, value);
    this.flowTypeSubject.next(value);
  }

  public get FlowType(): FlowType {
    return this._flowType;
  }

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private stateService: StateService,
    orderStateService: OrderStateService,
    private activatedRoute: ActivatedRoute
  ) {
    const flowType: string = this.stateService.getItem(storageKey.flowType);
    if (flowType) {
      this.FlowType = flowType as FlowType;
    }

    orderStateService.state$.pipe(untilDestroyed(this)).subscribe((order) => {
      if (order?.isOnlyGiftCardOrder()) {
        this._flowType = FlowType.GiftCard;
      }
    });
  }

  getSteps() {
    const navigationSteps: any[] = Object.values(this.environment.navigationSteps[this._flowType]);
    const steps: INavigationStep[] = [];

    navigationSteps.forEach((step, index) => {
      steps.push({
        key: step,
        canBack: (index + 1 === this.getCurrentStepIndex() && !this.isLastStep()) ?? false,
        index: index,
      });
    });

    this.steps = steps;

    return this.steps;
  }

  getCurrentStepIndex(): number {
    return this.currentStepIndex;
  }

  getStep(pageIdentity: string) {
    const steps = this.getSteps();
    return steps.find((step) => step.key === pageIdentity);
  }

  setCurrentStepIndex(index: number): void {
    this.currentStepIndex = index;
  }

  public initCurrentStep(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    const pageIdentity = route.snapshot.data['pageIdentify'] || undefined;
    const step = this.getStep(pageIdentity);
    this.setCurrentStepIndex(step.index);
  }

  isLastStep() {
    return this.getCurrentStepIndex() === (this.steps?.length ?? 0) - 1;
  }
}
