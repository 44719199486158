import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TranslateService } from '@ngx-translate/core';
import 'reflect-metadata';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import pl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import { ConfigService } from 'libs/core/src/lib/service/config-service';
import { APP_CONFIG_PATH, makeUrl, storageKey } from '@lib/core';

registerLocaleData(pl, 'pl-PL', localePlExtra);

const getCulture = (locale: string): string => {
  const item = environment.constants.language.cultureMap.find((o) => o.key === locale);
  return item ? item.value : locale;
};

const startApp = (config: any): void => {
  ConfigService.setEnvironment(config, environment);
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      providers: [
        {
          provide: LOCALE_ID,
          useFactory: (translate: TranslateService) => getCulture(translate.currentLang),
          deps: [TranslateService],
        },
      ],
    })
    .catch((err) => console.error(err));
};

const initializeApp = async (): Promise<void> => {
  const storedConfig = sessionStorage.getItem(storageKey.appConfig);
  if (storedConfig) {
    startApp(JSON.parse(storedConfig));
  } else {
    try {
      const response = await fetch(makeUrl(environment, APP_CONFIG_PATH));
      const config = await response.json();
      sessionStorage.setItem(storageKey.appConfig, JSON.stringify(config));
      startApp(config);
    } catch (error) {
      console.error('Failed to fetch app config', error);
    }
  }
};

initializeApp();
