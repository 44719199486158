import {Expose, Type} from 'class-transformer';
import { DefaultExtraFeeMembershipPriceApiModel } from './default-extra-fee-membership-price.api.model';

export class DefaultExtraFeeApiModel {
  @Expose()
  basketItemId: string;

  @Expose()
  defaultExtraFeeId: string;

  @Expose()
  defaultExtraFeeName: string;

  @Expose()
  defaultExtraFeePrice: number;

  @Expose()
  defaultExtraFeeEarnedPoints: number;

  @Expose()
  defaultExtraFeeRedemptionPoints: number;

  @Expose()
  @Type(() => DefaultExtraFeeMembershipPriceApiModel)
  membershipPrices: DefaultExtraFeeMembershipPriceApiModel[] = [];
}
