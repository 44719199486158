import { AbstractViewModel } from '../abstract.view.model';
import { PickupTimeStatusApiModel } from '../api-model/pickup-time-status.api.model';

export class PickupTimeStatusViewModel extends AbstractViewModel<PickupTimeStatusApiModel> {
  status: number = null;

  constructor(protected apiModel: PickupTimeStatusApiModel = new PickupTimeStatusApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.status = this.apiModel.status;                  
  }

  toApiModel(): PickupTimeStatusApiModel {
    return undefined;
  }
}
