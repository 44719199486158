import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { trimEnd } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { AbstractTagProviderComponent } from '../abstract-tag-provider.component';
import { CustomWhaleProviderParameters } from './blue-whale-provider.parameters';

@Component({
  selector: 'app-blue-whale-tag-component',
  template: '',
})
export class CustomWhaleComponent extends AbstractTagProviderComponent implements OnInit {
  constructor(protected renderer: Renderer2, private route: ActivatedRoute, @Inject(DOCUMENT) protected _document) {
    super(renderer, _document);
  }

  public ngOnInit(): void {
    if (!window.location.href.includes('order/summary')) {
      return;
    }

    // @ts-ignore
    window.uniid = window.uniid || {};

    this.route.queryParams.subscribe((params) => {
      // @ts-ignore
      window.uniid.pubid = params.cric_pubid;
      // @ts-ignore
      window.uniid.hid = params.cric_hid;
      // @ts-ignore
      window.uniid.adid = params.cric_adid;

      // @ts-ignore
      if (window.uniid.pubid && window.uniid.hid && window.uniid.adid) {
        this.injectScript();
      }
    });
  }

  private injectScript(): void {
    let baseHost: string | null = (this.parameters as CustomWhaleProviderParameters).host;
    baseHost = trimEnd(baseHost, '/');

    this.addElement(this.createScriptExternalElement(`${baseHost || 'https://squid.gazeta.pl'}/bdtrck/getScript/bluewhale?src=15`, {}), this._document.head);
  }
}
