import {Inject, Injectable} from '@angular/core';
import flatMap from 'lodash-es/flatMap';
import indexOf from 'lodash-es/indexOf';
import uniq from 'lodash-es/uniq';
import {TranslateService} from '@ngx-translate/core';
import { OrderStateService } from '../../state/order.state.service';
import { OrderViewModel } from '../../model/view-model/order/order.view.model';
import { MessageModel, MessageType } from '../../model/message.model';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';

@Injectable({
  providedIn: 'root'
})
export class ScreenValidatorService {
  public errors = [];

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderStateService: OrderStateService,
    private translate: TranslateService,
  ) {
  }

  validate(order: OrderViewModel, screeningId: string) {
    this.errors = [];

    if (order === null) {
      return true;
    }

    // Walidacja dopuszczalnej ilości seansów w koszyku
    const screenings = uniq(flatMap(order.screeningItems, (i) => i.screeningId));

    if (screenings.length === this.environment.constants.basketMaxScreenings && indexOf(screenings, screeningId) === -1) {
      this.errors.push(new MessageModel(MessageType.warning, this.translate.instant('errors.104'), '104'));
    }

    return this.errors.length <= 0;
  }

  handleErrors() {
    // Zmiana screeningu
    const error = this.errors.find(e => e.code === '104');

    if (error) {
      this.orderStateService.removeOrder();
    }
  }
}
