import { VoucherTypeModel } from '../../voucher-type.model';

export class OrderSummaryVoucherItemViewModel {
  private _voucher: VoucherTypeModel = null;
  private _quantity = 0;

  get voucher(): VoucherTypeModel {
    return this._voucher;
  }

  set voucher(value: VoucherTypeModel) {
    this._voucher = value;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(value: number) {
    this._quantity = value;
  }
}
