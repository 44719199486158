import { Expose, Type } from 'class-transformer';
import { VoucherPurchaseItemApiModel } from './voucher-purchase-item.api.model';
import { VoucherRedeemDayOfScreeningApiModel } from './voucher-redeem-day-of-screening.api.model';
import { VoucherRedeemDayOfWeekApiModel } from './voucher-redeem-day-of-week.api.model';

export class VoucherPurchaseApiModel {
  @Expose()
  id: string;

  @Expose()
  itemId: string;

  @Expose()
  itemPrice: number;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  note: string;

  @Expose()
  taxRate: number;

  @Expose()
  groupId: string;

  @Expose()
  groupName: string;

  @Expose()
  flgVoucherType: number;

  @Expose()
  flgVoucherUseType: number;

  @Expose()
  usesNumber: number;

  @Expose()
  numberPerScreening: number;

  @Expose()
  numberPerDay: number;

  @Expose()
  numberPerWeek: number;

  @Expose()
  numberPerMonth: number;

  @Expose()
  pictureUrl: string;

  @Expose()
  flgValidForSpecialProjects: boolean;

  @Expose()
  batchId: string;

  @Expose()
  batchName: string;

  @Expose()
  batchDescription: string;

  @Expose()
  batchNote: string;

  @Expose()
  batchUnlimited: boolean;

  @Expose()
  batchQuantityLeft: number;

  @Expose()
  batchPictureUrl: string;

  @Expose()
  batchValidFor: number;

  @Expose()
  batchValidForType: number;

  @Expose()
  batchFlgEndOfMonth: boolean;

  @Expose()
  batchValidToDate: string;

  @Expose()
  @Type(() => VoucherRedeemDayOfWeekApiModel)
  dayOfWeekForRedeemList: VoucherRedeemDayOfWeekApiModel[] = [];

  @Expose()
  @Type(() => VoucherRedeemDayOfScreeningApiModel)
  dayOfScreeningForRedeemList: VoucherRedeemDayOfScreeningApiModel[] = [];

  @Expose()
  @Type(() => VoucherPurchaseItemApiModel)
  items: VoucherPurchaseItemApiModel[] = [];

  @Expose()
  availableInCinemas: string[];
}
