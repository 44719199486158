import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { ScreeningApiModel } from '../../../api-model/account-items/reservation/screening.api.model';

export class ScreeningViewModel extends AbstractViewModel<ScreeningApiModel> {
  public screeningTimeFrom: DateTime;
  public screeningTimeTo: DateTime;
  public screeningId: string;

  constructor(protected apiModel: ScreeningApiModel = new ScreeningApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.screeningTimeFrom = this.apiModel.screeningTimeFrom ? DateTime.fromISO(this.apiModel.screeningTimeFrom, { setZone: true }) : null;
    this.screeningTimeTo = this.apiModel.screeningTimeTo ? DateTime.fromISO(this.apiModel.screeningTimeTo, { setZone: true }) : null;
  }

  toApiModel(): ScreeningApiModel {
    return undefined;
  }
}
