
export enum PayUInternalPaymentMethodType {

  googlePayToken = 'GOOGLEPAY_TOKEN',

  cardToken = 'CARD_TOKEN',

  blikToken = 'BLIK',

  blikCode = 'BLIK_T6',

  payByLink = 'PBL',

  applePayToken = 'APPLEPAY_TOKEN'

}
