import { AbstractViewModel } from '../../../abstract.view.model';
import { ScreenApiModel } from '../../../api-model/account-items/reservation/screen.api.model';
import 'libs/core/src/lib/prototypes/string.prototype';

export class ScreenViewModel extends AbstractViewModel<ScreenApiModel> {
  public name: string;
  public feature: string;
  public number: number;

  constructor(protected apiModel: ScreenApiModel = new ScreenApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.feature = this.apiModel.feature?.correctSpaceAfterCommas();
    this.number = this.apiModel.number;
  }

  toApiModel(): ScreenApiModel {
    return undefined;
  }
}
