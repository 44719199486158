import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { NavigationHelperService } from './navigation/navigation-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private screen = new BehaviorSubject<ScreenViewModel>(null);
  screenModelChange$: Observable<ScreenViewModel>;

  private innerContent = new ReplaySubject<string>(1);
  public innerContent$ = this.innerContent.asObservable();

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private router: Router,
    private navigationHelperService: NavigationHelperService
  ) {
    this.screenModelChange$ = this.screen.asObservable();
  }

  public performActionForButton(actionButtonIdentifier: string): void {
    const actionButtonMap = this.environment.header.actionButtonList;

    if (actionButtonMap && actionButtonMap[actionButtonIdentifier]) {
      const action = actionButtonMap[actionButtonIdentifier];
      if (action && action['action'] && action['action']['nextRouteFor']) {
        this.router.navigate([
          this.navigationHelperService.getNextRouteForRoute(action['action']['nextRouteFor'])
        ]);
      }
    }
  }

  public setData(model: ScreenViewModel) {
    this.screen.next(model);
  }

  public setInnerContent(value: string) {
    this.innerContent.next(value);
  }
}
