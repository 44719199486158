import {Expose, Type} from 'class-transformer';
import {ReservationEventApiModel} from './reservation-event.api.model';
import {ReservationItemApiModel} from './reservation-item.api.model';
import {ReservationMovieApiModel} from './reservation-movie.api.model';

export class ReservationApiModel {
  @Expose()
  id: string;

  @Expose()
  number: string;

  @Expose()
  status: number;

  @Expose()
  creationDate: string;

  @Expose()
  expiryDate: string;

  @Expose()
  cinemaId: string;

  @Expose()
  cinemaName: string;

  @Expose()
  screeningId: string;

  @Expose()
  screeningName: string;

  @Expose()
  screenNumber: number;

  @Expose()
  screenId: string;

  @Expose()
  screenName: string;

  @Expose()
  screeningDate: string;

  @Expose()
  screeningStatus: number;

  @Expose()
  userFirstName: string;

  @Expose()
  userLastName: string;

  @Expose()
  userPhone: string;

  @Expose()
  userEmail: string;

  @Expose()
  userId: string;

  @Expose()
  @Type(() => ReservationMovieApiModel)
  movie: ReservationMovieApiModel;

  @Expose()
  @Type(() => ReservationEventApiModel)
  event: ReservationEventApiModel;

  @Expose()
  @Type(() => ReservationItemApiModel)
  reservationItems: ReservationItemApiModel[];
}

