import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { RegionApiModel } from '../model/api-model/region/region.api.model';

@Injectable({
  providedIn: 'root',
})
export class RegionHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  public getRegions(): Observable<RegionApiModel[]> {
    return this.http.get<RegionApiModel[]>('/region');
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
    maxAge: 3600000,
  })
  public getRegion(id: string): Observable<RegionApiModel> {
    return this.http.get<RegionApiModel>(`/region/${id}`);
  }
}
