import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
import { EventHttpService } from '../http/event.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { EventRequestModel } from '../model/request/event.request.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { MovieHttpService } from '../http/movie.http.service';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { CmsHelper } from '../helper/cms.helper';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { WordpressDataProvider } from './wordpress.data-provider';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class EventDataProvider {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private eventHttpService: EventHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private wordpressDataProvider: WordpressDataProvider,
    private cmsHelper: CmsHelper
  ) {}

  public getCinemaEventList(model: EventRequestModel): Observable<EventViewModel[]> {
    return this.eventHttpService
      .getCinemaEvent(model.cinemaId, model.eventId, model.isoDateTimeFrom, model.isoDateTimeTo)
      .pipe(map((result) => result.map((event) => new EventViewModel(event))));
  }

  getCinemaEvent(cinemaId: string, eventId: string, screeningId?: string, dateFrom?: DateTime, dateTo?: DateTime): Observable<EventViewModel> {
    return this.eventHttpService.getCinemaEvent(cinemaId, eventId, dateFrom.toISO(), dateTo.toISO()).pipe(
      map((result) => result.filter((e) => !screeningId || e.screeningId === screeningId)[0]),
      mergeMap((eventResponse) => iif(() => !!eventResponse, of(new EventViewModel(eventResponse)), of(new EventViewModel()))),
      mergeMap((eventResponse) =>
        iif(
          () => !!eventResponse.items[0]?.moviePrintId,
          this.movieCopyHttpService.getMoviePrint(eventResponse.items[0]?.moviePrintId).pipe(
            map((moviePrintApiModel: MoviePrintApiModel) => {
              eventResponse.movieCopy = new MoviePrintViewModel(moviePrintApiModel);
              return eventResponse;
            })
          ),
          of(eventResponse)
        )
      ),
      mergeMap((eventResponse) =>
        iif(
          () => !!eventResponse.movieCopy?.movieId,
          this.movieHttpService.getMovie(eventResponse.movieCopy?.movieId).pipe(
            map((movieApiModel: MovieApiModel) => {
              eventResponse.movieCopy.movie = new MovieViewModel(movieApiModel);
              return eventResponse;
            })
          ),
          of(eventResponse)
        )
      )
    );
  }

  getEventsByCinema(requestModel: EventRequestModel): Observable<EventViewModel[]> {
    const restEndpoint = this.eventHttpService.getCinemaEvent(
      requestModel.cinemaId,
      requestModel.eventId,
      requestModel.isoDateTimeFrom,
      requestModel.isoDateTimeTo
    );

    if (this.cmsHelper.canUseCms) {
      return forkJoin({
        restEvents: restEndpoint.pipe(map((result) => result.map((event) => new EventViewModel(event)))),
        wpEvents: this.wordpressDataProvider.getEvents(),
      }).pipe(
        tap((result) => result.restEvents.map((event) => (event.priority = result.wpEvents.filter((e) => e.id === event.id)[0]?.priority))),
        tap((result) =>
          result.restEvents.map((event) => {
            event.regionId = requestModel.regionId;
          })
        ),
        map((r) => {
          return r.restEvents.filter((f) => r.wpEvents.some((e) => e.id === f.id));
        })
      );
    }

    return restEndpoint.pipe(map((result) => result.map((event) => new EventViewModel(event))));
  }

  getEventsByRegion(requestModel: EventRequestModel) {
    return this.wordpressDataProvider.getRegionById(requestModel.regionId).pipe(
      mergeMap((region) => {
        const observables = region.cinemas.map((cinema) =>
          this.getEventsByCinema(
            new EventRequestModel(
              cinema.id,
              requestModel.regionId,
              requestModel.dateTimeFrom.startOf('day'),
              requestModel.dateTimeTo.endOf('day'),
              requestModel.eventId,
              requestModel.convertDates
            )
          )
        );
        return combineLatest(observables);
      }),
      map((result) => {
        return ([] as EventViewModel[]).concat(...result);
      })
    );
  }

  getEvents(requestModel: EventRequestModel) {
    const restEndpoint = this.eventHttpService
      .getEvent(requestModel.isoDateTimeFrom, requestModel.isoDateTimeTo)
      .pipe(map((result) => result.map((event) => new EventViewModel(event))));

    return forkJoin({
      restEvents: restEndpoint,
      wpEvents: this.wordpressDataProvider.getEvents(),
    }).pipe(
      tap((result) => result.restEvents.map((event) => (event.priority = result.wpEvents.filter((e) => e.id === event.id)[0]?.priority))),
      tap((result) =>
        result.restEvents.map((event) => {
          event.regionId = requestModel.regionId;
        })
      ),
      map((r) => {
        return r.restEvents.filter((f) => r.wpEvents.some((e) => e.id === f.id));
      })
    );
  }
}
