import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ScreenHttpService } from '../http/screen.http.service';
import { ScreeningDataProvider } from './screening.data-provider';
import { EventDataProvider } from './event.data-provider';
import { SeatsOccupancyService } from '../service/seats-occupancy.service';
import { appProjectName, storageKey } from '../../app.const';
import { EventRequestModel } from '../model/request/event.request.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { ScreeningViewModelAdapter } from '../model/view-model/screening/screening.view.model.adapter';
import { ScreeningModel } from '../model/screening.model';
import { OccupancyModel } from '../model/occupancy.model';
import { DateTimeService } from '../service/datetime.service';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { AppService } from '../service/app.service';
import { StateService } from '../state/state.service';
import { ScreeningDetails } from '../wp-model/adapters';
import { ClientCmsScreenHelper } from '../helper/client/cms.screen.helper';
import { ClientTransactionScreenHelper } from '../helper/client/transaction.screen.helper';

export interface FindByScreeningOptions {
  disableOccupancy: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenDataProvider {
  constructor(
    private screenHttpService: ScreenHttpService,
    private screeningDataProvider: ScreeningDataProvider,
    private screenOccupancyService: SeatsOccupancyService,
    private eventDataProvider: EventDataProvider,
    private dateTimeService: DateTimeService,
    private appService: AppService,
    private stateService: StateService
  ) {}

  get multiSeatGroupTypes(): Array<string> {
    return ['couch', 'lounge'];
  }

  getScreenByAndCheckIfScreeningIsEvent(cinemaId: string, screeningId: string, options: FindByScreeningOptions = null): Observable<ScreenViewModel> {
    const dateSelected = this.dateTimeService.local(cinemaId);
    const eventRequestModel = new EventRequestModel(cinemaId, null, dateSelected.startOf('day'), dateSelected.plus({ days: 1000 }).endOf('day'));

    //check if screeningId is an event
    return this.eventDataProvider.getCinemaEventList(eventRequestModel).pipe(
      switchMap((events) => {
        const ev = events.find((o) => o.screeningId.toLowerCase() === screeningId.toLowerCase());
        if (ev) {
          this.stateService.setItem(storageKey.chosenDate, ev.timeFrom.toISODate());
          this.stateService.setItem(storageKey.isEvent, 'true');
          this.stateService.setItem(storageKey.lastEventId, ev.id);
        }
        return this.getScreenBy(cinemaId, screeningId, ev?.id, options);
      })
    );
  }

  getScreenBy(cinemaId: string, screeningId: string, eventId?: string, options?: FindByScreeningOptions): Observable<ScreenViewModel> {
    const dateSelected = this.dateTimeService.local(cinemaId);

    return forkJoin({
      event: eventId
        ? this.eventDataProvider.getCinemaEvent(cinemaId, eventId, screeningId, dateSelected.startOf('day'), dateSelected.plus({ days: 365 }).endOf('day'))
        : of<EventViewModel>(null),
      screening: !eventId ? this.screeningDataProvider.getScreeningDetailsById(cinemaId, screeningId, null) : of<ScreeningDetails>(null),
    }).pipe(
      switchMap((data) => {
        return forkJoin({
          screenModel: this.screenHttpService.getScreens(cinemaId, screeningId),
          occupancy: options && options.disableOccupancy ? of(null) : this.screeningDataProvider.getOccupancyList(cinemaId, screeningId),
        }).pipe(
          switchMap((res) => {
            if (res.screenModel?.length > 0) {
              const screenModel = this.mapFromScreenModel(res.screenModel[0]);

              screenModel.makeScreenModelForMovie(
                eventId ? null : data.screening.moviePrint,
                eventId
                  ? Object.assign(new MovieViewModel(), {
                      title: data.event.name,
                      duration: data.event.duration,
                      posters: data.event.posters,
                      description: data.event.description,
                      ratings: data.event.ratings,
                    })
                  : data.screening.movie,
                eventId ? new ScreeningViewModelAdapter().adapt(data.event) : data.screening.screening,
                eventId ? data.event.genres : data.screening.movie.genres,
                res.occupancy
              );

              if (res.occupancy) {
                this.screenOccupancyService.markOccupiedSeats(screenModel, res.occupancy);
              }

              return of(screenModel);
            } else {
              return of(null);
            }
          })
        );
      })
    );
  }

  public makeScreenModelForEvent(screenModel: ScreenViewModel, event: EventViewModel, selectedScreening?: ScreeningModel, occupancy?: OccupancyModel) {
    screenModel.isEvent = true;
    const movieCopyModel = event.movieCopy;

    if (selectedScreening) {
      screenModel.id = selectedScreening.id;
      screenModel.generalAdmission = selectedScreening.generalAdmission;
      screenModel.maxOccupancy = selectedScreening.maxOccupancy;
      screenModel.audience = selectedScreening.audience;
      screenModel.screeningDuration = selectedScreening.screeningDuration;
      screenModel.isScreenSwapping = selectedScreening.isScreenSwapping;
      screenModel.screeningAvailabilityStatus = selectedScreening.availabilityStatus;
    } else {
      screenModel.id = event.screeningId;
      screenModel.generalAdmission = false;
      screenModel.screeningDuration = event.duration;
      screenModel.isScreenSwapping = false;
      screenModel.screeningAvailabilityStatus = event.availabilityStatus;

      screenModel.maxOccupancy = occupancy ? occupancy.seatsLeft + occupancy.totalOccupied : 500;
    }

    screenModel.movieName = event.name;
    screenModel.movieDate = event.timeFrom;
    screenModel.pictureUrl = '';

    screenModel.movieDescription = event.description;
    screenModel.movieGenres = event.genres;
    screenModel.movieTags = event.tagGroups || [];
    screenModel.movieRating = event.ratings;

    screenModel.reservationTimeTo = event.reservationTimeTo;
    screenModel.saleTimeTo = event.saleTimeTo;
    screenModel.screeningTimeFrom = event.timeFrom;
    screenModel.screeningTimeTo = event.timeTo;
    screenModel.occupancy = occupancy;

    if (movieCopyModel) {
      if (movieCopyModel.movie) {
        screenModel.moviePremiere = movieCopyModel.movie.premiereDate;
        screenModel.movieDuration = movieCopyModel.movie.duration;
        screenModel.movieId = movieCopyModel.movie.id;
        screenModel.posterUrl = event.posters ? event.posters[0] : movieCopyModel.movie.posters ? movieCopyModel.movie.posters[0] : '';
      }

      screenModel.movieLanguage = movieCopyModel.language;
      screenModel.movieSpeakingType = movieCopyModel.speakingType;
      screenModel.moviePrintType = movieCopyModel.printType;
      screenModel.movieSoundType = movieCopyModel.soundType;
      if (movieCopyModel.subtitles.length > 0) {
        screenModel.movieSubtitles.push(movieCopyModel.subtitles);
      }
      if (movieCopyModel.subtitles2.length > 0) {
        screenModel.movieSubtitles.push(movieCopyModel.subtitles2);
      }
    }

    if (occupancy) {
      this.screenOccupancyService.markOccupiedSeats(screenModel, occupancy);
    }

    return screenModel;
  }

  findById(cinemaId: string, screenId: string): Observable<ScreenViewModel> {
    return this.screenHttpService.getScreen(cinemaId, screenId).pipe(
      map((x) => {
        return this.mapFromScreenModel(x);
      })
    );
  }

  findScreenByScreeningId(cinemaId: string, screeningId: string): Observable<ScreenViewModel> {
    return this.screenHttpService.getScreens(cinemaId, screeningId).pipe(
      map((x: Array<ScreenApiModel>) => {
        const first: ScreenApiModel | undefined = x.shift();
        if (!first) {
          return null;
        }
        return this.mapFromScreenModel(first);
      })
    );
  }

  private mapFromScreenModel(model: ScreenApiModel) {
    return this.appService.isProject(appProjectName.HELIOS, appProjectName.KINOTEKA, appProjectName.LANDMARK, appProjectName.ONEIL)
      ? ClientTransactionScreenHelper.mapFromScreenModel(model)
      : ClientCmsScreenHelper.mapFromScreenModel(model);
  }
}
