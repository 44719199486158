import { PaymentProviderEvent } from "./payment-provider.event";

export class WaitPaymentProviderEvent extends PaymentProviderEvent {

  public seconds: number;

  constructor(seconds: number) {
    super();
    this.seconds = seconds;
  }
}
