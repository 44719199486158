<div class="d-flex movie-info" *ngIf="screen && cinema" [smooth-in]="screen && cinema">
  <div class="movie-info-container">
    <div class="movie-poster">
      <img pimg [imageUrl]="screen?.posterUrl" [alt]="screen?.movieName">
    </div>
    <div class="movie-title">{{screen?.movieName}}</div>
    <div class="d-flex justify-content-around justify-content-md-between screen-info-1">
      <div>
        <div class="movie-info-label">{{ "personal.register.day" | translate }}</div>
        <div class="movie-info-val">{{ screen?.screeningTimeFrom | dateTime: 'DATE_SHORT_DMY' }}</div>
      </div>
      <div>
        <div class="movie-info-label">{{ "personal.register.hour" | translate }}</div>
        <div class="movie-info-val">{{ screen?.screeningTimeFrom | dateTime: 'HH:mm' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-around justify-content-md-between screen-info-1">
      <div>
        <div class="movie-info-label">Sala</div>
        <div class="movie-info-val">{{ screen?.name }}</div>
      </div>
      <div>
        <div class="movie-info-label">Wersja</div>
        <div class="movie-info-val">{{ screen?.moviePrintType }}</div>
      </div>
    </div>
  </div>
  <div class="movie-info-footer">
    <div class="movie-info-footer-line concave">
      <div class="l"></div>
      <div class="r"></div>
    </div>
    <div class="movie-info-footer-barcode">
      <div class="movie-info-footer-barcode-image"></div>
    </div>
  </div>
</div>