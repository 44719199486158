import { AbstractViewModel } from '../../abstract.view.model';
import { UserWatchlistApiModel } from '../../api-model/user/user-watchlist.api.model';
import {EventViewModel} from '../event/event.view.model';
import {MovieViewModel} from '../movie/movie.view.model';

export class UserWatchlistViewModel extends AbstractViewModel<UserWatchlistApiModel> {
  movies: MovieViewModel[];
  events: EventViewModel[];

  constructor(protected apiModel: UserWatchlistApiModel = new UserWatchlistApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.movies = this.apiModel.movies ? this.apiModel.movies.map(item => new MovieViewModel(item)) : [];
    this.events = this.apiModel.events ? this.apiModel.events.map(item => new EventViewModel(item)) : [];
  }

  toApiModel(): UserWatchlistApiModel {
    return undefined;
  }

}
