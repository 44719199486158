<ng-container *ngIf="paymentProviderConfig?.providerConfiguration">
    <div [smooth-in]="paymentInitialized">
        <h5 class="pb-2 font-weight-bold">{{'cart.paymentMethod' | translate}}</h5>
        <form id="paymentForm" method="post">
            <div id="card" class="d-flex dark-elevation-block rounded mt-1 py-2 mx-0"></div>
            <div id="errorMessage"></div>
            <div id="customStyles" hidden>
                <style>
                    #card {
                        height: 80px;

                        @media (max-width: 768px) {
                            height: 150px;
                        }
                    }

                    #errorMessage {
                        color: #c0392b;
                    }
                </style>
            </div>
            <div class="pb-4 px-3 px-md-0 mx-0 my-3 d-flex elevation-block user-cart-navigation-buttons">
                <input class="btn btn-secondary px-5" type="button" [value]="'shared.cancel' | translate"
                       (click)="reload()" />

                <button class="btn btn-primary px-4 px-sm-5" [disabled]="isSubmitting" (click)="handleButtonClick()">
                    <ng-container *ngIf="!isSubmitting">{{'shared.confirm' | translate}}</ng-container>
                    <ng-container *ngIf="isSubmitting"><span class="spinner"><i
                               class="fas fa-spinner fa-spin"></i></span></ng-container>

                </button>
                <button type="submit" #bridgePayConfirmBtn id="bridgePayConfirmBtn" style="display:none;"></button>
            </div>
        </form>
    </div>

    <!-- <form name="tokenform" id="tokenform">
        <iframe id="tokenFrame" name="tokenFrame" [src]="ajaxTokenizerUrl | safe:'resourceUrl'" frameborder="0"
                scrolling="no" height="175" (load)="onLoadIFrame()"></iframe>
    </form> -->

    <!-- <div class="error" *ngIf="paymentProviderState === PaymentProviderStateEnum.Error">
        <p>{{!fiservEvent ? ('errors.10022' | translate) : fiservEvent.validationError}}</p>
    </div> -->
</ng-container>