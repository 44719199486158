import { Component, Inject, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { appProjectName, validationPattern } from 'libs/core/src/app.const';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class PhoneComponent implements ControlValueAccessor, Validator {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment) {}

  @Input() hasError = false;
  @Input() placeholder = '';
  @Input() maxLength: number = 15;

  phone: string = null;

  number: string = null;
  prefix: string = null;
  provider: string = null;

  providers: any[] = [];

  onChange = (phone) => {};
  onTouched = () => {};

  touched = false;
  disabled = false;

  onNumberChange(event) {
    if (!this.disabled) {
      this.number = event.target.value;
      this.onPhoneChange();
    }
  }

  onProviderChange(event) {
    if (!this.disabled) {
      this.provider = event.id;
      this.onPhoneChange();
    }
  }

  onPhoneChange() {
    switch (this.environment.projectName) {
      case appProjectName.BLUE:
        if (this.number && this.number.length && this.prefix && this.provider) {
          this.markAsTouched();
          this.phone = this.prefix + this.provider + this.number;
        } else {
          this.phone = '';
        }
        break;
      default:
        if (this.number && this.number.length) {
          this.markAsTouched();
          this.phone = this.number;
        } else {
          this.phone = '';
        }
        break;
    }

    this.onChange(this.replaceAll(this.phone, ' ', ''));
  }

  onInput(event: any) {
    let value = event.target.value;
    const firstChar = value.charAt(0);
    if (firstChar === '+') {
      value = value.substring(1).replace(/[^0-9]/g, '');
      value = `+${value}`;
    } else {
      value = value.replace(/[^0-9]/g, '');
    }

    const maxLength = this.maxLength + (value.indexOf('+') == -1 ? 0 : 1);
    if (value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    event.target.value = value;
  }

  writeValue(phone: string) {
    this.phone = phone;
    if (phone) {
      switch (this.environment.projectName) {
        case appProjectName.BLUE:
          this.prefix = !this.isNullOrEmpty(phone) && phone.charAt(0) === '+' ? phone.substring(0, 3) : '';
          this.provider = !this.isNullOrEmpty(phone, 3) ? (phone.charAt(3) === '0' ? phone.substring(4, 6) : phone.substring(3, 5)) : '';
          this.number = phone.substring(this.prefix.length + this.provider.length + (!this.isNullOrEmpty(phone, 3) && phone.charAt(3) === '0' ? 1 : 0));
          this.providers = this.environment.constants.phoneProviderNumbers.map((o) => {
            return { id: o, name: o.padStart(3, '0'), selected: o === this.provider };
          });
          break;
        default:
          this.prefix = '';
          this.provider = '';
          this.number = phone;
          break;
      }

      return;
    }

    switch (this.environment.projectName) {
      case appProjectName.BLUE:
        this.prefix = '+41';
        const providers = this.environment.constants.phoneProviderNumbers;
        this.provider = '';
        this.providers = providers.map((o) => {
          return { id: o, name: o.padStart(3, '0'), selected: o === this.provider };
        });
        break;
      default:
        this.prefix = '';
        this.provider = '';
        this.number = phone;
        break;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.hasError = false;

    switch (this.environment.projectName) {
      case appProjectName.BLUE:
        if (this.number && !this.provider) {
          return {
            pattern: 'invalid',
          };
        }
        break;
      default:
        break;
    }

    return null;
  }

  private isNullOrEmpty(value: any, length: number = null) {
    return !(value !== null && value !== undefined && (value as string) !== '' && (!length || value.length >= length));
  }

  private replaceAll(str: string, find: string, replace: string) {
    return str ? str.replace(new RegExp(find, 'g'), replace) : null;
  }
}
