import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class UserTokenResponseModel {
  @Expose()
  public token: string;

  @Expose()
  public refresh_token: string;
}
