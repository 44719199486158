import {DateTime} from 'luxon';

export class CollisionItemModel {

  private _screeningId: string;

  private _movieTitle: string;

  private _screeningTimeFrom ?: DateTime = null;

  private _screeningTimeTo ?: DateTime = null;


  constructor(screeningId: string, movieTitle: string, screeningTimeFrom: DateTime, screeningTimeTo: DateTime) {
    this._screeningId = screeningId;
    this._movieTitle = movieTitle;
    this._screeningTimeFrom = screeningTimeFrom;
    this._screeningTimeTo = screeningTimeTo;
  }

  get screeningId(): string {
    return this._screeningId;
  }

  get movieTitle(): string {
    return this._movieTitle;
  }


  get screeningTimeFrom(): DateTime {
    return this._screeningTimeFrom;
  }

  get screeningTimeTo(): DateTime {
    return this._screeningTimeTo;
  }
}
