import { AbstractViewModel } from "../../../abstract.view.model";
import { TicketExtraFeeApiModel } from "../../../api-model/account-items/reservation/ticket-extra-fee.api.model";

export class TicketExtraFeeViewModel extends AbstractViewModel<TicketExtraFeeApiModel> {
  public name: string;
  public price: number;
  public isOptional: boolean;

  constructor(
    protected apiModel: TicketExtraFeeApiModel = new TicketExtraFeeApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.isOptional = this.apiModel.isOptional;
  }

  toApiModel(): TicketExtraFeeApiModel {
    return undefined;
  }
}
