import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserMoviePrintApiModel } from '../../../api-model/user-history/movie-print/user-movie-print.api.model';

export class UserMoviePrintViewModel extends AbstractViewModel<UserMoviePrintApiModel> {
  public id: string = null;
  public printType: string = null;
  public soundType: string = null;
  public speakingType: string = null;
  public movieId: string = null;
  public language: string = null;
  public subtitles: string = null;
  public subtitles2: string = null;
  public premiereDate: DateTime = null;
  public release: string = null;

  constructor(
    protected apiModel: UserMoviePrintApiModel = new UserMoviePrintApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.printType = this.apiModel.printType;
    this.soundType = this.apiModel.soundType;
    this.speakingType = this.apiModel.speakingType;
    this.movieId = this.apiModel.movieId;
    this.language = this.apiModel.language;
    this.subtitles = this.apiModel.subtitles;
    this.subtitles2 = this.apiModel.subtitles2;
    this.premiereDate = this.apiModel.premiereDate;
    this.release = this.apiModel.release;
  }

  toApiModel(): UserMoviePrintApiModel {
    return undefined;
  }
}
