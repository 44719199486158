import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { CardApiModel } from '../../../api-model/account-items/card/card.api.model';
import { CardTypeViewModel } from '../../card/card-type.view.model';
import { UserCardViewModel } from '../../user-history/card/user-card.view.model';

export class CardViewModel extends AbstractViewModel<CardApiModel> {
  public id: string;
  public cardTypeId: string;
  public name: string;
  public description: string;
  public message: string;
  public picture: string;
  public number: string;
  public flgSubscription: boolean;
  public type: number;
  public valueBalance: number;
  public pointsBalance: number;
  public expiryDate: DateTime;
  public typeDescriptions: string[] = [];
  public nextPointsToExpire: number;
  public nextPointsExpiryDate: DateTime;
  public collectedPoints: number;

  public cardType: CardTypeViewModel;
  public isUsed = false;

  constructor(protected apiModel: CardApiModel = new CardApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.cardTypeId = this.apiModel.cardTypeId;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.message = this.apiModel.message;
    this.picture = this.apiModel.picture;
    this.number = this.apiModel.number;
    this.flgSubscription = this.apiModel.flgSubscription;
    this.type = this.apiModel.type;
    this.valueBalance = this.apiModel.valueBalance;
    this.pointsBalance = this.apiModel.pointsBalance;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.typeDescriptions = this.apiModel.typeDescriptions;
    this.nextPointsToExpire = this.apiModel.nextPointsToExpire;
    this.nextPointsExpiryDate = this.apiModel.nextPointsExpiryDate ? DateTime.fromISO(this.apiModel.nextPointsExpiryDate) : null;
    this.collectedPoints = this.apiModel.collectedPoints;
  }

  toApiModel(): CardApiModel {
    return undefined;
  }

  toUserCardViewModel(extra?: UserCardViewModel): UserCardViewModel {
    const model = Object.assign(extra ?? new UserCardViewModel(), {
      id: this.id,
      number: this.number,
      name: this.name,
      description: this.description,
      typeId: this.cardTypeId,
      type: this.type,
      valueBalance: this.valueBalance,
      pointBalance: this.pointsBalance,
      expiryDate: this.expiryDate,
      cardType: this.cardType?.toUserCardTypeViewModel(),
      picture: this.picture,
      isUsed: this.isUsed,
    });

    return model;
  }
}
