export class SeatTranslatorService {
  static romanDigits: Array<string> = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XVIII', 'XIX', 'XX'];
  static arabicDigits: Array<string> = ['A', 'B', 'E', 'F', 'G', 'H', 'J', 'K', 'N', 'O', 'P', 'R', 'S', 'T', 'Q', 'Y', 'Z'];

  public static translateValue(value: string, skipRomanChecking: boolean = false): number {
    let translateValue: number = Number(value);
    if (!isNaN(translateValue)) {
      return translateValue;
    } 
    
    if (!skipRomanChecking) {
      translateValue = this.romanDigits.indexOf(value);
      if (translateValue !== -1) {
        return translateValue;
      }
    }

    return value?.charCodeAt(0) - 65;
  }

  public static isArabicDigit(values: Array<string>): boolean {
    for (const char of values) {
      if (this.arabicDigits.includes(char) || !isNaN(Number(char))) {
        return true;
      }
    }

    return false;
  }

  public static isNotRomanDigit(values: Array<string>): boolean {
    return this.isArabicDigit(values);
  }
}
