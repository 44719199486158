import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { getDropdownDays, getDropdownMonths, getDropdownYears } from 'libs/core/src/lib/date/date.helper';
import { DateTime } from 'luxon';

@Component({
  template: '',
})
export class DateComponent implements ControlValueAccessor, Validator {
  @Input() hasError = false;
  @Input() minimumAge: number = null;
  @Input() disabledFutureDate = true;
  @Input() sortType: 'dmy' | 'mdy' | 'ymd' | 'ydm' = 'dmy';

  controlsMapping = new Map<string, string>([
    ['d', 'day'],
    ['m', 'month'],
    ['y', 'year'],
  ]);

  controls: any[] = [];

  date: DateTime = null;
  year: string = null;
  month: string = null;
  day: string = null;

  days = getDropdownDays(1, 31);
  months = getDropdownMonths(1, 12);
  years = getDropdownYears();

  onChange = (date: DateTime | null) => {
    if (!date) {
      this.markAsTouched();
    }

    this.date = date;
    return;
  };
  onTouched = () => {};

  touched = false;
  disabled = false;

  ngOnInit(): void {
    this.controls = this.getControls();
  }

  private panValue(value: string | number) {
    if (typeof value === 'number') {
      value = value.toString();
    }

    return value && value.length ? value.padStart(2, '0') : '';
  }

  onYearChange(event) {
    if (!this.disabled) {
      this.year = event?.target?.value || event.id;
      this.onDateChange('y');
    }
  }

  onMonthChange(event) {
    if (!this.disabled) {
      this.month = this.panValue(event?.target?.value || event.id);
      this.onDateChange('m');
    }
  }

  onDayChange(event) {
    if (!this.disabled) {
      this.day = this.panValue(event?.target?.value || event.id);
      this.onDateChange('d');
    }
  }

  onDateChange(field: string) {
    const date =
      (field === 'y' && this.month && this.day) || (field === 'm' && this.year && this.day) || (field === 'd' && this.year && this.month)
        ? `${this.year}-${this.month}-${this.day}`
        : null;

    if (date) {
      this.onChange(DateTime.fromISO(date, { zone: 'utc' }));
    } else {
      this.onChange(null);
    }
  }

  writeValue(date: DateTime | null) {
    if (!date) {
      return;
    }

    this.year = date.year.toString();
    this.month = this.panValue(date.month);
    this.day = this.panValue(date.day);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.hasError = false;
    const date = control.value as DateTime;
    const now = DateTime.utc().startOf('day');
    if (date) {
      const yearsDiff = date.diff(now, 'years').years;
      if (!date.isValid || date.year < 1900) {
        this.hasError = true;
        return { invalidDate: { date } };
      }

      if (this.minimumAge && yearsDiff > -this.minimumAge) {
        this.hasError = true;
        return { minimumAge: { requiredAge: this.minimumAge, actualAge: yearsDiff } };
      }

      if (this.disabledFutureDate && now < date) {
        this.writeValue(now);
      }
    }
  }

  getIndex(field: 'y' | 'm' | 'd') {
    return this.sortType.indexOf(field);
  }
  getFocusOnParams(field: 'y' | 'm' | 'd') {
    const focusOnParam: { prev?: string; next?: string } = {};
    const fieldIndex = this.getIndex(field);
    const getInput = (f) => {
      const m = this.controlsMapping.get(f);
      return m ? `input[id='${m}']` : null;
    };
    if (fieldIndex < this.sortType.length - 1) {
      const nextField = this.sortType[fieldIndex + 1];
      focusOnParam.next = getInput(nextField);
    }

    if (fieldIndex > 0) {
      const prevField = this.sortType[fieldIndex - 1];
      focusOnParam.prev = getInput(prevField);
    }

    return focusOnParam;
  }

  getControls() {
    const order = this.sortType.split('');
    return order
      .map((char) => {
        switch (char) {
          case 'd':
            return { id: 'day' };
          case 'm':
            return { id: 'month' };
          case 'y':
            return { id: 'year' };
          default:
            return null;
        }
      })
      .filter((control) => control !== null);
  }
}
