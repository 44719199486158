import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject, Observable, throwError, iif, of } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { FormEventModel } from './model/form-event.model';
import { CheckListItemModel } from 'libs/core/src/lib/model/checklistitem.model';
import { RefundDataProvider } from 'libs/core/src/lib/data-provider/refund.data-provider';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { CanBeRefundedItemResponse, CanBeRefundedResponse } from 'libs/core/src/lib/model/response/order-refund.response.models';
import { CanBeRefundedRequest, CanBeRefundedSalesDocumentItemRequest } from 'libs/core/src/lib/model/request/order-refund.request.models';
import { RefundTransactionRequestModel } from 'libs/core/src/lib/model/request/refund-transaction-payment.request.model';
import { RefundTransactionPaymentResponse } from 'libs/core/src/lib/model/response/refund-transaction.response.model';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import orderBy from 'lodash-es/orderBy';
import { OrderAliasApiModel } from 'libs/core/src/lib/model/api-model/order/order-alias.api.model';
import { SalesDocumentViewModel } from 'libs/core/src/lib/model/view-model/sales-document/sales-document.view.model';
import { SalesDocumentApiModel } from 'libs/core/src/lib/model/api-model/sales-document/sales-document.api.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { DateTime } from 'luxon';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { ModalStatusEnum } from '../../component/modal/model/modal-status.enum';
import { DateHelper } from 'libs/core/src/lib/date/date.helper';

@Component({
  template: '',
})
export class RefundPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public lang: string = null;
  public loadingStatus = LoadingStatus.pending;
  public showStepsContainer = false;
  public formEvent = new Subject<FormEventModel>();
  public isSuccessSend = '';

  public items: CheckListItemModel[];
  public successContent: {};
  public failContent: {};

  public allowTimeBeforeScreening: number;

  private cinemaId: string;
  private salesDocumentId: string;
  private bookingId: string;
  private email: string;

  private refObject: {
    items: CanBeRefundedItemResponse[];
    salesDocument: SalesDocumentViewModel;
    screen: ScreenViewModel;
  };

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected renderer: Renderer2,
    protected refundDataProvider: RefundDataProvider,
    protected navigationHelperService: NavigationHelperService,
    protected translateService: TranslateService,
    protected headerService: HeaderService,
    protected screeningDataProvider: ScreeningDataProvider,
    protected screenDataProvider: ScreenDataProvider,
    protected googleTagManagerService: GoogleTagManagerService,
    protected stateService: StateService,
    protected loadingService: LoadingService
  ) {
    this.allowTimeBeforeScreening = this.environment.constants?.refundAllowTimeBeforeScreening ?? 30;
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'custom-body');
    this.lang = this.stateService.getItem('lang');

    this.formEvent.subscribe((event) => {
      if (event.action === 'validated') {
        this.loader(true);
        this.readFormFields(event.form);
      }
    });

    this.loadingStatus = LoadingStatus.success;
    this.loadingService.hideLoader(LoaderEnum.MAIN);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.headerService.setInnerContent(this.translateService.instant('refund.header')), 100);
  }

  ngOnDestroy(): void {
    this.headerService.setInnerContent(null);
  }

  onNextClick() {
    this.formEvent.next({ action: 'submit', form: null });
  }

  readFormFields(form: FormGroup) {
    const emailFiels = form.get('email');
    const bookingField = form.get('bookingId');
    const cinemaField = form.get('cinemaId');

    this.refObject = {
      items: [],
      salesDocument: null,
      screen: null,
    };

    const bookingId = bookingField?.value;
    if (!bookingId) {
      return;
    }

    this.bookingId = bookingId;
    this.cinemaId = cinemaField?.value;
    this.email = emailFiels?.value;

    const canBeRefundedRequest = new CanBeRefundedRequest(this.email);
    const canBeRefunded = !this.cinemaId
      ? this.refundDataProvider.getOrderTransactionNumber(this.bookingId).pipe(
          map((orderAliasResponse: OrderAliasApiModel) => {
            this.cinemaId = orderAliasResponse.cinemaId;
            this.salesDocumentId = orderAliasResponse.salesDocumentId;

            canBeRefundedRequest.salesDocumentId = this.salesDocumentId;
            return canBeRefundedRequest;
          })
        )
      : of(null).pipe(
          tap(() => {
            canBeRefundedRequest.ticketNumber = this.bookingId;
          })
        );

    canBeRefunded
      .pipe(
        switchMap((request) => this.refundDataProvider.canBeRefunded(this.cinemaId, request)),
        mergeMap((response) =>
          iif(
            () => !response.refundToken && response.executionStatus > 0,
            throwError(() =>
              this.translateService.instant('refund.error.' + response.executionStatus.toString()) === 'refund.error.' + response.executionStatus.toString()
                ? new Error()
                : new Error(this.translateService.instant('refund.error.' + response.executionStatus.toString()))
            ),
            of(response)
          )
        ),
        tap((canBeRefunded: CanBeRefundedResponse) => (this.refObject.items = canBeRefunded.itemList)),
        switchMap((canBeRefunded) => this.refundDataProvider.getSalesDocumentById(this.cinemaId, canBeRefunded.salesDocumentId)),
        tap((salesDocument: SalesDocumentApiModel) => (this.refObject.salesDocument = new SalesDocumentViewModel(salesDocument))),
        switchMap((salesDocument) => this.screenDataProvider.getScreenByAndCheckIfScreeningIsEvent(this.cinemaId, salesDocument.bookings[0]?.screeningId)),
        tap((screen: ScreenViewModel) => (this.refObject.screen = screen))
      )
      .subscribe(
        () => {
          if (this.parameterControl(this.refObject)) {
            this.prepareDataToDisplayInModal(this.refObject.items, this.refObject.salesDocument, this.refObject.screen);
          }
        },
        (error) => {
          this.displayFail({ error });
        }
      );
  }

  private parameterControl(refObject) {
    const screeningDate = refObject.screen.screeningTimeFrom;
    if (!DateHelper.isPast(screeningDate.minus({ minutes: this.allowTimeBeforeScreening }))) {
      return true;
    }

    this.displayFail();
    return false;
  }

  private prepareDataToDisplayInModal(canBeRefundedItems: CanBeRefundedItemResponse[], salesDocument: SalesDocumentViewModel, screen: ScreenViewModel) {
    if (canBeRefundedItems && canBeRefundedItems.length > 0) {
      const noRefundableItems = canBeRefundedItems.every((o) => !o.isRefundable);
      if (!noRefundableItems) {
        const items = canBeRefundedItems.map((response: CanBeRefundedItemResponse) => {
          const reservationItem = salesDocument.reservationItems.find((o) => o.ticketNumber === response.ticketMaskNumber);

          const rowInfo = reservationItem.row ? `, ${this.translateService.instant('ticketssummary.row')} <b>${reservationItem.row}</b>` : '';
          const colInfo = reservationItem.seat ? `, ${this.translateService.instant('ticketssummary.seat')} <b>${reservationItem.seat}</b>` : '';
          const innerInfo = `${this.translateService.instant('basket.list.ticket')} <b>${reservationItem.ticketNumber}</b>${rowInfo}${colInfo}`;

          const listItem = new CheckListItemModel(response.salesDocumentItemId, response.itemName, innerInfo);
          const rowIndex = screen.rowSymbols.findIndex((o) => o === reservationItem.row);
          if (rowIndex >= 0) {
            const seat = screen.pseats[rowIndex].find(
              (o) => o.symbol === reservationItem.seat || (o.groupConnectedSeats && o.groupConnectedSeats.some((g) => g.symbol === reservationItem.seat))
            );
            listItem.group = seat ? seat.id : '';
          } else {
            listItem.group = reservationItem.ticketNumber;
          }

          listItem.content = response;
          listItem.ticketNumber = reservationItem.ticketNumber;

          const sortKey: { row: Number | string; col: Number | string } = {
            row: parseInt(reservationItem.row, 10) || reservationItem.row,
            col: parseInt(reservationItem.seat, 10) || parseInt(reservationItem.ticketNumber, 10),
          };
          listItem['sortKey'] = sortKey;

          return listItem;
        });

        this.items = orderBy(items, ['sortKey.row', 'sortKey.col']);
        this.displayModal('normal');
        return;
      }
    }

    this.displayFail({ isRefundable: false });
  }

  onSendSalesDocumentItemsToRefund() {
    const canBeRefundedRequest = new CanBeRefundedRequest(this.email);
    canBeRefundedRequest.salesDocumentItemList = this.getCheckedItems().map((item: CheckListItemModel): CanBeRefundedSalesDocumentItemRequest => {
      return { id: item.id, quantity: item.content.quantity };
    });
    const refundedTicketsCount = canBeRefundedRequest.salesDocumentItemList.length;
    const allTicketsCount = this.items.length;

    this.refundDataProvider
      .getSalesDocument(this.cinemaId, this.salesDocumentId, canBeRefundedRequest)
      .pipe(
        switchMap((data) => {
          let totalRefundValue = data.canBeRefundedResponse.refundTotalValue;
          const refundRequest: RefundTransactionRequestModel = {
            emailTo: this.email,
            refundToken: data.canBeRefundedResponse.refundToken,
            paymentTypeList: data.refundTransactionPaymentResponse.map((p) => {
              let refundPayment = p.refundPaymentList.find((o) => o.id === p.id);
              if (!refundPayment && p.refundPaymentList.length > 0) {
                refundPayment = p.refundPaymentList[0];
              }

              let refundValue: any;
              ({ refundValue, totalRefundValue } = this.calculateRefundValue(p, totalRefundValue));
              return {
                id: refundPayment.id,
                refundValue,
                cardId: null,
                transactionId: null,
              };
            }),
          };

          return this.refundDataProvider.refund(this.cinemaId, this.salesDocumentId, refundRequest);
        })
      )
      .subscribe(
        (refundTransactionResponse) => {
          this.googleTagManagerService?.refund(
            this.cinemaId,
            this.refObject.salesDocument,
            allTicketsCount != refundedTicketsCount,
            this.getCheckedItems().map((m) => m.ticketNumber)
          );
          this.displaySuccess({ refundedTicketsCount, allTicketsCount });
        },
        (error) => {
          this.displayFail({ error });
        }
      );
  }

  private calculateRefundValue(p: RefundTransactionPaymentResponse, totalRefundValue: number) {
    let refundValue = 0;
    if (p.maxRefundValue < totalRefundValue) {
      refundValue = p.maxRefundValue;
      totalRefundValue -= p.maxRefundValue;
    } else {
      refundValue = totalRefundValue;
    }
    return { refundValue, totalRefundValue };
  }

  loader(active: boolean) {
    if (active) {
      this.loadingStatus = LoadingStatus.pending;
      this.loadingService.showLoader(LoaderEnum.MAIN);
    } else {
      this.loadingStatus = LoadingStatus.success;
      this.loadingService.hideLoader(LoaderEnum.MAIN);
    }
  }

  private displayFail(content: any = {}) {
    this.failContent = Object.assign(content, { timeBeforeScreening: this.allowTimeBeforeScreening });
    this.displayModal('fail');
  }

  private displaySuccess(content?: any) {
    this.successContent = content ? content : {};
    this.displayModal('success');
  }

  private displayModal(type: 'normal' | 'success' | 'fail') {
    this.loader(false);
    this.isSuccessSend = type;
  }

  onModalClick(event) {
    switch (event.state) {
      case ModalStatusEnum.accept:
        this.loader(true);
        this.onSendSalesDocumentItemsToRefund();
        break;
      case ModalStatusEnum.close:
        this.isSuccessSend = null;
        break;
      case ModalStatusEnum.open:
        break;
      case ModalStatusEnum.back:
        this.renderer.removeClass(document.body, 'custom-body');
        this.navigationHelperService.goToRepertoire();
        break;
    }
  }

  getCheckedItems() {
    return this.items && this.items.length ? this.items.filter((o) => o.checked) : [];
  }

  public onBrandClicked() {
    window.location.href = this.environment['siteUrl'];
  }
}
