import { AbstractViewModel } from "../../../abstract.view.model";
import { UserFbModifierApiModel } from "../../../api-model/user-history/fb-item/user-fb-modifier.api.model";
import { UserFbModifierItemViewModel } from "./user-fb-modifier-item.view.model";

export class UserFbModifierViewModel extends AbstractViewModel<UserFbModifierApiModel> {
  public modifierId: string = null;
  public modifierName: string = null;
  public separateItem: number = null;
  public modifierItems: UserFbModifierItemViewModel[] = [];

  constructor(
    protected apiModel: UserFbModifierApiModel = new UserFbModifierApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.modifierId = this.apiModel.modifierId;
    this.modifierName = this.apiModel.modifierName;
    this.separateItem = this.apiModel.separateItem;
    this.modifierItems = this.apiModel.modifierItems.map(modifierItem => new UserFbModifierItemViewModel(modifierItem));
  }

  toApiModel(): UserFbModifierApiModel {
    return undefined;
  }
}
