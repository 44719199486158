import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';

@Component({
    selector: 'app-screen-ticket-count-selector',
    templateUrl: './ticket-count-selector.component.html',
    styleUrls: ['./ticket-count-selector.component.scss']
})
export class ScreenTicketCountSelectorComponent implements OnInit {

    @Output() counterEvent = new EventEmitter<number>();
    counter = 1;
    @Input() initValue: number;
    @Input() seatIds: string[];
    private min: number;
    private max: number;

    constructor() {
        this.min = 1;
        this.max = 10;
    }

    ngOnInit() {
        this.counter = this.initValue;
    }

    onDecrementClick() {
        if (this.counter > this.min) {
            this.counter--;
            this.counterEvent.emit(this.counter);
        }
    }

    onIncrementClick() {
        if (this.counter < this.max) {
            this.counter++;
            this.counterEvent.emit(this.counter);
        }
    }
}
