import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { EventApiModel } from '../model/api-model/event/event.api.model';
import { makeHttpParams } from './http.helper';

@Injectable({
  providedIn: 'root',
})
export class EventHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: EventHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  public getCinemaEvent(cinemaId: string, eventId?: string, dateTimeFrom?: string, dateTimeTo?: string): Observable<EventApiModel[]> {
    let url = `/cinema/${cinemaId}/event`;
    if (eventId) {
      url += `/${eventId}`;
    }

    return this.http.get<EventApiModel[]>(url, {
      params: makeHttpParams({
        dateTimeFrom,
        dateTimeTo,
      }),
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: EventHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  public getEvent(dateTimeFrom?: string, dateTimeTo?: string): Observable<EventApiModel[]> {
    return this.http.get<EventApiModel[]>(`/event`, {
      params: makeHttpParams({
        dateTimeFrom,
        dateTimeTo,
      }),
    });
  }
}
