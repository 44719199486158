import { DateTime } from 'luxon';

export class EventRequestModel {
  constructor(
    public cinemaId?: string,
    public regionId?: string,
    public dateTimeFrom?: DateTime,
    public dateTimeTo?: DateTime,
    public eventId?: string,
    public convertDates: boolean = true
  ) {
    if (!dateTimeTo) {
      dateTimeTo = dateTimeFrom;
    }
  }

  public get isoDateTimeFrom() {
    return this.dateTimeFrom.toISO({ includeOffset: false });
  }
  public get isoDateTimeTo() {
    return this.dateTimeTo.toISO({ includeOffset: false });
  }
}
