import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import {
  SalesDocumentApiModel,
  SalesDocumentBookingApiModel,
  SalesDocumentItemApiModel,
  SalesDocumentModifierItemApiModel,
  SalesDocumentReservationItemApiModel,
  SalesDocumentReservationItemExtraFeeApiModel,
  SalesDocumentSubItemApiModel,
} from '../../api-model/sales-document/sales-document.api.model';
import { MovieCopyViewModel } from '../movie-copy.view.model';
import { ScreenViewModel } from '../screen/screen.view.model';

export class SalesDocumentViewModel extends AbstractViewModel<SalesDocumentApiModel> {
  id: string;
  totalValue: number;
  transactionDate: DateTime;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhoneNumber: string;
  userId: string;
  cardId: string;
  orderNumber: string;
  pickupTime: DateTime;
  items: SalesDocumentItemViewModel[];
  reservationItems: SalesDocumentReservationItemViewModel[];
  bookings: SalesDocumentBookingViewModel[];

  screen: ScreenViewModel;
  movieCopy: MovieCopyViewModel;

  constructor(protected apiModel: SalesDocumentApiModel = new SalesDocumentApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.totalValue = this.apiModel.totalValue;
    this.transactionDate = this.apiModel.transactionDate;
    this.userFirstName = this.apiModel.userFirstName;
    this.userLastName = this.apiModel.userLastName;
    this.userEmail = this.apiModel.userEmail;
    this.userPhoneNumber = this.apiModel.userPhoneNumber;
    this.userId = this.apiModel.userId;
    this.cardId = this.apiModel.cardId;
    this.orderNumber = this.apiModel.orderNumber;
    this.pickupTime = this.apiModel.pickupTime;
    this.items = this.apiModel.items.map((model) => new SalesDocumentItemViewModel(model));
    this.reservationItems = this.apiModel.reservationItems.map((model) => new SalesDocumentReservationItemViewModel(model));
  }

  toApiModel(): SalesDocumentApiModel {
    return undefined;
  }
}

export class SalesDocumentReservationItemViewModel extends AbstractViewModel<SalesDocumentReservationItemApiModel> {
  row: string;
  seat: string;
  ticketName: string;
  voucherName: string;
  price: number;
  checkStatus: number;
  checkDate: DateTime;
  ticketNumber: string;
  extraFees: SalesDocumentReservationItemExtraFeeViewModel[];

  constructor(protected apiModel: SalesDocumentReservationItemApiModel = new SalesDocumentReservationItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.row = this.apiModel.row;
    this.seat = this.apiModel.seat;
    this.ticketName = this.apiModel.ticketName;
    this.voucherName = this.apiModel.voucherName;
    this.price = this.apiModel.price;
    this.checkStatus = this.apiModel.checkStatus;
    this.checkDate = this.apiModel.checkDate ? DateTime.fromISO(this.apiModel.checkDate) : null;
    this.ticketNumber = this.apiModel.ticketNumber;
    this.extraFees = this.apiModel.extraFees.map((model) => new SalesDocumentReservationItemExtraFeeViewModel(model));
  }

  toApiModel(): SalesDocumentReservationItemApiModel {
    return undefined;
  }
}

export class SalesDocumentReservationItemExtraFeeViewModel extends AbstractViewModel<SalesDocumentReservationItemExtraFeeApiModel> {
  name: string;
  price: number;
  isOptional: boolean;

  constructor(protected apiModel: SalesDocumentReservationItemExtraFeeApiModel = new SalesDocumentReservationItemExtraFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.isOptional = this.apiModel.isOptional;
  }

  toApiModel(): SalesDocumentReservationItemExtraFeeApiModel {
    return undefined;
  }
}

export class SalesDocumentItemViewModel extends AbstractViewModel<SalesDocumentItemApiModel> {
  itemType: number;
  name: string;
  price: number;
  quantity: number;
  value: number;
  picture: string;
  voucherName: string;
  subItems: SalesDocumentSubItemViewModel[];
  modifierItems: SalesDocumentModifierItemViewModel[];

  constructor(protected apiModel: SalesDocumentItemApiModel = new SalesDocumentItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.itemType = this.apiModel.itemType;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.value = this.apiModel.value;
    this.picture = this.apiModel.picture;
    this.voucherName = this.apiModel.voucherName;
    this.subItems = this.apiModel.subItems.map((model) => new SalesDocumentSubItemViewModel(model));
    this.modifierItems = this.apiModel.modifierItems.map((model) => new SalesDocumentModifierItemViewModel(model));
  }

  toApiModel(): SalesDocumentItemApiModel {
    return undefined;
  }
}

export class SalesDocumentSubItemViewModel extends AbstractViewModel<SalesDocumentSubItemApiModel> {
  name: string;
  price: number;
  quantity: number;

  constructor(protected apiModel: SalesDocumentSubItemApiModel = new SalesDocumentSubItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
  }

  toApiModel(): SalesDocumentSubItemApiModel {
    return undefined;
  }
}

export class SalesDocumentModifierItemViewModel extends AbstractViewModel<SalesDocumentModifierItemApiModel> {
  name: string;
  price: number;
  quantity: number;
  modifierItemName: string;
  itemName: string;

  constructor(protected apiModel: SalesDocumentModifierItemApiModel = new SalesDocumentModifierItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.modifierItemName = this.apiModel.modifierItemName;
    this.itemName = this.apiModel.itemName;
  }

  toApiModel(): SalesDocumentModifierItemApiModel {
    return undefined;
  }
}

export class SalesDocumentBookingViewModel extends AbstractViewModel<SalesDocumentBookingApiModel> {
  bookingId: string;
  cinemaName: string;
  screeningId: string;
  screeningName: string;
  screeningDate: DateTime;
  screeningEndDate: DateTime;
  screeningStatus: number;
  screenName: string;
  screeningTimeToLeft: string;

  constructor(protected apiModel: SalesDocumentBookingApiModel = new SalesDocumentBookingApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.bookingId = this.apiModel.bookingId;
    this.cinemaName = this.apiModel.cinemaName;
    this.screeningId = this.apiModel.screeningId;
    this.screeningName = this.apiModel.screeningName;
    this.screeningDate = this.apiModel.screeningDate;
    this.screeningEndDate = this.apiModel.screeningEndDate;
    this.screeningStatus = this.apiModel.screeningStatus;
    this.screenName = this.apiModel.screenName;
    this.screeningTimeToLeft = this.apiModel.screeningTimeToLeft;
  }

  toApiModel(): SalesDocumentBookingApiModel {
    return undefined;
  }
}
