import {Expose, Type} from 'class-transformer';

export class VirtualCinemaConfig {

  @Expose()
  public enabled;

  @Expose()
  @Type(() => VirtualCinemaModel)
  public list: Array<VirtualCinemaModel> = new Array<VirtualCinemaModel>();

}

export class VirtualCinemaModel {

  @Expose()
  public name: string|null = null;

  @Expose()
  public translate = false;

  @Expose()
  @Type(() => VirtualCinemaConstraint)
  public constraints: Array<VirtualCinemaConstraint> = new Array<VirtualCinemaConstraint>();

  /**
   * Checks if input constraint matches virtual cinema
   * @param inputConstraintCollection
   */
  public checkConstraint(inputConstraintCollection: Array<object>): boolean {

    for (const virtualCinemaConstraint of this.constraints) {

      for (const inputConstraint of inputConstraintCollection) {

        const constraintCollection = Object.keys(virtualCinemaConstraint);

        const conditionCollection: Array<boolean|null> = constraintCollection.map(constraintName => {

          const input: any = inputConstraint[constraintName] || null;

          if (virtualCinemaConstraint[constraintName] !== undefined) {

            return input === virtualCinemaConstraint[constraintName];
          }

          return null;
        });

        const affectedConditionAmount: number = conditionCollection.filter(x => x !== null).length;

        const isPassed: boolean = conditionCollection.filter(x => x === true).length === affectedConditionAmount;

        if (isPassed === true) {

          return true;
        }

      }

    }

    return false;

  }

}

export class VirtualCinemaConstraint {

  @Expose()
  public screenId: string = null;

  @Expose()
  public screeningId: string = null;

}
