import {Expose, Transform, Type} from 'class-transformer';
import {DateTime} from 'luxon';
import { UserMovieGenreApiModel } from '../shared/user-movie-genre.api.model';
import { UserMoviePictureApiModel } from '../shared/user-movie-picture.api.model';
import { UserMovieRatingApiModel } from '../shared/user-movie-rating.api.model';
import { UserMovieTagApiModel } from '../shared/user-movie-tag.api.model';

export class UserMovieApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: string;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  shortTitle: string;

  @Expose()
  filmAge: string;

  @Expose()
  ageDescription: string;

  @Expose()
  ageLongDescription: string;

  @Expose()
  posters: string[];

  @Expose()
  @Type(() => UserMovieGenreApiModel)
  genres: UserMovieGenreApiModel[];

  @Expose()
  @Type(() => UserMovieRatingApiModel)
  ratings: UserMovieRatingApiModel[];

  @Expose()
  @Type(() => UserMovieTagApiModel)
  tags: UserMovieTagApiModel[];

  @Expose()
  @Type(() => UserMoviePictureApiModel)
  pictures: UserMoviePictureApiModel[];

  @Expose()
  @Transform(({value}) => (value !== null) ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  premiereDate: DateTime;
}
