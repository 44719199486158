import { AbstractViewModel } from '../../../abstract.view.model';
import { ProviderConfigurationApiModel } from '../../../api-model/payment/config/provider-configuration.api.model';

export class ProviderConfigurationViewModel extends AbstractViewModel<ProviderConfigurationApiModel> {
  url: string;
  key: string;

  constructor(protected apiModel: ProviderConfigurationApiModel = new ProviderConfigurationApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.url = this.apiModel.url_iframe || this.apiModel.payment_form_url;
    this.key = this.apiModel.public_key;
  }

  toApiModel(): ProviderConfigurationApiModel {
    return undefined;
  }
}
