import { Component, Input, SimpleChanges } from '@angular/core';
import { MovieCopyModel } from 'libs/core/src/lib/model/movie-copy.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { interval } from 'rxjs';
import { MovieScheduleViewModel } from '../../../page/screening/model/movie-schedule.view-model';

@Component({
  template: '',
})
export class ScreeningListComponent {
  @Input() movieCopyList: MovieCopyModel[];
  @Input() cinema: CinemaViewModel;
  @Input() public showOnlyGeneralAdmission = false;
  @Input() public movieScheduleCollection: Array<MovieScheduleViewModel> = new Array<MovieScheduleViewModel>();

  constructor(protected loadingService: LoadingService) {
    interval(1000).subscribe();
  }
}
