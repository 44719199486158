import {Expose} from 'class-transformer';
export class TrailerApiModel {

  @Expose()
  type: string;

  @Expose()
  lang: string;

  @Expose()
  value: string;
}
