import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class UserLoginRequestModel {
  @Expose()
  public username: string;

  @Expose()
  public password: string;

  @Expose()
  public rememberMe = false;
}
