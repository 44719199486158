import {EventEmitter, Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ZoomAbleService {

  public zoomIn$: EventEmitter<{ zoomId: string, match: boolean, scale: number|null }> = new EventEmitter<{ zoomId: string, match: boolean, scale: number|null }>();

  public zoomOut$: EventEmitter<{ zoomId: string, match: boolean, scale: number|null }> = new EventEmitter<{ zoomId: string, match: boolean, scale: number|null }>();

  public zoom$: EventEmitter<{ zoomId: string, match: boolean }> = new EventEmitter<{zoomId: string, match: boolean }>();

}
