
export enum ErrorType {

  timeBuyExpired = '103',

  paymentAlreadyStarted = '105',

  orderAlreadyPaid = '106',

  basketNotExists = '107',

  pageNotFound = '404',

  maintenanceWork = '503',

  sessionTimeout = '5000',

  noFreeSeatsForMovie = '5001',

  sessionAlreadyStarted = '5002',

  sessionAlreadyStartedChose = '10039',

  unexpectedErrorOccurred = '20000',

  unexpectedOperation = '20001',

  default = '20000',


}
