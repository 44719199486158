import {Component, Input} from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { SeatTranslatorService } from 'libs/core/src/lib/service/seat/seat-translator.service';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';

@Component({
  template: ''
})
export class ScreenSeatsSelectedListComponent {
  @Input() screen: ScreenViewModel;
  private _seatIds: string[];
  public seatsSelected: SeatViewModel[] = null;

  @Input()
  set seatIds(value: string[]) {
    this._seatIds = value;
    this.seatsSelected = new Array<SeatViewModel>();

    if (this._seatIds.length > 0) {
      const selectedSeats = ScreenSeatsSelectedListComponent.flatSeats(this.screen.pseats)
        .filter(
          (item: SeatViewModel) => this._seatIds.indexOf(item.id) !== -1
        );

      selectedSeats.forEach((elem: SeatViewModel) => {
        this.seatsSelected.push(elem);

        if (elem.groupConnectedSeats != null) {
          elem.groupConnectedSeats.forEach((celem: SeatViewModel) => {
            this.seatsSelected.push(celem);
          });
        }
      });

      const skippingRomanDigitsInRows = SeatTranslatorService.isNotRomanDigit(this.seatsSelected.map(elem => (
        elem.rowSymbol
      )));
      const skippingRomanDigitsInCols = SeatTranslatorService.isNotRomanDigit(this.seatsSelected.map(elem => (
        elem.symbol
      )));

      this.seatsSelected.forEach(elem => {
        elem.translateRow = SeatTranslatorService.translateValue(elem.rowSymbol, skippingRomanDigitsInRows);
        elem.translateCol = SeatTranslatorService.translateValue(elem.symbol, skippingRomanDigitsInCols);
      });

      this.seatsSelected = orderBy(this.seatsSelected, ['translateRow', 'translateCol']);
    }
  }

  private static flatSeats(seats) {
    return seats.flat();
  }
}
