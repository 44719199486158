import {Expose, Type} from 'class-transformer';
import { CanBeRefundItemExtraFeeApiModel } from './can-be-refund-item-extra-fee.api.model';

export class CanBeRefundItemApiModel {
  @Expose()
  itemType: number;

  @Expose()
  salesDocumentItemId: string;

  @Expose()
  itemId: string;

  @Expose()
  itemName: string;

  @Expose()
  quantity: number;

  @Expose()
  refundValue: number;

  @Expose()
  ticketMaskNumber: string;

  @Expose()
  isRefundable: boolean;

  @Expose()
  @Type(() => CanBeRefundItemExtraFeeApiModel)
  extraFeeList: CanBeRefundItemExtraFeeApiModel[];
}
